export const objNotNull = (obj) => {
    const isObject = Object.prototype.toString.call(obj) === "[object Object]";

    if (isObject) {
      return obj ? Object.keys(obj).length !== 0 : false;
    } else {
      return false;
    }
  }

export const findCurrentBadgeIndex = (totalRides, badgesList = []) => {
    let nextBadgeIndex = 0;
    for(const badge of badgesList) {
      const currIndex = badgesList.indexOf(badge);
      if(typeof badgesList[currIndex+1] === 'undefined') {
        return currIndex;
      }

      if(totalRides >= badge && totalRides < badgesList[currIndex+1]) {
        return currIndex;
      }
    }
    return nextBadgeIndex;
}