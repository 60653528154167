import config from '../../config/config';

export default [
  { name: 'Avatar-1', path: `${config.webappCdnEndpoint || ''}/avatars/Avatar-1.png` },
  { name: 'Avatar-2', path: `${config.webappCdnEndpoint || ''}/avatars/Avatar-2.png` },
  { name: 'Avatar-3', path: `${config.webappCdnEndpoint || ''}/avatars/Avatar-3.png` },
  { name: 'Avatar-4', path: `${config.webappCdnEndpoint || ''}/avatars/Avatar-4.png` },
  { name: 'Avatar-5', path: `${config.webappCdnEndpoint || ''}/avatars/Avatar-5.png` },
  { name: 'Avatar-6', path: `${config.webappCdnEndpoint || ''}/avatars/Avatar-6.png` },
];
