import React from 'react';

const ClassIcon = ({width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 2.32258C6.65531 2.32258 2.32258 6.65531 2.32258 12C2.32258 17.3447 6.65531 21.6774 12 21.6774C17.3447 21.6774 21.6774 17.3447 21.6774 12C21.6774 6.65531 17.3447 2.32258 12 2.32258ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 4.33548C12.6414 4.33548 13.1613 4.85541 13.1613 5.49677V11.2823L16.8548 13.1291C17.4285 13.4159 17.661 14.1134 17.3742 14.6871C17.0873 15.2607 16.3898 15.4933 15.8161 15.2064L11.4807 13.0387C11.0872 12.842 10.8387 12.4399 10.8387 12V5.49677C10.8387 4.85541 11.3586 4.33548 12 4.33548Z" fill="#828282"/>
        </svg>
    );
}

export default ClassIcon;