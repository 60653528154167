import React from 'react'

const WorkoutHistoryIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.14286 12C3.14286 5.92487 8.2596 1 14.5714 1C20.8833 1 26 5.92487 26 12C26 18.0751 20.8833 23 14.5714 23C9.77517 23 5.66899 20.1563 3.9736 16.125M3.14286 12L5.64286 10.2812M3.14286 12L1 9.9375M14.5714 7.28571V12L17.0204 14.3571" stroke={color !== '' ? color : '#828282'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default WorkoutHistoryIcon;