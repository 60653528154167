const domain = 'polymathlabs.co';

export default {
  domain,
  platform: 'web',
  constants: {
    PROFILE_ID_KEY: 'profileId',
  },
  subscription: {
    simulateSubscription: false,
  },
  triggerNew: 'dummyWebpackTrigger',
  mediaCdnEndpoint: process.env.REACT_APP_KATANA_MEDIA_CDN_ENDPOINT,
  webAppCdnEndpoint: process.env.REACT_APP_KATANA_WEB_APP_CDN_ENDPOINT,
  aws: {
    region: process.env.REACT_APP_AWS_REGION,
    cognitoDomain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
    cognitoIdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    cognitoUserPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
    cognitoUserPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
    cognitoHostedSigninPageUrl: process.env.REACT_APP_AWS_COGNITO_HOSTED_SIGNIN_PAGE_URL,
    cognitoRedirectSignin: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNIN,
    cognitoRedirectSignout: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNOUT,
  },
  environment: process.env.KATANA_ENVIRONMENT,
};
