import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { StripeProvider } from 'react-stripe-elements';
import Loader from 'react-loaders'

import '@fortawesome/fontawesome-free/css/all.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/portal.scss';
import Admin from './admin/index';
// Auth pages
import ConfirmSignUp from './pages/auth/confirm-sign-up';
import Login from './pages/auth/login';
import ResendCode from './pages/auth/resend-code';
import SignUp from './pages/auth/sign-up';
import ResetPassword from './pages/auth/reset-password';

// My Profile
import ProfileManagerPage from './pages/my/profile/profile-manager/profile-manager-page';
import ProfileEditorPage from './pages/my/profile/profile-editor/profile-editor-page';
import MyPage from './pages/my/my-page';

// Classes
import Dashboard from './pages/classes/dashboard-page';
import Classes from './pages/classes/classes-page';
import Leaderboard from './pages/classes/leaderboard';

// General portal
import Terms from './pages/terms';
import Unauthorized from './pages/unauthorized';
import NotFound from './pages/not-found';

import { getUserProfile, setCurrentUserRights } from './cross-platform/actions/user-actions';
import loadable from './cross-platform/common/loadable';

import AuthService from './cross-platform/services/auth-service';
import { PrivateRoute, UnauthRoute } from './helpers/route-helper';

export const Home = loadable(() => import('./pages/classes/home'), { fallback: <Loader type="ball-beat" /> });
class App extends Component {

    state = {
        authenticated: null
    }

    componentDidMount() {
        this.props.setCurrentUserRights();
        this.isAuthenticated();
        this.props.getUserProfile();

        document.title = 'Stryde';
    }

    componentDidUpdate(prevProps) {
        if (!['/sign-up', '/reset-password'].includes(this.props.location.pathname) && this.props.location.pathname !== prevProps.location.pathname) {
            this.props.setCurrentUserRights();
            this.isAuthenticated();
            this.props.getUserProfile();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.authenticated && this.props.location.pathname === '/login' && nextProps.location.pathname === '/') {
            this.props.setCurrentUserRights();
            this.props.getUserProfile();
        }
    }

    isAuthenticated = () => {
        AuthService.getCurrentSession()
            .then(session => {
                this.setState({
                    authenticated: true
                });
            }).catch(() => {
                // this.props.history.push('/login');
                this.setState({
                    authenticated: false
                })
            });
    }

    render() {
        if (this.state.authenticated === null) {
            return <Loader type="ball-beat" />;
        }

        const { authenticated } = this.state;

        return (
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_PUBLIC_KEY}>
                <div>
                    <Switch>
                        <PrivateRoute authenticated={authenticated} exact path="/" component={Home} />
                        <PrivateRoute authenticated={authenticated} path="/admin" component={Admin} />
                        <PrivateRoute authenticated={authenticated} exact path="/unauthorized" component={Unauthorized} />
                        <PrivateRoute authenticated={authenticated} exact path="/not-found" component={NotFound} />
                        <PrivateRoute authenticated={authenticated} exact path="/classes" component={Classes} />
                        <PrivateRoute authenticated={authenticated} exact path="/leaderboard" component={Leaderboard} />
                        <PrivateRoute authenticated={authenticated} path="/my" component={MyPage} />
                        <PrivateRoute authenticated={authenticated} exact path="/dashboard/:id" component={Dashboard} />
                        <PrivateRoute authenticated={authenticated} exact path="/profiles/:id/editor" component={ProfileEditorPage} />
                        <PrivateRoute authenticated={authenticated} exact path="/profiles" component={ProfileManagerPage} />
                        <PrivateRoute authenticated={authenticated} exact path="/profiles/manage" component={ProfileManagerPage} />

                        <UnauthRoute authenticated={authenticated} exact path="/unauthorized" component={Unauthorized} />
                        <UnauthRoute authenticated={authenticated} exact path="/login" component={Login} />
                        <UnauthRoute authenticated={authenticated} path="/sign-up" component={SignUp} />
                        <UnauthRoute authenticated={authenticated} path="/confirm-sign-up" component={ConfirmSignUp} />
                        <UnauthRoute authenticated={authenticated} path="/resend-code" component={ResendCode} />
                        <Route path="/terms" component={Terms} />
                        <Route path="/reset-password" component={ResetPassword} />

                        <Route render={props => (
                            <Redirect from="*" to={
                                !authenticated ? '/login' : ({
                                    pathname: `/not-found`,
                                    state: { from: props.location }
                                })
                            } />
                        )} />
                    </Switch>
                    <ToastContainer />
                </div>
            </StripeProvider>
        );
    }
}

export default connect(null, { getUserProfile, setCurrentUserRights })(withRouter(App));
