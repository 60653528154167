import React from 'react';

const AdminPageHeader = (props) => {
    return (
        <div className="admin-page-header">
            {props.children}
            <div className="admin-page-seperator-line">
                <hr />
            </div>
        </div>
    )
}

export default AdminPageHeader;