export const MUSIC_GENRE = [
  { label: 'Aternative', value: 'Aternative' },
  { label: 'Classic Rock', value: 'Classic Rock' },
  { label: 'Country', value: 'Country' },
  { label: 'Electronic', value: 'Electronic' },
  { label: 'Hip Hop', value: 'Hip Hop' },
  { label: 'Indie', value: 'Indie' },
  { label: 'Latin', value: 'Latin' },
  { label: 'Pop', value: 'Pop' },
  { label: 'R&B', value: 'R&B' },
  { label: 'Rock', value: 'Rock' },
];

export const CLASS_ACTION_TYPES = {
  ARCHIVE: 'archive',
  RESTORE: 'restore',
};

export const CLASS_STATUSES = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const STRIPE_SUBSCRIPTION_STATUSES = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  INACTIVE: 'inactive',
  TRIALING: 'trialing',
};

export const ACCOUNT_CHANGE_PASSWORD_MODES = {
  VIEW: 'view',
  EDIT: 'edit',
};

export const DATE_FORMAT = 'MM-DD-YYYY';

export const MUSIC_SERVICE_TRACK_PLAY_MODES = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  ONLINE_CACHED: 'online-cached',
};

// from the 7Digital format ID for Stryde AAC160
export const MUSIC_DEFAULT_FORMAT_ID = 56;

export const MUSIC_TEMP_TOTAL_TRACK_TIME_PLAYED = 100;

export const LEADERBOARD_MONTH_OPTION = {
  CURRENT_MONTH: 'current',
  LAST_MONTH: 'previous',
  ALL_TIME: 'all',
};

export const CONFIG_TYPES = {
  HOME: 'home',
};
