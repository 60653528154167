// eslint-disable-next-line
import uuid from 'uuid/v4';
import avatars from '../references/avatars';
import * as types from '../actions/action-types';
import config from '../../config/config';

const leaderboard = [
  {
    username: 'John',
    info: '20s New York',
    output: 111,
    averagePower: 111,
    totalEnergy: 0.5,
    owner: {
      id: uuid(),
      username: 'John',
      location: 'New York',
      avatar: avatars[0].path,
    },
  },
  {
    username: 'Jason',
    info: '40s San Francisko',
    output: 92,
    averagePower: 92,
    totalEnergy: 18,
    owner: {
      id: uuid(),
      username: 'Jason',
      location: 'San Francisko',
      avatar: avatars[1].path,
    },
  },
  {
    username: 'Lisa',
    info: '30s Los Angeles',
    output: 72,
    averagePower: 72,
    totalEnergy: 9,
    owner: {
      id: uuid(),
      username: 'Lisa',
      location: 'Los Angeles',
      avatar: avatars[2].path,
    },
  },
  {
    username: 'Mark',
    info: '20s New York',
    output: 60,
    averagePower: 60,
    totalEnergy: 7,
    owner: {
      id: uuid(),
      username: 'Mark',
      location: 'New York',
      avatar: avatars[3].path,
    },
  },
  {
    username: 'Mary',
    info: '30s Los Angeles',
    output: 45,
    averagePower: 45,
    totalEnergy: 3,
    owner: {
      id: uuid(),
      username: 'Mary',
      location: 'Los Angeles',
      avatar: avatars[4].path,
    },
  },
];

const initialState = {
  classLeaderboard: [],
  currentUserSessionLeaderboard: leaderboard.slice(),
  leaderboard: [],
  mode: null,
  openProfilePageToggle: false,
  signOutHelper: {
    errorMessage: '',
    mode: '',
  },
  signOutModal: false,
  userAvatars: avatars,
  userProfileHelper: {
    errorMessage: '',
    mode: '',
  },
  userAccount: {
    mode: '',
  },
  userCan: {
    isLoading: false,
    administer: false,
    ownsSubscription: true,
    accessSubscriptions: config.subscription.simulateSubscription,
  },
  userProfile: {
    avatar: null,
    birthday: null,
    fullName: null,
    gender: null,
    location: null,
    primary: false,
  },
  selectedProfile: {
    avatar: '',
    birthday: '',
    fullName: '',
    gender: '',
    location: '',
    primary: false,
  },
  userProfiles: [],
  userProfilePrevState: {},
  userSessionId: null,
  userSessionLeaderboard: leaderboard.slice(),
  userSessions: [],
  userSessionsByMonth: [],
  userSessionSummary: {
    chartData: {},
    workoutDetails: {
      cadence: [75, 78, 71, 82, 91, 102, 101, 92],
      output: [171, 123, 146, 132, 114, 153, 200, 187],
      avgCadence: 0,
      avgResistance: 0,
      avgOutput: 0,
      total: 0,
    },
    instructor: {},
  },
  selectedProfileId: null,
};

const extractUserAccountData = (state, userAccount) => ({
  ...state,
  userAccount,
});

// eslint-disable-next-line
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLOSE_SIGN_OUT_MODAL:
      return {
        ...state,
        signOutModal: false,
      };
    case types.OPEN_SIGN_OUT_MODAL:
      return {
        ...state,
        signOutModal: true,
      };
    case types.LOGOUT_MODE_ERROR:
      return {
        ...state,
        signOutHelper: {
          errorMessage: action.errorMessage,
          mode: action.mode,
        },
      };
    case types.LOGOUT_MODE:
      return {
        ...state,
        signOutHelper: {
          errorMessage: '',
          mode: action.mode,
        },
      };
    case types.SET_CURRENT_USER_RIGHTS:
      return {
        ...state,
        userCan: {
          ...state.userCan,
          ...action.userCan,
        },
      };
    case types.RESET_USER_PROFILE:
      return {
        ...state,
        userProfile: state.userProfilePrevState,
      };
    case types.SELECT_USER_AVATAR:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          avatar: action.avatar,
        },
      };
    case types.SELECT_USER_GENDER:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          gender: action.gender,
        },
      };
    case types.SET_USER_SESSION_ID:
      return {
        ...state,
        userSessionId: action.userSessionId,
      };
    case types.CURRENT_USER_PROFILE_RETRIEVED:
      return {
        ...state,
        userProfile: action.userProfile,
      };
    case types.SET_USER_PROFILE_PREV_STATE:
      return {
        ...state,
        userProfilePrevState: state.userProfile,
      };
    case types.SET_USER_SESSIONS:
      return {
        ...state,
        mode: action.mode,
        userSessions: action.userSessions,
        userSessionsByMonth: action.userSessionsByMonth,
        barChartData: action.barChartData,
        userProfileHelper: {
          ...state.userProfileHelper,
          mode: action.mode,
          errorMessage: action.errorMessage,
        },
      };
    case types.SET_USER_SESSION_SUMMARY:
      return {
        ...state,
        userSessionSummary: action.userSessionSummary,
      };
    case types.USER_SESSION_MODE:
      return {
        ...state,
        mode: action.mode,
      };
    case types.SET_LEADERBOARD:
      return {
        ...state,
        leaderboard: action.leaderboard,
      };

    // User profiles fetched, set the userProfiles store entry
    case types.USER_PROFILES_FETCHED:
      return {
        ...state,
        userProfiles: action.data,
      };

    case types.SET_USER_PROFILE_MODE:
      return {
        ...state,
        userProfileHelper: {
          mode: action.mode,
          errorMessage: '',
        },
      };
    case types.SET_USER_PROFILE_MODE_ERROR:
      return {
        ...state,
        userProfileHelper: {
          mode: action.mode,
          errorMessage: action.errorMessage,
        },
      };
    case types.SET_USER_SESSION_LEADERBOARD:
      return {
        ...state,
        userSessionLeaderboard: action.userSessionLeaderboard,
      };
    case types.SET_CURRENT_USER_SESSION_LEADERBOARD:
      return {
        ...state,
        currentUserSessionLeaderboard: action.currentUserSessionLeaderboard,
      };
    case types.RESET_USER_SESSION:
      return {
        ...initialState,
        userProfile: state.userProfile,
        userCan: state.userCan,
      };
    case types.USER_PROFILE_ON_CHANGE:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          [action.name]: action.value,
        },
      };
    case types.SET_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: action.userProfile,
      };
    case types.RESET_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: initialState.selectedProfile,
      };
    case types.UPDATE_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: {
          ...state.selectedProfile,
          [action.name]: action.value,
        },
      };
    case types.SET_USER_ACCOUNT_MODE:
      return {
        ...state,
        userAccount: {
          mode: action.mode,
        },
      };
    case types.USER_ACCOUNT_RETRIEVED:
      return extractUserAccountData(state, action.data);
    case types.OPEN_PROFILE_PAGE:
      return {
        ...state,
        openProfilePageToggle: !state.openProfilePageToggle,
      };
    case types.SET_SELECTED_PROFILE_ID:
      return {
        ...state,
        selectedProfileId: action.selectedProfileId,
      };
    case types.GET_USER_PROFILE_FAILED:
      console.warn('Could not get profile', action.error);
      return {
        ...state,
      };
    default:
      return state;
  }
};
