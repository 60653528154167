import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Switch, Route, Link, Redirect, withRouter,
} from 'react-router-dom';
import * as userActions from '../cross-platform/actions/user-actions';

import Navigation from './components/navigation';
import NotFound from '../pages/not-found';
import AnalyticsBrowser from './pages/analytics';
import PlaylistBrowser from './pages/class-session-playlists/PlaylistBrowser';
import PlaylistAddEdit from './pages/class-session-playlists/PlaylistAddEdit';

import ClassBrowser from './pages/classes/class-browser';
import ClassEditor from './pages/classes/class-editor';
import SessionEditor from './pages/classes/session-editor/session-editor';
import AdminDashboard from './pages/dashboard';
import HomeConfigBrowser from './pages/config/home/HomeConfigBrowser';

class Admin extends Component {
	render() {
		return (
			<div className="katana-admin">
				<Navigation />
				<div className="header">
					<Link className="ml-5 navbar-brand font-weight-bold d-none d-md-inline" to="/admin">
						<img
							className="logo mt-2"
							src="/Katana_Logo.png"
							alt="Katana Fitness"
						/>
					</Link>
					<Link to="/" className="float-right mt-3 mr-5 d-flex align-items-center text-decoration-none">
						<i className="fas fa-sign-out-alt fa-2x mr-2" />
						<span>Exit dashboard</span>
					</Link>
				</div>
				<div className="content-page">
					<div className="content">
						{this.props.userCan && this.props.userCan.administer
							&& (
								<Switch>
									<Route exact path="/admin" component={AdminDashboard} />
									<Route exact path="/admin/classes" component={ClassBrowser} />
									<Route exact path="/admin/analytics" component={AnalyticsBrowser} />
									<Route exact path="/admin/class-session/playlists" component={PlaylistBrowser} />
									<Route exact path="/admin/class-session/playlists/new" component={PlaylistAddEdit} />
									<Route exact path="/admin/class-session/playlists/edit/:id" component={PlaylistAddEdit} />
									<Route exact path="/admin/config/home" component={HomeConfigBrowser} />
									<Route exact path="/admin/classes/:classId/sessions/:sessionId" component={SessionEditor} />
									<Route path="/admin/classes/:classId" component={ClassEditor} />
									<Route exact path="/admin/not-found" component={NotFound} />
									<Route render={(props) => (
										<Redirect to={{
											pathname: '/admin/not-found',
											state: { from: props.location },
										}}
										/>
									)}
									/>
								</Switch>
							)}
						{(!this.props.userCan || !this.props.userCan.administer)
							&& (
								<Switch>
									<Route render={(props) => (
										<Redirect to={{
											pathname: '/unauthorized',
											state: { from: props.location },
										}}
										/>
									)}
									/>
								</Switch>
							)}
					</div>
				</div>
			</div>
		);
	}
}

// export default Admin;

const mapStateToProps = ({ userStore }) => ({
	...userStore,
});

export default connect(mapStateToProps, { ...userActions })(withRouter(Admin));
