import React, { useCallback, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import modes from 'references/modes';
import usePaginatedWorkoutHistoryData from 'hooks/usePaginatedWorkoutHistoryData';

import UserSessionRow from './components/UserSessionRow';

import {
  PaginationContainer,
  PaginationHeader,
  PaginationListWrapper,
  PaginationListEmpty,
  ListContainer,
  List,
  ListItem,
  ListDivider,
  ListDividerCount,
  Item,
  ItemInner,
  UserProfileHelper,
} from './styles';

export default function PaginationListWidget({ mode, userProfileHelper }) {
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data,
    total,
    lastSessionId,
    loading,
    hasMore,
    error,
  } = usePaginatedWorkoutHistoryData(pageNumber);

  const observer = useRef();
  const lastListElement = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((listItem) => {
        if (listItem[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  return (
    <PaginationContainer className="col-12 col-xl-3 col-lg-3 h-100 workouts d-xl-flex">
      <PaginationHeader className="w-100 bg-white border border-bottom-0 border-left-0 border-top-0 all-workouts py-3">
        <p className="mb-0 mx-auto text-center">{total}</p>
        <span className="d-block text-center">Total Workouts Lifetime</span>
      </PaginationHeader>
      <PaginationListWrapper
        className="overflow-auto workout-list-box bg-white pb-5"
        id="pagination-list-wrapper">
        {mode === modes.WITH_ERROR && (
          <UserProfileHelper className="d-flex align-content-center mt-6 px-2">
            <h5 className="m-auto text-center font-weight-bolder">
              {userProfileHelper.errorMessage}
            </h5>
          </UserProfileHelper>
        )}

        <ListContainer>
          <List className="workout-list">
            {(data || []).map((item, index) => (
              <ListItem key={index}>
                <ListDivider>
                  {item.month} {item.year}
                  <ListDividerCount>
                    {item.sessions.length}
                    {item.sessions.length === 1 ? 'workout' : 'workouts'}
                  </ListDividerCount>
                </ListDivider>
                {(item.sessions || []).map((session) => {
                    return session.userSessionId === lastSessionId ? (
                        <Item key={session.userSessionId} ref={lastListElement}>
                        <NavLink
                            to={`/my/exercise-history/${session.userSessionId}`}
                            activeClassName="active-workout">
                            <ItemInner className="list-group-item">
                            <UserSessionRow session={session} />
                            </ItemInner>
                        </NavLink>
                        </Item>
                    ) : (
                        <Item key={session.userSessionId}>
                        <NavLink
                            to={`/my/exercise-history/${session.userSessionId}`}
                            activeClassName="active-workout">
                            <ItemInner className="list-group-item">
                            <UserSessionRow session={session} />
                            </ItemInner>
                        </NavLink>
                        </Item>
                    )
                    }
                )}
              </ListItem>
            ))}
          </List>
          {loading && (
            <div className="pagination-loading d-block text-center p-3">
              Loading..
            </div>
          )}
          {error && (
            <div className="pagination-error d-block text-center p-3">
              Something went wrong!
            </div>
          )}
        </ListContainer>
        {total === 0 && (
          <PaginationListEmpty className="d-flex align-content-center mt-6 px-2">
            <h5 className="m-auto text-center text-light-font font-weight-bolder">
              You have not exercised yet!
            </h5>
          </PaginationListEmpty>
        )}
      </PaginationListWrapper>
    </PaginationContainer>
  );
}

PaginationListWidget.defaultProps = {
  mode: modes.BROWSE,
  userProfileHelper: {},
};

PaginationListWidget.propTypes = {
  mode: PropTypes.oneOf(Object.values(modes)),
  userProfileHelper: PropTypes.shape({
    errorMessage: PropTypes.string,
  }),
};
