import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { buildUrlFromUriWithBasePath } from '../../helpers/class-url';

class VideoThumbnailSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedThumbnailUri: props.selectedThumbnailUri,
            totalNumberOfThumbnailsToShow: props.numberOfThumbnailsPerPage,
            paginatedThumbnailsList: _.filter(props.listOfThumbnails, (item, index) => index < props.numberOfThumbnailsPerPage) // default number
        }
    }

    onThumbnailSelected = (uri) => {
        if (!uri) return;
        if (this.props.onThumbnailSelected) {
            this.props.onThumbnailSelected(uri)
        }
    }

    loadMore = () => {
        const totalNumberOfThumbnailsToShow = this.state.totalNumberOfThumbnailsToShow + this.props.numberOfThumbnailsPerPage;
        const paginatedThumbnailsList = _.filter(this.props.listOfThumbnails, (item, index) => index < totalNumberOfThumbnailsToShow)
        this.setState({
            totalNumberOfThumbnailsToShow,
            paginatedThumbnailsList
        })
    }

    render() {
        if (!this.props.listOfThumbnails || !this.props.listOfThumbnails.length === 0) {
            return (
                <div className="video-thumbnail-item">
                    <p className="thumbnail-text-description">No thumbnail generated for this session!</p>
                </div>
            )
        }

        const loadMoreCard = (
            <div className="video-thumbnail-item load-more-box" onClick={this.loadMore}>
                <p className="thumbnail-text-description">Load More</p>
            </div>
        )
        const selectedThumbnailIndex = _.findIndex(this.props.listOfThumbnails, { uri: this.props.selectedThumbnailUri });

        return (
            <div className="video-thumbnail-slider">
                <div className="video-thumbnail-wrapper">
                    {this.state.paginatedThumbnailsList.map((uriItem, index) => (
                        <div key={index} onClick={() => this.onThumbnailSelected(uriItem.uri)}
                            className={selectedThumbnailIndex === index ? 'video-thumbnail-item thumbnail-selected' : 'video-thumbnail-item'}
                            style={{ backgroundImage: `url(${buildUrlFromUriWithBasePath(uriItem.uri)})` }}>
                            {selectedThumbnailIndex === index ?
                                <i className="fas fa-check-circle"></i>
                                :
                                <i className="far fa-circle"></i>
                            }
                        </div>
                    ))}
                    {this.state.paginatedThumbnailsList.length < this.props.listOfThumbnails.length && loadMoreCard}
                </div>
            </div>
        )
    }
}

VideoThumbnailSlider.defaultProps = {
    numberOfThumbnailsPerPage: 5,
    selectedThumbnailUri: '',
    listOfThumbnails: []
}

VideoThumbnailSlider.propTypes = {
    listOfThumbnails: PropTypes.array.isRequired,
    selectedThumbnailUri: PropTypes.string,
    onThumbnailSelected: PropTypes.func,
    numberOfThumbnailsPerPage: PropTypes.number
}

export default VideoThumbnailSlider;
