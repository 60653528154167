import BaseService from './base-service';

class ClassService extends BaseService {

    fetchClasses(params) {
        const mode = (params.mode && `?mode=${params.mode}`) || '';

        return this._apiGet({
            path: '/classes' + mode
        });
    }

    fetchClassSessions() {
        return this._apiGet({
            path: '/classes/sessions'
        });
    }

    fetchActiveClassSessions() {
        return this._apiGet({
            path: '/classes/sessions/active'
        });
    }

    fetchSessions(classId) {
        return this._apiGet({
            path: `/classes/${classId}/sessions`
        });
    }

    getClass(classId) {
        return this._apiGet({
            path: `/classes/${classId}`
        });
    }

    deleteClass(classId) {
        return this._apiDelete({
            path: `/classes/${classId}`
        });
    }

    getClassSession(sessionId) {
        return this._apiGet({
            path: `/classes/sessions/${sessionId}`
        });
    }

    fetchClassSessionPlaylists() {
        return this._apiGet({
            path: `/classes/sessions/playlists`
        });
    }

    getActiveClassSessionPlaylists() {
        return this._apiGet({
            path: `/classes/sessions/playlists/active`
        });
    }

    getClassSessionPlaylist(classSessionPlaylistId) {
        return this._apiGet({
            path: `/classes/sessions/playlists/${classSessionPlaylistId}`
        });
    }

    saveClassSessionPlaylist(data) {
        const options = {
            path: `/classes/sessions/playlists/new`,
            data
        };
        return this._apiPost(options)
    }

    updateClassSessionPlaylist(classSessionsPlaylistId, data) {
        const options = {
            path: `/classes/sessions/playlists/${classSessionsPlaylistId}`,
            data
        };
        return this._apiPut(options)
    }

    deleteClassSessionPlaylist(classId) {
        const options = {
            path: `/classes/sessions/playlists/${classId}`
        }
        return this._apiDelete(options);
    }

    saveWatchedTimeForClass(classSessionId, sessionId, watchedTime) {
        return this._apiPost({
            path: `/classes/${classSessionId}/sessions/${sessionId}/watched-time`,
            data: { watchedTime }
        });
    }

    getMostPopularClassSessions() {
        return this._apiGet({
            path: `/classes/sessions/most-popular`
        });
    }

    archiveClass = (classId) => {
        const actionType = 'archive';
        const options = {
            path: `/classes?actionType=${actionType}`,
            data: { id: classId }
        }
        return this._apiPut(options);
    }

    restoreClass = (classId) => {
        const actionType = 'restore';
        const options = {
            path: `/classes?actionType=${actionType}`,
            data: { id: classId }
        }
        return this._apiPut(options);
    }

    deleteClass = (classId) => {
        const options = {
            path: `/classes/${classId}`
        }
        return this._apiDelete(options);
    }

    deleteClassSession = (classId, id) => {
        const options = {
            path: `/classes/${classId}/sessions/${id}`
        }
        return this._apiDelete(options);
    }

    publishClassSession = (session) => {
        const options = {
            path: `/classes/${session.classId}/sessions/${session.id}/published`,
            data: {}
        }
        return this._apiPut(options);
    }

    archiveClassSession = (session) => {
        const options = {
            path: `/classes/${session.classId}/sessions/${session.id}/archived`,
            data: {}
        }
        return this._apiPut(options);
    }

    saveClass = (workoutClass, params) => {
        const options = {
            data: workoutClass,
            path: `/classes` + (params && params.actionType ? `?actionType=${params.actionType}` : '')
        };

        if (workoutClass.id) return this._apiPut(options);
        return this._apiPost(options);
    }

    saveClassSession = (session) => {
        const options = {
            path: `/classes/${session.classId}/sessions`,
            data: session
        }
        if (session.id && session.id !== 'new') {
            options.path += `/${session.id}`
            return this._apiPut(options);
        }

        return this._apiPost(options);
    }

    fetchClassSessionThumbnails = (sessionId, ownerId) => {
        const options = {
            path: `/classes/sessions/${sessionId}/thumbnails?ownerId=${ownerId}`
        }

        return this._apiGet(options);
    }

    getFeaturedClassSession = () => {
        const options = {
            path: `/classes/sessions/featured`
        }

        return this._apiGet(options);
    }

    setFeaturedClassSession = (sessionId) => {
        const options = {
            path: `/classes/sessions/${sessionId}/featured`
        }

        return this._apiPost(options);
    }

    setFavoriteClassSession = (classSessionId, data) => {
        const options = {
            path: `/classes/sessions/${classSessionId}/favorite`,
            data
        };

        return this._apiPost(options);
    }

    saveClassSessionFeedback = (data) => {
        const options = {
            path: `/users/surveys`,
            data
        }

        return this._apiPost(options);
    }

    getHomeConfigurationSettings() {
        return this._apiGet({
            path: `/config/home/data`
        });
    }
}

export default new ClassService();