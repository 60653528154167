import * as types from '../actions/action-types';

const initialState = {
  simulation: {
    resistance: 0,
    cadence: 0,
    power: 0,
    energy: 0,
  },
  simulationRunning: false,
  simulationProcessInSeconds: 0,
  simulationProcessInMinutes: 0,
  simulations: [],
  savedSimulationMetrics: [],
};

export const simulationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.START_SIMULATION:
      return {
        ...state,
        simulationRunning: true,
      };
    case types.STOP_SIMULATION:
      return {
        ...state,
        simulationRunning: false,
      };
    case types.UPDATE_SIMULATION:
      return {
        ...state,
        simulation: action.simulation,
        simulations: [...state.simulations, action.simulation],
        simulationProcessInSeconds: action.simulationProcessInSeconds,
      };
    case types.SAVED_SIMULATION_METRICS:
      return {
        ...state,
        savedSimulationMetrics: action.metrics,
      };
    case types.RESET_SIMULATION:
      return initialState;
    default:
      return state;
  }
};
