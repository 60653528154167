import React from 'react';
import classService from '../../services/class-service';
import {FREE_RIDE_CLASS_SESSION_ID} from '../../constants/constants';

class Classes extends React.Component {
    state = {
        classSessions: [],
        classMostPopularSessions: [],
        instructors: [],
        musicGenres: [],
        sessionLength: [],
        selectedStudio: '',
        selectedInstructor: '',
        selectedMusicGenre: '',
        selectedSessionLength: '',
        selectedWeights: null,
        showFavoritesOnly: false,
        studioNames: [],
        weights: []
    };

    componentDidMount() {
        this.setClassesData();
        this.setMostPopularClassesData();
    }

    setClassesData = async () => {
        classService.fetchClassSessions()
            .then(data => {
                const classSessions = data.filter((classSession) => classSession.id !== FREE_RIDE_CLASS_SESSION_ID);
                const instructors = [];
                const musicGenres = [];
                const sessionLength = [];
                const studioNames = [];

                (classSessions || []).sort((a, b) => new Date(b.sessionDateTime) - new Date(a.sessionDateTime)).forEach(element => {
                    //Check for undefined values
                    element.instructor.fullName && instructors.push(element.instructor.fullName);
                    element.musicType && musicGenres.push(element.musicType);
                    element.duration && sessionLength.push(element.duration);
                    element.studioName && studioNames.push(element.studioName);
                });

                this.setState({
                    classSessions,
                    instructors: [...new Set(instructors)].sort().map(element => { return { label: element, value: element } }),
                    musicGenres: [...new Set(musicGenres)].sort().map(element => { return { label: element, value: element } }),
                    sessionLength: [...new Set(sessionLength)].sort((a, b) => a - b).map(element => { return { label: element, value: element } }),
                    studioNames: [...new Set(studioNames)].sort().map(element => { return { label: element, value: element } }),
                    weights: [
                        {
                            "label": "With weights",
                            "value": true
                        },
                        {
                            "label": "Without weights",
                            "value": false
                        }
                    ]
                });
            })
    }

    setMostPopularClassesData = async () => {
        classService.getMostPopularClassSessions()
            .then(data => {
                this.setState({
                    classMostPopularSessions: data
                });
            });
    }

    onClearFilters = () => {
        this.setState({
            selectedStudio: '',
            selectedInstructor: '',
            selectedMusicGenre: '',
            selectedSessionLength: '',
            selectedWeights: null,
            showFavoritesOnly: false
        });
    }

    onClassLengthMultipleSelect = (selectedSessionLength) => {
        const classLengths = selectedSessionLength.map((classLength) => classLength.value);
        this.setState({
            selectedSessionLength: classLengths.length > 0 ? classLengths : []
        });
    }

    onClassLengthSelect = (selectedSessionLength) => {
        this.setState({
            selectedSessionLength: selectedSessionLength ? selectedSessionLength.value : ''
        });
    }

    onInstructorMultipleSelect = (selectedInstructor) => {
        const instructorNames = selectedInstructor.map((instructor) => instructor.value);
        this.setState({
            selectedInstructor: selectedInstructor.length > 0 ? instructorNames : []
        });
    }

    onInstructorSelect = (selectedInstructor) => {
        this.setState({
            selectedInstructor: selectedInstructor ? selectedInstructor.value : ''
        });
    }
    
    onMusicGenreMultipleSelect = (selectedMusicGenre) => {
        const genreNames = selectedMusicGenre.map((genre) => genre.value);
        this.setState({
            selectedMusicGenre: selectedMusicGenre.length > 0 ? genreNames : []
        });
    }

    onMusicGenreSelect = (selectedMusicGenre) => {
        this.setState({
            selectedMusicGenre: selectedMusicGenre ? selectedMusicGenre.value : ''
        });
    }

    onStudioSelect = (selectedStudio) => {
        this.setState({
            selectedStudio: selectedStudio ? selectedStudio.value : ''
        });
    }

    onWeightsSelect = (selectedWeights) => {
        this.setState({
            selectedWeights: selectedWeights.value != null ? selectedWeights.value : null
        });
    }

    onFavoritesCheck = () => {
       if(!this.state.showFavoritesOnly === true) {
           const classSessions = this.state.classSessions.slice().filter(classSession => {
                if(classSession.favorite) {
                    return classSession;
                }
           });
           this.setState({
               classSessions
           });
       } else {
            this.setClassesData();
       }
       this.setState({
            showFavoritesOnly: !this.state.showFavoritesOnly
       });
    }
}

export default Classes;