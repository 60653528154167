import React from 'react';

const MileIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M3 3H17.4474C19.3804 3 20.9474 4.567 20.9474 6.5C20.9474 8.433 19.3804 10 17.4474 10H6.55263C4.61964 10 3.05263 11.567 3.05263 13.5C3.05263 15.433 4.61964 17 6.55263 17H21M3 3C3 3.55228 3.44772 4 4 4C4.55228 4 5 3.55228 5 3C5 2.44772 4.55228 2 4 2C3.44772 2 3 2.44772 3 3ZM21 17C21 17.5523 20.5523 18 20 18C19.4477 18 19 17.5523 19 17C19 16.4477 19.4477 16 20 16C20.5523 16 21 16.4477 21 17Z" stroke={color !== '' ? color : 'white'} strokeWidth="2" strokeLinecap="round"/>
            </g>
        </svg>
    );
}

export default MileIcon;