import React from 'react';
import moment from 'moment';
import { STRIPE_SUBSCRIPTION_STATUSES, DATE_FORMAT } from '../../../cross-platform/references/constants';

const ProductSection = (props) => {
    const ActiveProduct = (
        <React.Fragment>
            <h4>Thank you for being a member!</h4>
            <p className="text-center mb-2 mt-2 stripe-subscription-row-info">Subscribe since: &nbsp;
                <span className="value">{
                    moment.unix(props.subscription.current_period_start).format(DATE_FORMAT)}</span>
            </p>
            <p className="text-center mb-2 stripe-subscription-row-info">Subscription Status: &nbsp;
                <span className="stripe-subscription-status value">{props.subscription.status}</span>
            </p>
            <p className="text-center mb-2 stripe-subscription-row-info">Next payment amount: &nbsp;
                <span className="value">{props._parseAmount(props.subscription)}</span>
            </p>
            <p className="text-center mb-2 stripe-subscription-row-info">Date of next scheduled payment: &nbsp;
                <span className="value">{
                    moment.unix(props.subscription.current_period_end).format(DATE_FORMAT)}</span>
            </p>
            {(props.subscriptionStatus === STRIPE_SUBSCRIPTION_STATUSES.ACTIVE ||
                props.subscriptionStatus === STRIPE_SUBSCRIPTION_STATUSES.TRIALING) ?
                <button disabled={props.isCancelingSubscription} onClick={props._openCancelSubscriptionDialog}
                    className="btn btn-outline-primary font-weight-bolder mt-3 d-md-block">
                    {props.isCancelingSubscription ? 'Canceling Subscription ...' : 'Cancel Subscription'}
                </button>
                :
                <p className="text-center mt-4">You have canceled the subscription.</p>
            }
        </React.Fragment>
    )
    const BuyProduct = (
        <div className="text-center">
            <h4 className="mb-1 p-0 ">Start Your Membership to Ride</h4>
            <h4 className="mt-1">With Top Studios</h4>
            <div>
                <h3 className="mt-5 mb-5">$29.99</h3>
                <p>Plus tax per month</p>
            </div>
            <p className="mt-4 mb-3">Cancel your Membership at any time.</p>
        </div>
    )

    switch (props.subscriptionActive) {
        case STRIPE_SUBSCRIPTION_STATUSES.ACTIVE:
            return ActiveProduct;
        case STRIPE_SUBSCRIPTION_STATUSES.CANCELED:
            return BuyProduct;
        default:
            return ActiveProduct;
    }
}

export default ProductSection;