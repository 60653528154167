import styled from 'styled-components';

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const CancellationDescription = styled.label`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.textColor ? props.textColor : '#828282')};
  text-align: center;
  font-weight: 500;
`;

export const SubscriptionCanellationDropDownWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f2f2f2;
  height: 86px;
  width: 494px;
  border-radius: 14px;
  justify-content: space-between;
  border: none;
  margin-top: 36px;
`;

export const SelectedCancellationReason = styled.label`
  font-size: 20px;
  line-height: 30px;
  color: ${(props) => (props.textColor ? props.textColor : '#828282')};
  text-align: left;
  font-weight: 500;
  padding-left: 20px;
`;

export const SelectedReasonDownArrow = styled.img`
    width: 20px;
    height: 10px;
    margin-right: 30px;
`;
