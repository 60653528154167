import React from 'react'

const ShowIcon = ({ width = 34, height = 22, color = 'white' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
            <path d="M17 1C7 1 3 10.001 3 10.001C3 10.001 7 19 17 19C27 19 31 10.001 31 10.001C31 10.001 27 1 17 1Z" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M17 15.0011C19.7614 15.0011 22 12.7625 22 10.0011C22 7.23963 19.7614 5.00105 17 5.00105C14.2386 5.00105 12 7.23963 12 10.0011C12 12.7625 14.2386 15.0011 17 15.0011Z" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            </g>
            <defs>
            <filter id="filter0_d" x="0.905273" y="0" width="32.1886" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            </defs>
        </svg>
    );
}

export default ShowIcon;