import * as types from './action-types';
import classService from '../services/class-service';
import {FREE_RIDE_CLASS_SESSION_ID} from '../constants/constants';

export const setClass = (data) => ({
  type: types.SET_CLASS, class: data,
});

export const fetchClasses = () => (dispatch) => {
  classService.fetchClasses()
    .then((data) => {
      dispatch({ type: types.SET_CLASSES, classes: data });
    });
};

export const setLastUpdatedSessions = () => (dispatch) => {
  dispatch({
    type: types.SET_LAST_UPDATED_SESSIONS,
  });
};

export const setClassSessionsRefreshing = (isRefreshing) => (dispatch) => {
  dispatch({
    type: types.SET_CLASS_SESSIONS_REFRESHING,
    isRefreshing,
  });
};

export const fetchClassSessions = () => (dispatch) => {
  dispatch(setClassSessionsRefreshing(true));
  return classService.fetchClassSessions()
    .then((data) => {
      const classSessions = data.filter((classSession) => classSession.id !== FREE_RIDE_CLASS_SESSION_ID);
      const studioNames = [];
      const instructors = [];
      const sessionLength = [];
      const musicGenres = [];

      (classSessions || []).forEach((element) => {
        studioNames.push(element.studioName);
        instructors.push(element.instructor.fullName);
        sessionLength.push(parseInt(element.duration, 10));
        musicGenres.push(element.musicType);
      });

      dispatch({
        type: types.SET_CLASS_SESSIONS,
        classSessions: classSessions // TODO Temporary front-end fix (Needs to be handled in the backend)
          .map((item) => ({
            ...item,
            suggestedRank: parseInt(item.suggestedRank, 10),
            duration: parseInt(item.duration, 10),
          }))
          .sort((a, b) => new Date(b.sessionDateTime) - new Date(a.sessionDateTime)),
        studioNames: [...new Set(studioNames)]
          .map((element) => ({ label: element, value: element })),
        instructors: [...new Set(instructors)]
          .map((element) => ({ label: element, value: element })),
        sessionLength: [...new Set(sessionLength)]
          .map((element) => ({ label: `${element}`, value: element })),
        musicGenres: [...new Set(musicGenres)]
          .map((element) => ({ label: element, value: element })),
        weights: [
          {
            label: 'With weights',
            value: true,
          },
          {
            label: 'Without weights',
            value: false,
          },
        ],
      });
      dispatch(setClassSessionsRefreshing(false));
      Promise.resolve();
    });
};

export const getClass = (id) => (dispatch) => {
  classService.getClass(id)
    .then((data) => {
      dispatch(setClass(data));
    });
};

export const getClassSession = (id) => (dispatch) => classService.getClassSession(id)
  .then((data) => {
    console.log('Session data: ', data);
    dispatch({ type: types.SET_CLASS_SESSION, classSession: data });
    return data;
  });

export const setFilteredClassSessions = (filteredClasses) => (dispatch) => {
  dispatch({
    type: types.SET_FILTERED_CLASS_SESSIONS,
    filteredClassSessions: filteredClasses,
  });
  Promise.resolve();
};

export const setFilters = (filters) => (dispatch) => {
  dispatch({
    type: types.SET_FILTERS,
    filters,
  });
  Promise.resolve();
};

export const getMostPopularClassSessions = () => (dispatch) => classService
  .getMostPopularClassSessions()
  .then((data) => {
    dispatch({
      type: types.SET_CLASS_MOST_POPULAR_SESSIONS,
      // TODO Temporary front-end fix (Needs to be handled in the backend)
      classMostPopularSessions: data.map((item) => (
        { ...item, suggestedRank: parseInt(item.suggestedRank, 10) }
      ))
        .sort((a, b) => new Date(b.sessionDateTime) - new Date(a.sessionDateTime)),
    });
  });

export const getHomeConfigurationData = () => (dispatch) => classService
  .getHomeConfigurationSettings()
  .then((config) => {
    dispatch({
      type: types.SET_HOME_CONFIGURATION,
      config,
    });
  });

export const toggleClassSessionFavorite = (classSessionId, isFavorite) => (dispatch) => {
  dispatch({
    type: types.SET_FAVORITE_CLASS_SESSION,
    payload: { classSessionId, isFavorite },
  });
};

export const setClassSessionFavorite = (classSessionId, isFavorite) => (dispatch) => classService
  .setFavoriteClassSession(classSessionId, { isFavorite })
  .catch((error) => {
    console.log('setClassSessionFavorite_ERROR', { error });
    dispatch({
      type: types.SET_FAVORITE_CLASS_SESSION_ERROR,
      payload: { classSessionId, isFavorite },
    });
    return types.SET_FAVORITE_CLASS_SESSION_ERROR;
  });

export const resetClassSession = () => ({
  type: types.RESET_CLASS_SESSION,
});

export const getFeaturedClassSession = () => async (dispatch, getState) => {
  const { classStore = [] } = getState();
  const { classSessions = [] } = classStore;

  classService.getFeaturedClassSession().then((data) => {
    const { classSesssionId = '' } = data;

    const featuredClassSession = classSessions
      .filter((classSession) => (classSession.id === classSesssionId))
      .map((item) => ({
        ...item,
        suggestedRank: parseInt(item.suggestedRank, 10),
        duration: parseInt(item.duration, 10),
      }))
      .shift();
    if (!featuredClassSession) {
      console.warn("action/getFeaturedClassSession -> feature class session not found!");
    }
    dispatch({
      type: types.SET_FEATURED_CLASS_SESSION,
      featuredClassSession,
    });
  });
};

export const saveClassSessionFeedback = (feedbackData) => async () => classService
  .saveClassSessionFeedback(feedbackData);
