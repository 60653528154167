import React from 'react';

const CloseIcon = ({ width, height, color = ''}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 2L2 22M2 2L22 22" stroke={color !== '' ? color : '#828282'} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default CloseIcon;
