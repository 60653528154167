import React from 'react';
import modes from '../../references/modes';
import authService from '../../services/auth-service';
import accountService from '../../services/accountService';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMessage: '',
      mode: modes.BROWSE,
      password: ''
    }
  }

  _signIn = () => {
    const { email, password } = this.state;
    this.setState({
      mode: modes.FETCHING
    });
    return authService.authenticate({ username: email.trim().toLowerCase(), password })
      .then(() => {
        //Set default;
        this.setState({ mode: modes.WITH_RESULTS });
        return authService.setUserProfileId();
      })
      .then(() => {
        return accountService.getUserProfile();
      })
      .then((userProfile) => {
        //Set Default profile ID
        return authService.setUserProfileId(userProfile.id);
      })
      .catch((error) => {
        let errorMessage = '';
        switch (error.code) {
          case 'NotAuthorizedException':
            if (error.message.includes("Incorrect username or password")) {
              errorMessage = "Incorrect email or password";
            } else {
              errorMessage = error.message;
            }
            break;
          case 'InvalidParameterException':
            errorMessage = error.message.includes('Invalid email address format')
              ? 'Invalid email address format.'
              : 'Password should have 6 or more characters';
            break;
          case 'UserNotFoundException':
            errorMessage = "User does not exist";
            break;
          case 'UserLambdaValidationException':
            if (error.message.indexOf('User is not invited') !== -1) {
              errorMessage = 'User is not invited to Stryde';
            } else {
              errorMessage = 'Internal server error';
              console.log("Error", error.message);
            }
            break;
          case 'UsernameExistsException':
            errorMessage = 'Account with this email already exists';
            break;
          default:
            errorMessage = 'Failed to sign up';
            break;
        };
        this.setState({
          errorMessage,
          mode: modes.WITH_ERROR
        })
        return Promise.reject();
      });
  }
}
export default SignIn;