import React from 'react';
import PropTypes from 'prop-types';

const MESSAGE_TYPES = {
    ERROR: "error",
    WARNING: "warning"
}

const MessageBox = (props) => {
    switch (props.messageType) {
        case MESSAGE_TYPES.ERROR:
            return <div className="alert alert-danger alert-dismissible fade show mb-0"><strong>Error:</strong> {props.message}</div>
        case MESSAGE_TYPES.WARNING:
            return <div className="alert alert-warning alert-dismissible fade show mb-0"><strong>Warning:</strong> {props.message}</div>
        default:
            return <div className="alert alert-info alert-dismissible fade show mb-0"><strong>Info:</strong> {props.message}</div>
    }
}

MessageBox.propTypes = {
    messageType: PropTypes.string,
    message: PropTypes.string.isRequired
}

export default MessageBox;