import React from 'react';
import Subscription from '../../../cross-platform/containers/my/account/subscription';
import { connect } from "react-redux";

import { toast } from 'react-toastify';
import moment from 'moment';
import * as userActions from '../../../cross-platform/actions/user-actions';
import accountService from '../../../cross-platform/services/accountService';
import authService from '../../../cross-platform/services/auth-service';
import userService from '../../../cross-platform/services/user-service';

import { DATE_FORMAT } from '../../../cross-platform/references/constants';
import Breadcrumbs from '../../../admin/components/breadcrumbs';
import MessageBox from '../../../admin/components/common/message-box';
import ConfirmationDialog from '../../../components/confirm-dialog';
import modes from '../../../cross-platform/references/modes';
import CardForm from './_subscription-card-form';
import ProductSection from './_subscription-product-section';
import SubscriptionCancellationDialog from './subscription-cancellation-dialog-content/SubscriptionCancellationDialogContent.jsx';

const crumbs = [{
    title: 'My Account',
    path: '/my/account'
}, {
    title: 'Subscription Management'
}]

class SubscriptionPage extends Subscription {
    state = {
        ...this.state,
        showCancelSubscriptionDialog: false,
        showCancellationReasonModal: false,
        selectedCancellationReason: 'na',
    };
    cancellationReasonChanged = (selectedOption) => {
        this.setState({
            selectedCancellationReason: selectedOption,
        });
    };

    _tryUpdateCard = (token) => {
        const $this = this;

        return accountService.updateDefaultSubscriptionCard(token.id)
            .then((updatedUserAccount) => {
                this.props.setUserAccount(updatedUserAccount);
                toast.success("Successfully updated cred card infos!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                $this.setState({
                    error: '',
                    isSavingSubscription: false
                });
            })
            .catch(error => {
                console.log('Failed to update cred card infos', error);
                $this.setState({
                    error: (error && error.message) || 'Failed to update cred card infos',
                    isSavingSubscription: false
                });
            })
    }

    _trySubscribe = (token) => {
        const $this = this;

        const queryParams = new URLSearchParams(this.props.location.search);
        const redirectTo = queryParams.get('redirectTo');
        let tokenLevelExecution = false;
        accountService.createSubscription(token.id)
            .then(userAccount => {
                toast.success("Subscribed successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                $this.props.setUserAccount(userAccount);
                // Refresh user token with subscription changes
                return authService.getCurrentAuthenticatedUser(true)

            })
            .then(() => {
                tokenLevelExecution = true;
                // set new user claim to local storage
                return $this.props.setCurrentUserRights();
            })
            .then(() => {
                if (redirectTo) return $this.props.history.push(redirectTo);
                $this.props.history.push('/my/account');
            })
            .catch(error => {
                if (tokenLevelExecution) {
                    return $this.setState({
                        isSavingSubscription: false,
                        error: { message: 'Subscription was successful but we couldn\'t updated profile. \n please logout and then login to activate subscription.' }
                    })
                }

                $this.setState({ error: error.message, isSavingSubscription: false });
            })
    }

    render = () => {
        return (
            <>
            <div className="subscription col-12 col-xl-10">
                <div className="row m-0">
                    <div className="col-14">
                        <div className="row m-2">
                            <div className="col-12 mt-3">
                                <ul className="breadcrumb-nav m-0 p-0">
                                    <Breadcrumbs crumbs={crumbs} root={''}></Breadcrumbs>
                                </ul>
                            </div>
                            <div className="col-12 mb-3 mt-2">
                                <h2>Subscription Management</h2>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-12 subscription-content">
                                {this.state.error && <div className="pb-3"><MessageBox messageType="error" message={this.state.error} /></div>}
                                {this.props.userAccount.mode === modes.FETCHING ?
                                    <div className="bg-white p-4 h-100 d-flex flex-column justify-content-center align-items-center">
                                        <p>Loading subscription data...</p>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className="bg-white h-100">

                                            <div className="col d-flex p-4 flex-column justify-content-center align-items-center">
                                                <ProductSection 
                                                    {...this.props}
                                                    {...this.state}
                                                    _openCancelSubscriptionDialog={this._openCancelSubscriptionDialog}
                                                    _parseAmount={this._parseAmount}
                                                    {...this.state} 
                                                />
                                            </div>
                                            <div className="border-left border-light-gray"></div>
                                            <div className="col d-flex">
                                                {this.props.userAccount.mode !== modes.FETCHING && !this.state.subscriptionActive ?
                                                    <div className="mt-2 col flex-shrink">
                                                        <h4>Pay with card</h4>
                                                        <p className="mb-1">Credit Card Number</p>
                                                        <CardForm disabledSubmitButton={this.state.isSavingSubscription || this.state.isCancelingSubscription}
                                                            onSubmitPayment={this._trySubscribe}
                                                            isSavingSubscription={this._isSavingSubscription}
                                                            isSaving={this.state.isSavingSubscription}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="mt-2 col flex-shrink">
                                                        <h4>Update your card</h4>
                                                        <p className="mb-1">Credit Card Number</p>
                                                        <CardForm
                                                            disabledSubmitButton={this.state.isSavingSubscription || this.state.isCancelingSubscription}
                                                            submitButtonText={'Update'} onSubmitPayment={this._tryUpdateCard}
                                                            isSavingSubscription={this._isSavingSubscription}
                                                            isSaving={this.state.isSavingSubscription}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="border-left border-light-gray my-5"></div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                {/* <ConfirmationDialog
                    title='Confirm Cancellation'
                    showDialog={this.state.showCancelSubscriptionDialog}
                    onCancel={this._hideCancelSubscriptionDialog}
                    onConfirm={this.cancelSubscription}
                    onCancelTextButton={'No'}
                    onSaving={this.state.isCancelingSubscription}
                    onConfrimTextButton={'Yes'}>
                    <div>
                        <p className="stripe-subscription-row-info m-0 mb-1">Are you sure you want to cancel subsription?</p>
                        <p className="stripe-subscription-row-info m-0 mb-1">You will have access to the subscription until the</p>
                        <p className="stripe-subscription-row-info mb-4">billing cycle end date &nbsp;
                            {this.state.subscription && this.state.subscription.current_period_start &&
                                <span className="value">
                                    ({moment.unix(this.state.subscription.current_period_start).format(DATE_FORMAT)})
                                </span>}
                        </p>
                    </div>
                </ConfirmationDialog> */}
            </div>
            <SubscriptionCancellationDialog
                showModal={this.state.showCancelSubscriptionDialog}
                showCancellationReasonModal={this.state.showCancellationReasonModal}
                dontCancelHandler={this._hideCancelSubscriptionDialog}
                cancelSubscriptionHandler={this.cancelSubscription}
                handleCancellationReasonVisibilityChange={() => {
                    this.setState((prevState) => ({
                        showCancellationReasonModal:
                            !prevState.showCancellationReasonModal,
                    }));
                }}
                selectedCancellationReason={this.state.selectedCancellationReason}
                cancellationReasonChanged={this.cancellationReasonChanged}
            />
        </>
        );
    }

    cancelSubscription = async () => {
        const { selectedCancellationReason } = this.state;
        try {
            await this._cancelSubscription();
            if(selectedCancellationReason && selectedCancellationReason !== 'na') {
                await userService.saveUserCancellationFeedback(selectedCancellationReason);
                return this.props.history.push('/my/account');
            }
        } catch(error) {
            console.error('Failed to save cancellation feedback:', error);
        }
    }
}

const mapStateToProps = ({ userStore }) => {
    return {
        ...userStore
    };
};

export default connect(mapStateToProps, { ...userActions })(SubscriptionPage);