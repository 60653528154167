import React from 'react';

const LogoutIcon = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9999 12.9999C14.4469 12.9999 13.9999 13.448 13.9999 13.9999V18C13.9999 18.551 13.5519 18.9999 12.9998 18.9999H9.99984V3.99994C9.99984 3.14593 9.45584 2.38293 8.63792 2.09894L8.34184 1.99988H12.9998C13.5519 1.99988 13.9999 2.44885 13.9999 3V6C13.9999 6.55188 14.4469 6.99994 14.9999 6.99994C15.5528 6.99994 15.9998 6.55188 15.9998 6V3C15.9998 1.34601 14.6538 0 12.9998 0H2.24998C2.21189 0 2.18003 0.0170288 2.14305 0.0219726C2.09489 0.0179443 2.04893 0 2.00004 0C0.897025 0 0 0.896851 0 1.99988V19.9999C0 20.8539 0.544001 21.6169 1.36193 21.9009L7.38 23.907C7.58397 23.97 7.78685 24 7.99998 24C9.103 24 9.99984 23.103 9.99984 21.9999V21H12.9998C14.6538 21 15.9998 19.654 15.9998 18V13.9999C15.9998 13.448 15.5528 12.9999 14.9999 12.9999Z" fill="#EB5757"/>
        <path d="M23.7068 9.29297L19.7067 5.29303C19.4209 5.00702 18.9908 4.92096 18.6169 5.07587C18.2439 5.23096 17.9998 5.59589 17.9998 6V9H13.9999C13.4479 9 12.9998 9.44788 12.9998 9.99994C12.9998 10.552 13.4479 10.9999 13.9999 10.9999H17.9998V13.9999C17.9998 14.404 18.2439 14.7689 18.6169 14.924C18.9908 15.0789 19.4209 14.9929 19.7067 14.707L23.7068 10.7069C24.0977 10.316 24.0977 9.6839 23.7068 9.29297Z" fill="#EB5757"/>
        </svg>
    );
}

export default LogoutIcon;