import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const CustomDropdownOption = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps}>
      {children}
    </components.Option>
  );
};

CustomDropdownOption.propTypes = {
  innerProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomDropdownOption;
