import React from 'react';
import PropTypes from 'prop-types';
import {
  CancellationButtonsWrapper,
  DontCancelButton,
  CancelButton,
} from '../elements/SubscriptionCancellationButtonElements';

const CacellationButtons = ({
  dontCancelHandler,
  cancelSubscriptionHandler,
  cancelButtonEnabled,
}) => (
  <CancellationButtonsWrapper>
    <DontCancelButton
      onClick={dontCancelHandler}
    >
      Don&apos;t Cancel
    </DontCancelButton>
    <CancelButton
      onClick={cancelSubscriptionHandler}
      isEnabled={cancelButtonEnabled}
    >
      Cancel
    </CancelButton>
  </CancellationButtonsWrapper>
);

export default CacellationButtons;

CacellationButtons.propTypes = {
  dontCancelHandler: PropTypes.func.isRequired,
  cancelSubscriptionHandler: PropTypes.func.isRequired,
  cancelButtonEnabled: PropTypes.bool.isRequired,
};
