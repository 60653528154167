import React from 'react';
import PropTypes from 'prop-types';
import cancellationReasonArray from './cancellation-reason-array';
import CancellationButtons from './components/SubscriptionCacellationButtons';
import CancellationBody from './components/SubscriptionCancellationBody';
import CancellationHeader from './components/SubscriptionCancellationHeader';
import {
  CancellationReasonItemWrapper,
  CancellationReasonItem,
  CancellationDialogModal,
  CancellationModalInnerWrapper,
  CancellationReasonListModal,
  CancellationReasonListInnerWrapper,
} from './elements/SubscriptionCancellationDialogModalElements';

const SubscriptionCancellationDialog = ({
  showModal,
  showCancellationReasonModal,
  dontCancelHandler,
  cancelSubscriptionHandler,
  handleCancellationReasonVisibilityChange,
  selectedCancellationReason,
  cancellationReasonChanged,
}) => (
  <CancellationDialogModal show={showModal}>
    <CancellationReasonListModal show={showCancellationReasonModal}>
      <CancellationReasonListInnerWrapper>
        {cancellationReasonArray.map((item) => (
          <CancellationReasonItemWrapper
            key={item.value}
            onClick={() => {
              cancellationReasonChanged(item.value);
              handleCancellationReasonVisibilityChange();
            }}
          >
            <CancellationReasonItem>
              {item.reason}
            </CancellationReasonItem>
          </CancellationReasonItemWrapper>
        ))}
      </CancellationReasonListInnerWrapper>
    </CancellationReasonListModal>

    <CancellationModalInnerWrapper>
      <CancellationHeader dontCancelHandler={dontCancelHandler} />
      <CancellationBody
        handleCancellationReasonVisibilityChange={handleCancellationReasonVisibilityChange}
        selectedCancellationReason={selectedCancellationReason}
      />
      <CancellationButtons
        dontCancelHandler={dontCancelHandler}
        cancelSubscriptionHandler={cancelSubscriptionHandler}
        cancelButtonEnabled={selectedCancellationReason !== 'na'}
      />
    </CancellationModalInnerWrapper>
  </CancellationDialogModal>
);

export default SubscriptionCancellationDialog;

SubscriptionCancellationDialog.propTypes = {
  showModal: PropTypes.bool.isRequired,
  showCancellationReasonModal: PropTypes.bool.isRequired,
  dontCancelHandler: PropTypes.func.isRequired,
  cancelSubscriptionHandler: PropTypes.func.isRequired,
  handleCancellationReasonVisibilityChange: PropTypes.func.isRequired,
  selectedCancellationReason: PropTypes.string.isRequired,
  cancellationReasonChanged: PropTypes.func.isRequired,
};
