import React from 'react';
import PropTypes from 'prop-types';
import IcDownArrow from 'images/ic_down_arrow.svg';
import {
  SelectedReasonDownArrow,
  SelectedCancellationReason,
  SubscriptionCanellationDropDownWrapper,
  BodyWrapper,
  CancellationDescription,
} from '../elements/SubscriptionCancellationBodyElements';
import cancellationReasonArray from '../cancellation-reason-array';

const CancellationBody = ({
  handleCancellationReasonVisibilityChange,
  selectedCancellationReason,
}) => (
  <BodyWrapper>
    <CancellationDescription textColor="#828282">
      We&apos;re here to help if you&apos;re having any issues.
      Call
      {' '}
      <CancellationDescription
        textColor="#FCB333"
      >
        1-888-999-1234
      </CancellationDescription>
      {' '}
      or email
      {' '}
      <CancellationDescription
        textColor="#FCB333"
      >
        support@strydebike.com
      </CancellationDescription>
    </CancellationDescription>
    <SubscriptionCanellationDropDownWrapper
      onClick={handleCancellationReasonVisibilityChange}
    >
      <SelectedCancellationReason>
        {selectedCancellationReason === 'na'
          ? 'Choose a reason for cancelling'
          : cancellationReasonArray
            .filter((item) => item.value === selectedCancellationReason)[0].reason}
      </SelectedCancellationReason>
      <SelectedReasonDownArrow src={IcDownArrow} />
    </SubscriptionCanellationDropDownWrapper>

  </BodyWrapper>
);

export default CancellationBody;

CancellationBody.propTypes = {
  handleCancellationReasonVisibilityChange: PropTypes.func.isRequired,
  selectedCancellationReason: PropTypes.string.isRequired,
};
