import dayjs from 'dayjs';
import { useState, useEffect, useRef } from 'react';
import analyticsService from 'services/analytics-service';

export default function usePaginatedWorkoutHistoryData(currentPage) {
  const isMounted = useRef(true);
  const perPage = 20;
  const [total, setTotal] = useState(0);
  const [lastSessionId, setLastSessionId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allSessions, setAllSessions] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  function transformSessions(sessions) {
    const uniqueMonths = [];
    const overallSessions = [];
    const overallSessionDates = [];

    sessions.sort(
      (first, second) => new Date(second.createdOn) - new Date(first.createdOn),
    );
    sessions.forEach((item, index) => {
      if (index < 12) {
        overallSessions.push(item.totalEnergy);
        overallSessionDates.push(dayjs(item.createdOn).format('MMM DD'));
      }

      if (
        !uniqueMonths.find(
          (month) => month.number === dayjs(item.createdOn).format('MM'),
        )
      ) {
        uniqueMonths.push({
          number: dayjs(item.createdOn).format('MM'),
          name: dayjs(item.createdOn).format('MMMM'),
          year: dayjs(item.createdOn).format('YYYY'),
        });
      }
    });

    const userSessionsByMonth = [];
    uniqueMonths.forEach((month) => {
      const group = {
        month: month.name,
        year: month.year,
        sessions: sessions.filter(
          (session) => month.number === dayjs(session.createdOn).format('MM') && month.year === dayjs(session.createdOn).format('YYYY'),
        ),
      };
      group.numberOfSessions = (group || []).length;
      userSessionsByMonth.push(group);
    });

    const tmpSession = userSessionsByMonth.slice(-1)[0];

    return {
      sessions: userSessionsByMonth,
      lastUserSessionId: tmpSession.sessions
        ? tmpSession.sessions.slice(-1)[0].userSessionId
        : 0,
    };
  }

  const setIsMountedToFalse = () => {
    isMounted.current = false;
  };

  useEffect(() => {
    setData([]);
    setAllSessions([]);
    return () => setIsMountedToFalse();
  }, [isMounted.current]);

  useEffect(() => {
    if (allSessions.length === 0) return;
    const { sessions, lastUserSessionId } = transformSessions(allSessions);
    setData([...sessions]);
    setLastSessionId(lastUserSessionId);
  }, [allSessions]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    if (currentPage === 1) {
      setAllSessions([]);
    }
    analyticsService
      .fetchPaginatedUserSession(currentPage, perPage)
      .then((res) => {
        if (isMounted.current) {
          setLoading(false);
          setAllSessions((prevSessions) => [...prevSessions, ...res.data]);
          setTotal(res.total);
          setHasMore(res.data.length > 0);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [currentPage]);

  return {
    data, total, lastSessionId, loading, hasMore, error,
  };
}
