// eslint-disable-next-line 
import React, { Component } from 'react';
import { getUserSubscriptionStatus } from '../../../../cross-platform/common/helpers';

class Account extends Component {
    state = {
        selectedAvatar: this.props.userAvatars[0].name,
        editing: false,
        editingBoxId: 0,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        userAccount: {},
        userProfileHelper: {},
        subscription: {},
        subscriptionActive: false,
        subscriptionStatus: ''
    };

    static getDerivedStateFromProps(props, state) {
        const subscriptionActive = props.userCan?.accessSubscriptions;
        const subscriptionStatus = getUserSubscriptionStatus(props.userAccount);
        const subscription = props.userAccount && props.userAccount.subscription;

        return {
            ...state,
            userAccount: props.userAccount,
            userProfileHelper: props.userProfileHelper || state.userProfileHelper,
            subscriptionActive,
            subscriptionStatus,
            subscription
        };
    }

    componentDidMount() {
        if (!this.props.userAccount.id)
            this.props.getUserAccount();
    }

    _cancel = () => {
        this.props.resetUserProfile();
        this.setState({
            editing: false,
            editingBoxId: 0,
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        });
    };

    _changePassword = () => {
        return this.props.changePassword(this.state.oldPassword, this.state.newPassword, this.state.confirmPassword)
            .then(() => {
                this.setState({
                    editing: false,
                    editingBoxId: 0,
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });
                return Promise.resolve();
            });
    };

    _edit = (id) => {
        this.props.setUserProfilePrevState();
        this.setState({ editing: true, editingBoxId: id });
    };

    _onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    };

    // saveUserProfile = () => {
    //     this.props.saveUserProfile()
    //         .then(() => {
    //             this.setState({
    //                 editing: false,
    //                 editingBoxId: 0
    //             });
    //             toast.success('User information updated successfully', {
    //                 position: toast.POSITION.BOTTOM_RIGHT
    //             });
    //         }).catch(() => false);
    // };

    // selectAvatar = (path) => {
    //     if (this.state.editingBoxId !== 1)
    //         return false;
    //     this.props.selectUserAvatar(path);
    // };
}

export default Account;