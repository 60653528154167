import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ValidatedForm, ValidatedInput } from 'react-validated';

import Navigation from '../../../../components/navigation';
import { saveUserProfile, deleteUserProfile, setUserProfile } from '../../../../cross-platform/actions/user-actions';
import authService from '../../../../cross-platform/services/auth-service';
import modes from '../../../../cross-platform/references/modes';
import ProfileEditor from '../../../../cross-platform/containers/my/profile/profile-editor';

import Avatar from '../../../../components/avatar';

import moment from 'moment';

class ProfileEditorPage extends ProfileEditor {
  componentDidMount() {
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this._getUserProfile(this.props.match.params.id);
    }
  }

  _deleteUserProfile = () => {
    if (window.confirm('Are you sure you want to delete user profile?')) {
      this.setState({ mode: modes.FETCHING });
      this.props.deleteUserProfile(this.state.profile)
        .then(async () => {
          toast.success('User deleted successfully.', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
          this.setState({ mode: modes.BROWSE });

          //Check if the deleted profile is currently the selected profile
          const selectedProfileId = await authService.getUserProfileId();
          if (selectedProfileId === this.state.profile.id) {
            const primaryUser = this.props.userProfiles.find(profile => profile.primary);
            this.props.setUserProfile(primaryUser);
            authService.setUserProfileId(primaryUser.id);
          }
          this.props.history.push('/profiles/manage');
        }).catch(() => {
          this.setState({ mode: modes.WITH_ERROR });
        })
    }
  }

  _onSaveUserProfile = () => {
    this._saveUserProfile()
      .then(() => {
        toast.success('User saved succesfully.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        this.setState({ mode: modes.BROWSE });
        this.props.history.push('/profiles/manage');
      })
  }

  _onChangeWithEvent = (event) => {
    const name = event.target && event.target.name;
    const value = event.target && event.target.value;
    this._onChange({ name, value });
  }

  render() {
    const pageTitle = (this.props.location.pathname === '/profiles/new/editor') ? 'Add Profile' : 'Edit Profile';
    return (
      <div className="container-fluid bg-light classes">
        <div className="row">
          <div className="profiles-container">
            <div className="col-12 mt-5 text-center">
              <h2>{pageTitle}</h2>
            </div>
            <div className="profiles-centered">
              <div className="profile-editor col-lg-9">
                <ValidatedForm className="profile-form-wrapper bg-white" onSubmit={this._onSaveUserProfile}>
                  <div className="row">
                    <div className="col-4 avatar-wrapper">
                      <div className="h-100 d-flex flex-column justify-content-center p-4">
                        {(this.state.profile.fullName || this.state.profile.username) ? <div className="avatar mx-auto">
                            <Avatar profile={this.state.profile} width={240} height={240} />
                          </div> : <div className="avatar mx-auto">
                            <Avatar profile={{username: 'D'}} width={240} height={240} showText={false}/>
                          </div>}
                      </div>
                    </div>
                    <div className="col-8 profile-fields-wrapper">
                          <div className="row">
                            <div className="col-6 mb-3">
                              <label>First Name</label>
                              <ValidatedInput>
                                <input type="text"
                                  className="form-control"
                                  name="profileFirstName"
                                  id="profileFirstName"
                                  placeholder="First Name"
                                  value={this.state.profile.profileFirstName || ''}
                                  onChange={this._onChangeWithEvent}
                                />
                              </ValidatedInput>
                            </div>

                            <div className="col-6 mb-3">
                              <label>Last Name</label>
                              <ValidatedInput>
                                <input type="text"
                                  className="form-control"
                                  name="profileLastName"
                                  id="profileLastName"
                                  placeholder="Last Name"
                                  value={this.state.profile.profileLastName || ''}
                                  onChange={this._onChangeWithEvent}
                                />
                              </ValidatedInput>
                            </div>
                          </div>
                        {/* <div className="row">
                          <div className="col-12 mb-3">
                            <label>Location</label>
                            <input type="text"
                              className="form-control bg-light p-4"
                              name="location"
                              id="location"
                              placeholder="Location"
                              value={this.state.profile.location || ''}
                              onChange={this._onChangeWithEvent}
                            />
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-lg-6 col-6 mb-3">
                              <label>Birthday</label>
                              <input type="date"
                                className="form-control"
                                name="birthday"
                                id="birthday"
                                placeholder="MM/DD/YYYY"
                                value={moment(this.state.profile.birthday).format('YYYY-MM-DD') || ''}
                                onChange={this._onChangeWithEvent}
                              />
                          </div>
                          <div className="col-lg-6 col-6 mb-3">
                            <label>Gender</label>
                            <div className="gender">
                              <button
                                onClick={() => this._onChange({ name: 'gender', value: 'female' })}
                                className={`female ${this.state.profile.gender === 'female' ? 'selected' : ''}`}
                                type="button">
                                Female
                              </button>
                              <button
                                onClick={() => this._onChange({ name: 'gender', value: 'male' })}
                                className={`male ${this.state.profile.gender === 'male' ? 'selected' : ''}`}
                                type="button">
                                Male
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-5">
                          <div className="col-6">
                            {!this.state.profile.primary && this.state.profile.id && (
                              <button
                                onClick={this._deleteUserProfile}
                                disabled={this.state.mode === modes.FETCHING}
                                className="btn btn-danger-light float-left"
                                type="button">
                                Delete
                              </button>
                            )}
                          </div>
                          <div className="col-6">
                              <div className="row">
                                <div className="col-6">
                                  <Link to='/profiles/manage' className=" btn-cancel-light d-block">
                                    Cancel
                                  </Link>
                                </div>
                                <div className="col-6">
                                  <button
                                      type="submit"
                                      disabled={
                                        !this.state.profile.profileFirstName}
                                      className="btn btn-save d-block w-100">
                                      Save
                                  </button>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </ValidatedForm>
              </div>
            </div>
          </div>
          <Navigation />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ userStore }) => {
  return {
    userProfiles: userStore.userProfiles,
    mode: userStore.userProfileHelper.mode
  };
};

export default connect(mapStateToProps, { saveUserProfile, deleteUserProfile, setUserProfile })(ProfileEditorPage);