import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../components/avatar';

class NavigationAvatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigationTextShow: false
        };
    }

    toggleHover = () => {
        this.setState(prevState => {
            return {
                navigationTextShow: !prevState.navigationTextShow
            }
        });
    }

    render() {
        const { navigationTextShow } = this.state;
        const { profile } = this.props;
        return (
            <div className="navigation-avatar-wrapper">
                <span className={`${!navigationTextShow ? 'd-none' : ''} navigation-avatar-text`}>My Profile</span>
                <Link to="/my/profile" className="navigation-avatar" onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                    <Avatar profile={profile} width={86} height={86}/>
                </Link>
            </div>
        );
    }
}

export default NavigationAvatar;