import React from "react";
import PropTypes from "prop-types";
import { buildBadgeImageUrl } from "helpers/class-url";

const BADGES_LIST = [1, 10, 25, 50, 75, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000];

function findNextBadge(totalRides) {
    let nextBadge = 1;
    for(const badge of BADGES_LIST) {
      const currIndex = BADGES_LIST.indexOf(badge);
      if(typeof BADGES_LIST[currIndex+1] === 'undefined') {
        return BADGES_LIST[currIndex];
      }

      if(totalRides >= badge && totalRides < BADGES_LIST[currIndex+1]) {
        return BADGES_LIST[currIndex+1];
      }
    }
    return nextBadge;
}

function AchievementBadge(props) {
  const { totalRides, badgePath } = props;
  const nextBadgeImageUrl = buildBadgeImageUrl(findNextBadge(totalRides));
  return (
    <div className="item-achievement-badge-wrapper">
      <img className='current-badge' src={badgePath} alt={totalRides} />
      <img className='next-badge' src={nextBadgeImageUrl} alt={findNextBadge(totalRides)} />
    </div>
  );
}

AchievementBadge.defaultProps = {
  totalRides: 0,
  badgePath: ''
};

AchievementBadge.propTypes = {
  totalRides: PropTypes.number,
  badgePath: PropTypes.string
};

export default AchievementBadge;
