import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import { SignupSchema } from '../../cross-platform/common/validation-schemas';

import SignUp from '../../cross-platform/containers/auth/sign-up';
import modes from '../../cross-platform/references/modes';
import LogoIcon from '../../components/elements/icons/logo-icon';
import studioService from '../../cross-platform/services/studio-service';
import styles from '../../styles/pages/__signup.scss';

class SignUpPage extends SignUp {

    constructor(props) {
        super(props);
        this.state = {
            logoName: 'STRYDE',
        }
    }

    signUp = (values) => {
        this.setState({
            ...values
        }, () => {
            const { email, password, } = this.state;
            this._signUp().then(() => {
                this.props.history.push({ pathname: '/confirm-sign-up', state: { user: { email, password } } });
                toast.success('Sign up success! Please check your email for verification code', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });
        });
    }

    onChange = (event) => {
        const value = event.target.name === 'name' ? event.target.value : event.target.value.trim();
        this._onChange({
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : value
        });
    }

   componentDidMount() {
        const studioId = new URLSearchParams(this.props.location.search).get('studioId');
        studioService.fetchStudioByExternalId(studioId)
            .then(studio => {
                if (studio.name) {
                    this.setState({logoName: `STRYDE x ${studio.name}`});
                }
            });
    }

    render() {
        const { errorMessage, mode, firstname, lastname, email, password, terms } = this.state;
        const { onChange } = this //Actions

        return (
            <div className="auth">
                <div className="row">
                    <div className="col-xl-9 col-lg-10 align-self-center mx-auto top-logo-wrapper logo-container">
                        <div className="text-center">
                            <MediaQuery maxWidth={768}>
                                <Link to="/login">
                                    <LogoIcon width={50} height={50} />
                                    <h4 className="logo-text">{this.state.logoName}</h4>
                                </Link>
                            </MediaQuery>
                        </div>
                        <div className='mobile-logo-container'>
                            <MediaQuery minWidth={769}>
                                <LogoIcon width={50} height={50} />
                                <h4 className="logo-text logo-desc">{this.state.logoName}</h4>
                            </MediaQuery>
                        </div>
                    </div>
                </div>
                <div className="row h-80">
                    <div className="col-xl-9 col-lg-10 auth-content-wrapper align-self-center m-auto shadow-sm">
                        <div className="row">
                            <div className="col-md-5 col-xs-12 background-image link-box shadow-sm">
                                <div className="row h-100">
                                    <div className="col-12 col-md-12 align-self-center m-auto p-0">
                                        <i className="fas fa-quote-left"></i>
                                        <blockquote className="quote-content pb-8 pt-5">
                                            If you need me, I’ll be on my bike <strong>FOREVER</strong>. So many amazing classes; how in the world am I supposed to choose?!
                                        </blockquote>
                                        <cite>Rachel Libowitz</cite>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 col-xs-12 order-md-2 order-1 bg-white form-box">
                                <div className="row h-100">
                                    <div className="col-12 p-4 align-self-center login-form-wrapper">
                                        {mode === modes.WITH_ERROR && (
                                            <div className="alert alert-danger">
                                                <i className="fas fa-exclamation-circle pr-2"></i>
                                                {errorMessage}
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-between">
                                            <h2>
                                                Create Account
                                            </h2>
                                            <Link to="/login" className="link-item mt-2">
                                                Back to Login
                                            </Link>
                                        </div>

                                        {<p className="mt-3 pull-right">
                                            
                                        </p>}
                                        <Formik
                                            initialValues={{
                                                firstname,
                                                lastname,
                                                email,
                                                password
                                            }}
                                            validationSchema={SignupSchema}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            onSubmit={(values) => this.signUp(values)}
                                        >
                                            {({ errors, touched }) => (
                                            <Form className="form-group mt-5 mb-5">
                                                <div className="row">
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3">
                                                        <label htmlFor="firstname" className="control-label">First Name</label>
                                                        <Field 
                                                            name="firstname" 
                                                            className={classNames('form-control', {'is-invalid': errors.firstname && touched.firstname})}
                                                            placeholder="First name"
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3">
                                                        <label htmlFor="lastname" className="control-label">Last Name</label>
                                                        <Field 
                                                            name="lastname" 
                                                            className={classNames('form-control', {'is-invalid': errors.lastname && touched.lastname})}
                                                            placeholder="Last name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3">
                                                        <label htmlFor="email" className="control-label">Email</label>
                                                        <Field 
                                                            name="email" 
                                                            className={classNames('form-control', {'is-invalid': errors.email && touched.email})}
                                                            placeholder="Email"
                                                        />
                                                        {errors.email && <span className="error-message">{errors.email}</span>}
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3">
                                                        <label htmlFor="password" className="password">Password</label>
                                                        <Field 
                                                            name="password" 
                                                            className={classNames('form-control', {'is-invalid': errors.password && touched.password})}
                                                            placeholder="Password" 
                                                            type="password"
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <button type="submit" className="btn btn-block btn-primary btn-main p-3 mt-5 mb-5" disabled={mode === modes.FETCHING}>
                                                    Sign Up
                                                </button>

                                                <div className="form-check mt-3">
                                                    <input className="form-check-input d-none" type="checkbox" name="terms" checked={terms} onChange={onChange} id="terms-of-service" />
                                                    <label className="form-check-label katana-terms" htmlFor="terms-of-service">
                                                        By signing up I confirm and agree with <a href="/terms" target="_blank">Stryde’s Terms of Service, Payments Terms of Service, Privacy Policy.</a>
                                                    </label>
                                                </div>
                                            </Form>
                                        )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyrights-content text-center">
                            <p>&copy;2020 Stryde Bike. All Rights Reserved.</p> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignUpPage;