import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ authenticated, redirectTo = '/login', component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        authenticated
            ? <Component {...props} />
            : <Redirect to={redirectTo} />
    )} />
)

export const UnauthRoute = ({ authenticated, redirectTo = '/', component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        !authenticated
            ? <Component {...props} />
            : <Redirect to={redirectTo} />
    )} />
)