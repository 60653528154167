const cancellationReasonArray = [
  {
    reason: 'Illness, injury or life event',
    value: 'illness-injury-life-event',
  }, {
    reason: 'Seasonal use',
    value: 'seasonal-use',
  }, {
    reason: 'Using Peloton app',
    value: 'using-peloton-app',
  }, {
    reason: 'Using another cycling app',
    value: 'using-another-cycling-app',
  }, {
    reason: 'Too Expensive',
    value: 'too-expensive',
  }, {
    reason: 'Don\'t like the classes',
    value: 'dont-like-classes',
  }, {
    reason: 'Software Issues',
    value: 'software-issues',
  }, {
    reason: 'Sold the bike',
    value: 'solde-bike',
  }, {
    reason: 'Problems with the bike',
    value: 'problems-with-bike',
  }, {
    reason: 'Other',
    value: 'other',
  },
];
export default cancellationReasonArray;
