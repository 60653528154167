import React from 'react'

const PlayIcon = ({ width, height, color = ''}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 58 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.700684 9.43641C0.700684 2.33133 8.5475 -1.97202 14.5387 1.84737L53.0698 26.4109C58.6191 29.9486 58.6191 38.0513 53.0698 41.589L14.5387 66.1526C8.54751 69.972 0.700684 65.6687 0.700684 58.5636V9.43641Z" fill={color !== '' ? color : 'white' }/>
        </svg>
    );
}

export default PlayIcon;