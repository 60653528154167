import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ crumbs, root }) => {
    const count = (crumbs || []).length;
    return (
        <nav aria-label='breadcrumb'>
            <ol className='breadcrumb p-0 bg-transparent mb-0'>
                {(crumbs || []).map((crumb, index) => (
                    <li className={`breadcrumb-item ${index === count - 1 ? 'active' : ''}`} key={index}>
                        {(index === count - 1 && (
                            <span>{crumb.title}</span>
                        )) || (
                                <Link className="" to={`${root}${crumb.path}`} title={crumb.tooltip}>
                                    {crumb.title}
                                </Link>
                            )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export default Breadcrumbs;