import React from 'react'

const FlagIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 11.4C1 11.4 1.75 10.6 4 10.6C6.25 10.6 7.75 12.2 10 12.2C12.25 12.2 13 11.4 13 11.4V1.8C13 1.8 12.25 2.6 10 2.6C7.75 2.6 6.25 1 4 1C1.75 1 1 1.8 1 1.8V11.4ZM1 11.4V17" stroke={color !== '' ? color : 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default FlagIcon;