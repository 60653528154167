import React from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';
import moment from 'moment';

import CameraIcon from '../../../components/elements/icons/camera-icon';
import ClockIcon from '../../../components/elements/icons/clock-icon';
import Diagram from '../../../components/diagram';
import GaugeIcon from '../../../components/elements/icons/gauge-icon';
import HeartIcon from '../../../components/elements/icons/heart-icon';
import FireIcon from '../../../components/elements/icons/fire-icon';
import IntensityIcon from '../../../components/elements/icons/intensity-icon';
import MileIcon from '../../../components/elements/icons/mile-icon';
import PlayIcon from '../../../components/elements/icons/play-icon';
import PersonIcon from '../../../components/elements/icons/person-icon';
import RpmIcon from '../../../components/elements/icons/rpm-icon';
import StormIcon from '../../../components/elements/icons/storm-icon';
import StarIcon from '../../../components/elements/icons/star-icon';
import UploadIcon from '../../../components/elements/icons/upload-icon';
class UserSessionMetrics extends React.Component {
    render() {
        const { selectedUserSession } = this.props;
        const userSessionTotalDuration = (selectedUserSession.duration && selectedUserSession.duration !== 0) ? Math.ceil(selectedUserSession.duration / 60) : 0;
        if (!this.props.sessionMetrics) return <></>;
        const { items: metrics, session } = this.props.sessionMetrics;
        if (!metrics || !metrics.length) return (
            <div className="col-7 d-flex justify-content-center align-items-center">
                <span>No metrics</span>
            </div>
        );

        const powerData = metrics.map((currentSession, index) => (currentSession.power || 0)).filter((ci, index) => index % 10 === 0);
        const cadenceData = metrics.map((currentSession, index) => (currentSession.cadence || 0)).filter((ci, index) => index % 10 === 0);
        const labels = userSessionTotalDuration !== 0 ? (_.range(Math.ceil(selectedUserSession.duration / 60) + 1)) : [];

        const data = (canvas) => {
            const ctx = canvas.getContext("2d");
            const cadenceGradient = ctx.createLinearGradient(0, 0, 0, 500);
            cadenceGradient.addColorStop(0, '#24A1F5');   
            cadenceGradient.addColorStop(1, 'rgba(36, 161, 245,0)');
            const powerGradient = ctx.createLinearGradient(0, 0, 0, 500);
            powerGradient.addColorStop(0, '#FCB333');   
            powerGradient.addColorStop(1, 'rgba(252, 179, 51, 0)');
            return {
                labels,
                datasets: [{
                    label: 'Cadence',
                    data: cadenceData,
                    borderColor: '#24A1F5',
                    backgroundColor: cadenceGradient,
                    pointColor : "#fff",
                    pointStrokeColor : "#24A1F5",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "#24A1F5",
                    yAxisID: 'y-axis-1'
                }, {
                    label: 'Output',
                    data: powerData,
                    borderColor: '#FCB333',
                    backgroundColor: powerGradient,
                    pointColor : "#fff",
                    pointStrokeColor : "#FCB333",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "#FCB333",                }]
            }
        }

        return (
            <div className="col-7 pb-5 d-none d-xl-block top-class-info">
                <div className="row m-4 border-bottom class-info-wrapper no-gutters">
                    <div className="col-9 class-info-details">
                        <div className="row no-gutters">
                            {/* <div className="col-3 class-info-details__left">
                                <figure className="figure">
                                    <img src="https://d35s5jsy8wi2a0.cloudfront.net/videos/b07aaedd-3ba9-43dc-a8dc-f7866c057a6f/fb00a6c8-c8bf-4fbb-a1e0-9676d1d32993-1m-00001.png" alt="" className="img-responsive"/>
                                </figure>
                            </div> */}
                            <div className="col-12 class-info-details__right">
                                <h3>{(selectedUserSession && selectedUserSession.sessionSummary) ? selectedUserSession.sessionSummary.trainingName : ''}</h3>
                                <div className="info-btns">
                                <button className="btn-dark mr-2">
                                    <PersonIcon width={12} height={14} />
                                    {(selectedUserSession && selectedUserSession.sessionSummary) ? selectedUserSession.sessionSummary.instructor.fullName : '-'}</button>
                                <button className="btn-dark mr-2">
                                    <ClockIcon width={16} height={16} />
                                    {selectedUserSession ? selectedUserSession.sessionDuration : '-'} min 
                                </button>
                                {/* <button className="btn-dark">
                                    <StarIcon width={20} height={18} />
                                    76%
                                </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 d-flex align-items-center class-info-btns">
                        <div className="ml-auto">
                            {/* <div className="other-icons">
                                <button className="plain-btn btn btn-icon rounded-circle mr-3">
                                    <UploadIcon width={20} height={20} />
                                </button>
                                <button className="plain-btn btn btn-icon rounded-circle">
                                    <HeartIcon width={20} height={20} />
                                </button>
                            </div> */}
                            <Link to={`/dashboard/${selectedUserSession.classSessionId}`} className="play-icon"><PlayIcon width={28} height={28} /></Link>
                        </div>
                    </div>
                </div>

                <div className="row m-4 align-items-center class-stats-wrapper no-gutters">
                    <div className="col-3 text-center">
                        <div className="mb-3 mr-3 stats-item">
                            <IntensityIcon width={26} height={26} />
                            <span>{Number(selectedUserSession.averageResistance
                            ? selectedUserSession.averageResistance
                            : 0).toFixed(1)} <small>%</small></span>
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <div className="mb-3 mr-3 stats-item">
                            <RpmIcon width={26} height={22} />
                        <span>{Number(selectedUserSession.averageCadence
                            ? selectedUserSession.averageCadence
                            : 0).toFixed(1)} <small>rpm</small></span>
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <div className="mb-3 mr-3 stats-item">
                            <CameraIcon width={26} height={12} color={'#fcb333'} fill={'#fef0d6'} />
                        <span>{Number(selectedUserSession.averagePower
                            ? selectedUserSession.averagePower
                            : 0).toFixed(0)} <small>watts</small></span>
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <div className="mb-3 stats-item">
                            <StormIcon width={18} height={28} color={'#fcb333'} fill={'#fef0d6'} />
                            <span>{Number(selectedUserSession.totalEnergy
                            ? selectedUserSession.totalEnergy
                            : 0).toFixed(1)} <small>kj</small></span>
                        </div>
                    </div>
                    {/* <div className="col-3 text-center">
                        <div className="mr-3 stats-item">
                            <GaugeIcon width={24} height={18} />
                            <span>18.5 <small>mph</small></span>
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <div className="mr-3 stats-item">
                            <MileIcon width={20} height={18} color={'#fcb333'} />
                            <span>24.8 <small>mi</small></span>
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <div className="mr-3 stats-item">
                            <HeartIcon width={22} height={20} color={'#fcb333'} fill={'#fef0d6'} />
                            <span>141 <small>bpm</small></span>
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <div className="stats-item">
                            <FireIcon width={20} height={26} color={'#fcb333'} fill={'#fef0d6'} />
                            <span>892 <small>cal</small></span>
                        </div>
                    </div> */}
                </div>
                <div className="row m-0">
                    {/* <div className="col-12 summary">
                        <div className="row no-gutters bg-white ml-2 mb-2 mt-3 mr-0 py-4 px-3">
                            <div className="col-3 cadence border-right">
                                <div className="row no-gutters m-0">
                                    <div className="col-5 d-flex align-items-center">
                                        <div
                                            className="circle mx-auto rounded-circle p-2 d-flex align-items-center">
                                            <i className="fas fa-heartbeat fa-lg mx-auto"></i>
                                        </div>
                                    </div>
                                    <div className="col-7 py-2">
                                        <div className="row m-0 pb-1">
                                            <span>Avg Cadence</span>
                                        </div>
                                        <div className="row m-0">
                                            <span
                                                className="avg-value font-weight-bolder">{Number(this.props.sessionMetrics.averageCadence).toFixed(0)} </span>
                                            <span className="pl-1 mt-auto text-light-font unit">rpm</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 resistance border-right">
                                <div className="row no-gutters m-0">
                                    <div className="col-5 d-flex align-items-center">
                                        <div
                                            className="circle mx-auto rounded-circle p-2 d-flex align-items-center">
                                            <i className="fas fa-running fa-lg mx-auto"></i>
                                        </div>
                                    </div>
                                    <div className="col-7 py-2">
                                        <div className="row m-0 pb-1">
                                            <span>Avg Resistance</span>
                                        </div>
                                        <div className="row m-0">
                                            <span
                                                className="avg-value font-weight-bolder">{Number(this.props.sessionMetrics.averageResistance).toFixed(0)} </span>
                                            <span className="pl-1 mt-auto text-light-font unit">Percent</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 output border-right">
                                <div className="row no-gutters m-0">
                                    <div className="col-5 d-flex align-items-center">
                                        <div
                                            className="circle mx-auto rounded-circle p-2 d-flex align-items-center">
                                            <i className="fas fa-battery-three-quarters fa-lg mx-auto"></i>
                                        </div>
                                    </div>
                                    <div className="col-7 py-2">
                                        <div className="row m-0 pb-1">
                                            <span>Avg Output</span>
                                        </div>
                                        <div className="row m-0">
                                            <span
                                                className="avg-value font-weight-bolder">{Number(this.props.sessionMetrics.averagePower).toFixed(0)} </span>
                                            <span className="pl-1 mt-auto text-light-font unit">Watts</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 total">
                                <div className="row no-gutters m-0">
                                    <div className="col-5 d-flex align-items-center">
                                        <div className="circle mx-auto rounded-circle p-2 d-flex align-items-center">
                                            <i className="fas fa-dumbbell fa-lg mx-auto"></i>
                                        </div>
                                    </div>
                                    <div className="col-7 py-2">
                                        <div className="row m-0 pb-1">
                                            <span>Total (kj)</span>
                                        </div>
                                        <div className="row m-0">
                                            <span className="avg-value font-weight-bolder">{Number(this.props.sessionMetrics.totalEnergy).toFixed(1)} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row m-0">
                    <div className="col-12">
                        <Diagram data={data} selectedUserSession={selectedUserSession}></Diagram>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserSessionMetrics;