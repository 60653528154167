import React from 'react'

const MenuIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2H22M2 9H22M2 16H22" stroke={color !== '' ? color : '#828282'} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default MenuIcon;