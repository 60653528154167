import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import { Formik, Form, Field } from 'formik';
import { ConfirmSignupSchema } from '../../cross-platform/common/validation-schemas';

import accountService from '../../cross-platform/services/accountService';
import authService from '../../cross-platform/services/auth-service';
import modes from '../../cross-platform/references/modes';
import * as actions from '../../cross-platform/actions/auth-actions';
import { setUserProfile } from '../../cross-platform/actions/user-actions';

import LogoIcon from '../../components/elements/icons/logo-icon';

class ConfirmSignUp extends React.Component {

    componentDidMount() {
        if (this.props.location.state) {
            const { email, password } = this.props.location.state.user;
            this.props.onChange({ target: { name: 'email', value: email } });
            this.props.onChange({ target: { name: 'password', value: password } });
        }
        this.props.resetErrorMessage();
    }

    confirmSignUp = (values) => {
        this.props.confirmSignUp(values)
            .then(() => {
                return this.props.authenticate(values);
            })
            .then(() => {
                return accountService.getUserProfile({ retry: true });
            })
            .then(async (userProfile) => {
                //Set Default profile ID
                await authService.setUserProfileId(userProfile.id);
                return this.props.setUserProfile(userProfile);
            })
            .then(() => {
                this.props.history.push('/login');
                // toast.success('User confirmed successfully.', {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }).catch((error) => {
                console.error("Error logging in", error);
            })
    }

    render() {
        return (
            <div className="auth">
                <div className="row">
                    <div className="col-xl-9 col-lg-10 align-self-center mx-auto top-logo-wrapper">
                        <div className="text-center">
                            <MediaQuery maxWidth={768}>
                                <Link to="/login">
                                    <LogoIcon width={25} height={25} />
                                    <h4 className="logo-text">Stryde</h4>
                                </Link>
                            </MediaQuery>
                            <MediaQuery minWidth={769}>
                                <Link to="/login">
                                    <img className="logo"
                                    src="/Logo.png"
                                    alt="img" />
                                </Link>
                            </MediaQuery>
                        </div>
                    </div>
                </div>
                <div className="row h-100">
                    <div className="col-xl-9 col-lg-10 auth-content-wrapper align-self-center m-auto shadow-sm">
                        <div className="row">
                            <div className="col-md-6 col-xs-12 background-image link-box shadow-sm">
                                <div className="row h-100">
                                    <div className="col-12 col-md-12 align-self-center m-auto p-0">
                                        <i className="fas fa-quote-left"></i>
                                        <blockquote className="quote-content pb-8 pt-5">
                                            If you need me, I’ll be on my bike <strong>FOREVER</strong>. So many amazing classes; how in the world am I supposed to choose?!
                                        </blockquote>
                                        <cite>Rachel Libowitz</cite>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12 bg-white form-box">
                                <div className="row h-100">
                                    <div className="col-xl-9 col-lg-10 align-self-center mx-auto">
                                        {this.props.mode === modes.WITH_ERROR && (
                                            <div className="alert alert-danger">
                                                <i className="fas fa-exclamation-circle pr-2"></i>
                                                {this.props.errorMessage}
                                            </div>
                                        )}
                                        <h2>
                                            Verify Account
                                        </h2>
                                        <p className="mt-3">
                                            You should have this code in your email
                                        </p>
                                        <Formik
                                            initialValues={{
                                                email: this.props.email || '',
                                                code: this.props.code|| ''
                                            }}
                                            validationSchema={ConfirmSignupSchema}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            onSubmit={(values) => this.confirmSignUp(values)}
                                        >
                                            {({ errors, touched }) => (
                                                <Form className="form-group mt-5 mb-5">
                                                    <div className="mb-2">
                                                        <label htmlFor="email" className="control-label">Email</label>
                                                        <Field name="email" className={classNames('form-control', {'is-invalid': errors.email && touched.email})} autoFocus={true} placeholder="Email" />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="code" className="control-label">Code</label>
                                                        <Field name="code" className={classNames('form-control', {'is-invalid': errors.code && touched.code})} placeholder="Code" />
                                                        <small>{errors.code}</small>
                                                    </div>
                                                    <button type="submit" className="btn btn-block btn-main float-right p-2 mt-5 mb-5" disabled={this.props.mode === modes.FETCHING}>
                                                        Submit
                                                    </button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyrights-content text-center">
                            <p>&copy;2020 Stryde Bike. All Rights Reserved.</p> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ authStore }) => {
    return {
        ...authStore
    };
};

export default connect(mapStateToProps, { ...actions, setUserProfile })(ConfirmSignUp);