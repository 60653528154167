import {useState, useEffect} from 'react';
import analyticsService from 'services/analytics-service';

export default function useMonthlyExerciseSessions(month) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setData([]);
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(false);

    analyticsService
      .fetchMonthlySessions(month)
      .then((res) => {
        setLoading(false);
        setData((prevData) => [...prevData, ...res.items]);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [month]);

  return {data, loading, error};
}
