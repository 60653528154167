import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Calendar from 'react-calendar'
import { NavLink } from "react-router-dom";
import { buildBadgeImageUrl } from 'helpers/class-url';
import { findCurrentBadgeIndex } from 'helpers/object-helper';

import userService from '../../../cross-platform/services/user-service';
import accountService from '../../../cross-platform/services/accountService';
import * as analyticsActions from '../../../cross-platform/actions/analytics-actions';

import CameraIcon from '../../../components/elements/icons/camera-icon';
import StormIcon from '../../../components/elements/icons/storm-icon';
import FireIcon from '../../../components/elements/icons/fire-icon';
import MileIcon from '../../../components/elements/icons/mile-icon';
import LogoSmallIcon from '../../../components/elements/icons/logo-small-icon';

import 'react-calendar/dist/Calendar.css';

import OverviewMonthlyList from 'widgets/overview-monthly-list/OverviewMonthlyList'

const BADGES_PER_ROW = 7;
const BADGES_LIST = [1, 10, 25, 50, 75, 100, 150, 200, 250];

class OverviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userAccount: {},
            totalRides: 0,
            totalSessionsCount: 0
        }
    }

    componentDidMount() {
        this.props.fetchUserSessions().then(() => {
            this.setState({
                totalSessionsCount: this.props.totalExercises
            });
        }).catch(error => console.warn("Failed to fetch user sessions.", error));

        accountService.getUserAccount().then((userAccount) => {
            this.setState({
                userAccount
            });
        }).catch(error => console.warn("Failed to fetch user account.", error));

        userService.userRideCount().then((data) => {
            this.setState({
                totalRides: data.total,
            });
        }).catch(error => console.warn("Failed to fetch user rides count.", error));


    }

    calculateExerciseSummaries = (exercises) => {
        return (exercises || []).reduce((running, current) => ({
            duration: running.duration + (current.duration || 0),
            totalEnergy: running.totalEnergy + (current.totalEnergy || 0),
            totalPower: running.totalPower + (current.totalPower || 0)
        }), {
            duration: 0, totalEnergy: 0, totalPower: 0
        });
    }

    groupExercisesByDay = (exercises) => {
        return (exercises || []).reduce((result, item) => {
            const formatedDate = moment(item.createdOn).format('YYYY-MM-DD');
            if (!result[formatedDate]) {
                result[formatedDate] = [];
            }
            result[formatedDate].push(item);
            return result;
        }, {});
    }

    render() {
        // TODO: refactor calculation and check validity of this formula (Energy (kcal) = Power (watts) * Time (hours) * 3.6)
        const { userAccount, totalRides, totalSessionsCount } = this.state;
        let lastMonthExercisesGroupByDay = [], lastMonthExerciseSummaries = [], totalExerciseMonthlyDuration = 0;
        const { exerciseMonthlySummaries } = this.props;
        const currentMonth = moment().format('MMMM');
        const lastMonth = exerciseMonthlySummaries[0] ? exerciseMonthlySummaries[0].month : '';
        // const beforeLastMonth = exerciseMonthlySummaries[1];
        if (currentMonth === lastMonth) {
            lastMonthExercisesGroupByDay = this.groupExercisesByDay(exerciseMonthlySummaries[0].sessions);
            lastMonthExerciseSummaries = this.calculateExerciseSummaries(exerciseMonthlySummaries[0].sessions);
            totalExerciseMonthlyDuration = lastMonthExerciseSummaries.duration;
        }

        const totalHoursExcercisedMonth = totalExerciseMonthlyDuration !== 0 ? Math.floor(totalExerciseMonthlyDuration / 3600) : 0;
        const totalCaloriesMonth = totalHoursExcercisedMonth !== 0 ? lastMonthExerciseSummaries.totalPower * totalHoursExcercisedMonth * 3.6 : 0;
        const currentBadgeIndex = findCurrentBadgeIndex(totalRides, BADGES_LIST);
        const badges = BADGES_LIST.reduce((result, item, index) => {
            const badgeIndex = Math.floor(index / BADGES_PER_ROW);
            if (!result[badgeIndex]) {
                result[badgeIndex] = [];
            }
            result[badgeIndex].push(item);
            return result;
        }, []);

        return (
            <div className="overview-content col-12 col-xl-10">
                <div className="top-overview">
                    <div className="row">
                        <div className="col-4 border-bottom px-4 py-3 border-right">
                            <div className="row top-left-overview border-bottom mx-2 py-2 mb-3">
                                <div className="col-4">
                                    <h3>{totalSessionsCount}</h3>
                                </div>
                                <div className="col-8">
                                    <p className="">
                                        Total Workouts <br />
                                        Lifetime
                                    </p>
                                </div>
                            </div>
                            <div className="row mx-2 pt-2 top-left-bottom-overview">
                                <div className="col-2 top-left-logo">
                                    <LogoSmallIcon width={37} height={40} />
                                </div>
                                <div className="col-10 top-left-content">
                                    <h4>Stryde Membership</h4>
                                    {userAccount.subscription ?
                                        <p>{userAccount.subscription.cancel_at_period_end ? 'Active until' : 'Next payment on: '}
                                        <strong>{userAccount.subscription.cancel_at_period_end
                                            ? moment.unix(userAccount.subscription.cancel_at).format('DD MMMM, YYYY')
                                            : moment.unix(userAccount.subscription.current_period_end).format('DD MMMM, YYYY')}</strong>
                                        </p>
                                        : <p>None</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-8 border-bottom top-right-content">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="pl-3 py-3">Recent Achivements</h4>
                                    <NavLink
                                        to='/my/achievements'
                                        className='top-right-link'>
                                        See All
                                    </NavLink>
                                </div>
                                <div className="col-12 achievement-wrapper">
                                    {badges.map((items, index) => {
                                        return (
                                            <div className="achievement-item" key={`achievement-item-${index}`}>
                                                {items.map((item, idx) => {
                                                    const currentIndex = BADGES_LIST.indexOf(item);
                                                    return (
                                                        <figure className={`figure text-center ${(typeof totalRides === 'undefined' || totalRides === 0) || (currentBadgeIndex < currentIndex) ? 'figure-img-disabled' : ''}`} key={`figure-img-${idx}`}>
                                                            <img src={buildBadgeImageUrl(item, 'achievements/rides')} alt="" className={`achievement-badge-img ${(typeof totalRides === 'undefined' || totalRides === 0) || (currentBadgeIndex < currentIndex) ? 'achievement-badge-waiting' : ''}`} />
                                                        </figure>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-overview m-6">
                    <div className="row">
                        <div className="col-8 bottom-calendar ">
                            <div className="row">
                                <div className="col-8 mt-0 mb-0 border-right">
                                    <div className="pt-4 px-4 pb-0">
                                        <h4 className="text-center pt-3 pb-4">30 Day Activity</h4>
                                        <Calendar
                                            tileClassName={tile => {
                                                const formatedDate = moment(tile.date).format('YYYY-MM-DD');
                                                return lastMonthExercisesGroupByDay[formatedDate] ? 'day-has-exercises' : '';
                                            }}
                                            tileDisabled={tile => {
                                                const formatedDate = moment(tile.date).format('YYYY-MM-DD');
                                                return !lastMonthExercisesGroupByDay[formatedDate];
                                            }}
                                            showNavigation={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 bottom-calendar-overview">
                                    <h6 className="pt-5">Active Days</h6>
                                    <h3>{Object.keys(lastMonthExercisesGroupByDay).length || 0}</h3>
                                    {/* <div className="bottom-overview-summary mb-3">
                                        <strong>-7</strong> vs last 30 days
                                    </div> */}
                                    <div className="bottom-overview-list">
                                        <div className="row overview-list-item py-4 px-3 align-items-center">
                                            <div className="col-3 item-icon">
                                                <StormIcon width={18} height={28} color={'#fcb333'} fill={'#fef0d6'} />
                                            </div>
                                            <div className="col-9 item-val">{Number(lastMonthExerciseSummaries.totalEnergy || 0).toFixed(1)} <small>kj</small></div>
                                            {/* <div className="col-3 item-progress">+12%</div> */}
                                        </div>

                                        <div className="row overview-list-item py-4 px-3 align-items-center">
                                            <div className="col-3 item-icon">
                                                <FireIcon width={20} height={26} color={'#fcb333'} fill={'#fef0d6'} />
                                            </div>
                                            <div className="col-9 item-val">{totalCaloriesMonth} <small>Cal</small></div>
                                            {/* <div className="col-3 item-progress">+39%</div> */}
                                        </div>

                                        {/* <div className="row overview-list-item py-4 px-3 align-items-center">
                                            <div className="col-3 item-icon">
                                                <CameraIcon width={28} height={12} color={'#fcb333'} fill={'#fef0d6'} />
                                            </div>
                                            <div className="col-9 item-val">{Number(lastMonthExerciseSummaries.totalPower || 0).toFixed(1)} <small>watts</small></div>
                                            <div className="col-3 item-progress item-progress-down">-0.2%</div>
                                        </div> */}

                                        {/* <div className="row overview-list-item py-4 px-3 align-items-center">
                                            <div className="col-3 item-icon">
                                                <MileIcon width={25} height={18} color={'#fcb333'} />
                                            </div>
                                            <div className="col-6 item-val">24.8 <small>mi</small></div>
                                            <div className="col-3 item-progress">+11%</div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 bottom-stats">
                            <OverviewMonthlyList currentMonth={moment().month()} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ analyticsStore, classStore }) => {
    return {
        ...analyticsStore,
        ...classStore
    };
};

export default connect(mapStateToProps, { ...analyticsActions })(OverviewPage);