import React from 'react';

const PauseIcon = ({ width, height, color = '', fill = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 188 188" fill='none'>
        <circle cx="94" cy="94" r="94" fill={fill !== '' ? fill : 'black'} fillOpacity="0.4"/>
        <path d="M71 65C71 62.7909 72.7909 61 75 61H83C85.2091 61 87 62.7909 87 65V123C87 125.209 85.2091 127 83 127H75C72.7909 127 71 125.209 71 123V65Z" fill={color !== '' ? color : 'white' } />
        <path d="M101 65C101 62.7909 102.791 61 105 61H113C115.209 61 117 62.7909 117 65V123C117 125.209 115.209 127 113 127H105C102.791 127 101 125.209 101 123V65Z" fill={color !== '' ? color : 'white' } />
        </svg>
    );
}

export default PauseIcon;