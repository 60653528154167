import * as types from '../actions/action-types';

const initialState = {
  class: null,
  classes: [],
  classSession: null,
  classSessions: [],
  classSessionsRefreshing: false,
  classMostPopularSessions: [],
  instructors: [],
  sessionLength: [],
  studioNames: [],
  musicGenres: [],
  weights: [],
  featuredClassSession: null,
  filteredClassSessions: [],
  lastUpdatedSessions: null,
  filters: {
    ...{
      selectedClassSessions: [], // Duration
      selectedInstructors: [],
      selectedMusicGenres: [],
      selectedStudioNames: [],
      selectedWeights: null,
      favorites: false,
    },
  },
};

export const classReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CLASSES:
      return {
        ...state,
        classes: action.classes,
      };
    case types.SET_CLASS_SESSION:
      return {
        ...state,
        classSession: action.classSession,
      };
    case types.SET_CLASS_SESSIONS:
      return {
        ...state,
        classSessions: action.classSessions,
        instructors: action.instructors,
        sessionLength: action.sessionLength,
        studioNames: action.studioNames,
        musicGenres: action.musicGenres,
        weights: action.weights,
      };
    case types.SET_FILTERED_CLASS_SESSIONS:
      return {
        ...state,
        filteredClassSessions: action.filteredClassSessions,
      };
    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case types.SET_HOME_CONFIGURATION:
      return {
        ...state,
        config: action.config,
      };
    case types.SET_CLASS_MOST_POPULAR_SESSIONS:
      return {
        ...state,
        classMostPopularSessions: action.classMostPopularSessions,
      };
    case types.SET_FAVORITE_CLASS_SESSION: {
      const { classSessionId, isFavorite } = action.payload;
      return {
        ...state,
        classSessions: state.classSessions
          .map((classSession) => ((classSession.id === classSessionId)
            ? { ...classSession, favorite: isFavorite }
            : classSession)),
        filteredClassSessions: state.filteredClassSessions
          .map((classSession) => ((classSession.id === classSessionId)
            ? { ...classSession, favorite: isFavorite }
            : classSession)),
      };
    }
    case types.SET_FAVORITE_CLASS_SESSION_ERROR: {
      const { classSessionId, isFavorite } = action.payload;
      return {
        ...state,
        classSessions: state.classSessions
          .map((classSession) => ((classSession.id === classSessionId)
            ? { ...classSession, favorite: !isFavorite }
            : classSession)),
        filteredClassSessions: state.filteredClassSessions
          .map((classSession) => ((classSession.id === classSessionId)
            ? { ...classSession, favorite: !isFavorite }
            : classSession)),
      };
    }
    case types.SET_CLASS_SESSIONS_REFRESHING:
      return {
        ...state,
        classSessionsRefreshing: action.isRefreshing,
      };
    case types.SET_LAST_UPDATED_SESSIONS:
      return {
        ...state,
        lastUpdatedSessions: Date.now(),
      };
    case types.SET_CLASS:
      return {
        ...state,
        class: action.class,
      };
    case types.SET_FEATURED_CLASS_SESSION:
      return {
        ...state,
        featuredClassSession: action.featuredClassSession,
      };
    case types.RESET_CLASS_SESSION:
      return {
        ...state,
        classSession: null,
      };
    default:
      return state;
  }
};
