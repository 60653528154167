import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Dashboard from '../../cross-platform/containers/classes/dashboard';
import VideoPlayer from '../../components/video-player';
import VideoFeedbackPopup from '../../components/video-feedback-popup';

import analyticsService from '../../cross-platform/services/analytics-service';
import classService from '../../cross-platform/services/class-service';
import userService from '../../cross-platform/services/user-service';
import playlistService from '../../cross-platform/services/playlist-service';
import { hasWebSubscriptionExpired } from '../../helpers/subscription-helper';

import * as analyticsActions from '../../cross-platform/actions/analytics-actions';
import * as classActions from '../../cross-platform/actions/class-actions';
import * as simulationActions from '../../cross-platform/actions/simulation-actions';
import * as userActions from '../../cross-platform/actions/user-actions';
import { buildVideoUrl } from '../../helpers/class-url';
import { MUSIC_SERVICE_TRACK_PLAY_MODES, MUSIC_DEFAULT_FORMAT_ID, MUSIC_TEMP_TOTAL_TRACK_TIME_PLAYED } from '../../cross-platform/references/constants';

import Avatar from '../../components/avatar';
import BackArrowIcon from '../../components/elements/icons/back-arrow-icon';
import LeaderboardRankFirstIcon from '../../components/elements/icons/leaderboard-rank-first-icon';
import LeaderboardRankSecondIcon from '../../components/elements/icons/leaderboard-rank-second-icon';
import LeaderboardRankThirdIcon from '../../components/elements/icons/leaderboard-rank-third-icon';
import activeUserSessionService from '../../cross-platform/services/active-user-session-service.js'
// import MusicNoteIcon from '../../components/elements/icons/music-note-icon';
import MileIcon from '../../components/elements/icons/mile-icon';
import MileBoardIcon from '../../components/elements/icons/mile-board-icon';
import FireIcon from '../../components/elements/icons/fire-icon';
import StormIcon from '../../components/elements/icons/storm-icon';
import ShowIcon from '../../components/elements/icons/show-icon';
import HideIcon from '../../components/elements/icons/hide-icon';
import accountService from 'cross-platform/services/accountService.js';
import getBrowserFingerprint from 'get-browser-fingerprint';
import AnotherActiveSessionPopup from '../../components/another-active-session-popup.jsx';
import moment from 'moment';

class DashboardPage extends Dashboard {

    constructor(props) {
        super(props);
        this.videoPlayerRef = React.createRef();
        this.state = {
            watchedTime: 0,
            focusMode: false,
            showTimeline: false,
            leaderboardShowing: true,
            playlist: {},
            sentMusicLogs: false,
            currentMusicTrack: {},
            fullVideoUrl: '',
            activeUserSession: { isCurrent: true, isActive: true }
        };
    }

    onCloseVideoFeedback = () => {
        this.setState({
            showVideoFeedback: false
        });
    }

    getVideoUrl = async (classSession) => {
        const res = await buildVideoUrl(classSession);
        console.log(res);
        this.setState({ ...this.state, fullVideoUrl: res });
    };

    setFocusMode = () => {
        this.setState(prevState => ({
            focusMode: !prevState.focusMode,
            leaderboardShowing: !prevState.leaderboardShowing
        }));
    }

    async componentDidMount() {
        const {
            history,
            match,
            userProfile,
            resetUserSession,
        } = this.props;

        const userAccount = await accountService.getUserAccount();
        if (!userAccount.subscription || hasWebSubscriptionExpired(userAccount)) {
            return history.push(`/my/account?redirectTo=${history.location.pathname}`);
        }

        resetUserSession();
        classService.getClassSession(match.params.id)
            .then(classSession => {
                this.setState({
                    classSession
                });
                this.getVideoUrl(classSession);
                if (classSession && classSession.id) {
                    analyticsService.createUserSession({ classSessionId: classSession.id, ...classSession }).then(userSession => {
                        const { userSessionId } = userSession;
                        this.setState({ userSessionId });
                    });
                    this.fetchPlaylist(classSession.playlistId);
                }

            })
            .catch(error => console.log("User session failed to create", error))

        this._fetchSessionLeaderboard(match.params.id);

        const { musicSubscription } = userProfile;
        if (!musicSubscription) {
            this.createOrUpdateUserSubscriptionMusicService();
        }

        this.checkAndUpdateActiveUserSession().then();
        this.saveActiveUserSessionDataIntervalID = setInterval(() => this.checkAndUpdateActiveUserSession().then(), 20000);
        this.intervalID = setInterval(() => this.saveWatchedTime(), 10000);
    }

    _fetchSessionLeaderboard(classSessionId) {
        const { userProfile } = this.props;
        userService.fetchClassLeaderboard(classSessionId)
            .then(data => {
                //debugger;
                const items = (data && data.items) || [];
                const orderedLeaderboard = items.map(session => {
                    if (session.owner.profileId === userProfile.id) {
                        session.owner = { ...session.owner, ...userProfile };
                        session.previousSession = true;
                    }
                    return session;
                }).sort((a, b) => a.totalEnergy < b.totalEnergy ? 1 : -1);

                const dashboardLeaderboard = [
                    ...orderedLeaderboard.slice(0, 5),
                    {
                        totalEnergy: 0,
                        owner: {
                            ...userProfile,
                        }
                    }
                ];
                this.setState({ currentUserSessionLeaderboard: dashboardLeaderboard, topFiveUsers: orderedLeaderboard.slice(0, 5) });
            });
    }

    fetchPlaylist(playlistId) {
        playlistService.fetchPlaylist(playlistId)
            .then(data => {
                const { playlist } = data;
                this.setState({
                    playlist
                });
            })
            .catch(error => console.log("Playlist failed to fetch", error))
    }

    createOrUpdateUserSubscriptionMusicService() {
        playlistService.createUserMusicService().then(() => {
            playlistService.createOrUpdateUserSubscriptionMusicService();
        })
            .catch(error => console.log("Failed to create user and subscription with music service", error));
    }

    toggleDashboard = () => {
        this.setState({
            leaderboardShowing: !this.state.leaderboardShowing
        });
    }

    componentWillUnmount() {
        clearInterval(this.simulationInterval);
        this.props.calculateUserSessionMetrics();
        this.props.resetUserSession();
        clearInterval(this.intervalID);
        clearInterval(this.saveActiveUserSessionDataIntervalID);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.userProfile.id && this.props.userProfile.id !== nextProps.userProfile.id) {
            this.props.fetchClassLeaderboard(this.props.match.params.id);
        }
    }

    controlFeedbackPopup = () => {
        this.setState({
            showVideoFeedback: true
        });
    }

    onPlaying = () => {
        this.setState({ startedPlayingVideo: true });
    }

    saveMusicLogs = async (tracks) => {
        const requests = (tracks || []).map((track) => this.saveSingleTrackMusicLogs(track));
        await Promise.all(requests);
    };

    refreshActiveUserSession = async () => {
        let activeUserSession;
        try {
            activeUserSession = await activeUserSessionService.getActiveUserSession();
            activeUserSession.isCurrent = activeUserSessionService.isCurrentActiveSession(activeUserSession);
        } catch (error) {
            activeUserSession = { isCurrent: true };
        }
        const userProfile = await accountService.getUserProfile({ retry: true });

        activeUserSession.fullName = userProfile.fullName;
        activeUserSession.classSession = await classService.getClassSession(activeUserSession.classSessionId);
        activeUserSession.class = await classService.getClass(activeUserSession.classSession.classId);

        const oneMinuteBefore = moment().utc().subtract(1, 'minutes').unix();
        const activeUserSessionDate = moment(activeUserSession.updatedAt).utc().unix();
        activeUserSession.isActive = activeUserSessionDate >= oneMinuteBefore;

        this.setState({
            activeUserSession
        });
    }

    saveActiveUserSession = async (lastWatchedDuration) => {
        const userProfile = await accountService.getUserProfile({ retry: true });
        const user = await accountService.getUserAccount();
        const activeUserSessionTransport = {
            uniqueDeviceId: getBrowserFingerprint(),
            userId: user.id,
            profileId: userProfile.id,
            classSessionId: this.props.match.params.id,
            lastWatchedDuration: lastWatchedDuration
        };
        await activeUserSessionService.saveActiveUserSession(activeUserSessionTransport);
    }

    saveSingleTrackMusicLogs = (track) => {
        const data = {
            trackId: track.trackId,
            releaseId: track.releaseId,
            formatId: MUSIC_DEFAULT_FORMAT_ID,
            userAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/71.0.3578.98 Safari/537.36', /** TODO: temp solution, it needs a lib to get user agent */
            dateTimePlayed: new Date().toISOString(),
            totalTimePlayed: MUSIC_TEMP_TOTAL_TRACK_TIME_PLAYED,
            playMode: MUSIC_SERVICE_TRACK_PLAY_MODES.ONLINE,
        };
        return playlistService.addUserSubscriptionMusicLogs(data);
    };

    checkAndUpdateActiveUserSession = async () => {
        await this.refreshActiveUserSession();
        const { activeUserSession } = this.state;
        if (activeUserSession.isCurrent) {
            // TODO: fix lastWatchedDuration
            /*
                {"currentTime":58.391,"seekableDuration":1437.502}
             */
            this.saveActiveUserSession(10).then();
        }
    }

    saveWatchedTime = () => {
        const { watchedTime, sentMusicLogs, playlist, startedPlayingVideo } = this.state;
        const { duration, isPaused } = (this.videoPlayerRef && this.videoPlayerRef.current) ? this.videoPlayerRef.current.state : { duration: 0, isPaused: false };

        if (startedPlayingVideo && !isPaused && watchedTime <= duration) {
            // Save 7digital logs for all tracks in playlist, if any.
            if (!sentMusicLogs && playlist && playlist.tracks && playlist.tracks.length > 0) {
                this.saveMusicLogs(playlist.tracks).then(() => {
                    this.setState({
                        sentMusicLogs: true
                    });
                });
            }

            this.setState(prevState => {
                return {
                    watchedTime: prevState.watchedTime + 10
                }
            }, () => {
                // update the watched time for class session
                const { userSessionId } = this.state;
                const classSessionId = this.props.match.params.id;
                if (!userSessionId || !classSessionId) return;
                return classService.saveWatchedTimeForClass(classSessionId, userSessionId, this.state.watchedTime);
            });
        }
    }

    setFeedbackResponse = (feedback) => {
        const classSessionId = this.props.match.params.id;

        const feedbackData = {
            classSessionId,
            feedback: {
                satisfied: feedback
            }
        };

        this.props.saveClassSessionFeedback(feedbackData).then((feedbackResponse) => {
            this.setState({
                didUserCompleteFeedback: true,
                showVideoFeedback: false
            }, () => {
                if (feedbackResponse && feedbackResponse.message) {
                    return this.showFeedbackFailed();
                }
            });
        }).catch(() => {
            this.setState({
                didUserCompleteFeedback: true,
                showVideoFeedback: false
            }, this.showFeedbackFailed);
        });
    }

    showFeedbackFailed = () => {
        toast.error("Couldn't store your feedback. Please try again later!", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    showRankIcon = (rank) => {
        if (rank + 1 > 3) {
            return rank + 1;
        }

        return (
            <>
                {rank + 1 === 1 && <LeaderboardRankFirstIcon width={22} height={22} />}
                {rank + 1 === 2 && <LeaderboardRankSecondIcon width={22} height={22} />}
                {rank + 1 === 3 && <LeaderboardRankThirdIcon width={22} height={22} />}
            </>
        );
    }

    setShowTimeline = () => {
        this.setState({
            showTimeline: !this.state.showTimeline
        });
    }

    render() {
        const { userProfile } = this.props; //Variables
        const {
            classSession,
            didUserCompleteFeedback,
            leaderboardShowing,
            showVideoFeedback,
            showTimeline,
            topFiveUsers,
            playlist,
            activeUserSession
        } = this.state //Variables
        const { controlFeedbackPopup, setFeedbackResponse, toggleDashboard, onPlaying, onCloseVideoFeedback } = this; //Actions

        if (!classSession) {
            return (
                <div className="loading-container vjs-seeking">
                    <div className="vjs-loading-spinner">
                        <div className="vjs-loading-spinner"></div>
                    </div>
                </div>
            )
        }

        return (
            <div className={`dashboard-player ${classSession && Object.keys(classSession).length === 0 ? 'dashboard-player-disabled' : ''}`}>
                <div className="dashboard-player-bg"></div>
                <div className="timeline-toggler">
                    {!showTimeline && (
                        <button onClick={() => this.setShowTimeline()}>
                            <ShowIcon />
                        </button>
                    )}
                    {showTimeline && (
                        <button onClick={() => this.setShowTimeline()}>
                            <HideIcon />
                        </button>
                    )}
                </div>
                <VideoFeedbackPopup
                    visible={showVideoFeedback && !didUserCompleteFeedback}
                    onYesPress={() => setFeedbackResponse(true)}
                    onNoPress={() => setFeedbackResponse(false)}
                    onClose={onCloseVideoFeedback}
                />
                <AnotherActiveSessionPopup
                    visible={!activeUserSession.isCurrent}
                    activeUserSession={activeUserSession}
                    onYesPress={() => {
                        activeUserSession.isCurrent = true;
                        this.setState({
                            activeUserSession
                        });
                        this.saveActiveUserSession(10).then();
                    }}
                    onNoPress={() => this.props.history.goBack()}
                />
                {!showTimeline && <button className="dashboard-back" onClick={() => this.props.history.goBack()}><BackArrowIcon width={18} height={18} /> Back</button>}

                <div className={`dashboard-leaderboard ${(leaderboardShowing) ? '' : 'hidden'}`}>
                    <div className="dashboard-leaderboard-row leaderboard-title-row">
                        <h5>Leaderboard</h5>
                        {leaderboardShowing ? (
                            <span className="leaderboard-arrow" onClick={toggleDashboard}>
                                <i className="fas fa-chevron-left fa-lg"></i>
                            </span>
                        ) : (
                            <span className="leaderboard-arrow" onClick={toggleDashboard}>
                                <i className="fas fa-chevron-right"></i>
                            </span>
                        )}
                    </div>
                    {(topFiveUsers || []).map((item, index) => {
                        return (
                            <React.Fragment key={index}>

                                <div className={'dashboard-leaderboard-row'}>
                                    <div className="rank">
                                        {this.showRankIcon(index)}
                                    </div>
                                    <div className="avatar">
                                        {(item.owner.id !== userProfile.id && item.owner.avatar) && <Avatar profile={item.owner} width={40} height={40} />}
                                    </div>
                                    <div className="user">
                                        <span className="name">{item.owner.username || item.owner.fullName || item.owner.profile.fullName}</span>
                                        {/* <span className="info">{item.info}</span> */}
                                    </div>
                                    <div className="points">
                                        {Number.isInteger(item.totalEnergy) ? item.totalEnergy : Number(item.totalEnergy).toFixed(2)}
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })}
                    <React.Fragment>
                        <div className='dashboard-leaderboard-row primary-bg'>
                            <div className="rank">
                                --
                            </div>
                            <div className="avatar">
                                {userProfile.avatar && <Avatar profile={userProfile} width={40} height={40} />}
                            </div>
                            <div className="user">
                                <span className="name">{userProfile.username || userProfile.fullName}</span>
                                {/* <span className="info">{item.info}</span> */}
                            </div>
                            <div className="points">
                                --
                            </div>
                        </div>
                    </React.Fragment>
                </div>

                <div className={`dashboard-playlist ${showTimeline ? 'dashboard-playlist-narrow' : ''}`}>
                    {/* {(playlist && Object.keys(playlist).length > 0) && (
                        <div className={`playlist-item playlist-song ${this.state.showTimeline ? 'content-hidden' : 'content-visible'}`}>
                            {(playlist.tracks || []).map((track) => {
                                return (
                                    <div className="row py-2 my-2" key={track.id}>
                                        <div className="col-3 my-auto">
                                            <div className="primary-bg p-2 song-icon">
                                                <MusicNoteIcon width={24} height={24} />
                                            </div>
                                        </div>
                                        <div className="col-9 my-auto song-details">
                                            <h5>{track.artistAppearsAs}</h5>
                                            <p>{track.trackTitle}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )} */}

                    {/*
                    <div className={`playlist-item playlist-monitor`}>
                        <div className="row h-100">
                            <div className="col-3 my-auto monitor-icon">
                                <span></span>
                                <span></span>
                                <span className="active"></span>
                                <span className="active"></span>
                                <span className="active"></span>
                            </div>
                            <div className={`col-9 my-auto text-center monitor-details ${this.state.focusMode ? 'd-none' : 'd-block'}`}>
                                <h5>Heart Rate</h5>
                                <strong>125</strong>
                                <small>bpm</small>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="playlist-item playlist-focus">
                        <div className="row">
                            <div className="col-3 my-auto focus-icon">
                                <button className={this.state.focusMode ? 'plain-btn btn-highlighted' : 'plain-btn'} onClick={ () => this.setFocusMode() }>
                                    <div className="outer-circle">
                                        <div className="inner-circle">&nbsp;</div>
                                    </div>
                                </button>
                            </div>

                            <div className={`col-9 my-auto text-center focus-details ${this.state.focusMode ? 'd-none' : 'd-block'}`}>
                                <h5>Focus mode off</h5>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="dashboard-stats">
                    <div className="row d-none">
                        <div className="col-3 left-stats">
                            <div className="row">
                                <div className="col-6">
                                    <button className="btn-light-transparent"><MileIcon width={24} height={22} /> 24.8 <small>mi</small></button>
                                </div>
                                <div className="col-6 pl-0">
                                    <button className=" btn-light-transparent"><MileBoardIcon width={30} height={24} /> 18.5 <small>mph</small></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 middle-stats">

                        </div>
                        <div className="col-3 right-stats">
                            <div className="row">
                                <div className="col-6">
                                    <button className="btn-light-transparent"><FireIcon width={24} height={30} /> 892 <small>kcal</small></button>
                                </div>
                                <div className="col-6 pl-0">
                                    <button className=" btn-light-transparent"><StormIcon width={22} height={32} /> 219.4 <small>kj</small></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(activeUserSession.isCurrent && classSession && Object.keys(classSession).length !== 0 && this.state.fullVideoUrl) ? <VideoPlayer
                    ref={this.videoPlayerRef}
                    onVideoEnded={controlFeedbackPopup}
                    onPlaying={onPlaying}
                    url={this.state.fullVideoUrl}
                    showTimeline={showTimeline}
                /> : <div className="video-not-found-placeholder">
                    <h2 className="mb-3">Video is not available.</h2>
                    <button className="btn btn-dark" onClick={() => this.props.history.goBack()}>Go back</button>
                </div>}
            </div>
        )
    }
}

const mapStateToProps = ({ classStore, simulationStore, userStore }) => {
    return {
        ...classStore,
        ...simulationStore,
        userCan: userStore.userCan,
        userProfile: userStore.userProfile,
        userSessionLeaderboard: userStore.userSessionLeaderboard,
        currentUserSessionLeaderboard: userStore.currentUserSessionLeaderboard,
        userAccount: userStore.userAccount
    };
};

export default connect(mapStateToProps, {
    ...analyticsActions,
    ...classActions,
    ...simulationActions,
    ...userActions
})(DashboardPage);
