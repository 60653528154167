import styled from 'styled-components/macro';

export const PaginationContainer = styled.div`
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
`;

export const ListContainer = styled.div`
    display: block;
    flex: 1 1;
    overflow: auto;
    background-color: white;
    padding-bottom: 3rem;
`;

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
`;

export const ListDivider = styled.li`
    position: relative;
    display: block;
    padding: 0.8rem 0.5rem;
    background: none;
    background-color: #f2f2f2;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #828282;
`;

export const ListDividerCount = styled.span`
    float: right;
`;

export const ListItem = styled.div``;

export const Item = styled.div`
    color: #828282;
    text-decoration: none;
`;

export const ItemInner = styled.li`
    position: relative;
    display: block;
    padding: 1rem 1.75rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 0;
    &:last-child {
        border-radius: 0;
    }
`;

export const PaginationHeader = styled.div``;
export const PaginationListWrapper = styled.div``;
export const PaginationListEmpty = styled.div``;

export const UserProfileHelper = styled.div``;