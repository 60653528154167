import React from 'react';
import classNames from 'classnames';
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive';
import { connect } from "react-redux";
import { Formik, Form, Field } from 'formik';
import { LoginSchema } from '../../cross-platform/common/validation-schemas';

import modes from '../../cross-platform/references/modes';
import SignIn from '../../cross-platform/containers/auth/sign-in';
import * as actions from '../../cross-platform/actions/auth-actions';
import LogoIcon from '../../components/elements/icons/logo-icon';

class Login extends SignIn {

    componentDidMount() {
        this.props.resetErrorMessage();
    }

    signIn = (values) => {
        this.setState({
            ...values
        }, () => {
            this._signIn()
            .then(() => {
                this.props.history.push("/");
            });
        });
    }

    render() {
        const { mode, errorMessage } = this.state;
        return (
            <div className="auth">
                <div className="row">
                    <div className="col-xl-9 col-lg-10 align-self-center mx-auto top-logo-wrapper">
                        <div className="text-center">
                            <MediaQuery maxWidth={768}>
                                <Link to="/login">
                                    <LogoIcon width={25} height={25} />
                                    <h4 className="logo-text">Stryde</h4>
                                </Link>
                            </MediaQuery>
                            <MediaQuery minWidth={769}>
                                <Link to="/login">
                                    <img className="logo"
                                    src="/Logo.png"
                                    alt="img" />
                                </Link>
                            </MediaQuery>
                        </div>
                    </div>
                </div>
                <div className="row h-100">
                    <div className="col-xl-9 auth-content-wrapper col-lg-10 align-self-center mx-auto">
                        <div className="row">
                            <div className="col-md-6 col-xs-12 background-image link-box shadow-sm">
                                <div className="row h-100">
                                    <div className="col-12 col-md-12 align-self-center m-auto p-0">
                                        <i className="fas fa-quote-left"></i>
                                        <blockquote className="quote-content pb-8 pt-5">
                                            If you need me, I’ll be on my bike <strong>FOREVER</strong>. So many amazing classes; how in the world am I supposed to choose?!
                                        </blockquote>
                                        <cite>Rachel Libowitz</cite>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12 bg-white form-box shadow-sm">
                                <div className="row h-100">
                                    <div className="col-12 p-4 align-self-center login-form-wrapper">
                                        {mode === modes.WITH_ERROR && (
                                            <div className="alert alert-danger">
                                                <i className="fas fa-exclamation-circle pr-2"></i>
                                                {errorMessage}
                                            </div>
                                        )}
                                        <h2>
                                            Login
                                        </h2>
                                        <Formik
                                            initialValues={{
                                                email: '',
                                                password: ''
                                            }}
                                            validationSchema={LoginSchema}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            onSubmit={(values) => this.signIn(values)}
                                        >
                                            {({ errors, touched }) => (
                                                <Form className="form-group mt-5 mb-5">
                                                <label htmlFor="email">Email</label>
                                                <Field name="email" className={classNames('form-control', {'is-invalid': errors.email && touched.email})} placeholder="Email" />
                                                <div className="mt-3">
                                                    <label htmlFor="password">Password</label>
                                                    <Field name="password" className={classNames('form-control', {'is-invalid': errors.password && touched.password})} placeholder="Password" type="password" />
                                                    
                                                    {/* <input type="password" className="form-control"
                                                        name="password"
                                                        placeholder="Password"
                                                        value={this.props.password}
                                                        onChange={this.props.onChange}></input> */}
                                                </div>
                                                
                                                <Link to="/reset-password" className="float-right forgot-pwd-link my-2">Forgot Password?</Link>
                                                <button
                                                    type="submit"
                                                    className="btn btn-block btn-main btn-primary float-right p-3 mt-5 mb-2"
                                                    disabled={mode === modes.FETCHING}>
                                                    Login
                                                </button>
                                                <Link to="/sign-up"
                                                className="btn btn-block btn-light float-right p-3">
                                                    Don’t have an account yet?
                                                </Link>
                                            </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="copyrights-content text-center">
                                <p>&copy;2020 Stryde Bike. All Rights Reserved.</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ authStore }) => {
    return {
        ...authStore
    };
};

export default connect(mapStateToProps, actions)(Login);