import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const pageSizes = [10, 20, 25, 50];
const orderOptions = [{
    label: 'Started',
    value: 'participation_count'
}, {
    label: 'Survey',
    value: 'feedback_count'
},
{
    label: 'Favorited',
    value: 'favorite_count'
}];

const isFirstPage = (page) => {
    return page <= 1;
};

const isLastPage = (page, total, pageSize) => {
    return page === (total / pageSize);
};

const Pagination = ({ page, pageSize, selectPageSize, setPage, total }) => {
    const previous = () => {
        if (isFirstPage(page)) return;
        setPage(parseInt(page - 1));
    }

    const next = () => {
        if (isLastPage(page, total, pageSize)) return;
        setPage(parseInt(page + 1));
    }

    const pages = () => {
        if (!total) return;
        const pages = total / pageSize;
        const pagesArray = [];
        for (let index = 1; index < pages; index++) {
            pagesArray.push(<option value={index} key={index}>{index}</option>);
        }
        return pagesArray;
    }

    const selectPage = (event) => {
        setPage(parseInt(event.target.value));
    }

    const onSelectPageSize = (event) => {
        selectPageSize(parseInt(event.target.value));
    }

    return (
        <div className="pagination">
            <div>
                <button className={`btn btn-primary`} disabled={isFirstPage(page)} onClick={previous}>
                    Previous
            </button>
                <select className="custom-select select-page" onChange={selectPage} value={page}>
                    {pages()}
                </select>
                <button className={`btn btn-primary`} disabled={isLastPage(page, total, pageSize)} onClick={next}>
                    Next
            </button>
                <span className="total-items">
                    '{total}' total class sessions
            </span>
            </div>
            {/* <div>
                <span>
                    Order by
                </span>
                <select className="custom-select select-order" onChange={selectOrderBy} value={orderBy}>
                    {orderOptions.map(options =>
                        <option value={options.value} key={options.value}>{options.label}</option>
                    )}
                </select>
            </div> */}
            <div>
                <span>Page size:</span>
                <select className="custom-select select-page" onChange={onSelectPageSize} value={pageSize}>
                    {pageSizes.map(sizes =>
                        <option value={sizes} key={sizes}>{sizes}</option>
                    )}
                </select>
            </div>
        </div>
    )
}

Pagination.defaultProps = {
    page: 1,
    total: 0,
    pageSize: 0
}

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired
}

export default Pagination;