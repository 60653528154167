import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import Navigation from '../../../../components/navigation';
import { fetchUserProfiles, switchUserProfile, setUserProfile, openProfilePage } from '../../../../cross-platform/actions/user-actions';

import Avatar from '../../../../components/avatar';
import ProfileIcon from '../../../../images/user-2.svg';
import AddProfileIcon from '../../../../images/add-ico.svg';
import PlusIcon from '../../../../components/elements/icons/plus-icon';
import CogIcon from '../../../../components/elements/icons/cog-icon';
import EditIcon from '../../../../components/elements/icons/edit-icon';

const MAXIMUM_NUMBER_OF_PROFILES = 4;

class ProfileManagerPage extends React.Component {

  state = {
    manageProfiles: false
  };

  selectUserProfile = (profile) => {
    this.props.openProfilePage();
    this.props.switchUserProfile(profile);
    this.switchUserProfile(profile);
    this.props.history.push('/');
  }

  switchUserProfile = (userProfile) => {
    this.props.setUserProfile(userProfile);
  }

  componentDidMount() {
    this.props.fetchUserProfiles();
    this.setState({
      manageProfiles: this.props.location.pathname === '/profiles/manage'
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        manageProfiles: nextProps.location.pathname === '/profiles/manage'
      });
    }
  }

  toggleManageProfiles = () => {
    this.setState({
      manageProfiles: !this.state.manageProfiles
    });
  }

  render() {
    const freeProfiles = [...Array(MAXIMUM_NUMBER_OF_PROFILES - this.props.userProfiles.length).keys()];

    return (
      <div className="container-fluid classes profile-manage-wrapper">
        <div className="row">
          <div className="profiles-container">
            <div className="profiles-centered">
              <h2 className="profiles-label">Who's Exercising</h2>
              <div className={`profiles-list ${this.state.manageProfiles ? 'manage-profiles-edit' : ''}`}>
                {(this.props.userProfiles || []).map((profile) => {
                  return (
                    <div key={profile.id} className="profile-item">
                      <div className="profile-item-image">
                        {(profile.fullName || profile.username) && <div className="avatar">
                          <MediaQuery maxWidth={768}>
                            <Avatar profile={profile} width={180} height={180} />
                          </MediaQuery>
                          <MediaQuery minWidth={769}>
                            <Avatar profile={profile} width={240} height={240} />
                          </MediaQuery>
                        </div>}
                        {!this.state.manageProfiles ? (
                          <div className="profile-select" onClick={() => this.selectUserProfile(profile)}></div>
                        ) : (
                            <Link to={`/profiles/${profile.id}/editor`} className="profile-select">
                              <EditIcon width={54} height={54} color={'#FCB333'} fill={'rgba(252, 179, 51, 0.5)'} />
                            </Link>
                          )}
                      </div>
                      <div className="profile-item-label">{profile.fullName}</div>
                    </div>
                  )
                })}

                {freeProfiles.map((item, index) => {
                  return (
                    <div className="profile-item" key={index}>
                      {freeProfiles.length - 1 === item ? (
                        <>
                          <Link className="profile-item-image profile-add-btn" to="/profiles/new/editor">
                            <MediaQuery maxWidth={768}>
                              <PlusIcon width={43} height={43} />
                            </MediaQuery>
                            <MediaQuery minWidth={769}>
                              <PlusIcon width={86} height={86} />
                            </MediaQuery>
                          </Link>
                          <div className="profile-item-label font-weight-bolder">Add Profile</div>
                        </>
                      ) : (
                          <div className="profile-item-image">
                            <img src={ProfileIcon} alt="" />
                          </div>
                        )}
                    </div>
                  )
                })}
              </div>

              {this.props.location.pathname === '/profiles' ? (
                <Link to='/profiles/manage' className="btn btn-secondary manage-profiles-button">
                  <CogIcon width={22} height={22} /> Manage Profiles
                </Link>
              ) : (
                  <Link to='/profiles' className="btn btn-secondary manage-profiles-button">
                    Done
                </Link>
                )}
            </div>
          </div>
          <Navigation />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ userStore }) => {
  return {
    userProfiles: userStore.userProfiles
  };
};

export default connect(mapStateToProps, { fetchUserProfiles, switchUserProfile, setUserProfile, openProfilePage })(ProfileManagerPage);