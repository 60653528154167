import BaseService from './base-service';
import getBrowserFingerprint from 'get-browser-fingerprint';

class ActiveUserSessionService extends BaseService {
  getActiveUserSession() {
    const options = {
      path: '/active-user-sessions',
    };

    return this._apiGet(options);
  }

  saveActiveUserSession(activeUserSession) {
    const options = {
      path: '/active-user-sessions',
      data: {
        ...activeUserSession,
      },
    };

    return this._apiPut(options);
  }

  isCurrentActiveSession(activeUserSession) {
    return activeUserSession.uniqueDeviceId === getBrowserFingerprint();
  }
}

export default new ActiveUserSessionService();
