// eslint-disable-next-line
import AsyncStorage from '../../platform-specific/async-storage';
import AWS from 'aws-sdk';
import config from '../../config/config';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
const oauth = {
  // Domain name
  domain: config.aws.cognitoDomain,

  // Authorized scopes
  scope: ['email', 'profile', 'openid'],

  // Callback URL
  redirectSignIn: config.aws.cognitoRedirectSignin,

  // Sign out URL
  redirectSignOut: config.aws.cognitoRedirectSignout,

  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  responseType: 'token',

  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true,
  },
};
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    identityPoolId: config.aws.cognitoIdentityPoolId,
    region: config.aws.region,
    userPoolId: config.aws.cognitoUserPoolId,
    userPoolWebClientId: config.aws.cognitoUserPoolWebClientId,
    redirectSignOut: config.aws.cognitoRedirectSignout,
    oauth: oauth,
  },
  // ...
});
class AuthService {
  authenticate = ({ username, password }) => {
    console.log('username', username);
    return Auth.signIn(username, password);
  };
  authenticateWithFacebook = (email, token, expires_at) => {
    console.log('Authenticating with federated signin:', [email, token, expires_at]);
    return Auth.federatedSignIn('facebook', { token, expires_at }, { email });
  };
  authenticate2 = (token) => {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.aws.cognitoIdentityPoolId,
      Logins: {
        'accounts.google.com': token.idToken,
      },
    });
  };

  customizeToken = (idToken) => {
    const $this = this;
    const options = { auth: false, data: { idToken: idToken }, path: `/auth/token` };

    return new Promise((resolve, reject) => {
      $this
        ._apiPost(options)
        .then((result) => {
          resolve(result);
        })
        .catch(reject);
    });
  };
  getCurrentSession = () => {
    return Auth.currentSession();
  };
  getJwtToken = async () => {
    const authSession = await this.getCurrentSession();
    return authSession.idToken.jwtToken;
  };
  /**
   * BypassCache should be true if we want to refresh the session token
   */
  getCurrentAuthenticatedUser = (bypassCache = false) => {
    //cognitoUser.signInUserSession.accessToken.jwtToken
    return Auth.currentAuthenticatedUser({ bypassCache });
  };

  refreshCurrentUserSession = async (bypassCache = false) => {
    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache })
      .catch(err => {
        console.log('currentAuthenticatedUser', { err })
      })
    const currentSession = await Auth.currentSession()
      .catch(err => {
        console.log('currentSession', { err })
      })
    return new Promise((resolve, reject) => {
      if (currentSession) {
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          if (err) {
            reject(err);
          }
          // const { idToken, refreshToken, accessToken } = session;
          resolve(session);
        });
      }
    });
  }


  /**
   * This method refreshes the id_token after it is verified to update any customisation done in API on verification.
   * After the refresh the id_token is set in sessionStorage
   *
   * */

  refreshToken = async (result) => { };

  signUp = (data) => {
    const { username, password, ...attributes } = data;

    return Auth.signUp({
      username,
      password,
      attributes,
    });
  };
  signOut = () => {
    return Auth.signOut();
  };
  fetchCurrentAuthenticatedUser = () => {
    return Auth.currentAuthenticatedUser();
  };
  resendCode = (username) => {
    return Auth.resendSignUp(username);
  };
  confirmSignUp = (username, code) => {
    return Auth.confirmSignUp(username, code);
  };
  forgotPasswordRequest = (username) => {
    return Auth.forgotPassword(username);
  };
  forgotPasswordSubmit = (username, code, password) => {
    return Auth.forgotPasswordSubmit(username, code, password);
  };
  changePassword = (currentPassword, newPassword) => {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.changePassword(user, currentPassword, newPassword),
    );
  };
  updateUserAttributes = (attributes) => {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.updateUserAttributes(user, attributes),
    );
  };
  fetchUserAttributes = () => {
    return Auth.currentAuthenticatedUser().then((user) => Auth.userAttributes(user));
  };
  // TODO convert to inherited model, instead of checking platform
  getUserProfileId = async () => {
    // console.log("CONFIG_PLATFORM", config.platform);
    if (config.platform === 'web')
      return await new Promise((resolve) =>
        resolve(localStorage.getItem(config.constants.PROFILE_ID_KEY)),
      );
    return await AsyncStorage.getItem(config.constants.PROFILE_ID_KEY);
  };
  // TODO convert to inherited model, instead of checking platform
  setUserProfileId = async (profileId) => {
    if (config.platform === 'web')
      return await new Promise((resolve) =>
        resolve(localStorage.setItem(config.constants.PROFILE_ID_KEY, profileId)),
      );
    return await AsyncStorage.setItem(config.constants.PROFILE_ID_KEY, profileId);
  };
  currentCredentials = () => {
    return Auth.currentCredentials().then((credentials) => {
      return Promise.resolve(Auth.essentialCredentials(credentials));
    });
  };
  isKatanaAdmin(userGroups) {
    if (!userGroups || userGroups.length === 0) return;
    return userGroups.includes(this.userGroups.admin);
  }
  hasActiveSubscription(userSession) {
    // TODO Remove this line of code, just for test.
    let activeSubscription = !!(
      userSession &&
      userSession.idToken &&
      userSession.idToken.payload &&
      userSession.idToken.payload['active_subscription'] &&
      userSession.idToken.payload['active_subscription']
    );
    // // console.log('hasActiveSubscription', activeSubscription);

    return activeSubscription;
  }

  userGroups = {
    admin: 'KatanaAdminGroup',
  };
}
export default new AuthService();
