import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import Ratings from 'react-ratings-declarative';
import { Link } from 'react-router-dom';
import { buildUrlFromUriWithBasePath, buildBadgeImageUrl } from '../helpers/class-url';
import classService from '../cross-platform/services/class-service';
import FavoriteIcon from './elements/favorite-icon';
import AchievementBadge from './elements/achievement-badge';

import CheckboxIcon from './elements/icons/checkbox-icon';
import ClockIcon from './elements/icons/clock-icon';
import PersonIcon from './elements/icons/person-icon';
import WeightsIcon from './elements/icons/weights-icon';

const setFavorite = async (event, props) => {
  event.preventDefault();
  const { classId, favorite, setSessionAsFavorite } = props;
  const body = {
    isFavorite: !favorite,
  };

  // set state before API call
  setSessionAsFavorite(classId, !favorite);

  return await classService.setFavoriteClassSession(classId, body).then((_) => {
    setSessionAsFavorite(classId, !favorite);
  }).catch(() => {
    setSessionAsFavorite(classId, favorite);
  });
};

function ItemWidget(props) {
  /** TODO: remove this when ratings are added */
  const rating = Math.random() * (5 - 4) + 4;
  const ratingPercent = Math.floor(rating / 5 * 100);

  return (

    <div className={`col-12 ${props.itemClass ? props.itemClass : 'col-md-4'} p-0 text-white item-widget`}>
      <Link to={props.link} className="item-link">
        <div
          className="rounded class m-2 item-widget-bg"
          style={{ backgroundImage: `url('${buildUrlFromUriWithBasePath(props.coverImage)}')` }}
        >
          <div className="item-wrapper">
            <div className="item-dark-bg" />
            <div className="item-dark-bg-bottom" />
            <div className="studio-name d-flex justify-content-between align-items-center">
              <p>
                {props.title}
                {' '}
              </p>
            </div>
            {/* <h5 className="ml-4 d-flex align-items-center">{(props.title || '').toUpperCase()} {props.watched && <CheckboxImg />}</h5>  */}
            <div className="ml-4 mb-2 ratings d-none">
              <Ratings
                rating={rating}
                widgetDimensions="20px"
                widgetSpacings="3px"
              >
                <Ratings.Widget widgetRatedColor="#FCB333" />
                <Ratings.Widget widgetRatedColor="#FCB333" />
                <Ratings.Widget widgetRatedColor="#FCB333" />
                <Ratings.Widget widgetRatedColor="#FCB333" />
                <Ratings.Widget widgetRatedColor="#FCB333" />
              </Ratings>
              <small>
                {ratingPercent}
                %
              </small>
            </div>
            <p className="mt-n1 ml-4 mb-4 font-weight-light instructor">
              {props.studioName}
              <span className="p-2">&bull;</span>
              {props.createdOn && moment(props.sessionDateTime).format('MMMM YYYY')}
            </p>

            <div className="ml-4 mb-2 info">
              <button className="btn-dark mr-2">
                <MediaQuery maxWidth={768}>
                  <PersonIcon width={12} height={12} />
                </MediaQuery>
                <MediaQuery minWidth={769}>
                  <PersonIcon width={20} height={20} />
                </MediaQuery>
                {props.instructor && props.instructor.fullName}

              </button>
              <button className="btn-dark mr-2">
                <MediaQuery maxWidth={768}>
                  <ClockIcon width={12} height={12} />
                </MediaQuery>
                <MediaQuery minWidth={769}>
                  <ClockIcon width={20} height={20} />
                </MediaQuery>
                {props.duration}
                {' '}
                min
              </button>
              {props.hasWeights && (
                <button className="btn-dark">
                  <MediaQuery maxWidth={768}>
                    <WeightsIcon width={12} height={12} />
                  </MediaQuery>
                  <MediaQuery minWidth={769}>
                    <WeightsIcon width={20} height={20} />
                  </MediaQuery>
                  Weights
                </button>
              )}
            </div>
            <div className="class-completed-checkbox">
              {props.watched && <CheckboxIcon width={18} height={18} />}
            </div>
            {props.showFavoriteIcon && <FavoriteIcon classId={props.classId} isFavorite={props.favorite} onClick={(event) => setFavorite(event, props)} />}
            {props.showAchievementsBadge && <AchievementBadge totalRides={props.totalRides} badgePath={buildBadgeImageUrl(`${props.totalRides}`)} />}
            {/* <Link to={props.link} className="btn btn-primary m-4 px-4 py-1">View Class</Link> */}

          </div>
        </div>
      </Link>
    </div>
  );
}

ItemWidget.defaultProps = {
  classId: '',
  coverImage: '',
  studioName: 'Studio name',
  duration: '0',
  title: 'Item title',
  instructor: {
    fullName: 'Instructor',
  },
  createdOn: `${moment().format()}`,
  sessionDateTime: `${moment().format()}`,
  favorite: false,
  showFavoriteIcon: false,
  link: '',
};

ItemWidget.propTypes = {
  classId: PropTypes.string,
  coverImage: PropTypes.string,
  studioName: PropTypes.string,
  duration: PropTypes.string,
  title: PropTypes.string,
  instructor: PropTypes.object,
  createdOn: PropTypes.string,
  sessionDateTime: PropTypes.string,
  favorite: PropTypes.bool,
  showFavoriteIcon: PropTypes.bool,
  link: PropTypes.string,
};

export default ItemWidget;
