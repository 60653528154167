import React from 'react';

const LeaderboardRankThirdIcon = ({width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5 0.866025C12.4282 0.330127 13.5718 0.330127 14.5 0.866025L22.7583 5.63397C23.6865 6.16987 24.2583 7.16025 24.2583 8.23205V17.768C24.2583 18.8397 23.6865 19.8301 22.7583 20.366L14.5 25.134C13.5718 25.6699 12.4282 25.6699 11.5 25.134L3.24167 20.366C2.31347 19.8301 1.74167 18.8397 1.74167 17.768V8.23205C1.74167 7.16025 2.31347 6.16987 3.24167 5.63397L11.5 0.866025Z" fill="#CAA25D"/>
        <path d="M11.875 3.51554C12.5712 3.11362 13.4288 3.11362 14.125 3.51554L20.6513 7.28349C21.3474 7.68542 21.7763 8.4282 21.7763 9.23205V16.7679C21.7763 17.5718 21.3474 18.3146 20.6513 18.7165L14.125 22.4845C13.4288 22.8864 12.5712 22.8864 11.875 22.4845L5.34872 18.7165C4.65257 18.3146 4.22372 17.5718 4.22372 16.7679V9.23205C4.22372 8.4282 4.65257 7.68542 5.34872 7.28349L11.875 3.51554Z" stroke={color !== '' ? color : 'black'} strokeWidth="1.5"/>
        <path d="M13.0202 8.204C13.5962 8.204 14.0882 8.304 14.4962 8.504C14.9122 8.704 15.2242 8.98 15.4322 9.332C15.6482 9.684 15.7562 10.076 15.7562 10.508C15.7562 11.052 15.6082 11.5 15.3122 11.852C15.0162 12.196 14.6242 12.408 14.1362 12.488V12.548C15.3202 12.9 15.9122 13.596 15.9122 14.636C15.9122 15.364 15.6682 15.956 15.1802 16.412C14.6922 16.86 14.0042 17.084 13.1162 17.084C12.1722 17.084 11.4202 16.852 10.8602 16.388C10.3002 15.916 9.9882 15.208 9.9242 14.264H11.5802C11.6202 14.696 11.7602 15.04 12.0002 15.296C12.2482 15.544 12.5962 15.668 13.0442 15.668C13.4282 15.668 13.7282 15.564 13.9442 15.356C14.1602 15.14 14.2682 14.86 14.2682 14.516C14.2682 14.108 14.1122 13.796 13.8002 13.58C13.4882 13.364 13.0282 13.256 12.4202 13.256H12.0722V11.876H12.4202C13.5402 11.892 14.1002 11.504 14.1002 10.712C14.1002 10.368 14.0002 10.1 13.8002 9.908C13.6002 9.708 13.3282 9.608 12.9842 9.608C12.6242 9.608 12.3282 9.724 12.0962 9.956C11.8642 10.18 11.7322 10.496 11.7002 10.904H10.0442C10.0922 10.04 10.3722 9.376 10.8842 8.912C11.3962 8.44 12.1082 8.204 13.0202 8.204Z" fill={color !== '' ? color : 'black'} />
        </svg>
    );
}

export default LeaderboardRankThirdIcon;