import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import AdminPageHeader from '../../components/admin-page-header';
import Breadcrumbs from '../../components/breadcrumbs';
import classService from '../../../cross-platform/services/class-service';
import editIcon from "../../../images/edit-icon.png";
import refreshIco from '../../../images/refresh-ico.svg';

class ClassBrowser extends Component {

    state = {
        isLoadingClasees: false,
        listOfClasses: []
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const $this = this;

        $this.setState({ isLoadingClasees: true });
        // api call
        classService.fetchClasses({ mode: 'full' })
            .then(classesListResponse => {
                $this.setState({
                    isLoadingClasees: false,
                    listOfClasses: $this._sortInAscOrderByTitle(classesListResponse)
                })
            })
            .catch(error => {
                $this.setState({
                    isLoadingClasees: false
                })
            })
    }

    render() {
        return (
            <div>
                <AdminPageHeader pageTitle={'Available Classes'}>
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumb-nav">
                                <Breadcrumbs crumbs={[{ title: 'Classes' }]} ></Breadcrumbs>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <h2 className="admin-page-title">Available Classes</h2>
                        </div>
                        <div className="col-6">
                            <div className="main-page-header-toolbar text-right">
                                <button onClick={this.loadData} className="btn default-admin-button"><img className="katana-svg-icon" src={refreshIco} alt="o" />Refresh</button>
                                <Link to={`/admin/classes/new/details`} className="btn primary-admin-button"><i className="fas fa-plus mr-2"></i>New</Link>
                            </div>
                        </div>
                    </div>
                </AdminPageHeader>
                <div className="admin-page-main-content">
                    <table className="table table-responsive-lg">
                        <tbody>
                            <tr>
                                <th className="column-30-width">Class Title</th>
                                <th className="column-30-width">Studio</th>
                                <th className="column-30-width">Number of Sessions</th>
                                <th className="text-center" width="150">Action</th>
                            </tr>
                            {this.state.isLoadingClasees ?
                                <tr>
                                    <th colSpan="4" className="text-center"><span>Loading data...</span></th>
                                </tr>
                                :
                                <React.Fragment>
                                    {this.state.listOfClasses.length > 0 ?
                                        this.state.listOfClasses.map((classItem, index) =>
                                            <tr key={index}>
                                                <td className="column-30-width">{classItem.title}</td>
                                                <td className="column-30-width">{classItem.studioName}</td>
                                                <td className="column-30-width pr-80p">{classItem.sessionCount}</td>
                                                <td className="text-center" width="150">
                                                    <Link to={`/admin/classes/${classItem.id}/details`}
                                                        className="primary-edit-button"><img src={editIcon} alt="edit" /></Link>
                                                </td>
                                            </tr>)
                                        :
                                        <tr>
                                            <th colSpan="4" className="text-center"><span>No data</span></th>
                                        </tr>
                                    }
                                </React.Fragment>}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    _sortInAscOrderByTitle = (data) => {
        if (!data) return;

        return data.sort(function (a, b) {
            return (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1;
        });
    }
}
export default ClassBrowser;