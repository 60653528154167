import * as types from '../actions/action-types';

const initialState = {
  code: '',
  email: '',
  errorMessage: '',
  mode: null,
  name: '',
  password: '',
  terms: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_ON_CHANGE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case types.AUTH_MODE:
      return {
        ...state,
        mode: action.mode,
      };
    case types.AUTH_MODE_ERROR:
      return {
        ...state,
        mode: action.mode,
        errorMessage: action.errorMessage,
      };
    case types.AUTH_MODE_ERROR_RESET:
      return {
        ...state,
        mode: action.mode,
        errorMessage: '',
      };
    default:
      return state;
  }
};
