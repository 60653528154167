import React from 'react';
import PropTypes from 'prop-types';

const nameToInitials = (fullName) => {
  if (!fullName || fullName === '') return '';
  const namesArray = fullName.trim().split(' ');
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
  return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
};

const AvatarBadge = ({ text, width, height }) => {
  switch (text.toString().toLowerCase()) {
    case 'a':
    case 'n':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint1_linear)" />
          <defs>
            <linearGradient
              id="paint1_linear"
              x1="43"
              y1="86"
              x2="43"
              y2="6.4075e-07"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7028E4" />
              <stop offset="1" stopColor="#E5B2CA" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'b':
    case 'o':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint2_linear)" />
          <defs>
            <linearGradient
              id="paint2_linear"
              x1="43"
              y1="86"
              x2="43"
              y2="-1.08954e-06"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#13547A" />
              <stop offset="1" stopColor="#80D0C7" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'c':
    case 'p':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint3_linear)" />
          <defs>
            <linearGradient
              id="paint3_linear"
              x1="43"
              y1="86"
              x2="43"
              y2="6.4075e-07"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00E3AE" />
              <stop offset="1" stopColor="#9BE15D" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'd':
    case 'q':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint4_linear)" />
          <defs>
            <linearGradient
              id="paint4_linear"
              x1="0"
              y1="0"
              x2="0"
              y2="86"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F9D423" />
              <stop offset="1" stopColor="#E14FAD" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'e':
    case 'r':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint5_linear)" />
          <defs>
            <linearGradient
              id="paint5_linear"
              x1="43"
              y1="86"
              x2="43"
              y2="6.4075e-07"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4481EB" />
              <stop offset="1" stopColor="#04BEFE" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'f':
    case 's':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint6_linear)" />
          <defs>
            <linearGradient
              id="paint6_linear"
              x1="43"
              y1="0"
              x2="43"
              y2="86"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FA71CD" />
              <stop offset="1" stopColor="#C471F5" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'g':
    case 't':
      return (
        <svg
          width={width}
          height={width}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint7_linear)" />
          <defs>
            <linearGradient
              id="paint7_linear"
              x1="43"
              y1="86"
              x2="43"
              y2="0"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3D4E81" />
              <stop offset="0.478729" stopColor="#5753C9" />
              <stop offset="1" stopColor="#6E7FF3" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'h':
    case 'u':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint8_linear)" />
          <defs>
            <linearGradient
              id="paint8_linear"
              x1="43"
              y1="-1.72387e-06"
              x2="43"
              y2="86"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF9944" />
              <stop offset="1" stopColor="#FC6076" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'i':
    case 'v':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint9_linear)" />
          <defs>
            <linearGradient
              id="paint9_linear"
              x1="43"
              y1="86"
              x2="43"
              y2="0"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3584A7" />
              <stop offset="1" stopColor="#30D2BE" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'j':
    case 'w':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint10_linear)" />
          <defs>
            <linearGradient
              id="paint10_linear"
              x1="43"
              y1="86"
              x2="43"
              y2="3.20375e-07"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#0C3483" />
              <stop offset="1" stopColor="#6B8CCE" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'k':
    case 'x':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint11_linear)" />
          <defs>
            <linearGradient
              id="paint11_linear"
              x1="43"
              y1="86"
              x2="43"
              y2="-6.4075e-07"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#495AFF" />
              <stop offset="1" stopColor="#0ACFFE" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'l':
    case 'y':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint12_linear)" />
          <defs>
            <linearGradient
              id="paint12_linear"
              x1="43"
              y1="0"
              x2="43"
              y2="86"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FBC8D4" />
              <stop offset="1" stopColor="#9795F0" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 'm':
    case 'z':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint13_linear)" />
          <defs>
            <linearGradient
              id="paint13_linear"
              x1="43"
              y1="0"
              x2="43"
              y2="86"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFB199" />
              <stop offset="1" stopColor="#FF0844" />
            </linearGradient>
          </defs>
        </svg>
      );

    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="43"
              y1="86"
              x2="43"
              y2="6.4075e-07"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#48474a" />
              <stop offset="1" stopColor="#cecece" />
            </linearGradient>
          </defs>
        </svg>
      );
  }
};

const extractNameFromProfile = (profile) => {
  if (!profile) return '';
  if (profile.username) return profile.username;
  if (profile.fullName) return profile.fullName;
  if (profile.profile.fullName) return profile.profile.fullName;
  return '';
};

const Avatar = ({
  profile, width, height, showText = true,
}) => {
  const initials = nameToInitials(profile.username || profile.fullName || profile.profile.fullName);
  const avatarChar = extractNameFromProfile(profile).charAt(0);
  return (
    <>
      <AvatarBadge text={avatarChar} width={width} height={height} />
      {showText && (
        <span className={initials.length > 1 ? 'initials' : 'initials initials-one'}>
          {initials}
        </span>
      )}
    </>
  );
};

Avatar.defaultProps = {
  profile: {
    username: '',
    fullName: '',
  },
  width: 40,
  height: 40,
};

Avatar.propTypes = {
  profile: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Avatar;
