import React from 'react'

const AchievementsIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke={color !== '' ? color : '#828282'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 7.1875L13.4871 10.3553L16.8125 10.8665L14.4062 13.3309L14.9741 16.8125L12 15.1679L9.02587 16.8125L9.59375 13.3309L7.1875 10.8665L10.5129 10.3553L12 7.1875Z" stroke={color !== '' ? color : '#828282'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default AchievementsIcon;