import React, { Component } from 'react';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classSession: null,
      currentUserSessionLeaderboard: [],
      topFiveUsers: [],
      didUserCompleteFeedback: null,
      leaderboardShowing: false,
      showVideoFeedback: false,
    };
  }
}

export default Dashboard;
