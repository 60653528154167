import React from 'react'

const HideIcon = ({ width = 34, height = 26, color = 'white' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <path d="M7 1L27 23M20.3634 15.6997C19.3821 16.5916 18.0868 17.0572 16.7623 16.9941C15.4378 16.9309 14.1926 16.3443 13.3006 15.3631C12.4086 14.3819 11.9429 13.0866 12.0059 11.7621C12.069 10.4376 12.6555 9.19233 13.6366 8.30027M10.2493 4.5742C5.15315 7.15489 3 12 3 12C3 12 7 20.999 17 20.999C19.343 21.0177 21.6567 20.478 23.7497 19.4247M27.0762 17.1374C29.8014 14.6965 31 12 31 12C31 12 27 2.99902 17 2.99902C16.1339 2.99761 15.2692 3.06802 14.4147 3.20954M17.9409 7.08847C19.0036 7.29254 19.9715 7.83567 20.6995 8.63639C21.4274 9.43711 21.876 10.4523 21.9782 11.5296" stroke={color} strokeWidth="2" strokeLinecap="round"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0.905273" y="0" width="32.1886" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="0.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>

    );
}

export default HideIcon;