import React from 'react';
// import ReactDOM from 'react-dom';
import MediaQuery from 'react-responsive';
import videojs from 'video.js';
import '@videojs/http-streaming';

import PlayIcon from '../components/elements/icons/play-icon';
import PauseIcon from '../components/elements/icons/pause-icon';
import FlagIcon from '../components/elements/icons/flag-icon';
import config from '../config/config';

class VideoPlayer extends React.Component {

    state = {
        currentTime: 0,
        duration: 0,
        remainingTime: null,
        animate: false,
        initialized: false,
        isPaused: false,
        showControls: true,
        actionClassnames: ''
    }

    constructor(props) {
        super(props);
        this.videoJsWrapper = React.createRef();
    }

    secondsToTime = (time) => {
        const hours = Math.floor(time / (60 * 60));

        const divisor_mins = time % (60 * 60);
        const minutes = Math.floor(divisor_mins / 60);

        const divisor_seconds = divisor_mins % 60;
        const seconds = Math.ceil(divisor_seconds);

        const hours_formatted = hours < 10 ? ('0' + hours) : hours;
        const minutes_formatted = minutes < 10 ? ('0' + minutes) : minutes;
        const seconds_formatted = seconds < 10 ? ('0' + seconds) : seconds;
        return hours_formatted + ':' + minutes_formatted + ':' + seconds_formatted;
    }

    initializePlayer = (fullVideoUrl) => {
    // base playlist URL that contains the signed query params
    const baseURL = new URL(fullVideoUrl);
    // this gets executed before each request in the m3u8 playlist
    videojs.Hls.xhr.beforeRequest = function (options) {
        // requests for the parts of the video
        const requestURL = new URL(options.uri);
        // mount the signed params to the request
        requestURL.search = baseURL.search;
        options.uri = requestURL;
        return options;
    };

    this.player = videojs(this.videoNode, this.props, () => {
            this.setState({ initialized: true });
            this.player.on('seeking', this.onSeeking);
            this.player.on('timeupdate', this.onTimeUpdate);
            this.player.on('seeked', this.onSeeked);
        });
    }

    onSeeking = () => {
        if (this.state.previousTime > this.player.currentTime()) {
            this.player.currentTime(this.state.previousTime);
        }
    }

    onSeeked = () => {
        if (this.state.previousTime > this.player.currentTime()) {
            this.player.currentTime(this.state.previousTime);
        }
    }

    onTimeUpdate = () => {
        const { currentTime } = this.state;
        this.setState({
            currentTime: this.player.currentTime(),
            remainingTime: this.secondsToTime(Math.floor(this.player.duration()) - Math.floor(this.player.currentTime())),
            duration: this.player.duration()
        }, () => {
            setTimeout(() => {
                this.setState({
                    previousTime: currentTime
                });
            }, 0);
        });
    }

    toggleVideoControls = () => {
        this.setState({
            showControls: !this.state.showControls
        });
    }

    togglePlay = () => {
        const isPlaying = !this.player.paused();
        isPlaying ? this.player.pause() : this.player.play();

        let actionClassnames = isPlaying ? 'display-pause-btn' : 'display-play-btn';
        this.setState({
            actionClassnames,
            isPaused: isPlaying
        }, () => {
            actionClassnames = isPlaying ? 'display-pause-btn animate-pause-btn' : 'display-play-btn animate-play-btn';
            setTimeout(() => {
                this.setState({ actionClassnames });
            }, 0);

            setTimeout(() => {
                if (this.state.actionClassnames === actionClassnames) {
                    this.setState({ actionClassnames: '' });
                }
            }, 500);
        });
    }

    componentDidMount = () => {
        this.initializePlayer(this.props.url);
    }

    componentWillUnmount = () => {
        if (this.player) {
            this.player.dispose();
        }
    }

    render() {
        const { remainingTime } = this.state;
        return (
            <div ref={this.videoJsWrapper} className={`video-js-wrapper ${this.state.actionClassnames} ${!this.props.showTimeline ? 'show-controls' : ''}`}>
                {/* {this.state.initialized && (
                    ReactDOM.createPortal(
                        <button onClick={this.toggleVideoControls} className='far fa-arrow-alt-circle-down toggle-controls'></button>,
                        this.videoJsWrapper.current.querySelector('.vjs-custom-control-spacer')
                    )
                )} */}
                <div className="play-pause-container" onClick={this.togglePlay}></div>
                <MediaQuery minWidth={769}>
                    <div className="play-video-btn"><PlayIcon width={188} height={188} /></div>
                    <div className="pause-video-btn"><PauseIcon width={188} height={188} /></div>
                </MediaQuery>

                <MediaQuery maxWidth={768}>
                <div className="play-video-btn"><PlayIcon width={94} height={94} /></div>
                    <div className="pause-video-btn"><PauseIcon width={94} height={94} /></div>
                </MediaQuery>
                
                {!this.props.showTimeline && remainingTime && <div className="remaining-time-container">
                    <button className="dashboard-survey"><FlagIcon width={14} height={18} /> {remainingTime}</button>
                </div>}
                {/* <button onClick={this.toggleVideoControls} className='far fa-arrow-alt-circle-up toggle-controls-up'></button> */}
                {this.props.url && (
                    <div data-vjs-player>
                        <video ref={node => this.videoNode = node} autoPlay controls preload="true" className="video-js" onEnded={this.props.onVideoEnded} onPlaying={this.props.onPlaying}>
                            <source src={this.props.url} type={config.environment === "localhost" ? "" : "application/x-mpegURL"} />
                        </video>
                    </div>
                )}
            </div>
        )
    }
}

export default VideoPlayer;
