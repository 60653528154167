import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import qs from 'query-string';
import Navigation from '../../components/navigation';
import * as analyticsActions from '../../cross-platform/actions/analytics-actions';
import avatars from '../../cross-platform/references/avatars';
import { LEADERBOARD_MONTH_OPTION } from '../../cross-platform/references/constants';

import NavigationAvatar from '../../components/navigation-avatar';
import Avatar from '../../components/avatar';
import LeaderboardRankFirstIcon from '../../components/elements/icons/leaderboard-rank-first-icon';
import LeaderboardRankSecondIcon from '../../components/elements/icons/leaderboard-rank-second-icon';
import LeaderboardRankThirdIcon from '../../components/elements/icons/leaderboard-rank-third-icon';

const LEADERBOARD_ITEMS = [
    { name: 'Current Month', tabId: 0 },
    { name: 'Last Month', tabId: 1 },
    { name: 'All Time', tabId: 2 }
];

class Leaderboard extends Component {
    state = {
        selectedTab: 0,
    }

    componentDidMount() {
        const currentMonth = qs.parse(
            this.props.location.search, 
            { ignoreQueryPrefix: true }
        );

        if(!currentMonth || !currentMonth.month) {
            this.setSelectedTab(0);
            this.props.fetchLeaderboard(LEADERBOARD_MONTH_OPTION.CURRENT_MONTH);
            return;
        }

        const { month } = currentMonth;
        const selectedTab = this.getSelectedMonthValue(month);
        this.setState({ selectedTab });
        this.props.fetchLeaderboard(month);
    }

    showRankIcon = (rank) => {
        if(rank + 1 > 3) {
            return <span className="rank-text">{rank + 1}</span>;
        }

        return (
            <>
            {rank+1 === 1 && <LeaderboardRankFirstIcon width={42} height={40} color="white" />}
            {rank+1 === 2 && <LeaderboardRankSecondIcon width={42} height={40} color="white" />}
            {rank+1 === 3 && <LeaderboardRankThirdIcon width={42} height={40} color="white" />}
            </>
        );
    }

    getSelectedMonthValue(month) {
        switch(month) {
            case LEADERBOARD_MONTH_OPTION.CURRENT_MONTH:
                return 0;
            case LEADERBOARD_MONTH_OPTION.LAST_MONTH:
                return 1;
            case LEADERBOARD_MONTH_OPTION.ALL_TIME:
                return 2;

            default:
                return 0;
        }
    }

    setSelectedTab(selectedTab) {
        let currentMonth = LEADERBOARD_MONTH_OPTION.ALL_TIME;
        switch(selectedTab) {
            case 0:
                currentMonth = LEADERBOARD_MONTH_OPTION.CURRENT_MONTH;
                break;
            case 1:
                currentMonth = LEADERBOARD_MONTH_OPTION.LAST_MONTH;
                break;

            default:
                currentMonth = LEADERBOARD_MONTH_OPTION.ALL_TIME;            
        }
   
        this.setState({ selectedTab }, () => {
            this.props.history.push({
                search: `?month=${currentMonth}`
            });
        });

        this.props.fetchLeaderboard(currentMonth);
    }

    render() {
        const { selectedTab } = this.state;
        const { userProfile } = this.props;
        const sortedLeaderboard = this.props.leaderboard.slice().sort((a, b) => a.totalEnergy < b.totalEnergy ? 1 : -1);
        const currentProfileLeaderboardIndex = (sortedLeaderboard || []).findIndex(item => (item.owner.profile && item.owner.profile.id === this.props.userProfile.id));

        return (
            <div className="container-fluid bg-light leaderboard-page">
                {userProfile.fullName && <NavigationAvatar profile={userProfile} />}
                <div className="row">
                    <div className="col-12 px-0 px-md-3 h-100 leaderboard-content">
                        <div className="row m-0">
                            <div className="col-12 mt-5 ml-3 leaderboard-title">
                                <h2>Leaderboard</h2>
                            </div>
                        </div>
                        <div className="leaderboard-tabs">
                            <ul id="tabs" className="nav nav-tabs nav-fill">
                                {LEADERBOARD_ITEMS.map((item) => {
                                    return (
                                    <li key={`nav-tab-item-${item.tabId}`} className="nav-item">
                                        <a 
                                        className={`nav-link ${ selectedTab === item.tabId ? 'active' : ''}`}
                                        onClick={() => this.setSelectedTab(item.tabId)}
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="row mx-md-3 text-light-font text-center text-md-left leaderboard-header">
                            <div className="col-3 col-xl-1 rank-col">#</div>
                            <div className="col-6 col-md-3 col-xl-3">ATHLETE</div>
                            <div className="col-2 col-xl-1 col-md-2 d-none d-md-block">AVG OUTPUT</div>
                            <div className="col-2 col-xl-2 d-none d-md-block">AVG CADENCE</div>
                            <div className="col-2 col-xl-2 d-none d-xl-block">AVG RESISTANCE</div>
                            <div className="col-4 col-xl-2 col-md-2">TOTAL ENERGY</div>
                            <div className="col-1 col-xl-1 d-none d-md-block">RIDES</div>
                        </div>
                        <div className="h-100 overflow-auto leaderboard-content-wrapper">
                            {sortedLeaderboard.length === 0 && <div className="row m-0 mx-md-3 mt-3 mb-3 text-dark text-center text-md-left rounded leaderboard-row-item bg-white">
                                <div className="col-12 py-3 d-flex align-items-center">
                                    No leaderboard data was found.
                                </div>
                            </div>}

                            {sortedLeaderboard.map((item, index) => (
                                <div key={index} className={`row m-0 mx-md-3 mt-3 mb-3 text-dark text-center text-md-left rounded leaderboard-row-item ${(item.owner.profile && item.owner.profile.id === this.props.userProfile.id) ? 'leaderboard-row-highlighted' : 'bg-white'}`}>
                                    <div className="col-2 col-xl-1 py-3 d-flex align-items-center rank-col leaderboard-row-wrapper">
                                        <div className={`pl-2 ${(item.owner.profile && item.owner.profile.id === this.props.userProfile.id) ? 'text-white' : ''}`}>
                                            {this.showRankIcon(index)}
                                        </div>
                                    </div>
                                    <div className="col-7 col-md-3 col-xl-3 py-3 align-middle leaderboard-row-wrapper leaderboard-avatar-wrapper">
                                        <div className="row m-0 d-flex align-items-center">
                                            <div className="mx-auto mx-md-0 avatar">
                                                {(item.owner.profile && item.owner.profile.avatar) && 
                                                <>
                                                <MediaQuery minWidth={769}>
                                                    <Avatar profile={item.owner.profile} width={60} height={60}/>
                                                </MediaQuery>
                                                <MediaQuery maxWidth={768}>
                                                    <Avatar profile={item.owner.profile} width={40} height={40}/>
                                                </MediaQuery>
                                                </>}
                                            </div>
                                            <div className="mx-auto mx-md-0 avatar-name">
                                                {
                                                    (item.owner.profile && item.owner.profile.fullName) ||
                                                    item.owner.username || item.owner.fullName
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 col-xl-1 col-md-2 py-3 d-none d-md-flex text-right align-middle leaderboard-row-wrapper">
                                        <div className="row m-0 d-flex align-items-center">
                                        <span className="leaderboard-row-text">{Number(item.averagePower).toFixed(0)}</span> <small className="leaderboard-row-small">Watts</small>
                                        </div>
                                    </div>
                                    <div className="col-2 col-xl-2 py-3 d-none d-md-flex text-right align-middle leaderboard-row-wrapper">
                                        <div className="row m-0 d-flex align-items-center">
                                            <span className="leaderboard-row-text">{Number(item.averageCadence).toFixed(0)}</span> <small className="leaderboard-row-small">rpm</small>
                                        </div>
                                    </div>
                                    <div className="col-2 col-xl-2 d-none d-xl-flex py-3 text-right align-middle leaderboard-row-wrapper">
                                        <div className="row m-0 d-flex align-items-center">
                                            <span className="leaderboard-row-text">{Number(item.averageResistance).toFixed(0)}</span> <small className="leaderboard-row-small">%</small>
                                        </div>
                                    </div>
                                    <div className="col-3 col-xl-2 col-md-2 py-3 d-flex text-right align-middle leaderboard-row-wrapper leaderboard-total-energy">
                                        <div className="row m-0 d-flex align-items-center">
                                            <span className="leaderboard-row-text">{Number(item.totalEnergy).toFixed(2)}</span> <small className="leaderboard-row-small">KJ</small>
                                        </div>
                                    </div>
                                    <div className="col-1 col-xl-1 d-none d-md-flex py-3 text-right align-middle leaderboard-row-wrapper">
                                        <div className="row m-0 d-flex align-items-center">
                                            <span className="leaderboard-row-text">{item.totalRides}</span> <small></small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <MediaQuery maxWidth={768}>
                            <>
                            { sortedLeaderboard[currentProfileLeaderboardIndex] && <div className='leaderboard-profile-highlighted'>
                                <div className="row py-3 px-2 leaderboard-row-item">
                                    <div className="col-2">
                                        <div className="pl-2">
                                            {this.showRankIcon(currentProfileLeaderboardIndex)}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row m-0 d-flex align-items-center">
                                            <div className="mx-auto mx-md-0 avatar">
                                                {(sortedLeaderboard[currentProfileLeaderboardIndex].owner.profile && sortedLeaderboard[currentProfileLeaderboardIndex].owner.profile.avatar) && 
                                                <>
                                                <MediaQuery minWidth={769}>
                                                    <Avatar profile={sortedLeaderboard[currentProfileLeaderboardIndex].owner.profile} width={60} height={60}/>
                                                </MediaQuery>
                                                <MediaQuery maxWidth={768}>
                                                    <Avatar profile={sortedLeaderboard[currentProfileLeaderboardIndex].owner.profile} width={40} height={40}/>
                                                </MediaQuery>
                                                </>}
                                            </div>
                                            <div className="mx-auto mx-md-0 avatar-name">
                                                {
                                                    (sortedLeaderboard[currentProfileLeaderboardIndex].owner.profile && sortedLeaderboard[currentProfileLeaderboardIndex].owner.profile.fullName) ||
                                                    sortedLeaderboard[currentProfileLeaderboardIndex].owner.username || sortedLeaderboard[currentProfileLeaderboardIndex].owner.fullName
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 px-0 pt-2">
                                        <div className="row m-0 d-flex align-items-center">
                                                <span className="leaderboard-row-text">{Number(sortedLeaderboard[currentProfileLeaderboardIndex].totalEnergy).toFixed(2)}</span> <small className="leaderboard-row-small">KJ</small>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            </>
                        </MediaQuery>
                    </div>
                    <Navigation />
                </div>
            </div>
        )
    }

    _extractAvatar = (user) => {
        if (user && user.profile && user.profile.avatar)
            return user.profile.avatar;
        return (user && user.avatar) ||
            avatars[0].path;
    }
}

const mapStateToProps = ({ userStore }) => {
    return {
        userProfile: userStore.userProfile,
        leaderboard: userStore.leaderboard
    };
};

export default connect(mapStateToProps, analyticsActions)(Leaderboard);