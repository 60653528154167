import React from 'react'

const StormIcon = ({ width, height, color = '', fill = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 22 32" fill={fill !== '' ? fill : 'none'} xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <path d="M12.8601 11.4146L12.7671 12.5H13.8564H18.2C18.5046 12.5 18.7708 12.6607 18.9056 12.8964C19.0382 13.1296 19.0308 13.4102 18.8852 13.6364L18.8849 13.6368L9.88503 27.6367C9.74184 27.8593 9.48313 28 9.19989 28C9.11309 28 9.02657 27.9869 8.94349 27.9602L8.94225 27.9599C8.58047 27.8442 8.37641 27.518 8.40204 27.1949L8.40206 27.1947L9.15311 17.7039L9.23849 16.625H8.15623H3.79995C3.49015 16.625 3.22153 16.4594 3.08935 16.219L3.08909 16.2186C2.9585 15.9814 2.97177 15.6963 3.12701 15.4699L3.12702 15.4699L12.127 2.34492C12.3229 2.05923 12.7114 1.92265 13.0716 2.04451C13.4271 2.16536 13.6249 2.4896 13.5975 2.80996C13.5975 2.80998 13.5975 2.81 13.5975 2.81003L12.8601 11.4146Z" stroke={color !== '' ? color : 'white'} strokeWidth="2"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0" y="0" width="22" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>
    );
}

export default StormIcon;