import BaseService from './base-service';

class PlaylistService extends BaseService {
  fetchPlaylist(id) {
    return this._apiGet({
      path: `/playlists/${id}`,
    });
  }

  fetchTrackDetailsBatch(ids) {
    const releaseDetailsPath = `/playlists/release/details/batch?releaseids=${ids}&usageTypes=subscriptionstreaming&imageSizes=50`;
    return this._apiGet({
      path: releaseDetailsPath,
    });
  }

  createUserMusicService(data) {
    return this._apiPost({
      path: '/playlists/user/create',
      data,
    });
  }

  getUserMusicSubscription() {
    return this._apiGet({
      path: '/playlists/user/unlimitedStreaming',
    });
  }

  createOrUpdateUserSubscriptionMusicService() {
    return this._apiPost({
      path: '/playlists/user/unlimitedStreaming',
      data: {},
    });
  }

  addUserSubscriptionMusicLogs(data) {
    return this._apiPost({
      path: '/playlists/user/subscription/log',
      data,
    });
  }
}

export default new PlaylistService();
