import React, { Component } from 'react';
import { CardElement, Elements, injectStripe } from 'react-stripe-elements';
import PropTypes from 'prop-types';

class InnerCard extends Component {

    state = {
        error: null
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.isSavingSubscription(true);
        const $this = this;
        this.props.stripe.createToken().then(({ token, error }) => {
            if (error) throw error;
            $this.props.onSubmitPayment(token);
            this._element.clear();
        }).catch(error => {
            $this.setState({
                error
            });
            $this.props.isSavingSubscription(false);
        });
    }

    render() {
        const { isSaving } = this.props;
        return (
            <div className="bg-dark-gray">
                <form onSubmit={this.handleSubmit}>
                    <div className={"cardElementContainer"}>
                        <CardElement
                            {...createOptions()}
                            onChange={() => {
                                if (this.state.error) {
                                    this.setState({ error: null });
                                }
                            }}
                            onReady={e => this._element = e}
                        />
                    </div>
                    <div className="divider"></div>
                    <button type="submit"
                        disabled={isSaving}
                        className="btn btn-primary btn-block btn-lg px-4 mt-3 subscribeButton"
                    >
                        {this.props.submitButtonText || 'Subscribe'}
                    </button>
                    {this.state.error && <div className="alert-text mt-2 alert alert-danger">
                        {this.state.error.message}
                    </div>}
                </form>
            </div>
        )
    }
}

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Open Sans, sans-serif',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
                padding: "20px"
            },
            invalid: {
                color: '#c23d4b',
            },
        }
    }
};

InnerCard.propTypes = {
    stripe: PropTypes.shape({
        createToken: PropTypes.func.isRequired
    }).isRequired
}

const InnerCardForm = injectStripe(InnerCard);

class CardForm extends Component {
    render = () => {
        return (
            <Elements >
                <InnerCardForm
                    disabledSubmitButton={this.props.disabledSubmitButton}
                    submitButtonText={this.props.submitButtonText}
                    onSubmitPayment={this.props.onSubmitPayment}
                    isSavingSubscription={this.props.isSavingSubscription}
                    isSaving={this.props.isSaving}
                />
            </Elements>
        )
    }
}

export default CardForm;