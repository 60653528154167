import React from "react";
import MediaQuery from 'react-responsive';
import { buildBadgeImageUrl } from "helpers/class-url";
import { findCurrentBadgeIndex } from 'helpers/object-helper';
import Achievements from '../../../cross-platform/containers/classes/achievements';

const BADGES_PER_ROW = 7;
const BADGES_LIST = [1, 10, 25, 50, 75, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1500, 2000];

class AchievementsPage extends Achievements {
  render() {
    const { totalRides } = this.state;
    return (
      <>
        <MediaQuery minWidth={769}>
          <div className="col-12 col-xl-10 px-4 pt-4 pb-6 achievements-page-wrapper bg-white">
              <div className="row">
                <div className="col-11">
                  <h4>Cycling Milestones</h4>
                </div>
                <div className="col-1">
                  <p>{totalRides} {totalRides === 1 ? 'Ride' : 'Rides'}</p>
                </div>
              </div>
              <BadgesList totalRides={totalRides} />
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
            <div className="col-12 p-3 mt-3 h-100">
              <h4>Cycling Milestones</h4>
              <p>{totalRides} {totalRides === 1 ? 'Ride' : 'Rides'}</p>
              <BadgesListSimple totalRides={totalRides} />
            </div>
        </MediaQuery>
      </>
    );
  }
}

const BadgesListSimple = (props) => {
  const { totalRides } = props;
  const currentBadgeIndex = findCurrentBadgeIndex(totalRides, BADGES_LIST);
  return <div className="achievements-mobile-wrapper">
    {BADGES_LIST.map((badge, index) => {
      const currentIndex = BADGES_LIST.indexOf(badge);
      return (
          <div key={index} className="item-achievement-badge">
            <div className={`img-achievement-wrapper ${(typeof totalRides === 'undefined' || totalRides === 0) || (currentBadgeIndex < currentIndex) ? 'border-faded' : ''}`}>
                <img src={buildBadgeImageUrl(badge, 'achievements/rides')} alt="" className={`achievement-badge-img ${(typeof totalRides === 'undefined' || totalRides === 0) || (currentBadgeIndex < currentIndex) ? 'achievement-badge-waiting' : ''}`} />
            </div>
          </div>
      );
    })}
  </div>
}


const BadgesList = (props) => {
  const { totalRides } = props;
  const currentBadgeIndex = findCurrentBadgeIndex(totalRides, BADGES_LIST);
  const badges = BADGES_LIST.reduce((result, item, index) => {
    const badgeIndex = Math.floor(index / BADGES_PER_ROW);
    if(!result[badgeIndex]) {
      result[badgeIndex] = [];
    }
    result[badgeIndex].push(item);
    return result;
  }, []);

  return <>
    {badges.map((items, index) => {
        return (
          <div key={`badge-wrapper-${index}`} className="row">
            {items.map(item => {
              const currentIndex = BADGES_LIST.indexOf(item);
              return (
                  <div key={item} className="col item-achievement-badge">
                    <div className={`img-achievement-wrapper ${(typeof totalRides === 'undefined' || totalRides === 0) || (currentBadgeIndex < currentIndex) ? 'border-faded' : ''}`}>
                        <img src={require(`../../../images/achievements/${item}.png`).default} alt="" className={`achievement-badge-img ${(typeof totalRides === 'undefined' || totalRides === 0) || (currentBadgeIndex < currentIndex) ? 'achievement-badge-waiting' : ''}`} />
                    </div>
                  </div>
              );
            })}
          </div>
        );
    })}
  </>
};


export default AchievementsPage;
