import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email.').required('This field is required!'),
    password: Yup.string().min(6, 'Password should contain more than 6 characters.').required('This field is required!')
});

export const SignupSchema = Yup.object().shape({
    firstname: Yup.string().min(3, 'First name should contain 3 or more characters.').max(50, 'First name should be less than 50 characters.').required('This field is required!'),
    lastname: Yup.string().min(3, 'Last name should contain 3 or more characters.').max(50, 'First name should be less than 50 characters.').required('This field is required!'),
    email: Yup.string().email('Invalid email.').required('This field is required!'),
    password: Yup.string().min(6, 'Password should contain more than 6 characters.').required('This field is required!')
});

export const ResetPasswordRequestSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email.').required('This field is required!'),
});

export const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email.').required('This field is required!'),
    code: Yup.string().required('This field is required!'),
    password: Yup.string().min(6, 'Password should contain more than 6 characters.').required('This field is required!'),
    confirmPassword: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Passwords must match!')
});

export const ConfirmSignupSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email.')
        .required('This field is required!'),
    code: Yup.number('Code should contain digits only.')
        .positive('Code should contain positive digits only.')
        .min(6, 'Code should contain 6 digits.')
        .required('This field is required!')
        .typeError('Code should contain digits only.')
});