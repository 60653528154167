import React from 'react'

const MusicIcon = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.33333 19.3333V3.44444L23 1V16.8889M8.33333 19.3333C8.33333 21.3584 6.69171 23 4.66667 23C2.64162 23 1 21.3584 1 19.3333C1 17.3083 2.64162 15.6667 4.66667 15.6667C6.69171 15.6667 8.33333 17.3083 8.33333 19.3333ZM23 16.8889C23 18.9139 21.3584 20.5556 19.3333 20.5556C17.3083 20.5556 15.6667 18.9139 15.6667 16.8889C15.6667 14.8638 17.3083 13.2222 19.3333 13.2222C21.3584 13.2222 23 14.8638 23 16.8889Z" stroke="#828282" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default MusicIcon;