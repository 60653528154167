import React from 'react';
import _ from 'lodash';
import { Line as LineChart, defaults } from 'react-chartjs-2';

class Diagram extends React.Component {

    state = {
        charHeight: Math.trunc(window.innerHeight * 0.158),
        chartOptions: {
            bezierCurve: true,
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            scales: {
                yAxes: [{
                    type: 'linear',
                    gridLines: {
                        display: false
                    },
                    weight: 5,
                    id: 'y-axis-1',
                    ticks: {
                        padding: 30,
                        min: 0,
                        maxTicksLimit: 6,
                        autoSkip: true,
                        sampleSize: 10,
                    },
                }],
                xAxes: [{
                    ticks: {
                        padding: 30,
                        callback: (value) => {
                            if(value === 0) return value;
                            return value + ' min';
                        }
                    },
                    gridLines: {
                        drawTicks: false,
                    }
                }]
            }
        }
    };

    componentDidMount() {
        _.merge(defaults, {
            global: {
                defaultFontColor: '#666785',
                defaultFontFamily: "'Ubuntu', sans-serif",
                defaultFontStyle: 'bold',
                line: {
                    borderColor: 'white',
                }
            },
        });
    }

    render() {
        const { selectedUserSession, data } = this.props;
        const userSessionTotalDuration = (selectedUserSession.duration && selectedUserSession.duration !== 0) ? Math.ceil(selectedUserSession.duration / 60) : '';
        return (
            <div className="row mb-5 ml-2 mr-2 diagram-wrapper">
                <div className="col-12 bg-white diagram-content">
                    <h4 className="my-4 ml-2">Workout Diagram <span>&bull;</span> {userSessionTotalDuration} Min</h4>
                    <div className="ml-2">
                        <LineChart data={data} options={this.state.chartOptions} height={this.state.charHeight} />
                    </div>
                    {/* <div className="my-4 ml-2">
                        <button className="btn btn-cadence mr-3 py-1" onClick={this.onClickCadence} disabled={true}><i
                            className="far fa-circle mr-2"></i>Cadence
                        </button>
                        <button className="btn btn-output py-1" onClick={this.onClickOutput} disabled={true}><i
                            className="far fa-circle mr-2"></i>Output
                        </button>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default Diagram;