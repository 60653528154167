import React from 'react'

const LogoIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.35156 21.6621C3.35222 21.5618 3.37858 21.4632 3.42827 21.3751C3.47797 21.2871 3.54946 21.2124 3.63632 21.1577L15.1434 13.7372C15.246 13.6714 15.3663 13.6363 15.4894 13.6363C15.6125 13.6363 15.7328 13.6714 15.8354 13.7372L17.954 15.0934C18.0409 15.1481 18.1123 15.2228 18.162 15.3109C18.2117 15.3989 18.2381 15.4975 18.2387 15.5978C18.2381 15.6981 18.2117 15.7967 18.162 15.8848C18.1123 15.9728 18.0409 16.0475 17.954 16.1023L4.32829 24.8982C4.23395 24.9591 4.12432 24.994 4.01109 24.9993C3.89786 25.0046 3.78527 24.98 3.68532 24.9283C3.58536 24.8765 3.5018 24.7994 3.44352 24.7053C3.38525 24.6112 3.35445 24.5035 3.35441 24.3938L3.35156 21.6621Z" fill={color !== '' ? color : '#FCB333'} />
        <path d="M4.32837 0.103637L22.7152 12.0199C22.8021 12.0747 22.8736 12.1494 22.9233 12.2374C22.973 12.3254 22.9993 12.424 23 12.5244C22.9991 12.6246 22.9726 12.7231 22.9229 12.8111C22.8733 12.8991 22.8019 12.9739 22.7152 13.0288L20.608 14.3905C20.5048 14.4575 20.3834 14.4932 20.2592 14.4932C20.135 14.4932 20.0136 14.4575 19.9104 14.3905L3.63071 3.8497C3.54384 3.79498 3.47236 3.72026 3.42266 3.63222C3.37296 3.54419 3.34661 3.44559 3.34595 3.34527V0.608075C3.34564 0.4979 3.37634 0.389724 3.43476 0.295169C3.49318 0.200613 3.57711 0.123251 3.67753 0.071389C3.77794 0.0195272 3.89106 -0.00487466 4.00472 0.000807018C4.11839 0.00648869 4.22831 0.0420386 4.32267 0.103637H4.32837Z" fill={color !== '' ? color : '#FCB333'} />
        <path d="M0.330321 20.9702C0.230436 20.9184 0.146963 20.8412 0.0887917 20.7471C0.0306208 20.6529 -6.89477e-05 20.5452 1.16309e-07 20.4355V17.701C0.000656698 17.6007 0.027015 17.5021 0.0767124 17.4141C0.12641 17.326 0.197893 17.2513 0.284759 17.1966L7.52617 12.5106L0.284759 7.82455C0.197893 7.76983 0.12641 7.6951 0.0767124 7.60706C0.027015 7.51902 0.000656698 7.42043 1.16309e-07 7.32011V4.60772C3.81173e-05 4.49799 0.0308385 4.39032 0.0891132 4.29619C0.147388 4.20206 0.230955 4.12501 0.330908 4.07323C0.430861 4.02146 0.543453 3.99691 0.656684 4.0022C0.769915 4.00749 0.879543 4.04244 0.973879 4.10329L13.2327 12.0144C13.3135 12.0732 13.3791 12.1494 13.4242 12.237C13.4693 12.3245 13.4928 12.421 13.4928 12.5188C13.4928 12.6167 13.4693 12.7132 13.4242 12.8007C13.3791 12.8883 13.3135 12.9645 13.2327 13.0233L0.973879 20.9344C0.880033 20.9963 0.770581 21.0323 0.657227 21.0386C0.543873 21.0449 0.430877 21.0213 0.330321 20.9702Z" fill={color !== '' ? color : '#FCB333'} />
        </svg>
    );
}

export default LogoIcon;