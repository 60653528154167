import React from 'react'

const FireIcon = ({ width, height, color = '', fill = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 30" fill={fill !== '' ? fill : 'none'} xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
            <path d="M9.18962 14.1381C9.19026 14.1383 9.19088 14.1385 9.49997 13.1875L9.19088 14.1385L10.6101 14.5998L10.4972 13.1127L10.4972 13.1127L10.4971 13.1123L10.4971 13.1118L10.497 13.1104L10.4961 13.0964C10.4952 13.0828 10.4938 13.0611 10.4922 13.0315C10.489 12.9725 10.4848 12.8823 10.4813 12.7647C10.4742 12.5294 10.4699 12.1849 10.4817 11.7599C10.5053 10.9072 10.5935 9.74318 10.8487 8.49065C11.265 6.44789 12.0948 4.3111 13.6794 2.83206C14.1998 5.41529 15.7644 7.27087 17.2079 8.98292C17.3005 9.09275 17.3926 9.20198 17.4839 9.31078C19.3408 11.5235 21 13.6705 21 17.25C21 22.0588 16.9946 26 12 26C7.00541 26 3 22.0588 3 17.25C3 14.5738 4.24376 12.1127 6.19637 10.4854C6.4647 11.612 6.97556 12.4326 7.52381 13.0117C7.91543 13.4254 8.31029 13.6994 8.61497 13.8726C8.76757 13.9594 8.89888 14.0217 8.99794 14.0643C9.04753 14.0856 9.08926 14.102 9.1218 14.1142C9.13807 14.1203 9.15207 14.1253 9.16363 14.1293L9.17914 14.1346L9.18549 14.1368L9.1883 14.1377L9.18962 14.1381Z" stroke={color !== '' ? color : 'white'} strokeWidth="2"/>
            </g>
            <defs>
            <filter id="filter0_d" x="0" y="0" width="24" height="30" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            </defs>
        </svg>
    );
}

export default FireIcon;