import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
    OverviewMonthlyListContainer
} from './styles';

import ProfileIcon from 'components/elements/icons/profile-icon';
import useMonthlyExerciseSessions from 'hooks/useMonthlyExerciseSessions';

export default function OverviewMonthlyList({ currentMonth }) {
  const { data, loading, error } = useMonthlyExerciseSessions(currentMonth);

  return (
    <OverviewMonthlyListContainer>
      {data.map((item, index) => {
        const dayNr = moment(item.createdOn).format('DD');
        const day = moment(item.createdOn).format('ddd');
        return (
          <div
            className="bottom-stats-item row align-items-center py-3 mb-3 ml-2 mr-2"
            key={index}>
            <div className="col-3 item-day">
              <span>{day}</span>
              <p>{dayNr}</p>
            </div>
            <div className="col-6 item-desc">
              <h4>{item.sessionSummary ? item.sessionSummary.trainingName : '-'}</h4>
              <p>
                <ProfileIcon width={9} height={10} color={'#828282'} />
                {item.sessionSummary ? item.sessionSummary.instructor.fullName : '-'}
              </p>
            </div>
            <div className="col-3 item-total">
              <p>
                {Number(item.totalEnergy).toFixed(2)} <small>kj</small>
              </p>
            </div>
          </div>
        );
      })}
      {loading && <div className="loading-sessions">Loading...</div>}
      {error && <div className="error-sessions">Something went wrong.</div>}
    </OverviewMonthlyListContainer>
  );
}

OverviewMonthlyList.defaultProps = {
  currentMonth: 0,
};

OverviewMonthlyList.propTypes = {
  currentMonth: PropTypes.number,
};
