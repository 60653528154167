import React from 'react';

const LeaderboardRankSecondIcon = ({width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5 0.866025C12.4282 0.330127 13.5718 0.330127 14.5 0.866025L22.7583 5.63397C23.6865 6.16987 24.2583 7.16025 24.2583 8.23205V17.768C24.2583 18.8397 23.6865 19.8301 22.7583 20.366L14.5 25.134C13.5718 25.6699 12.4282 25.6699 11.5 25.134L3.24167 20.366C2.31347 19.8301 1.74167 18.8397 1.74167 17.768V8.23205C1.74167 7.16025 2.31347 6.16987 3.24167 5.63397L11.5 0.866025Z" fill="#E0E0E0"/>
        <path d="M11.875 3.51554C12.5712 3.11362 13.4288 3.11362 14.125 3.51554L20.6513 7.28349C21.3474 7.68542 21.7763 8.4282 21.7763 9.23205V16.7679C21.7763 17.5718 21.3474 18.3146 20.6513 18.7165L14.125 22.4845C13.4288 22.8864 12.5712 22.8864 11.875 22.4845L5.34872 18.7165C4.65257 18.3146 4.22372 17.5718 4.22372 16.7679V9.23205C4.22372 8.4282 4.65257 7.68542 5.34872 7.28349L11.875 3.51554Z" stroke={color !== '' ? color : 'black'} strokeWidth="1.5"/>
        <path d="M10.0456 15.668C12.7576 13.788 14.1136 12.228 14.1136 10.988C14.1136 10.612 14.0216 10.316 13.8376 10.1C13.6616 9.884 13.3816 9.776 12.9976 9.776C12.2056 9.776 11.7856 10.316 11.7376 11.396H10.0936C10.1416 10.396 10.4456 9.64 11.0056 9.128C11.5656 8.608 12.2736 8.348 13.1296 8.348C14.0016 8.348 14.6696 8.576 15.1336 9.032C15.6056 9.48 15.8416 10.08 15.8416 10.832C15.8416 11.424 15.6656 12.02 15.3136 12.62C14.9696 13.212 14.5296 13.76 13.9936 14.264C13.4576 14.76 12.8936 15.188 12.3016 15.548H16.0096V16.928H10.0456V15.668Z" fill={color !== '' ? color : 'black'} />
        </svg>

    );
}

export default LeaderboardRankSecondIcon;