import Amplify, { Hub, Storage } from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      region: process.env.REACT_APP_AWS_REGION,
    },
  },
});

const getFileExtension = (file) => {
  const fileExtensionIndex = file.name.lastIndexOf('.');
  const extension = file.type.indexOf('video') !== -1 ? '.mp4' : `.${file.name.slice(fileExtensionIndex + 1)}`;
  return extension;
};

class FileService {
  async uploadFile(id, file) {
    const key = id + getFileExtension(file);
    const result = await Storage.put(key, file, {
      customPrefix: { public: 'inbox/' },
      contentType: file.type,
      progressCallback: (progress) => {
        Hub.dispatch('katana', {
          event: 'admin.sessions.assets.upload-progress',
          progress: _extractProgressPercentages(progress),
          fileId: id,
          message: 'File upload progress',
        });
      },
    });
    return {
      fileKey: result.key,
    };
  }
}

const _extractProgressPercentages = (progress) => ({
  max: 100,
  min: 0,
  value: parseInt(parseFloat(progress.loaded) / parseFloat(progress.total) * 100),
});

export default new FileService();
