import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import * as types from './action-types';
import modes from '../references/modes';
import analyticsService from '../services/analytics-service';
import userService from '../services/user-service';

dayjs.extend(utc);

export const calculateUserSessionMetrics = () => (dispatch, getState) => {
  const { classSession } = getState().classStore;
  const { userSessionId } = getState().userStore;
  if (!userSessionId) return;

  dispatch(saveUserSessionMetrics())
    .then(() => {
      userService.calculateUserSessionMetrics({ classSessionId: classSession.id, userSessionId })
        .then(() => {
          dispatch(fetchLeaderboard());
        });
    });
};

export const fetchUserSessionMetrics = (userSessionId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_ANALYTICS_SESSION_METRICS,
    userSessionId,
  });
  analyticsService.fetchUserSessionMetrics(userSessionId)
    .then((data) => {
      dispatch({
        type: types.ANALYTICS_SESSION_METRICS_FETCHED,
        userSessionId,
        data,
      });
    });
};

export const fetchLeaderboard = (month) => (dispatch) => {
  userService.fetchLeaderboard(month)
    .then((data) => {
      dispatch({ type: types.SET_LEADERBOARD, leaderboard: data.items || [] });
    });
};

export const fetchUserRidesCount = () => userService.userRideCount()
  .then((data) => data.total || 0)
  .catch((error) => Promise.reject(error));

export const fetchUserSessions = () => (dispatch) => {
  dispatch({ type: types.FETCHING_ANALYTICS_SESSIONS, mode: modes.FETCHING });
  return analyticsService.fetchUserSessions()
    .then((result) => {
      dispatch({
        type: types.ANALYTICS_SESSIONS_FETCHED,
        result,
      });
    })
    .catch((error) => {
      dispatch({
        error,
        type: types.ANALYTICS_SESSIONS_FETCH_FAILED,
      });
    });
};

export const saveUserSessionMetrics = () => (dispatch, getState) => {
  const { simulations, savedSimulationMetrics } = getState().simulationStore;
  const { activeUserSessionId } = getState().analyticsStore;
  const { classSession } = getState().classStore;
  const currentSimulations = simulations.slice(savedSimulationMetrics.length);
  dispatch({ type: types.SAVED_SIMULATION_METRICS, metrics: [...savedSimulationMetrics, ...currentSimulations] });
  const metrics = {
    createdOn: dayjs.utc().toISOString(),
    metrics: currentSimulations,
    classSessionId: classSession.id,
    userSessionId: activeUserSessionId,
    classId: classSession.classId,
  };
  return analyticsService.saveUserSessionMetrics(activeUserSessionId, metrics)
    .then(() => Promise.resolve())
    .catch(() => {
      dispatch({ type: types.SAVED_SIMULATION_METRICS, metrics: savedSimulationMetrics });
      return Promise.reject();
    });
};
