// eslint-disable-next-line
import React from 'react';
import {connect} from 'react-redux';
import * as userActions from '../../../cross-platform/actions/user-actions';
import {toast} from 'react-toastify';
import modes from '../../../cross-platform/references/modes';
import Account from '../../../cross-platform/containers/my/account/account';
import SubscribeCard from './_account-subscribe-card';
import SubscribeImg from '../../../images/subscribe-img.png';
class AccountPage extends Account {
  _onChangePassword = () => {
    this._changePassword().then(() => {
      toast.success('Password changed successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
  };

  render() {
    return (
      <div className="col-12 col-xl-10 p-lg-4 p-3 my-account">
        <div className="account">
          {this.state.userProfileHelper.mode === modes.WITH_ERROR && (
            <div className="alert alert-danger mx-3">
              <i className="fas fa-exclamation-circle pr-2"></i>
              {this.state.userProfileHelper.errorMessage}
            </div>
          )}
          <div className="row m-0 no-gutters">
            {/* <div className="col-12 col-lg-6 edit-box mb-5">
                            <ChangePasswordCard
                                _onChange={this._onChange}
                                _onChangePassword={this._onChangePassword}
                                {...this.state}
                            />
                        </div> */}
            <div className="col-12 col-lg-12 mb-5">
              <div className="row no-gutters">
                <div className="col-12 col-lg-7 col-md-7 justify-content-center h-100 subscribe-img-wrapper">
                  <div className="bg-gradient"></div>
                  <img src={SubscribeImg} alt="" className="img-responsive" />
                </div>
                <div className="col-12 col-lg-5 col-md-5 subscribe-card-wrapper">
                  <SubscribeCard {...this.state} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({userStore}) => ({...userStore});

export default connect(mapStateToProps, {...userActions})(AccountPage);
