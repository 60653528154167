export const eventTargets = (event) => ({
  name: !event.target ? event.name : event.target.name,
  value: !event.target ? event.value : event.target.value,
});

// export const eventTarget = (event, key) => {
//     event = {
//         "email": "d"
//     }
//     event.target = {
//         "name": "email",
//         "value": "d"
//     }
//     console.log("EVENT", event, );
//     console.log("KEY", key);
//     console.log("RESULT", event[key]);
//     console.log("DSADSA", event.target);
//     return !event.target ? event[key] : event.target[key];
// };
