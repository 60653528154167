import React, { Component } from 'react';
import Logo from '../images/unauthorized.svg';

class Unauthorized extends Component {
    render() {
        return (
            <div className="fixed-bottom h-100 d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center flex-column">
                    <img src={Logo} alt="Unauthorized"></img>
                    <h2 className="mt-5">Oops!!! Unauthorized Page</h2>
                    <p>Access forbidden. You don't have enough permissions.</p>
                </div>
            </div>
        );
    }
}

export default Unauthorized