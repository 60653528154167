import React from 'react';
import { ValidatedInput, ValidatedForm } from "react-validated";
import usaStates from '../../data/usa-states';

class ClassDetails extends React.Component {

    constructor(props) {
        super(props);
        this.onSubmitButtonRef = React.createRef();
    }

    attemptSave = () => {
        this.onSubmitButtonRef.current.click();
    }

    onSave = () => {
        if (this.props.onSave) {
            this.props.onSave();
        }
    }

    render() {
        return (
            <div className="card-body">
                <div className="col-12">
                    <ValidatedForm onSubmit={this.onSave}>
                        <button type="submit" ref={this.onSubmitButtonRef} style={{ opacity: 0, position: 'absolute' }}></button>
                        <div className="row mt-3">
                            <div className="col-6 justify-content-star text-left">
                                <label htmlFor="class-name">Class Name <span className="text-danger">*</span></label>
                                <ValidatedInput required min-length={{ params: 5 }} max-length={{ params: 100 }}>
                                    <input type="text" name="title" value={this.props.class.title || ''} onChange={this.props._onChange} className="form-control rounded-0" id="class-name" placeholder="Class Name" aria-label="Class Name" />
                                </ValidatedInput>
                            </div>
                            <div className="col-6 justify-content-start text-left">
                                <label htmlFor="studio-name">Studio Name <span className="text-danger">*</span></label>
                                <ValidatedInput required min-length={{ params: 5 }} max-length={{ params: 100 }}>
                                    <input type="text" value={this.props.class.studioName || ''} name="studioName" onChange={this.props._onChange} className="form-control rounded-0" id="studio-name" placeholder="Studio Name" aria-label="Studio Name" />
                                </ValidatedInput>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-6 justify-content-star text-left">
                                <label htmlFor="city">City</label>
                                <ValidatedInput min-length={{ params: 3 }} max-length={{ params: 100 }}>
                                    <input type="text" value={this.props.class.city || ''} name="city" onChange={this.props._onChange} className="form-control rounded-0" id="city" placeholder="City" aria-label="City" />
                                </ValidatedInput>
                            </div>
                            <div className="col-6 justify-content-start text-left">
                                <label htmlFor="state">State</label>
                                <select value={this.props.class.state || ''} name="state" onChange={this.props._onChange} className="form-control rounded-0" id="state">
                                    <option value="" >None selected</option>
                                    {usaStates.map(state => (
                                        <option key={state.abbreviation} value={state.abbreviation || ''}>{state.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 justify-content-star text-left">
                                <label htmlFor="class-description">Class description <span className="text-danger">*</span></label>
                                <ValidatedInput required min-length={{ params: 10 }} max-length={{ params: 255 }}>
                                    <textarea type="text" value={this.props.class.description || ''} onChange={this.props._onChange} name="description" rows="6" className="form-control rounded-0" id="class-description" placeholder="Description" aria-label="Class Description" />
                                </ValidatedInput>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-6 justify-content-star text-left">
                                <label htmlFor="difficulty">Difficulty <span className="text-danger">*</span></label>
                                <select value={this.props.class.difficulty} name="difficulty" onChange={this.props._onChange} className="form-control rounded-0" id="difficulty">
                                    <option className="select-placeholder" value="" disabled>Select Difficulty</option>
                                    <option value="1">Easy</option>
                                    <option value="2">Intermediate</option>
                                    <option value="3">Expert</option>
                                </select>
                                <ValidatedInput required>
                                    <input type="text" defaultValue={this.props.class.difficulty || ''} name="difficulty" style={{ opacity: 0, display: 'none' }} />
                                </ValidatedInput>
                            </div>
                        </div>
                    </ValidatedForm>
                </div>
            </div>
        )
    }
}

export default ClassDetails;