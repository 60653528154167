import dayjs from 'dayjs';

import _ from 'lodash';
import duration from 'dayjs/plugin/duration';
import {
  ANALYTICS_SESSION_CREATED,
  ANALYTICS_SESSIONS_FETCHED,
  ANALYTICS_SESSION_METRICS_FETCHED,
  FETCHING_ANALYTICS_SESSIONS,
  FETCHING_ANALYTICS_SESSION_METRICS,
} from '../actions/action-types';
import modes from '../references/modes';

dayjs.extend(duration);

const initialState = {
  achievementsCount: 0,
  exerciseSessions: [],
  exercisePage: 1,
  totalExercises: 0,
  exerciseMonthlySummaries: [],
  fetching: false,
  mode: modes.BROWSE,
  sessionMetrics: {
    items: [],
    session: null,
  },
};

export const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ANALYTICS_SESSION_CREATED:
      return {
        ...state,
        activeUserSessionId: action.userSessionId,
      };

    case ANALYTICS_SESSIONS_FETCHED:
      return reduceFetchedSessionState(state, action);

    case ANALYTICS_SESSION_METRICS_FETCHED:
      return reduceFetchedSessionMetricsState(state, action);

    case FETCHING_ANALYTICS_SESSIONS:
    case FETCHING_ANALYTICS_SESSION_METRICS:
      return {
        ...state,
        fetching: true,
        mode: modes.FETCHING,
      };

    default:
      return state;
  }
};

const reduceFetchedSessionState = (state, action) => {
  const sessions = action.result.items;
  const overallSessions = [];
  const overallSessionDates = [];

  const uniqueMonths = [];
  sessions.sort((first, second) => new Date(second.createdOn) - new Date(first.createdOn));
  sessions.forEach((item, index) => {
    if (index < 12) {
      overallSessions.push(item.totalEnergy);
      overallSessionDates.push(dayjs(item.createdOn).format('MMM DD'));
    }

    if (!uniqueMonths.find((month) => month.number === dayjs(item.createdOn).format('MM'))) {
      uniqueMonths.push({
        number: dayjs(item.createdOn).format('MM'),
        name: dayjs(item.createdOn).format('MMMM'),
        year: dayjs(item.createdOn).format('YYYY'),
      });
    }
  });

  const userSessionsByMonth = [];
  uniqueMonths.forEach((month) => {
    const group = {
      month: month.name,
      year: month.year,
      sessions: sessions.filter((session) => month.number === dayjs(session.createdOn).format('MM') && month.year === dayjs(session.createdOn).format('YYYY')),
    };
    group.numberOfSessions = (group || []).length;
    group.plotData = _createPlotData(group.sessions);
    userSessionsByMonth.push(group);
  });

  return {
    ...state,
    exerciseSessions: sessions,
    exerciseMonthlySummaries: userSessionsByMonth,
    mode: modes.BROWSE,
    totalExercises: action.result.items.length,
  };
};

const reduceFetchedSessionMetricsState = (state, action) => {
  // console.log('reduceFetchedSessionMetricsState', action);
  const data = (action.data && action.data.items) || [];
  console.log('THE_DATA', data);
  let metrics = [];
  data.forEach((entry) => metrics = metrics.concat(entry.collection));
  metrics = _.sortBy(metrics, (metric) => new Date(metric.createdOn));
  const collectionStartedOn = dayjs.utc(metrics.length > 0 && metrics[0].createdOn);
  const collectionEndedOn = dayjs.utc(metrics.length > 0 && metrics[metrics.length - 1].createdOn);
  const durationAsSeconds = dayjs.duration(collectionEndedOn.diff(collectionStartedOn)).as('seconds');
  const runningAverages = metrics.reduce((running, current) => ({
    cadence: running.cadence + (current.cadence || 0),
    energy: running.energy + (current.energy || 0),
    power: running.power + (current.power || 0),
    resistance: running.resistance + (current.resistance || 0),
  }), {
    cadence: 0, energy: 0, power: 0, resistance: 0,
  });
  runningAverages.cadence = parseFloat(runningAverages.cadence) / parseFloat(metrics.length);
  runningAverages.totalEnergy = runningAverages.energy;
  runningAverages.energy = parseFloat(runningAverages.energy) / parseFloat(metrics.length);
  runningAverages.power = parseFloat(runningAverages.power) / parseFloat(metrics.length);
  runningAverages.resistance = parseFloat(runningAverages.resistance) / parseFloat(metrics.length); // debugger;
  return {
    ...state,
    sessionMetrics: {
      averageCadence: runningAverages.cadence,
      averageEnergy: runningAverages.energy,
      averagePower: runningAverages.power,
      averageResistance: runningAverages.resistance,
      totalEnergy: runningAverages.totalEnergy,
      collectionStartedOn,
      collectionEndedOn,
      duration: durationAsSeconds,
      items: metrics,
      // TODO extract only the session fields from here
      session: data.length && data[0],
    },
    mode: modes.BROWSE,
  };
};

const _createPlotData = (sessions) => {
  if (!sessions || !sessions.length) return [];
  // const barChartData = {
  //     chartData: {
  //         labels: overallSessionDates,
  //         datasets: [{
  //             data: overallSessions,
  //             backgroundColor: 'rgba(28, 160, 145, 1)',
  //             borderColor: 'rgba(28, 160, 145, 1)'
  //         }]
  //     }
  // };
};
