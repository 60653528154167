import styled from 'styled-components';

export const CancellationButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
`;

export const DontCancelButton = styled.button`
background-color: #fff;
  color: #00B07B;
  border-radius: 14px;
  border: 2px solid #00B07B;
  height: 86px;
  width: 50%;
  font-weight: 600;
  box-sizing: border-box;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

export const CancelButton = styled.button`
  background-color: #EB5757;
  color: ${(props) => (props.isEnabled ? '#ffffff' : '#ffffff33')};
  border-radius: 14px;
  height: 86px;
  width: 50%;
  font-weight: 600;
  box-sizing: border-box;
  border: none;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;
