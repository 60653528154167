import * as types from './action-types';
import { updateUserSessionLeaderboard } from './user-actions';
import { saveUserSessionMetrics } from './analytics-actions';
import analyticsService from '../services/analytics-service';

// Deprecated
export const startSimulation = (userSession) => (dispatch, getState) => {
  const { classSession } = getState().classStore;
  const userSession = {
    classId: classSession.classId,
    classSessionId: classSession.id,
  };
  analyticsService.createUserSession(userSession)
    .then((result) => {
      dispatch({
        type: types.ANALYTICS_SESSION_CREATED,
        userSessionId: result.userSessionId,
      });
    });
  dispatch({ type: types.START_SIMULATION });
  dispatch(simulate());
};

// Depricated
export const resetSimulation = () => ({
  type: types.RESET_SIMULATION,
});

// Depricated
export const stopSimulation = () => ({
  type: types.STOP_SIMULATION,
});

// Depricated
const randomNumberBetween = (min = 1, max = 5) => Math.floor(Math.random() * (max - min + 1) + min);

// Depricated
export const simulate = () => (dispatch, getState) => {
  const currentSimulation = {};
  let {
    simulation: {
      cadence, resistance, power, energy,
    }, simulationProcessInSeconds,
  } = getState().simulationStore;

  const fiveSeconds = 5;
  const tenSeconds = 10;
  const minCadence = 60;
  const maxCadence = 90;
  const minResistance = 40;
  const maxResistance = 50;

  if (simulationProcessInSeconds % fiveSeconds === 0) {
    let addCadance = randomNumberBetween(1, 2);
    addCadance = cadence >= maxCadence ? 0 : cadence <= minCadence ? 1 : addCadance;
    currentSimulation.cadence = addCadance === 1 ? cadence + randomNumberBetween(1, 5) : cadence - randomNumberBetween(1, 5);
    currentSimulation.resistance = resistance || randomNumberBetween(minResistance, maxResistance);
    currentSimulation.power = parseInt((currentSimulation.resistance / 100 + 0.9) * ((currentSimulation.cadence - 40) * 1.5 * Math.max((currentSimulation.cadence / 55), 1) + 25) * (1 + Math.max(0, currentSimulation.resistance / 100 * (currentSimulation.cadence - 60) / 100)));
    currentSimulation.power = currentSimulation.power < 0 ? 0 : currentSimulation.power;
    currentSimulation.energy = currentSimulation.power / 1000;
  } else {
    currentSimulation.cadence = cadence;
    currentSimulation.resistance = resistance;
    currentSimulation.power = power;
    currentSimulation.energy = energy;
  }

  currentSimulation.createdOn = new Date().toISOString();
  simulationProcessInSeconds += 1;
  dispatch({ type: types.UPDATE_SIMULATION, simulation: currentSimulation, simulationProcessInSeconds });
  dispatch(updateUserSessionLeaderboard());
  if (simulationProcessInSeconds % tenSeconds === 0) {
    dispatch(saveUserSessionMetrics());
  }
};
