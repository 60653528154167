import config from '../config/config';
import authService from '../cross-platform/services/auth-service.js'

export const buildThumbnailUrl = (item) => {
    if (!item || !item.asset) return '';
    const index = item.asset.fileKey.lastIndexOf('.');
    const filename = item.asset.fileKey.slice(0, index);
    return  `${config.mediaCdnEndpoint}/videos/${item.id}/${filename}-1m-00002.png`;
};

export const buildVideoUrl = async (item) => {
    if (!item || !item.asset) return '';
    const index = item.asset.fileKey.lastIndexOf('.');
    const filename = item.asset.fileKey.slice(0, index);
    if (config.environment === 'localhost') {
        // sample video for local env. to prevent cors issue with cloud front
        return 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
    }
    return `${config.mediaCdnEndpoint}/videos/${item.id}/${filename}-playlist.m3u8?jwtToken=${await authService.getJwtToken()}`;
};

export const buildUrlFromUriWithBasePath = (uri) => {
    return `${config.mediaCdnEndpoint}/${uri}`;
}

export const buildBadgeImageUrl = (totalRides, folder = 'badges') => {
    return `${config.mediaCdnEndpoint}/images/${folder}/${totalRides}.svg`;
}

export const buildAchievementsImageUrl = (code) => {
    return `${config.mediaCdnEndpoint}/images/achievements/${code}.png`;
}
