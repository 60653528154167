import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classesIcon from '../../images/classes-ico.svg';
class Navigation extends Component {
    state = {
        collapseMenuClass: ''
    };

    showHideSubmenu = () => {
        this.setState((prevState) => ({
            collapseMenuClass: prevState.collapseMenuClass === '' ? 'show' : ''
        }));
    }

    render() {
        return (
            <div className="left-side-menu">
                <ul className="nav flex-column side-nav">
                    <li className="side-nav-title mb-5 d-flex text-center align-items-center justify-content-center">
                        <h5>Admin Dashboard</h5>
                    </li>
                    <li className="side-nav-item pl-4 py-2">
                        <NavLink to="/admin/classes" activeClassName="active" className="side-nav-link d-flex align-items-center text-decoration-none">
                            <img className="mr-3" src={classesIcon} alt="edit" />
                            <span className="title text-decoration-none"> Classes </span>
                        </NavLink>
                    </li>
                    <li className="side-nav-item pl-4 py-2">
                        <NavLink to="/admin/analytics" activeClassName="active" className="side-nav-link d-flex align-items-center text-decoration-none">
                            <i className="far fa-chart-bar fa-2x mr-3" />
                            <span className="title text-decoration-none"> Analytics </span>
                        </NavLink>
                    </li>
                    <li className="side-nav-item pl-4 py-2">
                        <NavLink to="/admin/class-session/playlists" activeClassName="active" className="side-nav-link d-flex align-items-center text-decoration-none">
                            <i className="far fa-plus-square fa-2x mr-3" />
                            <span className="title text-decoration-none"> Class Playlists </span>
                        </NavLink>
                    </li>
                    <li className="side-nav-item has-submenu pl-4 py-2 nav-item has-submenu">
                        <a
                            className="nav-link side-nav-link d-flex align-items-center text-decoration-none p-0"
                            onClick={() => this.showHideSubmenu()}
                        >
                            <i className="fa fa-cog fa-2x mr-3" />
                            <span className="title text-decoration-none"> Configuration </span>
                            <i className={`text-right fa ${this.state.collapseMenuClass === '' ? 'fa-caret-down' : 'fa-caret-up'} ml-2`}></i>
                        </a>
                        <ul className={`submenu collapse ${this.state.collapseMenuClass} pl-4`}>
                            <li>
                                <NavLink
                                    to="/admin/config/home" activeClassName="active"
                                    className="nav-link" href="#">Home
                                </NavLink>
                            </li>
                            {/* <li><a className="nav-link" href="#">Classes </a></li>
                            <li><a className="nav-link" href="#">General </a> </li> */}
                        </ul>
                    </li>
                </ul>
            </div>)
    }
}
export default Navigation;