import React from 'react';
import PropTypes from 'prop-types';

const ConfirmDialog = (props) => {

    const onConfirm = () => {
        if (props.onConfirm) props.onConfirm();
    }

    const onCancel = () => {
        if (props.onCancel) props.onCancel();
    }

    return (
        <React.Fragment>
            <div className="modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true" style={{ display: props.showDialog && 'block' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content w-75 mx-auto rounded">
                        <div className="modal-body m-2">
                            <div className="col-12 text-center mt-3">
                                <h4 className="mb-4">{props.title}</h4>
                                {props.children}
                            </div>
                            <div className="col-12 px-0 mb-3">
                                <div className="row no-gutters">
                                    <div className="col-6 text-right">
                                        <button type="button"
                                            className="px-4 px-md-5 btn btn-secondary mr-1"
                                            data-dismiss="modal"
                                            onClick={onCancel}>{props.onCancelTextButton}
                                        </button>
                                    </div>
                                    <div className="col-6 text-left">
                                        {props.onConfirm && 
                                        <button type="button"
                                            className="px-4 px-md-5 btn btn-danger ml-1"
                                            onClick={onConfirm}>{props.onConfrimTextButton}
                                        </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.showDialog && <div className="modal-backdrop fade show"></div>}
        </React.Fragment>
    )
}

ConfirmDialog.defaultProps = {
    onCancelTextButton: 'Cancel',
    onConfrimTextButton: 'Ok'
}

ConfirmDialog.propTypes = {
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    showDialog: PropTypes.bool,
    onCancelTextButton: PropTypes.string,
    onConfrimTextButton: PropTypes.string
}

export default ConfirmDialog;
