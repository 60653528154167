import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WarningImageWrapper = styled.div`
  background-color: rgba(235, 87, 87, 0.2);
  border-radius: 94px;
  height: 148px;
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CancellationTitle = styled.label`
  font-size: 28px;
  line-height: 42px;
  color: #333333;
  text-align: center;
  font-weight: 500;
  padding-top: 20px;
`;

export const SubscriptionCancellationWarningIcon = styled.img`
    width: 60px;
    height: 54px;
`;

export const SubscriptionCancellationCloseModalIcon = styled.img`
    width: 80px;
    height: 80px;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 30px;

    &:active {
      opacity: 0.5;
    }
`;
