// eslint-disable-next-line 
import React, { Component } from 'react';
import authService from '../../../services/auth-service';
import accountService from '../../../services/accountService';
import modes from '../../../references/modes';
import _ from 'lodash';
import dayjs from 'dayjs';

class ProfileEditor extends Component {

    state = {
        mode: '',
        profile: {
            avatar: '',
            gender: '',
            id: '',
            fullName: '',
            primary: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            mode: props.mode
        };
    }

    _getUserProfile = (id) => {
        accountService.getUserProfile({ id })
            .then(profile => {
                const [profileFirstName = null, profileLastName = null] = profile.fullName.split(' ');
                const updatedProfile = { ...profile, profileFirstName, profileLastName };
                this.setState({
                    profile: updatedProfile
                })
            })
    }

    _onChange = (data) => {
        this.setState({
            profile: {
                ...this.state.profile,
                [data.name]: data.value
            }
        })
    }

    _saveUserProfile = () => {
        this.setState({ mode: modes.FETCHING });
        const profile = _.cloneDeep(this.state.profile);
        profile.fullName = profile.profileFirstName + ' ' + profile.profileLastName;
        profile.birthday = dayjs(profile.birthday).format('MM/DD/YY');
        return this.props.saveUserProfile(profile)
            .then(async () => {
                return await authService.getCurrentAuthenticatedUser(true);
            })
    }
}

export default ProfileEditor;
