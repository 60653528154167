import React from 'react'

const OpenMenuIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1H19M1 7L19 7M1 13H19" stroke={color !== '' ? color : '#333333'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default OpenMenuIcon;