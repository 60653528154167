import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import createClass from 'create-react-class';
import Classes from '../../cross-platform/containers/classes';
import * as actions from '../../cross-platform/actions/class-actions';
import Select, { components } from 'react-select';

import NavigationAvatar from '../../components/navigation-avatar';
import Navigation from '../../components/navigation';
import ItemWidget from '../../components/item-widget';
import FavoriteIcon from '../../components/elements/favorite-icon';
import CloseIcon from '../../components/elements/icons/close-icon';
import MenuIcon from '../../components/elements/icons/menu-icon';
import InstructorIcon from '../../components/elements/icons/instructor-icon';
import ClassIcon from '../../components/elements/icons/class-icon';
import MusicIcon from '../../components/elements/icons/music-icon';
import WeightsIcon from '../../components/elements/icons/weights-icon';

import EmptyPlaceholder from '../../images/empty-placeholder.png';

const customDropdownStyles = {
    // container: styles => ({
    // ...styles,
    // width: 150
    // }),
    input: styles => ({
        ...styles,
        marginLeft: '10px',
        outline: "none"
    }),
    placeholder: styles => ({
        ...styles,
        fontFamily: "Poppins",
        fontSize: '0.875rem',
        marginLeft: '30px'
    }),
    control: styles => ({ 
        ...styles, 
        borderRadius: '1rem',
        fontFamily: "Poppins",
        fontSize: '0.875rem',
        padding: '8px 10px',
        width: 'auto',
        border: 0,
        boxShadow: 'none'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default'
        }
    },
    indicatorSeparator: styles => ({
        ...styles,
        display: 'none',
    }),
}

const DropdownStudioIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <CloseIcon width={16} height={16} color={props.selectProps.value && Object.keys(props.selectProps.value).length > 0 ? '#ffffff' : '#828282'} />
          ) : (
            <></>
          )}
        </components.DropdownIndicator>
      )
    );
};

const DropdownStudioValueContainer= ({ children, ...props }) => {
    return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            {!!children && (
              <MenuIcon width={20} height={20} color={props.selectProps.value && Object.keys(props.selectProps.value).length > 0 ? '#ffffff' : '#828282'} />
            )}
            {children}
          </components.ValueContainer>
        )
    );
}

const DropdownInstructorIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <CloseIcon width={16} height={16} color={props.selectProps.value && props.selectProps.value.length > 0 ? '#ffffff' : '#828282'} />
          ) : (
            <></>
          )}
        </components.DropdownIndicator>
      )
    );
};

const DropdownCustomOption = createClass({
    render() {
        return (
            <div>
                <components.Option {...this.props}>
                <input
                    type="checkbox"
                    checked={this.props.isSelected}
                    onChange={e => null}
                    className="d-none"
                />{" "}
                <label>{this.props.value} </label>
                </components.Option>
            </div>
        );
    }
});

const DropdownCustomMultiValue = props => {
    return (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );
}


const DropdownInstructorValueContainer= ({ children, ...props }) => {
    return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            {!!children && (
              <>
                {!props.selectProps.value || props.selectProps.value.length === 0 ? <InstructorIcon width={20} height={20} /> : <span className="count-selected">{props.selectProps.value.length}</span>}
              </>
            )}
            {(props.selectProps.value && props.selectProps.value.length > 0) ? <span className="instructor-default-val input-default-val">Instructor</span> : children}
          </components.ValueContainer>
        )
    );
}

const DropdownClassIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <CloseIcon width={16} height={16} color={props.selectProps.value && props.selectProps.value.length > 0 ? '#ffffff' : '#828282'} />
          ) : (
            <></>
          )}
        </components.DropdownIndicator>
      )
    );
};

const DropdownClassValueContainer= ({ children, ...props }) => {
    return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            {!!children && (
              <>
                {!props.selectProps.value || props.selectProps.value.length === 0 ? <ClassIcon width={20} height={20} /> : <span className="count-selected">{props.selectProps.value.length}</span>}
              </>
            )}
            {(props.selectProps.value && props.selectProps.value.length > 0) ? <span className="class-default-val input-default-val">Class Duration</span> : children}
          </components.ValueContainer>
        )
    );
}


const DropdownMusicIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <CloseIcon width={16} height={16} color={props.selectProps.value && props.selectProps.value.length > 0 ? '#ffffff' : '#828282'} />
          ) : (
            <></>
          )}
        </components.DropdownIndicator>
      )
    );
};

const DropdownMusicValueContainer= ({ children, ...props }) => {
    return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            {!!children && (
              <>
                {!props.selectProps.value || props.selectProps.value.length === 0 ? <MusicIcon width={20} height={20} /> : <span className="count-selected">{props.selectProps.value.length}</span>}
              </>
            )}
            {(props.selectProps.value && props.selectProps.value.length > 0) ? <span className="music-default-val input-default-val">Music Genre</span> : children}
          </components.ValueContainer>
        )
    );
}

const DropdownWeightsIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <CloseIcon width={16} height={16} />
          ) : (
            <></>
          )}
        </components.DropdownIndicator>
      )
    );
};

const DropdownWeightsValueContainer= ({ children, ...props }) => {
    return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            {!!children && (
              <WeightsIcon width={24} height={20} color={props.selectProps.value && Object.keys(props.selectProps.value).length > 0 ? '#ffffff' : '#828282'} />
            )}
            {children}
          </components.ValueContainer>
        )
    );
}

class ClassesPage extends Classes {
    onWeightsSelect = (selectedWeights) => {
        this.setState({
            selectedWeights: (selectedWeights && selectedWeights.value != null) ? selectedWeights.value : null
        });
    }

    setSessionAsFavorite = (classSessionId, isFavorite) => {
        this.setState({
            ...this.state,
            classSessions: this.state.classSessions.map((classSession) => (classSession.id === classSessionId) ? { ...classSession, favorite: isFavorite } : classSession
                )
        });
    }

    render() {
        const { selectedWeights } = this.state;
        const { userProfile } = this.props;
        const classSessions = this.state.classSessions.slice().filter(classSession => {
            return (
                (classSession.studioName || '').indexOf(this.state.selectedStudio) !== -1 &&
                (this.state.selectedSessionLength.length === 0 || this.state.selectedSessionLength.indexOf(`${classSession.duration}` || '') !== -1) &&
                (this.state.selectedMusicGenre.length === 0 || this.state.selectedMusicGenre.indexOf(classSession.musicType || '') !== -1) &&
                (this.state.selectedInstructor.length === 0 || this.state.selectedInstructor.indexOf(classSession.instructor.fullName || '') !== -1) &&
                (typeof selectedWeights !== 'boolean' || classSession.hasWeights === selectedWeights)
            )
        });

        return (
            <div className="container-fluid bg-light classes">
                {userProfile.fullName && <NavigationAvatar profile={userProfile} />}
                <div className="row">
                    <div className="col-12 pb-6">
                        <div className="row">
                            <div className="col-lg-11 col-sm-11 col-md-12 col-xs-12 d-flex flex-column flex-lg-row mt-5 mb-2 ml-2 pr-5 class-session-filter-wrapper">
                                <h2 className="font-weight-bold mr-5">Classes</h2>
                                <div onClick={this.onClickTest} className="row flex-grow-1 class-session-filters">
                                    <MediaQuery minWidth={769}>
                                      <div className="col-lg col-6">
                                          <Select 
                                          className={`custom-class-dropdown ${this.state.selectedStudio.length !== 0 ? 'custom-class-dropdown__highlighted' : ''}`}
                                          classNamePrefix="custom-class-dropdown"
                                          options={this.state.studioNames} 
                                          onChange={this.onStudioSelect} 
                                          components={{
                                              DropdownIndicator: DropdownStudioIndicator,
                                              ValueContainer: DropdownStudioValueContainer
                                          }}
                                          placeholder="Studio" 
                                          styles={customDropdownStyles}
                                          controlShouldRenderValue = {false}
                                          isClearable={false} />
                                      </div>
                                      <div className="col-lg col-6">
                                          <Select 
                                          className={`custom-class-dropdown ${this.state.selectedInstructor.length !== 0 ? 'custom-class-dropdown__highlighted' : ''}`}
                                          classNamePrefix="custom-class-dropdown"
                                          options={this.state.instructors} 
                                          onChange={this.onInstructorMultipleSelect}
                                          components={{
                                              DropdownIndicator: DropdownInstructorIndicator,
                                              ValueContainer: DropdownInstructorValueContainer,
                                              Option: DropdownCustomOption,
                                              MultiValue: DropdownCustomMultiValue
                                          }}
                                          placeholder="Instructor"
                                          styles={customDropdownStyles} 
                                          closeMenuOnSelect={false}
                                          hideSelectedOptions={false}
                                          backspaceRemovesValue={false}
                                          isMulti
                                          isClearable={false} />
                                      </div>
                                      <div className="col-lg col-6 mt-2 mt-lg-0">
                                          <Select 
                                          className={`custom-class-dropdown ${this.state.selectedSessionLength.length !== 0 ? 'custom-class-dropdown__highlighted' : ''}`}
                                          classNamePrefix="custom-class-dropdown"
                                          options={this.state.sessionLength} 
                                          onChange={this.onClassLengthMultipleSelect} 
                                          components={{
                                              DropdownIndicator: DropdownClassIndicator,
                                              ValueContainer: DropdownClassValueContainer,
                                              Option: DropdownCustomOption,
                                              MultiValue: DropdownCustomMultiValue
                                          }}
                                          placeholder="Class Duration" 
                                          styles={customDropdownStyles}
                                          closeMenuOnSelect={false}
                                          hideSelectedOptions={false}
                                          backspaceRemovesValue={false}
                                          isMulti
                                          isClearable={false} />
                                      </div>
                                      <div className="col-lg col-6 mt-2 mt-lg-0">
                                          <Select 
                                          className={`custom-class-dropdown ${this.state.selectedMusicGenre.length !== 0 ? 'custom-class-dropdown__highlighted' : ''}`}
                                          classNamePrefix="custom-class-dropdown"
                                          options={this.state.musicGenres} 
                                          onChange={this.onMusicGenreMultipleSelect} 
                                          components={{
                                              DropdownIndicator: DropdownMusicIndicator,
                                              ValueContainer: DropdownMusicValueContainer,
                                              Option: DropdownCustomOption,
                                              MultiValue: DropdownCustomMultiValue
                                          }}
                                          placeholder="Music Genre"
                                          styles={customDropdownStyles}
                                          closeMenuOnSelect={false}
                                          hideSelectedOptions={false}
                                          backspaceRemovesValue={false}
                                          isMulti
                                          isClearable={false} />
                                      </div>
                                      <div className="col-lg col-6 pr-lg-0 mt-2 mt-lg-0">
                                          <Select
                                          className={`custom-class-dropdown custom-class-dropdown__weights-content ${this.state.selectedWeights !== null ? 'custom-class-dropdown__highlighted' : ''}`}
                                          classNamePrefix="custom-class-dropdown"
                                          options={this.state.weights} 
                                          onChange={this.onWeightsSelect} 
                                          components={{
                                              DropdownIndicator: DropdownWeightsIndicator,
                                              ValueContainer: DropdownWeightsValueContainer
                                          }}
                                          placeholder="Weights" 
                                          styles={customDropdownStyles}
                                          controlShouldRenderValue = {false}
                                          isClearable={false} />
                                      </div>
                                      <div className="col-lg-3 col-6 pr-lg-0 mt-2 mt-lg-0 favorite-wrapper">
                                          <FavoriteIcon isFavorite={this.state.showFavoritesOnly} onClick={this.onFavoritesCheck} showText />
                                          {((this.state.selectedInstructor && this.state.selectedInstructor.length !== 0) || (this.state.selectedMusicGenre && this.state.selectedMusicGenre.length !== 0) || (this.state.selectedStudio && this.state.selectedStudio.length !== 0) || (this.state.selectedSessionLength && this.state.selectedSessionLength.length !== 0) || (this.state.selectedWeights !== null)) &&
                                          <>
                                          <span className="mx-2 dot-separator">&bull;</span>
                                          <button 
                                          className="clear-filters-btn" 
                                          // onClick={this.onClearFilters}
                                          /** TODO: fix the issue of clearing react-select options manually */
                                          onClick={() => window.location.reload()}
                                          >
                                              <CloseIcon width={14} height={14} color='#fcb333' />
                                              Clear All
                                          </button>
                                          </>
                                          }
                                      </div>
                                    </MediaQuery>

                                    <MediaQuery maxWidth={768}>
                                      <div className={`class-mobile-filters`}>
                                        <div className="favorite-wrapper">
                                            <FavoriteIcon isFavorite={this.state.showFavoritesOnly} onClick={this.onFavoritesCheck} showText={false} />
                                            {((this.state.selectedInstructor && this.state.selectedInstructor.length !== 0) || (this.state.selectedMusicGenre && this.state.selectedMusicGenre.length !== 0) || (this.state.selectedStudio && this.state.selectedStudio.length !== 0) || (this.state.selectedSessionLength && this.state.selectedSessionLength.length !== 0) || (this.state.selectedWeights !== null)) &&
                                            <>
                                            <button 
                                            className="clear-filters-btn" 
                                            // onClick={this.onClearFilters}
                                            /** TODO: fix the issue of clearing react-select options manually */
                                            onClick={() => window.location.reload()}
                                            >
                                                <CloseIcon width={14} height={14} color='#fcb333' />
                                            </button>
                                            </>
                                            }
                                            <span className="bull-separator">&bull;</span>
                                        </div>

                                        <div className="class-filter-item">
                                          <Select 
                                            className={`custom-class-dropdown ${this.state.selectedStudio.length !== 0 ? 'custom-class-dropdown__highlighted' : ''}`}
                                            classNamePrefix="custom-class-dropdown"
                                            options={this.state.studioNames} 
                                            onChange={this.onStudioSelect}
                                            menuPortalTarget={document.querySelector('body')}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            components={{
                                                DropdownIndicator: DropdownStudioIndicator,
                                                ValueContainer: DropdownStudioValueContainer
                                            }}
                                            placeholder="Studio" 
                                            styles={customDropdownStyles}
                                            controlShouldRenderValue = {false}
                                            isClearable={false} />
                                        </div>
                                        <div className="class-filter-item ml-1">
                                            <Select 
                                            className={`custom-class-dropdown-single custom-class-dropdown ${this.state.selectedInstructor.length !== 0 ? 'custom-class-dropdown__highlighted' : ''}`}
                                            classNamePrefix="custom-class-dropdown"
                                            options={this.state.instructors} 
                                            onChange={this.onInstructorMultipleSelect}
                                            menuPortalTarget={document.querySelector('body')}
                                            components={{
                                                DropdownIndicator: DropdownInstructorIndicator,
                                                ValueContainer: DropdownInstructorValueContainer,
                                                Option: DropdownCustomOption,
                                                MultiValue: DropdownCustomMultiValue
                                            }}
                                            placeholder="Instructor"
                                            styles={customDropdownStyles} 
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            backspaceRemovesValue={false}
                                            isMulti
                                            isClearable={false} />
                                        </div>
                                        <div className="class-filter-item ml-1">
                                            <Select 
                                            className={`custom-class-dropdown-single custom-class-dropdown ${this.state.selectedSessionLength.length !== 0 ? 'custom-class-dropdown__highlighted' : ''}`}
                                            classNamePrefix="custom-class-dropdown"
                                            options={this.state.sessionLength} 
                                            onChange={this.onClassLengthMultipleSelect} 
                                            menuPortalTarget={document.querySelector('body')}
                                            components={{
                                                DropdownIndicator: DropdownClassIndicator,
                                                ValueContainer: DropdownClassValueContainer,
                                                Option: DropdownCustomOption,
                                                MultiValue: DropdownCustomMultiValue
                                            }}
                                            placeholder="Class Duration" 
                                            styles={customDropdownStyles}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            backspaceRemovesValue={false}
                                            isMulti
                                            isClearable={false} />
                                        </div>
                                        <div className="class-filter-item ml-1">
                                            <Select 
                                            className={`custom-class-dropdown-single custom-class-dropdown ${this.state.selectedMusicGenre.length !== 0 ? 'custom-class-dropdown__highlighted' : ''}`}
                                            classNamePrefix="custom-class-dropdown"
                                            options={this.state.musicGenres} 
                                            onChange={this.onMusicGenreMultipleSelect}
                                            menuPortalTarget={document.querySelector('body')} 
                                            components={{
                                                DropdownIndicator: DropdownMusicIndicator,
                                                ValueContainer: DropdownMusicValueContainer,
                                                Option: DropdownCustomOption,
                                                MultiValue: DropdownCustomMultiValue
                                            }}
                                            placeholder="Music Genre"
                                            styles={customDropdownStyles}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            backspaceRemovesValue={false}
                                            isMulti
                                            isClearable={false} />
                                        </div>
                                        <div className="class-filter-item ml-1">
                                            <Select
                                            className={`custom-class-dropdown custom-class-dropdown__weights-content ${this.state.selectedWeights !== null ? 'custom-class-dropdown__highlighted' : ''}`}
                                            classNamePrefix="custom-class-dropdown"
                                            options={this.state.weights} 
                                            onChange={this.onWeightsSelect}
                                            menuPortalTarget={document.querySelector('body')} 
                                            components={{
                                                DropdownIndicator: DropdownWeightsIndicator,
                                                ValueContainer: DropdownWeightsValueContainer
                                            }}
                                            placeholder="Weights" 
                                            styles={customDropdownStyles}
                                            controlShouldRenderValue = {false}
                                            isClearable={false} />
                                        </div>
                                      </div>
                                    </MediaQuery>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 items-wrapper mt-4">
                                {classSessions.length > 0 ? classSessions.map(item => {
                                    return (
                                        <ItemWidget
                                            key={item.id}
                                            classId={item.id}
                                            coverImage={item.defaultThumbnailUri}
                                            studioName={item.studioName}
                                            duration={item.duration}
                                            title={item.title}
                                            instructor={item.instructor}
                                            watched={item.watched}
                                            createdOn={item.createdOn}
                                            userCan={this.props.userCan}
                                            sessionDateTime={item.sessionDateTime}
                                            favorite={item.favorite}
                                            hasWeights={item.hasWeights}
                                            showFavoriteIcon
                                            setSessionAsFavorite={this.setSessionAsFavorite}
                                            link={`/dashboard/${item.id}?from=classes`}
                                        />
                                    )
                                }) : (
                                    <div className="empty-placeholder my-8 mx-auto  align-items-center min-vh-100">
                                      <h3 className="text-center my-5">No Classes Yet</h3>
                                      
                                      <MediaQuery minWidth={769}>
                                        <p>
                                          <img src={EmptyPlaceholder} alt="" />
                                        </p>
                                      </MediaQuery>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Navigation />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ classStore, userStore }) => {
  return {
      ...classStore,
      userProfile: userStore.userProfile,
      userCan: userStore.userCan
  };
};

export default connect(mapStateToProps, actions)(ClassesPage);