import React from 'react';
import { ValidatedForm, ValidatedInput } from 'react-validated';
import { connect } from "react-redux";
import moment from 'moment';
import { toast } from "react-toastify";

import modes from '../../../cross-platform/references/modes';
import { ACCOUNT_CHANGE_PASSWORD_MODES } from '../../../cross-platform/references/constants';
import * as userActions from '../../../cross-platform/actions/user-actions';
import config from '../../../config/config';

import Avatar from '../../../components/avatar';

const MODES = ACCOUNT_CHANGE_PASSWORD_MODES;

class MyProfile extends React.Component {

    state = {
        selectedAvatar: this.props.userAvatars[0].name,
        editing: true,
        editingBoxId: 0,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        showChangePasswordFields: false,
    };

    _changePassword = () => {
        return this.props.changePassword(this.state.oldPassword, this.state.newPassword, this.state.confirmPassword)
            .then(() => {
                this.setState({
                    editing: false,
                    editingBoxId: 0,
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });
                return Promise.resolve();
            });
    };

    showPasswordFields = () => {
        return this.setState(prevState => {
            return {
                showChangePasswordFields: !prevState.showChangePasswordFields
            }
        });
    }

    cancel = () => {
        this.props.resetUserProfile();
        this.setState({
            editing: false,
            editingBoxId: 0,
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        });
    };

    static getDerivedStateFromProps(props, state) {
        if (props.userProfile.avatar === state.selectedAvatar)
            return state;
        return {
            selectedAvatar: props.userProfile.avatar
        };
    }

    changePassword = () => {
        this.props.changePassword(this.state.oldPassword, this.state.newPassword, this.state.confirmPassword)
            .then(() => {
                this.setState({
                    editing: false,
                    editingBoxId: 0,
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });
                toast.success('Password changed successfully', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(() => false);
    };

    edit = (id) => {
        this.props.setUserProfilePrevState();
        this.setState({ editing: true, editingBoxId: id });
    };

    onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    };

    saveUserProfile = () => {
        const { showChangePasswordFields } = this.state;
        this.props.saveCurrentUserProfile()
            .then(() => {
                this.setState({
                    editing: false,
                    editingBoxId: 0
                });

                if(showChangePasswordFields) {
                    this._changePassword()
                    .then(() => {
                        toast.success('Password changed successfully', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    });
                } else {
                    toast.success('User information updated successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }).catch(() => false);
    };

    selectAvatar = (path) => {
        if (this.state.editingBoxId !== 1)
            return false;
        this.props.selectUserAvatar(path);
    };

    _fullAvatarPath = (path) => {
        if (!path) return;
        if (path.includes(config.webAppCdnEndpoint))
            return path;
        return config.webAppCdnEndpoint + path;
    }

    render() {
        const { showChangePasswordFields } = this.state;
        const { userProfile, userProfileHelper, onChange, selectUserGender } = this.props;

        return (
            <div className="col-12 col-xl-10 my-account">
                <div className="profile-wrapper">
                    {userProfileHelper.mode === modes.WITH_ERROR && (
                        <div className="alert alert-danger mx-3">
                            <i className="fas fa-exclamation-circle pr-2"></i>
                            {userProfileHelper.errorMessage}
                        </div>
                    )}
                    <div className="row m-0 no-gutters">
                        <div className="col-12 col-lg-4">
                            <div className="h-100 d-flex flex-column justify-content-center p-3 p-lg-5 ">
                                <div className="avatar-content">
                                    <div className="mx-auto avatar">
                                    {(this.props.userProfile && this.props.userProfile.avatar) && <Avatar profile={this.props.userProfile} width={120} height={120} />}
                                </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`col-12 col-lg-8 mt-4 mt-lg-0  personal-content edit-box ${(this.state.editing) ? 'editing' : ''}`}>
                            <div className="p-lg-5 p-lg-3 h-100">
                                <ValidatedForm onSubmit={this.saveUserProfile} className="form-group w-100">
                                    <div className="d-none row m-0 no-gutters d-xl-block">
                                        <div className="col-7">
                                            <h5 className="mb-5 mt-3">Personal information</h5>
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-4">
                                        <div className="col-12 col-md-6 mt-3">
                                            <label>First Name</label>
                                            <ValidatedInput required>
                                                <input type="text" className="form-control"
                                                    name="profileFirstName"
                                                    id="profileFirstName"
                                                    placeholder="First Name"
                                                    value={userProfile.profileFirstName || ''}
                                                    onChange={onChange}
                                                    disabled={!(this.state.editing)}></input>
                                            </ValidatedInput>
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label>Last Name</label>
                                            <ValidatedInput required>
                                                <input type="text" className="form-control p-3"
                                                    name="profileLastName"
                                                    id="profileLastName"
                                                    placeholder="Last Name"
                                                    value={userProfile.profileLastName || ''}
                                                    onChange={onChange}
                                                    disabled={!(this.state.editing)}></input>
                                            </ValidatedInput>
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label>Birthday</label>
                                            <ValidatedInput min-length={{ params: 3 }}>
                                                <input type="date" className="form-control p-3"
                                                    name="birthday"
                                                    placeholder="Birthday"
                                                    value={moment(userProfile.birthday).format('YYYY-MM-DD') || ''}
                                                    onChange={onChange}
                                                    disabled={!(this.state.editing)}></input>
                                            </ValidatedInput>
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label>Location</label>
                                            <ValidatedInput min-length={{ params: 3 }}>
                                                <input type="text" className="form-control p-3"
                                                    name="location"
                                                    placeholder="Location"
                                                    value={userProfile.location || ''}
                                                    onChange={onChange}
                                                    disabled={!(this.state.editing)}></input>
                                            </ValidatedInput>
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label>Gender</label>
                                            <div className="gender">
                                                <button
                                                    onClick={() => selectUserGender('female')}
                                                    className={`female ${userProfile.gender === 'female' ? 'selected' : ''}`}
                                                    disabled={!(this.state.editing)}
                                                    type="button"
                                                >
                                                    Female
                                                </button>
                                                <button
                                                    onClick={() => selectUserGender('male')}
                                                    className={`male ${userProfile.gender === 'male' ? 'selected' : ''}`}
                                                    disabled={!(this.state.editing)}
                                                    type="button"
                                                >
                                                    Male
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            {!showChangePasswordFields ? 
                                            <>
                                                <label>PASSWORD</label>
                                                <button className="btn btn-block btn-light p-3" onClick={this.showPasswordFields}>Change Password</button>
                                            </>
                                            : <>
                                                <label>Old Password</label>
                                                <ValidatedInput min-length={{ params: 6 }}>
                                                    <input type="password" 
                                                        className="form-control p-3"
                                                        name="oldPassword"
                                                        placeholder="Old Password"
                                                        value={this.state.oldPassword}
                                                        disabled={false}
                                                        onChange={this.onChange}></input>
                                                </ValidatedInput>
                                            </>}
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            
                                        </div>
                                        <div className="col-6 d-none d-md-block"></div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label>New Password</label>
                                            {showChangePasswordFields ? 
                                                <ValidatedInput min-length={{ params: 6 }} required={showChangePasswordFields}>
                                                <input type="password" 
                                                    className="form-control p-3"
                                                    name="newPassword"
                                                    placeholder="New Password"
                                                    value={this.state.newPassword}
                                                    disabled={!showChangePasswordFields}
                                                    onChange={this.onChange}></input>
                                                </ValidatedInput> : 
                                                <input type="password" 
                                                className="form-control p-3"
                                                name="newPassword"
                                                placeholder="New Password"
                                                disabled
                                                value=''/>
                                            }
                                        </div>
                                        <div className="col-12 col-md-6 mt-3">
                                            <label>Confirm Password</label>
                                            {showChangePasswordFields ? 
                                                <ValidatedInput min-length={{ params: 6 }} required={showChangePasswordFields}>
                                                <input type="password"
                                                    className="form-control p-3"
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password"
                                                    value={this.state.confirmPassword}
                                                    disabled={!showChangePasswordFields}
                                                    onChange={this.onChange} />
                                                </ValidatedInput>
                                            : <input type="password"
                                                className="form-control p-3"
                                                name="confirmPassword"
                                                placeholder="Confirm Password"
                                                value=''
                                                disabled />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 edit-btn-wrapper">
                                        <React.Fragment>
                                            <button
                                                className="btn btn-main float-right px-7 py-3 ml-3 d-md-block"
                                                disabled={(userProfileHelper.mode === modes.FETCHING)}
                                                type="submit">
                                                Save Changes
                                                {userProfileHelper.mode === modes.FETCHING && (
                                                    <div className="spinner-grow ml-1" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                )}
                                            </button>
                                            {/* <button
                                                className="btn btn-outline-primary float-right font-weight-bolder px-2 py-2 d-none d-md-block"
                                                disabled={(userProfileHelper.mode === modes.FETCHING)}
                                                onClick={this.cancel}>Cancel
                                            </button> */}
                                            {/* {userProfileHelper.mode === modes.FETCHING ? (
                                                <div className="spinner-grow ml-1 float-right mt-2 ml-2 d-md-none"
                                                    role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                    <button className="far fa-lg fa-save float-right d-md-none p-2 ml-2"
                                                        type="submit">
                                                    </button>
                                                )}
                                            <i className="far fa-lg fa-times-circle float-right d-md-none p-2"
                                                onClick={this.cancel}></i> */}
                                        </React.Fragment>
                                    </div>
                                </ValidatedForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ userStore }) => {
    return {
        userProfileHelper: userStore.userProfileHelper,
        userProfile: userStore.userProfile,
        userAvatars: userStore.userAvatars,
        selectedProfileId: userStore.selectedProfileId
    };
};

export default connect(mapStateToProps, { ...userActions })(MyProfile);