import _ from 'lodash';
import dayjs from 'dayjs';
import * as types from './action-types';
import modes from '../references/modes';
import avatars from '../references/avatars';

import accountService from '../services/accountService';
// eslint-disable-next-line
import authService from '../services/auth-service';
import userService from '../services/user-service';
import { eventTargets } from '../common/event-target';
import { STRIPE_SUBSCRIPTION_STATUSES } from '../references/constants';

export const changePassword = (oldPassword, newPassword, confirmPassword) => (
  dispatch,
) => {
  if (confirmPassword !== newPassword) {
    dispatch({
      type: types.SET_USER_PROFILE_MODE_ERROR,
      mode: modes.WITH_ERROR,
      errorMessage: 'Confirmation password does not match',
    });
    return Promise.reject();
  }

  dispatch({ type: types.SET_USER_PROFILE_MODE, mode: modes.FETCHING });
  return authService
    .changePassword(oldPassword, newPassword)
    .then(() => {
      dispatch({ type: types.SET_USER_PROFILE_MODE, mode: modes.BROWSE });
      return Promise.resolve();
    })
    .catch((error) => {
      let message = '';
      switch (error.code) {
        case 'NotAuthorizedException':
          message = 'Wrong password! Old password should be your current password';
          break;
        case 'LimitExceededException':
          message = 'Too many attempts, please try later';
          break;
        default:
          message = 'Failed to change password';
          break;
      }

      dispatch({
        type: types.SET_USER_PROFILE_MODE_ERROR,
        mode: modes.WITH_ERROR,
        errorMessage: message,
      });
      return Promise.reject();
    });
};

export const updateUserSessionLeaderboard = () => (dispatch, getState) => {
  const { userProfile } = getState().userStore;
  const { simulations } = getState().simulationStore;
  const { userSessionLeaderboard } = getState().userStore;
  if (!userProfile || !userSessionLeaderboard.length) return;

  const totalEnergy = _.sum(
    simulations.map((simulation) => simulation.energy * 1000),
  );

  const leaderboard = [
    ...userSessionLeaderboard.slice(0, 5),
    {
      totalEnergy: Number((totalEnergy && totalEnergy / 1000) || 0).toFixed(2),
      owner: {
        ...userProfile,
      },
    },
  ];

  dispatch({
    type: types.SET_CURRENT_USER_SESSION_LEADERBOARD,
    currentUserSessionLeaderboard: leaderboard,
  });
};

export const getUserProfile = () => async (dispatch) => {
  const options = {};
  const userProfileId = await authService.getUserProfileId();
  options.id = userProfileId;
  try {
    const data = await accountService.getUserProfile(options);
    const [
      profileFirstName = null,
      profileLastName = null,
    ] = data.fullName.split(' ');
    data.profileFirstName = profileFirstName;
    data.profileLastName = profileLastName;
    if (!data) return;
    dispatch({
      type: types.CURRENT_USER_PROFILE_RETRIEVED,
      userProfile: { ...data, avatar: data.avatar || avatars[0].path },
    });
    // TODO figure out why this is here
    dispatch(updateUserSessionLeaderboard());
  } catch (error) {
    dispatch({
      type: types.GET_USER_PROFILE_FAILED,
      error,
    });
  }
};

export const deleteUserProfile = (profile) => async () => {
  accountService.deleteUserProfile(profile.id).then(async () => {
    await getUserProfile();
  });
};

export const fetchUserProfiles = () => (dispatch) => {
  accountService.fetchUserProfiles().then((data) => {
    dispatch({
      type: types.USER_PROFILES_FETCHED,
      data,
    });
  });
};

export const fetchClassLeaderboard = (classSessionId) => (
  dispatch,
  getState,
) => {
  const { userProfile, userSessionLeaderboard } = getState().userStore;

  userService.fetchClassLeaderboard(classSessionId).then((data) => {
    // debugger;
    const items = (data && data.items) || [];
    const mergedLeaderboard = [...userSessionLeaderboard, ...items];
    const orderedLeaderboard = mergedLeaderboard
      .map((results) => {
        const session = results;
        if (session.owner.profileId === userProfile.id) {
          session.owner = { ...session.owner, ...userProfile };
          session.previousSession = true;
        }
        return session;
      })
      .sort((a, b) => (a.totalEnergy < b.totalEnergy ? 1 : -1));
    dispatch({
      type: types.SET_USER_SESSION_LEADERBOARD,
      userSessionLeaderboard: orderedLeaderboard,
    });
    dispatch({
      type: types.SET_CURRENT_USER_SESSION_LEADERBOARD,
      currentUserSessionLeaderboard: orderedLeaderboard,
    });
    dispatch(updateUserSessionLeaderboard());
  });
};

export const getSelectedProfile = (profileId) => (dispatch) => {
  accountService.getUserProfile({ id: profileId }).then((data) => {
    dispatch({
      type: types.SET_SELECTED_PROFILE,
      userProfile: { ...data, avatar: data.avatar || avatars[0].path },
    });
    dispatch(updateUserSessionLeaderboard());
  });
};

/**
 *
 * @param {userAccount} userAccountData data.
 */
export const setUserAccount = (userAccountData) => {
  const updatedUserAccountData = userAccountData;
  if (
    updatedUserAccountData
    && updatedUserAccountData.subscription
    && updatedUserAccountData.subscription.cancel_at_period_end
  ) {
    updatedUserAccountData.subscription.status = STRIPE_SUBSCRIPTION_STATUSES.CANCELED;
  }
  return (dispatch) => {
    dispatch({
      type: types.USER_ACCOUNT_RETRIEVED,
      data: updatedUserAccountData,
    });
  };
};

/**
 * Get current user account
 */
export const getUserAccount = () => (dispatch) => {
  dispatch({ type: types.SET_USER_ACCOUNT_MODE, mode: modes.FETCHING });
  accountService
    .getUserAccount()
    .then((results) => {
      const data = results;
      if (data && data.subscription && data.subscription.cancel_at_period_end) {
        data.subscription.status = STRIPE_SUBSCRIPTION_STATUSES.CANCELED;
      }
      dispatch({
        type: types.USER_ACCOUNT_RETRIEVED,
        data,
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: types.USER_ACCOUNT_RETRIEVAL_FAILED,
        error,
      });
    });
};

export const setUserProfile = (data) => async (dispatch) => {
  const options = {};
  const userProfileId = await data.id;
  options.id = userProfileId;
  try {
    if (!data) return;
    dispatch({
      type: types.CURRENT_USER_PROFILE_RETRIEVED,
      userProfile: {
        ...data,
        avatar: data.avatar || avatars[0].path,
        userProfileId,
      },
    });
    // TODO figure out why this is here
    dispatch(updateUserSessionLeaderboard());
  } catch (error) {
    dispatch({
      type: types.GET_USER_PROFILE_FAILED,
      error,
    });
  }
};

export const onChange = (event) => (dispatch) => {
  dispatch({
    type: types.USER_PROFILE_ON_CHANGE,
    ...eventTargets(event),
  });
};

export const openProfilePage = () => (dispatch) => {
  dispatch({ type: types.OPEN_PROFILE_PAGE });
};

export const onCloseSignOutModal = () => (dispatch) => {
  dispatch({ type: types.CLOSE_SIGN_OUT_MODAL });
};

export const openSignOutModal = () => (dispatch) => {
  dispatch({ type: types.OPEN_SIGN_OUT_MODAL });
};

export const resetUserSession = () => ({
  type: types.RESET_USER_SESSION,
});

export const resetUserProfile = () => (dispatch) => {
  dispatch({ type: types.RESET_USER_PROFILE });
};

export const resetSelectedProfile = () => ({
  type: types.RESET_SELECTED_PROFILE,
});

export const setCurrentUserRights = (session) => (dispatch) => {
  if (session) {
    const userGroups = session.idToken.payload['cognito:groups'];
    const userCan = {
      administer: authService.isKatanaAdmin(userGroups),
      accessSubscriptions: authService.hasActiveSubscription(session),
    };
    dispatch({
      type: types.SET_CURRENT_USER_RIGHTS,
      userCan,
    });
  } else {
    return authService
      .getCurrentSession()
      .then((session) => {
        if (!session) return;
        const userGroups = session.idToken.payload['cognito:groups'];
        const userCan = {
          administer: authService.isKatanaAdmin(userGroups),
          accessSubscriptions: authService.hasActiveSubscription(session),
        };
        dispatch({
          type: types.SET_CURRENT_USER_RIGHTS,
          userCan,
        });
      })
      .catch((error) => {
        console.warn('failed to get current session', error);
        dispatch({
          type: types.SET_CURRENT_USER_RIGHTS,
          userSessionGroups: [],
        });
      });
  }
};

export const signOut = () => (dispatch) => {
  dispatch({ type: types.LOGOUT_MODE, mode: modes.FETCHING });
  return authService
    .signOut()
    .then(() => {
      dispatch({ type: types.LOGOUT_MODE, mode: modes.BROWSE });
      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.LOGOUT_MODE_ERROR,
        mode: modes.WITH_ERROR,
        errorMessage: error.message || 'Could not log out',
      });
      return false;
    });
};

export const selectUserAvatar = (avatar) => (dispatch) => {
  dispatch({ type: types.SELECT_USER_AVATAR, avatar });
  return Promise.resolve();
};

export const selectUserGender = (gender) => (dispatch) => {
  dispatch({ type: types.SELECT_USER_GENDER, gender });
};

export const setUserProfilePrevState = () => (dispatch) => {
  dispatch({ type: types.SET_USER_PROFILE_PREV_STATE });
};

export const setUserSessionSummary = (id) => (dispatch, getState) => {
  const { userSessions } = getState().userStore;
  const selectedSession = userSessions.find((session) => session.id === id);
  if (selectedSession) {
    // Calculate diagram labels
    const avgTime = selectedSession.duration / 7;
    const labels = ['0 min'];
    for (let index = 1; index < 8; index += 1) {
      const nextLabelInSeconds = parseFloat(avgTime) * index;
      // Label is in mm:ss format
      const nextLabel = `${parseInt(nextLabelInSeconds / 60, 10)}:${parseInt(nextLabelInSeconds, 10) % 60
        }`;
      labels.push(nextLabel);
    }

    const userSessionSummary = {
      ...selectedSession,
      chartData: {
        labels,
        datasets: [
          {
            label: 'cadence',
            data: [0, ...selectedSession.power],
            backgroundColor: 'rgba(255,51,71, 0.3)',
            borderColor: 'rgba(255,51,71, 1)',
            borderWidth: 4,
            pointBackgroundColor: 'rgba(255,51,71, 1)',
          },
          {
            label: 'output',
            data: [0, ...selectedSession.cadence],
            backgroundColor: 'rgba(76,47,254, 0.3)',
            borderColor: 'rgba(76,47,254, 1)',
            borderWidth: 4,
            pointBackgroundColor: 'rgba(76,47,254, 1)',
          },
        ],
      },
    };

    dispatch({ type: types.SET_USER_SESSION_SUMMARY, userSessionSummary });
  }
};

export const onChangeSelectedProfile = (event) => ({
  type: types.UPDATE_SELECTED_PROFILE,
  ...eventTargets(event),
});

export const saveCurrentUserProfile = () => (dispatch, getState) => {
  const { userProfile } = getState().userStore;
  dispatch({ type: types.SET_USER_PROFILE_MODE_ERROR, mode: modes.FETCHING });
  const profile = _.cloneDeep(userProfile);
  profile.birthday = dayjs(profile.birthday).format('MM/DD/YY');
  profile.fullName = `${profile.profileFirstName} ${profile.profileLastName}`;
  return accountService
    .saveUserProfile(profile)
    .then(() => {
      dispatch({ type: types.SET_USER_PROFILE_MODE, mode: modes.BROWSE });
      dispatch({ type: types.SET_USER_PROFILE_PREV_STATE });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: types.SET_USER_PROFILE_MODE_ERROR,
        mode: modes.WITH_ERROR,
        errorMessage: error.message || 'Failed to save changes',
      });
      dispatch({ type: types.RESET_USER_PROFILE });
      return Promise.reject();
    });
};

export const saveUserProfile = (profile) => (dispatch) => {
  dispatch({ type: types.SET_USER_PROFILE_MODE, mode: modes.FETCHING });
  return accountService
    .saveUserProfile(profile)
    .then(() => {
      dispatch({ type: types.SET_USER_PROFILE_MODE, mode: modes.BROWSE });
      dispatch(getUserProfile());
      return Promise.resolve();
    })
    .catch((error) => {
      console.warn('Could not save user profile', error);
      dispatch({
        type: types.SET_USER_PROFILE_MODE_ERROR,
        mode: modes.WITH_ERROR,
        errorMessage: error.message || 'Failed to save changes',
      });
      return Promise.reject();
    })
};

export const switchUserProfile = (profile) => async () => {
  await authService.setUserProfileId(profile.id);
  return setUserProfile(profile);
};

export const updateLeaderboard = (totalEnergy) => (dispatch, getState) => {
  const { userProfile } = getState().userStore;
  const { userSessionLeaderboard } = getState().userStore;
  if (!userProfile || !userSessionLeaderboard.length) return;

  const leaderboard = [
    ...userSessionLeaderboard.slice(0, 5),
    {
      totalEnergy,
      owner: {
        ...userProfile,
      },
    },
  ];

  dispatch({
    type: types.SET_CURRENT_USER_SESSION_LEADERBOARD,
    currentUserSessionLeaderboard: leaderboard,
  });
};

export const setSelectedProfileId = (profileId) => ({
  type: types.SET_SELECTED_PROFILE_ID,
  selectedProfileId: profileId,
});
