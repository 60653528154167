import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';

import AdminPageHeader from '../components/admin-page-header';
import Breadcrumbs from '../components/breadcrumbs';
import analyticsService from '../../cross-platform/services/analytics-service';
import Pagination from '../components/pagination';
import refreshIco from '../../images/refresh-ico.svg';

class AnalyticsBrowser extends Component {

    state = {
        isLoading: false,
        classSessions: [],
        orderBy: 'participation_count',
        pageSize: 20,
        page: 1
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
    }

    _refreshData = () => {
        this.setState({ classSessions: [], page: 1 }, () => this.loadData());
    }

    setPage = (page) => {
        if (typeof page !== 'number') return;
        this.setState({ page }, () => {
            this.loadData();
        })
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => {
            this.loadData();
        })
    }

    selectPageSize = (pageSize) => {
        this.setState({ pageSize }, () => {
            this.loadData();
        });
    }

    loadData = () => {
        const { orderBy, page, pageSize } = this.state;
        this.setState({ isLoading: true });
    analyticsService.fetchClassSessions(page, pageSize, orderBy)
            .then(results => {
                this.setState({ isLoading: false });
                this.setState({
                    classSessions: results.data,
                    totalClassSessions: results.total
                });
            })
            .catch(error => {
                alert('Something went wrong fetching data');
                console.log('Error: ', error.message);
                this.setState({
                    isLoading: false
                })
            })
    }

    _calculateParticipationSatisfaction = (participants, liked) => {
        if (participants && liked)
            return parseInt((liked * 100) / participants);
        return 0;
    }

    render() {
        const { classSessions, isLoading, orderBy, page, pageSize, totalClassSessions } = this.state; //Variables
        const { selectPageSize, setOrderBy, setPage, _calculateParticipationSatisfaction, _refreshData } = this;
        return (
            <div>
                <AdminPageHeader pageTitle={'Class Session Metrics'}>
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumb-nav">
                                <Breadcrumbs crumbs={[{ title: 'Classes' }]} ></Breadcrumbs>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <h2 className="admin-page-title">Class Session Metrics</h2>
                        </div>
                        <div className="col-6">
                            <div className="main-page-header-toolbar text-right">
                                <button onClick={_refreshData} className="btn default-admin-button"><img className="katana-svg-icon" src={refreshIco} alt="o" />Refresh</button>
                            </div>
                        </div>
                    </div>
                </AdminPageHeader>
                <Pagination orderBy={orderBy} total={totalClassSessions} page={page} pageSize={pageSize}
                    setPage={setPage} selectPageSize={selectPageSize} setOrderBy={setOrderBy} />
                <div className="admin-page-main-content">
                    <table className="table table-responsive-lg" id="table">
                        <tbody>
                            <tr>
                                <th className="column-5-width">#</th>
                                <th className="column-25-width">Name</th>
                                <th className="column-25-width">Date</th>
                                <th className="column-25-width">Coach</th>
                                <th className="column-25-width">Started</th>
                                <th className="column-25-width">survey</th>
                                <th className="column-25-width">% liked</th>
                            </tr>
                            {isLoading ?
                                <tr>
                                    <th colSpan="4" className="text-center"><span>Loading data...</span></th>
                                </tr>
                                :
                                <React.Fragment>
                                    {classSessions.length > 0 ?
                                        classSessions.map((classSession, index) =>
                                            <tr key={index}>
                                                <td className="column-25-width">{index + 1}</td>
                                                <td className="column-25-width">{classSession.title}</td>
                                                <td className="column-25-width">{moment(classSession.sessionDateTime).format('MM/DD/YYYY')}</td>
                                                <td className="column-25-width">{classSession.instructor && classSession.instructor.fullName}</td>
                                                <td className="column-25-width">{classSession.participationCount}</td>
                                                <td className="column-25-width">{classSession.feedbackCount}</td>
                                                <td className="column-25-width">{_calculateParticipationSatisfaction(classSession.feedbackCount, classSession.satisfiedParticipants)}%</td>
                                            </tr>)
                                        :
                                        <tr>
                                            <th colSpan="4" className="text-center"><span>No data</span></th>
                                        </tr>
                                    }
                                </React.Fragment>}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export default AnalyticsBrowser;