import React from 'react'

const ProfileIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 23V20.5556C21 19.2589 20.4732 18.0154 19.5355 17.0986C18.5979 16.1817 17.3261 15.6667 16 15.6667H6C4.67392 15.6667 3.40215 16.1817 2.46447 17.0986C1.52678 18.0154 1 19.2589 1 20.5556V23M16 5.88889C16 8.58895 13.7614 10.7778 11 10.7778C8.23858 10.7778 6 8.58895 6 5.88889C6 3.18883 8.23858 1 11 1C13.7614 1 16 3.18883 16 5.88889Z" stroke={color !== '' ? color : '#828282'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default ProfileIcon;