import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Select, { createFilter } from 'react-select';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';

import classService from 'services/class-service';
import configService from 'services/config-service';
import { CONFIG_TYPES } from 'references/constants';

import Breadcrumbs from 'admin/components/breadcrumbs';
import { array, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const crumbs = [{
  title: 'Configuration',
  path: '/config/home',
}];

const VALIDATION_SCHEMA = object().shape({
  playlists: array().nullable(),
});

const STATIC_PLAYLISTS = [{
  id: 'suggested',
  title: 'Suggested',
  isStatic: true,
}, {
  id: 'trending',
  title: 'Trending',
  isStatic: true,
}];

const HomeConfigBrowser = () => {
  const currentCrumbs = [].concat(crumbs);
  currentCrumbs.push({
    title: 'Home',
  });

  const refSubmitBtn = useRef(null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [playlistOptions, setPlaylistOptions] = useState([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [classSessionsPlaylists, setClassSessionsPlaylists] = useState([]);

  const selectStyles = {
    multiValue: (base, state) => (state.data.isStatic ? { ...base, backgroundColor: '#999999' } : base),
    multiValueLabel: (base, state) => (state.data.isStatic
      ? {
        ...base, color: 'white',
      }
      : base),
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: {
      playlists: [],
    },
  });

  const onSubmit = async (data) => {
    if (selectedPlaylists.length === 0) return;
    const content = {
      ...data,
      playlists: selectedPlaylists,
    };
    setIsFetchingData(true);
    try {
      const savedConfig = await configService.saveConfigs(content, CONFIG_TYPES.HOME);
      if (savedConfig && savedConfig.id === CONFIG_TYPES.HOME) {
        toast.success('Active playlist order saved.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setIsFetchingData(false);
    } catch (error) {
      setApiErrorMessage(error.message || 'Something went wrong.');
      setIsFetchingData(false);
    }
  };

  const handleSelectChange = (selectedOptions) => setSelectedPlaylists(selectedOptions);

  const fetchActiveClassSessionPlaylists = useCallback(async () => {
    setIsFetchingData(true);
    const data = await classService.getActiveClassSessionPlaylists();
    const selectOptions = [...STATIC_PLAYLISTS, ...data];
    setClassSessionsPlaylists(selectOptions);
    setIsFetchingData(false);
  }, []);

  const fetchConfigs = useCallback(async () => {
    setIsFetchingData(true);
    const data = await configService.fetchConfigs(CONFIG_TYPES.HOME);
    if (data && data.playlists && data.playlists.length > 0) {
      setSelectedPlaylists(data.playlists);
    }
    setIsFetchingData(false);
  }, []);

  useEffect(() => {
    fetchConfigs()
      .catch((error) => {
        setApiErrorMessage(error.message || 'Something went wrong.');
      });
    fetchActiveClassSessionPlaylists()
      .catch((error) => {
        setApiErrorMessage(error.message || 'Something went wrong.');
      });
  }, []);

  useEffect(() => {
    if (classSessionsPlaylists.length > 0) {
      const data = classSessionsPlaylists.map((item) => ({
        value: item.id,
        label: item.title,
        isStatic: item.isStatic || false,
      }));
      setPlaylistOptions(data);
    }
  }, [classSessionsPlaylists]);

  return (
    <div className="admin-page-config">
      <div className="row">
        <div className="col-12">
          <Breadcrumbs crumbs={currentCrumbs} root="/admin" />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2 className="admin-page-title">Home Configuration</h2>
          {apiErrorMessage && <div className="alert alert-danger text-left">{apiErrorMessage}</div>}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <div className="mt-4">
            <div className="btn-group" role="group">
              <button
                className="btn btn-white btn-light-border font-weight-bolder"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                <i className="far fa-save mr-2" />
                Save
              </button>
              <button
                type="button"
                className="btn btn-white font-weight-bolder"
                disabled={isSubmitting}
              >
                <i className="fas fa-undo mr-2" />
                Undo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card text-center border-0 mt-5">
            <div className="card-body">
              {apiErrorMessage && <div className="alert alert-danger text-left">{apiErrorMessage}</div>}
              <form>
                <div className="row">
                  <div className="col-12 justify-content-start text-left">
                    <label className="control-label" htmlFor="playlists">
                      Active Playlists
                      {errors && errors.playlists && <span className="text-danger">*</span>}
                    </label>
                    <Controller
                      name="playlists"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          isSearchable
                          isDisabled={isFetchingData}
                          value={selectedPlaylists}
                          options={playlistOptions}
                          onChange={(values) => handleSelectChange(values)}
                          filterOption={createFilter({ ignoreAccents: false })}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={selectStyles}
                        />
                      )}
                    />
                    <small>Note: Highlighted options are not playlists that can be edited in the admin panel.</small>
                  </div>
                  <input type="submit" hidden ref={refSubmitBtn} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeConfigBrowser.defaultProps = {
  classSessionPlaylists: [],
};

HomeConfigBrowser.propTypes = {};

export default HomeConfigBrowser;
