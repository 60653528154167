import React from 'react';

const LogoSmallIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.39106 34.6594C5.39211 34.4988 5.43452 34.3411 5.51447 34.2002C5.59441 34.0594 5.7094 33.9398 5.84914 33.8523L24.3602 21.9795C24.5253 21.8743 24.7188 21.8181 24.9168 21.8181C25.1148 21.8181 25.3083 21.8743 25.4734 21.9795L28.8815 24.1494C29.0213 24.237 29.1362 24.3565 29.2162 24.4974C29.2961 24.6383 29.3385 24.796 29.3396 24.9565C29.3385 25.117 29.2961 25.2748 29.2162 25.4157C29.1362 25.5565 29.0213 25.6761 28.8815 25.7636L6.96228 39.8372C6.81053 39.9345 6.63418 39.9904 6.45203 39.9989C6.26987 40.0073 6.08875 39.9681 5.92796 39.8852C5.76717 39.8024 5.63273 39.6791 5.53899 39.5285C5.44524 39.3779 5.39571 39.2056 5.39565 39.03L5.39106 34.6594Z" fill={ color !== '' ? color : '#333333'}/>
        <path d="M6.96355 0.165822L36.5419 19.2319C36.6817 19.3194 36.7966 19.439 36.8766 19.5799C36.9565 19.7207 36.9989 19.8785 37 20.039C36.9985 20.1994 36.956 20.357 36.876 20.4978C36.7961 20.6386 36.6814 20.7582 36.5419 20.8461L33.1521 23.0248C32.9861 23.1319 32.7908 23.1891 32.591 23.1891C32.3911 23.1891 32.1958 23.1319 32.0298 23.0248L5.84124 6.15954C5.7015 6.07198 5.58651 5.95241 5.50656 5.81155C5.42662 5.67069 5.38421 5.51294 5.38315 5.35243V0.97293C5.38266 0.796651 5.43206 0.623568 5.52603 0.472279C5.62001 0.32099 5.75502 0.197205 5.91656 0.114227C6.0781 0.0312477 6.26005 -0.00779955 6.4429 0.00129112C6.62575 0.0103818 6.80258 0.0672643 6.95439 0.165822H6.96355Z" fill={ color !== '' ? color : '#333333'}/>
        <path d="M0.531376 33.5523C0.370694 33.4693 0.236415 33.3459 0.142838 33.1952C0.0492601 33.0446 -0.000110914 32.8723 1.87097e-07 32.6967V28.3216C0.00105641 28.1611 0.0434637 28.0034 0.12341 27.8625C0.203357 27.7216 0.318345 27.6021 0.458082 27.5145L12.1071 20.0169L0.458082 12.5192C0.318345 12.4317 0.203357 12.3121 0.12341 12.1713C0.0434637 12.0304 0.00105641 11.8727 1.87097e-07 11.7121V7.37232C6.13181e-05 7.19675 0.0496072 7.02447 0.143352 6.87387C0.237097 6.72327 0.371532 6.59998 0.532324 6.51714C0.693116 6.4343 0.87424 6.39502 1.05639 6.40349C1.23854 6.41196 1.41489 6.46787 1.56665 6.56524L21.2871 19.223C21.417 19.3171 21.5225 19.439 21.5951 19.5791C21.6677 19.7192 21.7055 19.8736 21.7055 20.0301C21.7055 20.1867 21.6677 20.3411 21.5951 20.4811C21.5225 20.6212 21.417 20.7431 21.2871 20.8372L1.56665 33.495C1.41568 33.594 1.23962 33.6516 1.05727 33.6617C0.874919 33.6718 0.693137 33.634 0.531376 33.5523Z" fill={ color !== '' ? color : '#333333'}/>
        </svg>

    );
}

export default LogoSmallIcon;