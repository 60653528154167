import { LEADERBOARD_MONTH_OPTION } from '../references/constants';
import BaseService from './base-service';

class UserService extends BaseService {
  calculateUserSessionMetrics(data) {
    return this._apiPost({
      path: '/users/calculate-session-metrics',
      data,
    });
  }

  fetchLeaderboard(month) {
    const constructedPath = month === undefined || month === LEADERBOARD_MONTH_OPTION.ALL_TIME
      ? '/users/leaderboard'
      : `/users/leaderboard?month=${month}`;

    return this._apiGet({
      path: constructedPath,
    });
  }


  userRideCount() {
    return this._apiGet({
      path: '/users/achievements/count',
    });
  }

  fetchClassLeaderboard(classSessionId) {
    return this._apiGet({
      path: `/users/leaderboard/${classSessionId}`,
    });
  }

  getUserProfile(id = 'self') {
    return this._apiGet({
      path: `/users/${id}`,
    });
  }

  saveUserCancellationFeedback = (cancellationReason) => {
    const options = {
      path: `/users/cancellation/feedback`,
      data: { cancellationReason }
    }

    return this._apiPost(options);
  }
}

export default new UserService();
