import React, { Component } from 'react';
import moment from 'moment';

import ProfileIcon from '../../../components/elements/icons/profile-icon';

export default class UserSessionRow extends Component {

    _generateTitle = () => {
        if(this.props.session.duration !== null && this.props.session.duration !== 0){
            return `${this.calculateMinutes(this.props.session.duration)} min metrics`;
        }else{
            if(this.props.session.sessionDuration && this.props.session.trainingName)
                return `${this.props.session.sessionDuration} min ${this.props.session.trainingName}`
            return 'No Metrics Recorded'
        }
    }

    _getTitle = () => {
        const title = this._generateTitle();
        const maximumLength = 20;
        if(title.length < maximumLength)
            return title;
        return `${title.substring(0, 17)}...`;
    }

    calculateMinutes = (duration) => {
        return Math.ceil(duration / 60);
    }

    render() {
        return (
            <div className="row no-gutters">
                <div className="col-2 d-flex align-items-center">
                    <div>
                        <div className="row no-gutters day text-light-font">
                            <span className="mx-auto">{moment(this.props.session.createdOn).format("ddd")}</span>
                        </div>
                        <div className="row no-gutters date">
                            <span className="mx-auto mt-n1">{moment(this.props.session.createdOn).format("DD")}</span>
                        </div>
                        <div className="row no-gutters equipment text-light-font">
                            {/*<span className="mx-auto">{this.props.session.equipment}</span>*/}
                        </div>
                    </div>
                </div>
                {/* {this.props.session.instructor && this.props.session.instructor.profile && (
                    <div className="col-2">
                        <img src={this.props.session.instructor.profile} className="rounded-circle instructor " alt="img" />
                    </div>
                )} */}
                <div className='col-8 pl-2 d-flex align-items-center'>
                    <div>
                        <div className="row no-gutters class-title">
                           <h4>{this._getTitle()}</h4>
                        </div>
                        <div
                            className="row no-gutters instructor-name text-light-font font-weight-bold">
                            <div className="studio-desc">
                                <span>{this.props.session.instructor && this.props.session.instructor.fullName}</span>
                                <div className="studio-icon float-left">
                                    <ProfileIcon width={9} height={10} color={'#828282'} /> 
                                    <small>{this.props.session.studio}</small>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2 d-flex align-items-center">
                    <div className="ml-auto">
                        <div className="row no-gutters align-items-center">
                            <span className="total-value ml-auto">
                                {Number(this.props.session.totalEnergy).toFixed(1)}
                            </span>
                            <small className="ml-1 text-light-font total-unit">
                                kj
                            </small>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}