import React from 'react';

const OverviewIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 5H21V7H13V5Z" fill={color !== '' ? color : '#828282'} fillOpacity="0.4"/>
        <path d="M1 5H9V17H1V5Z" fill={color !== '' ? color : 'white'} fillOpacity="0.4"/>
        <path d="M13 10H21V12H13V10Z" fill={color !== '' ? color : '#828282'} fillOpacity="0.4"/>
        <path d="M13 15H21V17H13V15Z" fill={color !== '' ? color : '#828282'} fillOpacity="0.4"/>
        <path d="M21 1H1M21 21H1M21 5H13V7H21V5ZM9 5H1V17H9V5ZM21 10H13V12H21V10ZM21 15H13V17H21V15Z" stroke={color !== '' ? color : '#828282'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default OverviewIcon;