import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import classService from '../../../cross-platform/services/class-service';
import editIcon from "../../../images/edit-icon.png";

const MODES = {
    BROWSE: 'browse',
    FETCHING: 'fetching',
    NO_RESULTS: "no_results",
    WITH_RESULTS: 'with_results',
    WITH_ERROR: 'with_error'
}

class SessionBrowser extends Component {

    state = {
        sessions: [],
        mode: MODES.BROWSE
    }

    componentDidMount() {
        this._loadSessions();
    }

    render() {
        return (
            <div className="col-12 mt-3">
                <div className="admin-page-main-content">
                    <table className="table table-responsive-lg">
                        <tbody>
                            <tr>
                                <th className="text-left">Coach</th>
                                <th className="text-left">Date and Time</th>
                                <th className="text-left">Length</th>
                                <th className="text-center">Number of Participants</th>
                                <th className="text-left">Actions</th>
                            </tr>

                            {this.state.mode === MODES.NO_RESULTS && (
                                <tr>
                                    <th colSpan="5" className="text-center"><span>No Sessions</span></th>
                                </tr>
                            )}
                            {this.state.mode === MODES.FETCHING && (
                                <tr>
                                    <th colSpan="5" className="text-center"><span>Loading data...</span></th>
                                </tr>
                            )}
                            {this.state.mode === MODES.WITH_RESULTS && this.state.sessions.map((session) =>
                                <tr key={session.id}>
                                    <td className="text-left">{session.instructor && session.instructor.fullName}</td>
                                    <td className="text-left">{moment(session.sessionDateTime).format('MM/DD/YYYY, h:mm:ss a')}</td>
                                    <td className="text-left">{session.duration} min</td>
                                    <td className="text-center">{session.participantCount || 0}</td>
                                    <td className="text-left">
                                        <Link to={`/admin/classes/${this.props.classId}/sessions/${session.id}`}
                                            className="primary-edit-button"><img src={editIcon} alt="edit" /></Link>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    _loadSessions = () => {
        this.setState({ mode: MODES.FETCHING })
        classService.fetchSessions(this.props.classId)
            .then(sessions => {
                this.setState({
                    sessions: sessions.sort(this._sortSessions),
                    mode: sessions.length === 0 ? MODES.NO_RESULTS : MODES.WITH_RESULTS
                })
            })
            .catch(error => {
                this.setState({
                    mode: MODES.WITH_ERROR
                })
            })
    }

    _sortSessions = (first, second) => {
        //Session date in descending order
        if (moment(first.sessionDateTime).format('YYYYMMDD') > moment(second.sessionDateTime).format('YYYYMMDD')) return -1
        if (moment(first.sessionDateTime).format('YYYYMMDD') < moment(second.sessionDateTime).format('YYYYMMDD')) return 1
    }
}

export default SessionBrowser;