import React from 'react';
import userService from '../../services/user-service';

class Achievements extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
  }
  state = {
    totalRides: 0,
  };

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && userService.userRideCount().then((data) => {
      this._isMounted && this.setState({
        totalRides: data.total || 0,
      });
    }).catch(error => console.warn("Failed to fetch user rides count.", error));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

export default Achievements;
