// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { buildUrlFromUriWithBasePath } from '../helpers/class-url';

function AnotherActiveSessionPopup({
  visible,
  onYesPress,
  onNoPress,
  activeUserSession,
}) {
  let header = "";
  if (activeUserSession.isActive) {
    header = <h3 className="py-3 subtitle">Another session is active!</h3>;
  } else {
    header = <h3 className="py-3 subtitle">A session was running on other device.</h3>;
  }
  return (
    <div className="modal fade show feedback-modal" style={{ display: visible && 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body text-center">
            {header}
            {(activeUserSession.class) ? (
              <p className="under-title">
                Profile Full Name:
                {' '}
                {activeUserSession.fullName}
                <br />
                Session:
                {' '}
                {activeUserSession.class.title}
                <br />
                <img
                  className="img-fluid"
                  src={
                  buildUrlFromUriWithBasePath(
                    activeUserSession.classSession.defaultThumbnailUri,
                  )
                }
                  alt="Stryde"
                />
              </p>
            ) : <span />}

            <div className="row">
              <div className="col-12 mt-5">
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={(onYesPress)}
                >
                  Continue
                </button>
                <button type="button" className="btn btn-skip" onClick={onNoPress}>
                  <i className="fas fa-arrow-left" />
                  {' '}
                  Go back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AnotherActiveSessionPopup.defaultProps = {
  visible: false,
  activeUserSession: {},
  onYesPress: () => {},
  onNoPress: () => {},
};

AnotherActiveSessionPopup.propTypes = {
  visible: PropTypes.bool,
  activeUserSession: PropTypes.object,
  onYesPress: PropTypes.func,
  onNoPress: PropTypes.func,
};

export default AnotherActiveSessionPopup;
