import BaseService from './base-service';

class StudioService extends BaseService {

    fetchStudioByExternalId(id) {
        return this._apiGet({
            path: `/studio/${id}`,
            auth: false
        });
    }
}

export default new StudioService();
