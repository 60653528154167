import BaseService from './base-service';

class ConfigService extends BaseService {
  fetchConfigs(key) {
    return this._apiGet({
      path: `/config/${key}`,
    });
  }

  saveConfigs(data, key) {
    const options = {
      path: `/config/${key}`,
      data,
    };
    return this._apiPost(options);
  }
}

export default new ConfigService();
