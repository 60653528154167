import React from "react";
import PropTypes from "prop-types";

function FavoriteIcon(props) {
  const { isFavorite, showText } = props;
  return (
    <button className="item-favorite-wrapper" onClick={props.onClick}>
      {isFavorite ? (
        <i className="fas fa-heart"></i>
      ) : (
        <i className="far fa-heart"></i>
      )}
      {showText ? <span className="favorite-text">Favorites</span> : <></>}
    </button>
  );
}

FavoriteIcon.defaultProps = {
  classId: "",
  isFavorite: false,
  showText: false
};

FavoriteIcon.propTypes = {
  classId: PropTypes.string,
  isFavorite: PropTypes.bool,
  showText: PropTypes.bool,
};

export default FavoriteIcon;
