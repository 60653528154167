import React from 'react';
import PropTypes from 'prop-types';

const ColoredStatus = (props) => {
    switch (props.status) {
        case 'draft':
            return (<span className="status-badge draft-status-badge">Draft</span>)
        case 'active':
            return (<span className="status-badge active-status-badge">Active</span>)
        case 'archived':
            return (<span className="status-badge archived-status-badge">Archived</span>)
        case 'published':
            return (<span className="status-badge published-status-badge">Published</span>)
        default:
            return null;
    }
}

ColoredStatus.defaultProps = {
    status: ''
}

ColoredStatus.propTypes = {
    status: PropTypes.string.isRequired
};

export default ColoredStatus;