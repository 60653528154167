import React from 'react';
import { NavLink, Route, Switch, Link } from "react-router-dom";
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';

import Navigation from '../../components/navigation';
import OverviewPage from './overview/overview-page';
import ExerciseHistory from './analytics/exercise-history';
import AchievementsPage from './achievements/achievements-page';
import AccountPage from './account/account-page';
import MyProfile from './profile/my-profile';
import SubscriptionPage from './account/subscription-page';
import * as userActions from "../../cross-platform/actions/user-actions";

import Avatar from '../../components/avatar';

import AchievementsIcon from '../../components/elements/icons/achievements-icon';
import EditIcon from '../../components/elements/icons/edit-icon';
import OverviewIcon from '../../components/elements/icons/overview-icon';
import ProfileIcon from '../../components/elements/icons/profile-icon';
import WorkoutHistoryIcon from '../../components/elements/icons/workout-history-icon';
import SubscriptionIcon from '../../components/elements/icons/subscription-icon';
import CloseIcon from '../../components/elements/icons/close-icon';

const PAGE_NAMES = {
    '/my/profile': 'My Profile',
    '/my/account': 'My Account',
    '/my/achievements': 'Achievements',
    '/my/exercise-history': 'Class History'
};
class Profile extends React.Component {
    state = {
        isProfileMenuOpen: false
    };

    toggleOpen = () => this.setState({ isProfileMenuOpen: !this.state.isProfileMenuOpen });

    render() {
        return (
            <div className="container-fluid p-0 bg-light profile overflow-auto">
                <div className="row no-gutters h-100 d-flex align-items-stretch">
                    <MediaQuery minWidth={769}>
                    <div className="col-2 bg-white d-none d-xl-block border-right">
                        <div className="row m-0">
                            <div className="col-8 text-center mx-auto pb-5">
                                <div className="row mt-5">
                                    <div className="col-12 mx-auto avatar">
                                        {(this.props.userProfile && this.props.userProfile.avatar) && <Avatar profile={this.props.userProfile} width={120} height={120} />}
                                    </div>
                                </div>
                                <div className="row mt-5 under-avatar-content">
                                    <div className="col-12">
                                        <h5>{this.props.userProfile.fullName}</h5>
                                    </div>
                                    <div className="col-12">
                                        <Link to='/profiles' className="btn btn-secondary manage-profiles-button mt-3 py-2 px-3 text-center">
                                            <EditIcon width={18} height={18} /> Edit
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-menu-wrapper">
                            <div className="row no-gutters">
                                <div className="col-12 profile-menu">
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                        aria-orientation="vertical">
                                        <NavLink className="nav-link" activeClassName="active border-left"
                                            to="/my/overview">
                                            <OverviewIcon width={20} height={20} /> Overview
                                        </NavLink>
                                        <NavLink className="nav-link" activeClassName="active border-left"
                                            to="/my/exercise-history">
                                            <WorkoutHistoryIcon width={25} height={22} /> Workout History
                                        </NavLink>
                                        <NavLink className="nav-link" activeClassName="active border-left"
                                            to="/my/achievements">
                                            <AchievementsIcon width={22} height={22} /> Achievements
                                        </NavLink>
                                        <NavLink className="nav-link" activeClassName="active border-left"
                                            to="/my/account">
                                            <SubscriptionIcon width={22} height={16} /> Subscription
                                        </NavLink>
                                        <NavLink className="nav-link"       activeClassName="active border-left"
                                            to="/my/profile">
                                            <ProfileIcon width={20} height={22} /> My Profile
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </MediaQuery>
                    <div className="col-12 px-3 d-block d-xl-none">
                        <div className="top-account-link py-3">
                            <div className="row d-flex align-items-center">
                                <div className="col-8 my-account-link "><h4>My Account</h4></div>
                                <div className="col-4 close-icon-wrapper">
                                    <Link className="float-right" to="/">
                                        <CloseIcon width={16} height={16} color="#ffffff" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="top-nav-mobile">
                            <nav className="navbar p-0 mt-3">
                                <ul className="navbar-nav d-block">
                                    <li>
                                        <NavLink
                                            to='/my/exercise-history'
                                            activeClassName='selected'>
                                            <WorkoutHistoryIcon width={25} height={22} /> Workout History
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to='/my/achievements'
                                            activeClassName='selected'>
                                            <AchievementsIcon width={22} height={22} /> Achievements
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to='/my/account'
                                            activeClassName='selected'>
                                            <SubscriptionIcon width={22} height={16} /> My Account
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to='/my/profile'
                                            activeClassName='selected'>
                                            <ProfileIcon width={20} height={22} /> My Profile
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <nav className="navbar bg-light d-none">
                            <div className="profile-nav mx-auto mt-3">
                                <NavLink
                                    to='/my/overview'
                                    className='p-3 bg-white border-rr'
                                    activeClassName='selected'>
                                    <OverviewIcon width={20} height={20} /> Overview
                                </NavLink>
                                <NavLink
                                    to='/my/exercise-history'
                                    className='p-3 bg-white border-rr'
                                    activeClassName='selected'>
                                    <WorkoutHistoryIcon width={25} height={22} /> Workout History
                                </NavLink>
                                <NavLink
                                    to='/my/exercise-history'
                                    className='p-3 bg-white border-rr'
                                    activeClassName='selected'>
                                    <AchievementsIcon width={22} height={22} /> Achievements
                                </NavLink>
                                <NavLink
                                    to='/my/account'
                                    className='p-3 bg-white border-rl'
                                    activeClassName='selected'>
                                    <SubscriptionIcon width={22} height={16} /> My Account
                                </NavLink>
                                <NavLink
                                    to='/my/profile'
                                    className='p-3 bg-white border-rl'
                                    activeClassName='selected'>
                                    <ProfileIcon width={20} height={22} /> My Profile
                                </NavLink>
                            </div>
                        </nav>
                    </div>

                    <Switch>
                        <Route path="/my/overview" component={OverviewPage}></Route>
                        <Route path="/my/exercise-history/:sessionId?" component={ExerciseHistory}></Route>
                        <Route path="/my/achievements" component={AchievementsPage}></Route>
                        <Route path="/my/account/subscription" component={SubscriptionPage}></Route>
                        <Route path="/my/account" component={AccountPage}></Route>
                        <Route path="/my/profile" component={MyProfile}></Route>
                    </Switch>

                    <Navigation />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ userStore }) => {
    return {
        ...userStore
    };
};

export default connect(mapStateToProps, { ...userActions })(Profile);