import React from 'react';

const ClassPlayIcon = ({width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 7L15 11L9 15V7Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default ClassPlayIcon;