import moment from 'moment-timezone';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import { toast } from 'react-toastify';

import classService from 'services/class-service';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, date, object, string } from 'yup';
import CustomDropdownOption from 'admin/components/common/custom-dropdown-options';
import Breadcrumbs from 'admin/components/breadcrumbs';


const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';

const VALIDATION_SCHEMA = object().shape({
  title: string().required('A title is required.'),
  sessionId: string(),
  isActive: boolean(),
  startShowingDate: date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required('Playlist start showing date is required.'),
  endShowingDate: date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required('Playlist end showing date is required.')
});

const crumbs = [{
  title: 'Class Playlists',
  path: '/class-session/playlists'
}];

const PlaylistAddEdit = ({ history, match }) => {
  const { id } = match.params;
  const isCreatingNewItem = !id;
  const currentCrumbs = [].concat(crumbs);
  currentCrumbs.push({
    title: isCreatingNewItem ? 'New Class Session Playlist' : 'Update Class Session Playlist'
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: {
      title: "",
      sessionId: "",
      isActive: false,
      startShowingDate: new Date().toISOString().slice(0, 10),
      endShowingDate: null,
    }
  });

  const refSubmitBtn = useRef(null);
  const componentMounted = useRef(true);

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [classSessionPlaylist, setClassSessionPlaylist] = useState(null);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [classSessions, setClassSessions] = useState([]);
  const [selectedClassSessions, setSelectedClassSessions] = useState([]);
  const [activeClassSessions, setActiveClassSessions] = useState([]);

  const onDelete = () => {
    if (!window.confirm("Are you sure you want to delete this playlist?")) return;

    classService.deleteClassSessionPlaylist(id)
      .then(() => {
        toast.success("Class session playlist deleted successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return history.push(`/admin/class-session/playlists`);
      })
      .catch((error) => {
        console.error(error);
        setApiErrorMessage(error.message || 'Something went wrong!');
        toast.error("Failed to delete class!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }

  const handleSelectChange = selectedOptions => setSelectedClassSessions(selectedOptions);

  const onSubmit = async (data) => {
    if (selectedClassSessions.length === 0) return;
    data.sessions = selectedClassSessions.map((item) => item.value);
    setIsFetchingData(true);
    try {
      data.startShowingDate = moment(data.startShowingDate).format(MOMENT_DATE_FORMAT);
      data.endShowingDate = (data.endShowingDate && data.endShowingDate !== '') ? moment(data.endShowingDate).format(MOMENT_DATE_FORMAT) : '';
      const savedClassSessionPlaylist = isCreatingNewItem ? await classService.saveClassSessionPlaylist(data) : await classService.updateClassSessionPlaylist(id, data);
      setIsFetchingData(false);
      if (savedClassSessionPlaylist && savedClassSessionPlaylist.id) {
        setClassSessionPlaylist(savedClassSessionPlaylist);
        toast.success("Class session playlist saved.", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        if (isCreatingNewItem) {
          return history.push(`/admin/class-session/playlists/edit/${savedClassSessionPlaylist.id}`);
        }
      }
    } catch (error) {
      setApiErrorMessage(error?.message || "Something went wrong.");
      setIsFetchingData(false);
    }
  };

  const fetchActiveClassSessions = useCallback(async () => {
    setIsFetchingData(true);
    const res = await classService.fetchActiveClassSessions();
    if (res.length > 0 && componentMounted.current) {
      const data = res.map((item) => ({ value: item.id, label: item.id }));
      setActiveClassSessions(data);
      setIsFetchingData(false);
    }
  }, []);

  const fetchClassSessionsPlaylist = useCallback(async (classSessionPlaylistId) => {
    setIsFetchingData(true);
    const res = await classService.getClassSessionPlaylist(classSessionPlaylistId);
    if (componentMounted.current) {
      setClassSessionPlaylist(res);
      setIsFetchingData(false);
    }
  }, []);

  useEffect(() => {
    if (id && !isCreatingNewItem) {
      fetchClassSessionsPlaylist(id).catch((error) => {
        setClassSessionPlaylist(null);
        setApiErrorMessage(error?.message || "Something went wrong.")
      });
    }
    return () => {
      componentMounted.current = false;
    }
  }, [id]);

  useEffect(() => {
    if (id && classSessions.length > 0) {
      const data = classSessions.map((item) => ({ value: item, label: item }));
      setSelectedClassSessions(data);
    }
  }, [id, classSessions]);

  useEffect(() => {
    if (classSessionPlaylist && classSessionPlaylist.id && !isCreatingNewItem) {
      setValue("title", classSessionPlaylist.title);
      setValue("isActive", classSessionPlaylist.isActive);
      setValue("startShowingDate", classSessionPlaylist.startShowingDate ? new Date(classSessionPlaylist.startShowingDate).toISOString().slice(0, 10) : '');
      setValue("endShowingDate", classSessionPlaylist.endShowingDate ? new Date(classSessionPlaylist.endShowingDate).toISOString().slice(0, 10) : '');
      setClassSessions(classSessionPlaylist.sessions || []);
    }
  }, [classSessionPlaylist]);

  useEffect(() => {
    fetchActiveClassSessions()
      .catch((error) => {
        setClassSessionPlaylist(null);
        setApiErrorMessage(error?.message || "Something went wrong.")
      });
    return () => {
      componentMounted.current = false;
    }
  }, []);

  return (
    <div className="admin-page-playlists">
      <div className="row">
        <div className="col-12">
          <Breadcrumbs crumbs={currentCrumbs} root="/admin" />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2 className="admin-page-title">{isCreatingNewItem ? 'Create' : 'Edit'} </h2>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <div className="mt-4">
            <div className="btn-group" role="group">
              <button className="btn btn-white btn-light-border font-weight-bolder" type="submit" disabled={isSubmitting} onClick={handleSubmit(onSubmit)}><i className="far fa-save mr-2"></i>Save</button>
              {!isCreatingNewItem && <button type="button" onClick={() => onDelete()} className="btn btn-white font-weight-bolder" disabled={isSubmitting}><i className="far fa-trash-alt mr-2"></i>Delete</button>}
              <button type="button" className="btn btn-white font-weight-bolder"><i className="fas fa-undo mr-2" onReset={reset}></i>Undo</button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card text-center border-0 mt-4">
            <div className="card-body">
              {apiErrorMessage && <div className="alert alert-danger text-left">{apiErrorMessage}</div>}
              <form>
                <div className="row mt-3">
                  <div className="col-12 justify-content-start text-left">
                    <label htmlFor="title">Title: {errors && errors.title && <span className="text-danger">*</span>}</label>
                    <input name="title" type="text" disabled={isFetchingData} {...register('title')} className={`form-control ${errors && errors.title ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.title?.message}</div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 justify-content-start text-left">
                    <label htmlFor="sessionId">Class Session ID's {classSessions.length === 0 && <span className="text-danger">*</span>}</label>
                    <Select
                      isMulti
                      isSearchable
                      defaultValue={[]}
                      isDisabled={isFetchingData}
                      value={selectedClassSessions}
                      options={activeClassSessions}
                      captureMenuScroll={false}
                      onChange={(values) => handleSelectChange(values)}
                      components={{ Option: CustomDropdownOption }}
                      filterOption={createFilter({ ignoreAccents: false })}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6 text-left">
                    <label htmlFor="startShowingDate">Start showing date</label>
                    <input type="date" name="startShowingDate" className="form-control" disabled={isFetchingData} {...register('startShowingDate')} />
                  </div>
                  <div className="col-6 text-left">
                    <label htmlFor="endShowingDate">End showing date</label>
                    <input type="date" name="endShowingDate" className="form-control" disabled={isFetchingData} {...register('endShowingDate')} />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3 text-left">
                    <label htmlFor="suggestedRank">Is active?</label>
                    <div className="d-flex align-items-center">
                      <input name="isActive" type="checkbox"
                        id="isActive" disabled={isFetchingData} {...register("isActive")} />
                      <span className="ml-3">Active</span>
                    </div>
                  </div>
                </div>
                <button type="submit" hidden={true} ref={refSubmitBtn} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

PlaylistAddEdit.propTypes = {};

PlaylistAddEdit.defaultProps = {
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default PlaylistAddEdit;
