import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ValidatedForm, ValidatedInput } from 'react-validated';
import _ from 'lodash';
import uuid from 'uuid/v4';
import { Hub } from 'aws-amplify';

import Breadcrumbs from '../../../components/breadcrumbs';
import MessageBox from '../../../components/common/message-box';
import ColoredStatus from '../../../components/common/colored-status';
import VideoThumbnailSlider from '../../../components/video-thumbnail-slider';
import { MUSIC_GENRE } from '../../../../cross-platform/references/constants';
import fileService from '../../../../cross-platform/services/file-service';
import classService from '../../../../cross-platform/services/class-service';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm';

const PAGE_STATE = {
    NEW: 'new',
    EDIT: 'edit'
}

const ASSET_STATUSES = {
    UPLOADED: 'uploaded',
    TRANSCODING: 'transcoding',
    READY: 'ready',
    ERROR: 'error'
}

const SESSION_STATUSES = {
    ACTIVE: 'active',
    DRAFT: 'draft',
    ARCHIVED: 'archived',
    PUBLSIHED: 'published'
}

const VIDEO_SESSION_STATUSES = {
    READY: 'ready'
}

const defaultSession = {
    id: 'new',
    instructor: {},
    hasWeights: false,
    sessionDateTime: '',
    sessionLength: '',
    musicType: '',
    playlistId: '',
    status: SESSION_STATUSES.DRAFT,
    participantCount: 0,
    asset: {
        fileKey: null
    }
}

const DEFAULT_STATE = {
    session: defaultSession,
    savedSession: defaultSession,
    PAGE_STATE: PAGE_STATE.NEW,
    musicGenre: MUSIC_GENRE,
    selectedFile: null,
    selectedFileName: 'Choose file',
    isDirty: false,
    isUploading: false,
    disableAll: false,
    featuredSesssion: {},
    error: {
        message: ''
    },
    uploadProgress: {}
}

class SessionEditor extends Component {

    state = {
        ...DEFAULT_STATE,
        crumbs: [{
            title: 'Classes',
            path: '/classes'
        }, {
            title: 'Sessions',
            path: `/classes/${this.props.match.params.classId}/sessions`
        }]
    }

    componentDidMount() {
        this.loadSession();
        this.getFeaturedClassSession();

        const $this = this;
        Hub.listen('katana', data => {
            if (data.payload.event !== 'admin.sessions.assets.upload-progress') return;
            $this.setState({
                uploadProgress: data.payload.progress
            });
        });
    }

    componentWillReceiveProps() {
        if (this.state.session.id !== this.props.match.params.sessionId) {
            this.loadSession();
        }
    }

    render() {
        const sessionId = this.props.match.params.sessionId ? this.props.match.params.sessionId.toLowerCase() : '';
        const currentCrumbs = [].concat(this.state.crumbs);
        currentCrumbs.push({
            title: sessionId === 'new' ? 'New Session' : this.state.session.instructor && `${this.state.session.instructor.fullName}'s Session`
        });
        const videoSessionIsReady = this.state.session && this.state.session.asset && this.state.session.asset.status === 'ready';

        const { session } = this.state;
        return (
            <div>
                <ValidatedForm onSubmit={this.onSave}>
                    <div className="admin-page">
                        <div className="row">
                            <div className="col-12">
                                <Breadcrumbs crumbs={currentCrumbs} root={'/admin'}></Breadcrumbs>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <h2 className="admin-page-title">{sessionId === 'new' ? 'Create session' : 'Edit session'} </h2>
                            </div>
                            {this.state.session.status &&
                                <div className="col-6 text-right">
                                    <ColoredStatus status={this.state.session.status || ''} />
                                </div>
                            }
                        </div>
                        <hr />
                        {this.state.error && this.state.error.message && <MessageBox messageType="error" message={this.state.error.message} />}
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-4">
                                    {sessionId === 'new' ? (
                                        <div className="btn-group" role="group">
                                            <button type="submit" className="btn btn-white btn-light-border font-weight-bolder" disabled={!this.state.isDirty}>Save</button>
                                            <button type="button" onClick={this.undo} className="btn btn-white font-weight-bolder" disabled={!this.state.isDirty}>Undo</button>
                                        </div>
                                    ) : (
                                            <Fragment>
                                                <div className="btn-group" role="group">
                                                    {this.state.isDirty || this.state.isUploading ? (
                                                        <button className="btn btn-white btn-light-border font-weight-bolder" disabled={true}><i className="fas fa-plus mr-2"></i>New</button>
                                                    ) : (
                                                            <Link to={`/admin/classes/${this.props.match.params.classId}/sessions/new`} className="btn btn-white btn-light-border font-weight-bolder">
                                                                <i className="fas fa-plus mr-2"></i> New
                                                            </Link>
                                                        )}
                                                    <button type="submit" className="btn btn-white btn-light-border font-weight-bolder"
                                                        disabled={!this.state.isDirty || (this.state.disableAll || this.state.session.status !== SESSION_STATUSES.DRAFT)}>
                                                        <i className="far fa-save mr-2"></i>Save
                                                    </button>
                                                    <button type="button" onClick={this.undo} className="btn btn-white btn-light-border font-weight-bolder"
                                                        disabled={!this.state.isDirty || (this.state.disableAll || this.state.session.status !== SESSION_STATUSES.DRAFT)}>
                                                        <i className="fas fa-undo mr-2"></i>Undo
                                                    </button>

                                                    {(!this.state.session.participantCount || this.state.session.participantCount === 0) && (this.state.session.status === SESSION_STATUSES.DRAFT || this.state.session.status === SESSION_STATUSES.ACTIVE) && (
                                                        <button type="button" onClick={this.onDelete}
                                                            className="btn btn-white btn-light-border font-weight-bolder"
                                                            disabled={this.state.isDirty || this.state.disableAll}>
                                                            <i className="far fa-trash-alt mr-2"></i>Delete
                                                        </button>
                                                    )}
                                                    {this.state.session.participantCount > 0 && this.state.session.status !== SESSION_STATUSES.ARCHIVED && (
                                                        <button type="button" onClick={this.onArchive} className="btn btn-white btn-light-border font-weight-bolder"
                                                            disabled={this.state.isDirty || this.state.disableAll}><i class="fa fa-archive mr-2"></i>Archive</button>
                                                    )}

                                                </div>

                                                <button type="button" className="btn btn-white font-weight-bolder ml-3 px-2"
                                                    onClick={this.setFeaturedSession}
                                                    disabled={!(this.state.featuredSesssion.classSesssionId !== this.state.session.id
                                                        && !this.state.isLoadingFeaturedSession
                                                        && !this.state.isDirty && sessionId !== 'new')}>
                                                    <i className="fa fa-check mr-2"></i>Feature
                                                </button>
                                                <button type="button" className="btn btn-white font-weight-bolder ml-3 px-2" onClick={this._uploadVideo}
                                                    disabled={!(this.state.session.status === SESSION_STATUSES.DRAFT && !this.state.isDirty) || !this.state.selectedFile || this.state.disableAll}>
                                                    <i className="fas fa-cloud-upload-alt mr-2"></i>{this.state.isUploading ? 'Uploading...' : 'Upload Video'}
                                                </button>
                                                <button type="button" className="btn btn-primary text-white font-weight-bolder ml-3" onClick={this.onPublish}
                                                    disabled={!(this.state.session.status === SESSION_STATUSES.DRAFT && this.state.session.asset &&
                                                        this.state.session.asset.status === VIDEO_SESSION_STATUSES.READY && !this.state.isDirty) || this.state.disableAll || !this.state.session.defaultThumbnailUri}><i className="fab fa-telegram-plane mr-2"></i>Publish</button>
                                            </Fragment>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card text-center border-0 mt-4 ">
                                    <div className="card-body">
                                        <div className="col-12">
                                            <div className="row mt-3">
                                                <div className="col-6 justify-content-star text-left">
                                                    <label htmlFor="class-name">Coach <span className="text-danger">*</span></label>
                                                    <ValidatedInput required min-length={{ params: 3 }} max-length={{ params: 50 }}>
                                                        <input type="text" name="instructor"
                                                            value={(this.state.session.instructor && this.state.session.instructor.fullName) || ''}
                                                            onChange={this.onChange} className="form-control rounded-0"
                                                            placeholder="Coach" aria-label="Coach"
                                                            disabled={this.state.disableAll} />
                                                    </ValidatedInput>
                                                </div>
                                                <div className="col-6 justify-content-star text-left">
                                                    <label htmlFor="class-name">Date and Time <span className="text-danger">*</span></label>
                                                    <ValidatedInput required>
                                                      <input 
                                                        type="datetime-local" 
                                                        name="sessionDateTime" 
                                                        className="form-control rounded-0"
                                                        value={this.state.session.sessionDateTime} 
                                                        onChange={this.onChange} 
                                                        disabled={this.state.disableAll} 
                                                      />
                                                    </ValidatedInput>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-6 justify-content-star text-left">
                                                    <label htmlFor="class-name">Length <span className="text-danger">*</span></label>
                                                    <ValidatedInput required>
                                                        <input type="number" name="duration" value={this.state.session.duration || ''} onChange={this.onChange}
                                                            className="form-control rounded-0" placeholder="Length" aria-label="Length" disabled={this.state.disableAll} />
                                                    </ValidatedInput>
                                                </div>
                                                <div className="col-6 justify-content-start text-left">
                                                    <label htmlFor="state">Music Type</label>
                                                    <select value={this.state.session.musicType} name="musicType" onChange={this.onChange}
                                                        className="form-control rounded-0" disabled={this.state.disableAll}>
                                                        <option value="" >Select</option>
                                                        {MUSIC_GENRE.map((musicGenreItem, index) => (
                                                            <option key={index} value={musicGenreItem.value}>{musicGenreItem.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-6 justify-content-star text-left">
                                                    <label htmlFor="playlistId">Playlist ID</label>
                                                    <input type="text" name="playlistId" value={this.state.session.playlistId || ''} onChange={this.onChange}
                                                    className="form-control rounded-0" placeholder="Playlist ID" aria-label="Playlist ID" disabled={this.state.disableAll} />
                                                </div>
                                            </div>
                                            {this.props.match.params.sessionId !== 'new' && (
                                                <div className="row mt-4">
                                                    <div className="col-6 text-left">
                                                        {!this.state.isUploading &&
                                                            <label htmlFor="asset">Upload video
                                                            {this.state.session.asset && this.state.session.asset.status === ASSET_STATUSES.TRANSCODING && <span className="badge badge-warning ml-2">Processing...</span>}
                                                            </label>
                                                        }
                                                        {this.state.isUploading &&
                                                            <>
                                                                <label>Uploading video</label>
                                                                <div className="progress">
                                                                    <div className="progress-bar"
                                                                        style={{ width: `${this.state.uploadProgress.value || 0}%` }}
                                                                        role="progressbar"
                                                                        aria-valuenow={this.state.uploadProgress.value || 0}
                                                                        aria-valuemin="0"
                                                                        aria-valuemax={this.state.uploadProgress.max || 100}></div>
                                                                </div>
                                                            </>
                                                        }
                                                        {/* {this.state.isUploading && <span className="badge badge-primary ml-2">Uploading...</span>} */}
                                                        <div className="input-group mb-3">
                                                            {!this.state.isUploading &&
                                                                < div className="custom-file">
                                                                    <input type="file" name="asset" onChange={this._onSelectImage}
                                                                        className="custom-file-input" id="video-upload" disabled={this.state.disableAll} />
                                                                    <label className="custom-file-label" htmlFor="video-upload">{this.state.selectedFileName}</label>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-3 text-left">
                                                        <label htmlFor="suggestedRank">Suggested Rank</label>
                                                        <select value={this.state.session.suggestedRank} name="suggestedRank" onChange={this.onChange}
                                                            className="form-control rounded-0" id="suggestedRank">
                                                            <option className="select-placeholder" value="">No Rank</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-3 text-left">
                                                        <label htmlFor="suggestedRank">Workout type</label>
                                                        <div className="d-flex align-items-center">
                                                            <input className="ml-4" name="hasWeights" checked={session.hasWeights} type="checkbox" id="myCheck" onChange={this.onChange}></input>
                                                            <span className="ml-3">With weights</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {this.props.match.params.sessionId !== 'new' && (
                                                <div className="row mt-4">
                                                    <div className="col-12 justify-content-star text-left">
                                                        <label htmlFor="state">Select thumbnail</label>
                                                        <div>
                                                            {this.props.match.params.sessionId !== 'new' && videoSessionIsReady && !this.state.isLoadingSession &&
                                                                <React.Fragment>
                                                                    {this.state.isloadingThumbnails ?
                                                                        <p>Loading thumbnails...</p>
                                                                        :
                                                                        <VideoThumbnailSlider
                                                                            listOfThumbnails={this.state.listOfVideoSessionThumbnails}
                                                                            selectedThumbnailUri={this.state.session.defaultThumbnailUri}
                                                                            onThumbnailSelected={this.onThumbnailSelected} />
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {this.props.match.params.sessionId !== 'new' && !videoSessionIsReady && !this.state.isLoadingSession && (
                                                                <UploadingMessage asset={this.state.session.asset} />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ValidatedForm>
            </div >
        )
    }

    _onSelectImage = (event) => {
        const file = event.target.files[0];

        this.setState({
            selectedFile: file,
            selectedFileName: file.name,
        });
    }

    _uploadVideo = () => {
        const uploadingFileId = uuid();
        this.setState({
            isUploading: true,
            disableAll: true,
            uploadingFileId
        });
        fileService.uploadFile(uploadingFileId, this.state.selectedFile)
            .then(data => {
                const fileKey = data && data.fileKey.replace('inbox/', '');
                this.setState({
                    session: {
                        ...this.state.session,
                        asset: {
                            fileKey
                        }
                    },
                    isUploading: false
                }, this.onSave);
            })
            .catch(error => {
                this.setState({
                    isUploading: false,
                    disableAll: false,
                    uploadingFileId: null,
                    error
                });
            });
    }

    isDirty = () => {
        this.setState({ isDirty: !_.isEqual(this.state.session, this.state.savedSession) });
    }

    loadSession = () => {
        const $this = this;
        const sessionId = $this.props.match.params.sessionId;
        if (!sessionId || sessionId.toLowerCase() === 'new') {
            this.setState({ ...DEFAULT_STATE });
            return;
        }
        $this.setState({ isLoadingSession: true, disableAll: true });

        classService.getClassSession(sessionId)
            .then(sessionResponse => {
                if (!sessionResponse || !sessionResponse.id) {
                    return $this.props.history.push('/not-found')
                }
                //TODO: a better way to check is file is processing
                if (sessionResponse.asset.status === 'uploaded') sessionResponse.asset.status = 'transcoding'
                if (!sessionResponse.id) return this.props.history.push(`/not-found`);
                sessionResponse.sessionDateTime = moment(sessionResponse.sessionDateTime).format(MOMENT_FORMAT);
                $this.setState({
                    session: _.merge({}, this.state.session, sessionResponse),
                    savedSession: _.merge({}, this.state.session, sessionResponse),
                    disableAll: (sessionResponse.asset.status === ASSET_STATUSES.UPLOADED || sessionResponse.asset.status === ASSET_STATUSES.TRANSCODING || sessionResponse.status === SESSION_STATUSES.ARCHIVED) ? true : false,
                    state: PAGE_STATE.EDIT,
                    selectedFileName: sessionResponse.asset.fileKey || 'Choose file',
                    isLoadingSession: false
                }, this.isDirty);
                const videoIsReady = sessionResponse && sessionResponse.asset && sessionResponse.asset.status === 'ready';
                if (videoIsReady) {
                    $this.loadThumbnails(sessionId, sessionResponse && sessionResponse.ownerId);
                }
            })
            .catch(error => {
                console.error(error);
                $this.setState({
                    error,
                    isLoadingSession: false
                });
            });
    }

    onChange = (event) => {
        const name = event.target.name;
        let value = '';

        switch (name) {
            case 'instructor':
                value = { fullName: event.target.value }
                break;
            case 'asset':
                value = { fileKey: event.target.files && event.target.files[0] };
                break;
            case 'hasWeights':
                value = event.target.checked;
                break;
            default:
                value = event.target.value
        }

        this.setState({ session: { ...this.state.session, [name]: value } }, this.isDirty);
    }

    loadThumbnails = (sessionId, ownerId) => {
        const $this = this;
        $this.setState({ isloadingThumbnails: true });
        classService.fetchClassSessionThumbnails(sessionId, ownerId)
            .then(thumbnailsListResponse => {
                $this.setState({
                    isloadingThumbnails: false,
                    listOfVideoSessionThumbnails: thumbnailsListResponse
                });
            })
            .catch(error => {
                console.error('Failed to get list of thumbnails!');
                $this.setState({
                    error,
                    isloadingThumbnails: false
                })
            })
    }

    onThumbnailSelected = (uri) => {
        this.setState({
            session: { ...this.state.session, defaultThumbnailUri: uri },
            isDirty: true
        });
    }

    onSave = () => {
        const $this = this;
        const session = _.cloneDeep($this.state.session);
        session.classId = $this.props.match.params.classId;
        classService.saveClassSession(session)
            .then(sessionResponse => {
                //TODO: a better way to check is file is processing
                if (sessionResponse.asset.status === 'uploaded') sessionResponse.asset.status = 'transcoding'
                $this.setState({
                    session: _.merge({}, $this.state.session, sessionResponse),
                    savedSession: _.merge({}, $this.state.savedSession, sessionResponse),
                }, $this.isDirty);
                toast.success("Session saved!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                if (sessionResponse.id !== $this.props.match.params.classId) {
                    return $this.props.history.push(
                        `/admin/classes/${$this.props.match.params.classId}` +
                        `/sessions/${sessionResponse.id}`
                    );
                }
            })
            .catch(error => {
                console.error('Failed to save session:', error);
                $this.setState({ error });
            });
    }

    onPublish = () => {
        if (this.state.isDirty || this.state.session.status !== SESSION_STATUSES.DRAFT) return;

        const session = {
            classId: this.props.match.params.classId,
            id: this.state.session.id
        }

        classService.publishClassSession(session)
            .then((response) => {
                this.setState({
                    session: { ...this.state.session, status: response.status }
                })
                toast.success("Session published!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch((error) => this.setState({ error }))
    }

    onArchive = () => {
        if (!window.confirm("Are you sure you want to archive this session?")) return;
        if (this.state.isDirty || this.state.session.status === SESSION_STATUSES.ARCHIVED || this.state.session.participantCount <= 0) return;

        const session = {
            classId: this.props.match.params.classId,
            id: this.state.session.id
        }

        classService.archiveClassSession(session)
            .then((response) => {
                this.setState({
                    session: { ...this.state.session, status: response.status }
                });
                toast.success("Session archived!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch((error) => this.setState({ error }))
    }

    onDelete = () => {
        if (!window.confirm("Are you sure you want to delete this session?")) return;

        const classId = this.props.match.params.classId;
        const sessionId = this.state.session.id;

        classService.deleteClassSession(classId, sessionId)
            .then(_ => {
                return this.props.history.push(`/admin/classes/${this.props.match.params.classId}/sessions`);
            })
            .catch(error => {
                console.error('Failed to delete session:', error);
                this.setState({ error });
            })
    }

    undo = () => {
        this.setState({ session: _.merge({}, this.state.savedSession), isDirty: false });
    }

    getFeaturedClassSession = () => {
        const $this = this;
        const sessionId = $this.props.match.params.sessionId;
        if (sessionId === 'new' || $this.state.isLoadingFeaturedSession) return;

        $this.setState({ isLoadingFeaturedSession: true })
        classService.getFeaturedClassSession()
            .then(featuredSesssionDataResponse => {
                $this.setState({
                    featuredSesssion: featuredSesssionDataResponse,
                    isLoadingFeaturedSession: false
                });
            })
            .catch(_ => $this.setState({ isLoadingFeaturedSession: false }));
    }

    setFeaturedSession = () => {
        if (!window.confirm('Do you want to set as featured session?')) return;

        const $this = this;
        const sessionId = $this.props.match.params.sessionId;
        if (!sessionId || this.state.isLoadingFeaturedSession) return;

        $this.setState({ isLoadingFeaturedSession: false });
        classService.setFeaturedClassSession(sessionId)
            .then(featuredSesssionDataResponse => {
                $this.setState({
                    isLoadingFeaturedSession: false,
                    featuredSesssion: featuredSesssionDataResponse
                });
                toast.success('Successfully set session as featured!', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(error => {
                $this.setState({
                    featuredSesssion: {},
                    isLoadingFeaturedSession: false,
                    error
                })
            })
    }
}

const UploadingMessage = (asset) => {
    switch (asset && asset.status) {
        case 'uploaded':
        case 'transcoding':
            return (<p>You cannot choose a thumbnail, video is transcoding.</p>)
        default:
            return (<p>You need to upload a video before choosing a thumbnail.</p>)

    }
}

export default SessionEditor;