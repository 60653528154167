import BaseService from './base-service';
import { STRIPE_SUBSCRIPTION_STATUSES } from '../references/constants';

const maxTime = 10000; // 10 seconds
const retryApiCall = async function (callFunction, params, timePassed = 0) {
  const result = await callFunction.call(this, params);
  if (!result && timePassed <= maxTime) {
    await delay((timePassed += 1000));
    return await retryApiCall.call(this, callFunction, params, (timePassed += 1000));
  }
  return result;
};

const delay = function (time) {
  return new Promise((resolve) => {
    setTimeout(resolve.bind(null), time);
  });
};

class AccountService extends BaseService {
  /**
   * This method creates a subscription for the user
   * @param {string} token Payment token to apply
   */
  createSubscription(token) {
    return this._apiPost({
      path: '/accounts/current/subscriptions',
      data: { token },
    });
  }

  iapVerifyReceipt(userId, receipt, os) {
    return this._apiPost({
      path: `/accounts/${userId}/iap/verify/receipt`,
      data: { receipt, os },
    });
  }

  iapCheckCurrentUserOwnsSubscription(userId, originalTransactionId) {
    return this._apiPost({
      path: `/accounts/${userId}/iap/owns/subscription`,
      data: { originalTransactionId },
    });
  }

  /**
   * This method cancels the subscription for the user
   * @param {string} reason Reason selected by user for cancelling the subscription
   */
  cancelSubscription(reason) {
    return this._apiPost({
      path: '/accounts/current/subscriptions/cancelled',
      data: { reason },
    });
  }

  /**
   * This method will update default card for the subscription
   * @param {string} token Payment token to apply
   */
  updateDefaultSubscriptionCard(token) {
    return this._apiPost({
      path: '/accounts/current/subscriptions/cards/default',
      data: { token },
    });
  }

  deleteUserProfile(profileId) {
    return this._apiDelete({
      path: `/accounts/current/profiles/${profileId}`,
    });
  }

  getUserAccount() {
    return this._apiGet({
      path: '/accounts/current',
    });
  }

  /**
   *
   * @param {string} userId - user identification.
   */
  getUserSubscription(userId) {
    return this._apiGet({
      path: `/accounts/${userId}/subscriptions`,
    });
  }

  async getUserProfile({ id = 'current', retry = false } = {}) {
    if (retry) {
      return retryApiCall.call(this, this._apiGet, { path: `/accounts/current/profiles/${id}` });
    }
    return await this._apiGet({
      path: `/accounts/current/profiles/${id}`,
    });
  }

  fetchUserProfiles() {
    return this._apiGet({
      path: '/accounts/current/profiles',
    });
  }

  saveUserProfile(data) {
    if (!data.id) {
      return this._apiPost({
        path: '/accounts/current/profiles',
        data,
      });
    }
    return this._apiPut({
      path: `/accounts/current/profiles/${data.id}`,
      data,
    });
  }
}

export default new AccountService();
