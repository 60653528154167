import React from 'react'

const DislikeIcon = ({ width, height, color = ''}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.9033 14.2001L14.0259 25C13.0557 25 12.1253 24.6207 11.4392 23.9456C10.7532 23.2705 10.3678 22.3548 10.3678 21.4V16.6001H3.46631C3.11281 16.604 2.76267 16.5322 2.44014 16.3898C2.11761 16.2473 1.83042 16.0376 1.59844 15.775C1.36647 15.5125 1.19527 15.2035 1.0967 14.8694C0.998137 14.5352 0.974564 14.184 1.02761 13.8401L2.71031 3.04014C2.7985 2.46786 3.09388 1.94622 3.54202 1.57134C3.99017 1.19647 4.56088 0.993611 5.14901 1.00015H18.9033M18.9033 14.2001V1.00015M18.9033 14.2001H22.5613C23.2081 14.2001 23.8284 13.9472 24.2857 13.4971C24.7431 13.047 25 12.4366 25 11.8001V3.40014C25 2.76362 24.7431 2.15318 24.2857 1.70309C23.8284 1.25301 23.2081 1.00015 22.5613 1.00015H18.9033" stroke={color !== '' ? color : 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default DislikeIcon;