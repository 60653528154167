import styled from 'styled-components';

export const CancellationDialogModal = styled.div`
  z-index: auto;
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.5);
`;

export const CancellationModalInnerWrapper = styled.div`
  border-radius: 20px;
  background: #fff;
  height: 682px;
  width: 622px;
  padding: 64px;
  margin: 64px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

export const CancellationReasonListModal = styled.div`
  z-index: 99;
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.5);
`;

export const CancellationReasonListInnerWrapper = styled.div`
  border-radius: 20px;
  background: #fff;
  height: 560px;
  width: 494px;
  margin: 125px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

export const CancellationReasonItemWrapper = styled.button`
  background-color: #fff;
  display: flex;
  height: 70px;
  border: none;
  border-bottom: 1px solid #f2f2f2;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  width: 494px;

  &:active{
    background-color: #f2f2f2;
  }
`;

export const CancellationReasonItem = styled.label`
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  font-weight: 500;
  display: flex;
  padding: 0 30px;
`;
