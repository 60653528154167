import React from 'react';

import CloseIcon from '../components/elements/icons/close-icon';
import LogoutIcon from '../components/elements/icons/logout-icon';
import modes from "../cross-platform/references/modes";

class Dialog extends React.Component {
    confirm = () => {
        if (this.props.onActionButtonClick) this.props.onActionButtonClick();
    }

    close = () => {
        if (this.props.onClose) this.props.onClose();
    }

    render = () => {
        return (
            <React.Fragment>
                <div className="modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="true" style={{ display: this.props.display && 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content w-75 mx-auto">
                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                                    <CloseIcon width={20} height={20} />
                                </button>
                                <div className="col-12 text-center mb-5">
                                    <span className="logout-icon">
                                        <LogoutIcon width={42} height={42}/>
                                    </span>
                                    <h4>Logout</h4>
                                    <p>Are you sure you want to logout?</p>
                                </div>
                                <div className="col-12 px-0 mb-3">
                                    {this.props.signOutHelper.mode === modes.WITH_ERROR && (
                                        <div className="alert alert-danger">
                                            <i className="fas fa-exclamation-circle pr-2"></i>
                                            {this.props.signOutHelper.errorMessage}
                                        </div>
                                    )}
                                    <div className="row no-gutters">
                                        <div className="col-12 text-center">
                                            {this.props.onActionButtonClick &&
                                                <button type="button" className="px-4 py-3 btn btn-danger btn-block"
                                                    onClick={this.confirm}>{this.props.buttonText || 'Ok'}</button>}
                                            <button type="button" className="px-4 py-3 btn btn-secondary btn-block" data-dismiss="modal"
                                                onClick={this.close}>Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.display && <div className="modal-backdrop fade show"></div>}
            </React.Fragment>
        )
    }

}

export default Dialog;
