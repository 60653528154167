import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { toast } from 'react-toastify';
import { ValidatedForm, ValidatedInput } from 'react-validated';

import modes from '../../cross-platform/references/modes';
import * as actions from '../../cross-platform/actions/auth-actions';
import LogoIcon from '../../components/elements/icons/logo-icon';
class ResendCode extends React.Component {

    componentDidMount() {
        this.props.resetErrorMessage();
    }

    resendSignUpCode = () => {
        this.props.resendSignUpCode().then((success) => {
            if (success) {
                this.props.history.push('/confirm-sign-up');
                toast.success('Code successfully sent.', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        });
    }

    render() {
        return (
            <div className="auth">
                <div className="row">
                    <div className="col-xl-9 col-lg-10 align-self-center mx-auto top-logo-wrapper">
                        <div className="text-center">
                            <MediaQuery maxWidth={768}>
                                <Link to="/login">
                                    <LogoIcon width={25} height={25} />
                                    <h4 className="logo-text">Stryde</h4>
                                </Link>
                            </MediaQuery>
                            <MediaQuery minWidth={769}>
                                <Link to="/login">
                                    <img className="logo"
                                    src="/Logo.png"
                                    alt="img" />
                                </Link>
                            </MediaQuery>
                        </div>
                    </div>
                </div>
                <div className="row h-100">
                    <div className="col-xl-9 auth-content-wrapper col-lg-10 align-self-center m-auto shadow-sm">
                        <div className="row">
                            <div className="col-md-6 col-xs-12 order-2 order-md-1 background-image link-box">
                                <div className="row h-100">
                                    <div className="col-12 col-md-12 align-self-center m-auto p-0">
                                        <i className="fas fa-quote-left"></i>
                                        <blockquote className="quote-content pb-8 pt-5">
                                            If you need me, I’ll be on my bike <strong>FOREVER</strong>. So many amazing classes; how in the world am I supposed to choose?!
                                        </blockquote>
                                        <cite>Rachel Libowitz</cite>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12 bg-white order-1 form-box">
                                <div className="row h-100">
                                    <div className="offset-1 col-10 align-self-center mt-5">
                                        {this.props.mode === modes.WITH_ERROR && (
                                            <div className="alert alert-danger">
                                                <i className="fas fa-exclamation-circle pr-2"></i>
                                                {this.props.errorMessage}
                                            </div>
                                        )}
                                        <h2>
                                            Verification code
                                        </h2>
                                        <p className="mt-3">
                                            Input your email here to send verification code
                                        </p>
                                        <ValidatedForm onSubmit={this.resendSignUpCode} className="form-group mt-5 mb-5">
                                            <div className="">
                                                <ValidatedInput required>
                                                    <label htmlFor="email" className="control-label">Email</label>
                                                    <input type="text" name="email" autoFocus={true} className="form-control" placeholder="Email" value={this.props.email} onChange={this.props.onChange} />
                                                </ValidatedInput>
                                            </div>
                                            <button type="submit" className="btn btn-block btn-primary float-right font-weight-bold p-2 mt-5 mb-5" disabled={this.props.mode === modes.FETCHING}>
                                                SEND
                                            </button>
                                        </ValidatedForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ authStore }) => {
    return {
        ...authStore
    };
};

export default connect(mapStateToProps, actions)(ResendCode);