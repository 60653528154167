import React from 'react'

const RpmIcon = ({ width, height, color = '', fill = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.7755 12C18.7755 14.6036 16.6374 16.7143 14 16.7143C12.6813 16.7143 11.4874 16.1866 10.6232 15.3335C9.75901 14.4804 9.22449 13.3018 9.22449 12C9.22449 9.39637 11.3626 7.28571 14 7.28571C15.3187 7.28571 16.5126 7.81338 17.3768 8.6665C18.241 9.51962 18.7755 10.6982 18.7755 12Z" fill={fill !== '' ? fill : '#FCB333'} fillOpacity="0.2"/>
        <path d="M2.85714 23L10.6232 15.3335M2.85714 23H4.71429M2.85714 23H1M25.1429 1L17.3768 8.6665M25.1429 1H27M25.1429 1H23.2857M17.3768 8.6665C16.5126 7.81338 15.3187 7.28571 14 7.28571C11.3626 7.28571 9.22449 9.39637 9.22449 12C9.22449 13.3018 9.75901 14.4804 10.6232 15.3335M17.3768 8.6665C18.241 9.51961 18.7755 10.6982 18.7755 12C18.7755 14.6036 16.6374 16.7143 14 16.7143C12.6813 16.7143 11.4874 16.1866 10.6232 15.3335M17.7144 2.60406C16.5632 2.16025 15.3105 1.91667 14.0001 1.91667C8.35892 1.91667 3.78583 6.43113 3.78583 12C3.78583 13.2936 4.03258 14.5302 4.48215 15.6667M23.5181 8.33333C23.9676 9.46975 24.2144 10.7064 24.2144 12C24.2144 17.5689 19.6413 22.0833 14.0001 22.0833C12.6897 22.0833 11.437 21.8397 10.2858 21.3959" stroke={color !== '' ? color : '#FCB333'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default RpmIcon;