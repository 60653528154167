import React from 'react';
import modes from '../../references/modes';
import authService from '../../services/auth-service';

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errorMessage: '',
            mode: modes.BROWSE,
            firstname: '',
            lastname: '',
            name: '',
            password: '',
            terms: false,
        }
    }

    _signUp = (fromValues = null) => {
        const { password, email, firstname, lastname } = fromValues ? fromValues : this.state;
        const name = firstname + ' ' + lastname;
        // if (!terms) {
        //     this.setState({
        //         errorMessage: 'You have to agree with our terms and service',
        //         mode: modes.WITH_ERROR
        //     })
        //     return Promise.reject();
        // }
        //Email has to always be lowercase
        const search = this.props.location.search;
        const studioId = new URLSearchParams(search).get('studioId');
        return authService.signUp({ username: email.toLowerCase(), password, email: email.toLowerCase(), name, 'custom:studioId': studioId })
            .then(() => this.setState({ mode: modes.WITH_RESULTS }))
            .catch(error => {
                let errorMessage = '';
                switch (error.code) {
                    case 'InvalidParameterException':
                        errorMessage = error.message.includes('Invalid email address format')
                            ? 'Invalid email address format.'
                            : 'Password should have 6 or more characters';
                        break;
                    case 'UserLambdaValidationException':
                        errorMessage = error.message.indexOf('User is not invited') !== -1
                            ? 'User is not invited to Stryde'
                            : 'Only letters are allowed in fullname field';
                        break;
                    case 'UsernameExistsException':
                        errorMessage = 'Account with this email already exists';
                        break;
                    default:
                        errorMessage = 'Failed to sign up';
                        break;
                }
                this.setState({
                    errorMessage,
                    mode: modes.WITH_ERROR
                })
                return Promise.reject();
            });
    }

    _onChange = (data) => {
        this.setState({
            ...data
        });
    }
}
export default SignUp;