import React from 'react'

const InstructorIcon = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 24V21.5556C22 20.2589 21.4732 19.0154 20.5355 18.0986C19.5979 17.1817 18.3261 16.6667 17 16.6667H7C5.67392 16.6667 4.40215 17.1817 3.46447 18.0986C2.52678 19.0154 2 20.2589 2 21.5556V24M17 6.88889C17 9.58895 14.7614 11.7778 12 11.7778C9.23858 11.7778 7 9.58895 7 6.88889C7 4.18883 9.23858 2 12 2C14.7614 2 17 4.18883 17 6.88889Z" stroke="#828282" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default InstructorIcon;