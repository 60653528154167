import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import { Formik, Form, Field } from 'formik';
import { ResetPasswordRequestSchema, ResetPasswordSchema } from '../../cross-platform/common/validation-schemas'

import modes from '../../cross-platform/references/modes';
import * as actions from '../../cross-platform/actions/auth-actions';
import LogoIcon from '../../components/elements/icons/logo-icon';
class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailSentSuccessfully: false
        };
    }
    componentDidMount() {
        this.props.resetErrorMessage();
    }

    resetPasswordRequest = (values) => {
        this.props.resetPasswordRequest(values)
        .then(() => {
            this.props.history.push({
                search: '?requested=1'
            });
        })
        .catch(error => {
            console.error('error', error);
        });
    }

    resetPassword = (values) => {
        this.props.resetPassword(values)
        .then(() => {
            this.props.history.push({
                pathname: '/login'
            });
        });
    }

    render() {
        const query = new URLSearchParams(this.props.location.search);
        const requestedPasswordReset = query.get('requested');
        const {emailSentSuccessfully} = this.state;
        return (
            <div className="auth">
                <div className="row">
                    <div className="col-xl-9 col-lg-10 align-self-center mx-auto top-logo-wrapper">
                        <div className="text-center">
                            <MediaQuery maxWidth={768}>
                                <Link to="/login">
                                    <LogoIcon width={25} height={25} />
                                    <h4 className="logo-text">Stryde</h4>
                                </Link>
                            </MediaQuery>
                            <MediaQuery minWidth={769}>
                                <Link to="/login">
                                    <img className="logo"
                                    src="/Logo.png"
                                    alt="img" />
                                </Link>
                            </MediaQuery>
                        </div>
                    </div>
                </div>
                <div className="row h-100">
                    <div className="col-xl-9 col-lg-10 auth-content-wrapper align-self-center mx-auto">
                        <div className="row">
                            <div className="col-md-6 col-xs-12 order-1 order-md-1 background-image link-box">
                                <div className="row h-100">
                                    <div className="col-12 col-md-12 align-self-center m-auto p-0">
                                        <i className="fas fa-quote-left"></i>
                                        <blockquote className="quote-content pb-8 pt-5">
                                            If you need me, I’ll be on my bike <strong>FOREVER</strong>. So many amazing classes; how in the world am I supposed to choose?!
                                        </blockquote>
                                        <cite>Rachel Libowitz</cite>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12 order-md-2 order-2 bg-white form-box">
                                <div className="row h-100">
                                    <div className="col-12 p-4 align-self-center login-form-wrapper">
                                        {this.props.mode === 'with_error' && (
                                            <div className="alert alert-danger">
                                                <i className="fas fa-exclamation-circle pr-2"></i>
                                                {this.props.errorMessage}
                                            </div>
                                        )}
                                        
                                        {!requestedPasswordReset ? (
                                            <>
                                            {emailSentSuccessfully ? (
                                                <div className="email-sent-content align-items-center text-center mx-auto inner-auth-wrapper">
                                                    <span className="email-icon">
                                                        <i className="fas fa-envelope fa-3x mr-2"></i>
                                                    </span>
                                                    <p className="mt-2">We sent you a mail!</p>
                                                    <small>Check your inbox and follow instructions.</small>
                                                    <Link to="/login"
                                                    className="btn btn-block btn-main btn-primary float-right p-3">
                                                        Back to Login
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="inner-auth-wrapper">
                                                    <h2>
                                                    Reset Password
                                                    </h2>
                                                    <Formik 
                                                        initialValues={{
                                                            email: ''
                                                        }}
                                                        validationSchema={ResetPasswordRequestSchema}
                                                        validateOnChange={false}
                                                        validateOnBlur={false}
                                                        onSubmit={(values) => this.resetPasswordRequest(values)}
                                                    >
                                                        {({ errors, touched }) => (
                                                        <Form className="form-group mt-5 mb-5">
                                                            <label htmlFor="email" className="control-label">Email</label>
                                                            <Field name="email" className={classNames('form-control', {'is-invalid': errors.email && touched.email})} placeholder="Email" />
                                                            <button className="btn btn-block btn-primary btn-main float-right p-3 mt-7 mb-2" 
                                                            disabled={this.props.mode === modes.FETCHING} type="submit">
                                                                Reset Password
                                                            </button>
                                                            <Link to="/login"
                                                            className="btn btn-block btn-light float-right p-3">
                                                                Remember it? Login
                                                            </Link>
                                                        </Form>)}
                                                    </Formik>
                                                </div>
                                            )}
                                            </>
                                        ) : (
                                            <Formik 
                                            initialValues={{
                                                email: '',
                                                code: '',
                                                password: '',
                                                confirmPassword: ''
                                            }}
                                            validationSchema={ResetPasswordSchema}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            onSubmit={(values) => this.resetPassword(values)}
                                            >
                                                {({ errors, touched }) => (
                                                <Form className="form-group mt-5 mb-5">
                                                    <div className="mb-2">
                                                        <label htmlFor="email" className="control-label">Email</label>
                                                        <Field name="email" className={classNames('form-control', {'is-invalid': errors.email && touched.email})} placeholder="Email" />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label htmlFor="code" className="control-label">Code</label>
                                                        <Field name="code" className={classNames('form-control', {'is-invalid': errors.code && touched.code})} placeholder="Code" />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label htmlFor="password" className="control-label">Password</label>
                                                        <Field name="password" className={classNames('form-control', {'is-invalid': errors.password && touched.password})} placeholder="Password" type="password" />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="confirmPassword" className="control-label">Confirm Password</label>
                                                        <Field name="confirmPassword" className={classNames('form-control', {'is-invalid': errors.confirmPassword && touched.confirmPassword})} placeholder="Confirm New Password" type="password" />
                                                    </div>
                                                    <button className="btn btn-block btn-primary btn-main float-right p-2 mt-5 mb-5" disabled={this.props.mode === modes.FETCHING}>
                                                        SUBMIT
                                                    </button>
                                                </Form>)}
                                            </Formik>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="copyrights-content text-center">
                                <p>&copy;2020 Stryde Bike. All Rights Reserved.</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ authStore }) => {
    return {
        ...authStore
    };
};

export default connect(mapStateToProps, actions)(ResetPassword);