import React from 'react';

const LikeIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6322 9.39995V4.59998C15.6322 3.6452 15.2468 2.72954 14.5608 2.05441C13.8747 1.37928 12.9443 1 11.9741 1L7.09674 11.7999V24.9998H20.851C21.4391 25.0064 22.0098 24.8035 22.458 24.4287C22.9061 24.0538 23.2015 23.5321 23.2897 22.9599L24.9724 12.1599C25.0254 11.816 25.0019 11.4648 24.9033 11.1306C24.8047 10.7965 24.6335 10.4875 24.4016 10.225C24.1696 9.96242 23.8824 9.75266 23.5599 9.61021C23.2373 9.46775 22.8872 9.39601 22.5337 9.39995H15.6322ZM7.09674 24.9998H3.4387C2.79191 24.9998 2.17162 24.747 1.71428 24.2969C1.25693 23.8468 1 23.2364 1 22.5999V14.1999C1 13.5634 1.25693 12.953 1.71428 12.5029C2.17162 12.0528 2.79191 11.7999 3.4387 11.7999H7.09674" fill={color !== '' ? color : 'white'} fillOpacity="0.4"/>
            <path d="M7.09674 11.7999L11.9741 1C12.9443 1 13.8747 1.37928 14.5608 2.05441C15.2468 2.72954 15.6322 3.6452 15.6322 4.59998V9.39995H22.5337C22.8872 9.39601 23.2373 9.46775 23.5599 9.61021C23.8824 9.75266 24.1696 9.96242 24.4016 10.225C24.6335 10.4875 24.8047 10.7965 24.9033 11.1306C25.0019 11.4648 25.0254 11.816 24.9724 12.1599L23.2897 22.9599C23.2015 23.5321 22.9061 24.0538 22.458 24.4287C22.0098 24.8035 21.4391 25.0064 20.851 24.9998H7.09674M7.09674 11.7999V24.9998M7.09674 11.7999H3.4387C2.79191 11.7999 2.17162 12.0528 1.71428 12.5029C1.25693 12.953 1 13.5634 1 14.1999V22.5999C1 23.2364 1.25693 23.8468 1.71428 24.2969C2.17162 24.747 2.79191 24.9998 3.4387 24.9998H7.09674" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default LikeIcon;