import React from 'react'

const CheckboxIcon = ({ width, height, color = '', fill = ''}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color !== '' ? color : 'none'} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.1778 9.60301C17.6074 9.14479 17.6074 8.40188 17.1778 7.94366C16.7482 7.48545 16.0518 7.48545 15.6222 7.94366L10.35 13.5673L8.37782 11.4637C7.94824 11.0054 7.25176 11.0054 6.82218 11.4637C6.39261 11.9219 6.39261 12.6648 6.82218 13.123L9.57218 16.0563C10.0018 16.5146 10.6982 16.5146 11.1278 16.0563L17.1778 9.60301Z" fill={fill !== '' ? fill : 'white'} />
        </svg>
    );
}

export default CheckboxIcon;