import React from 'react';
import _ from 'lodash';
import { Bar as BarChart, defaults } from 'react-chartjs-2';

class BarDiagram extends React.Component {

    state = {
        charHeight: Math.trunc(window.innerHeight * 0.17),
        chartOptions: {
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            scales: {
                yAxes: [{
                    gridLines: {
                        drawTicks: false,
                        drawBorder: false,
                    },
                    weight: 5,
                    ticks: {
                        padding: 30,
                        min: 0,
                        maxTicksLimit: 6
                    }
                }],
                xAxes: [{
                    barThickness: 'flex',
                    maxBarThickness: 8,
                    ticks: {
                        padding: 30,
                    },
                    gridLines: {
                        display: false,
                        drawTicks: false,
                    }
                }]
            }
        }
    };

    componentDidMount() {
        _.merge(defaults, {
            global: {
                defaultFontColor: '#666785',
                defaultFontFamily: "'Ubuntu', sans-serif",
                defaultFontStyle: 'bold',
                line: {
                    borderColor: 'white',
                }
            },
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-7 d-none d-xl-block">
                    <div className="row m-0">
                        <div className="col-12">
                            <div className="row my-3 ml-2 mr-0">
                                <div className="col-12 bg-white">
                                    <p className="my-4 ml-2 font-weight-bolder">Diagram</p>
                                    <div className="ml-2">
                                        <BarChart data={this.props.data}
                                            options={this.state.chartOptions} height={this.state.charHeight} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="text-center mx-auto d-inline d-xl-none">This page is not available on mobile</h1>
            </React.Fragment>
        )
    }
}

export default BarDiagram;