import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import Ratings from 'react-ratings-declarative';
import LikeIcon from '../components/elements/icons/like-icon';
import DislikeIcon from '../components/elements/icons/dislike-icon';
function VideoFeedbackPopup(props) {
    // const rating = Math.random() * (5 - 4) + 4;
    const [feedback, setFeedback] = useState(0);
    return (
        <div className="modal fade show feedback-modal" style={{ display: props.visible && 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <h1 className="mb-2">Congratulations!</h1>
                        <p className="under-title">You just finished another class. <br />Rate your experience.</p>

                        <h5 className="py-3 subtitle">Did you like this class?</h5>
                        
                        <div className="feedback-rating-icons mb-3">
                            <button 
                            className={`plain-btn rating-icon rating-like mr-2 ${feedback === 1 ? 'rating-active' : ''}`}
                            onClick={() => setFeedback(1)}
                            >
                                <LikeIcon width={26} height={26} />
                            </button>
                            <button 
                            className={`plain-btn rating-icon rating-dislike ${feedback === 2 ? 'rating-active' : ''}`}
                            onClick={() => setFeedback(2)}
                            >
                                <DislikeIcon width={26} height={26} />
                            </button> 
                        </div>

                        {/* <div className="feedback-star-ratings">
                            <div className="row">
                                <div className="col-6 mb-5">
                                    <h5>Overall</h5>
                                    <Ratings
                                        rating={rating}
                                        widgetDimensions="20px"
                                        widgetSpacings="3px"
                                    >
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                    </Ratings>
                                </div>
                                <div className="col-6 mb-5">
                                    <h5>Instructor</h5>
                                    <Ratings
                                        rating={rating}
                                        widgetDimensions="20px"
                                        widgetSpacings="3px"
                                    >
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                    </Ratings>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <h5>Music</h5>
                                    <Ratings
                                        rating={rating}
                                        widgetDimensions="20px"
                                        widgetSpacings="3px"
                                        // changeRating={this.changeRating('music')}
                                    >
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                    </Ratings>
                                </div>
                                <div className="col-6">
                                    <h5>Difficulty</h5>
                                    <Ratings
                                        rating={rating}
                                        widgetDimensions="20px"
                                        widgetSpacings="3px"
                                    >
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                        <Ratings.Widget widgetRatedColor="#FCB333" />
                                    </Ratings>
                                </div>
                            </div>
                        </div> */}
                        
                        
                        <div className="row">
                            <div className="col-12 mt-5">
                                <button className="btn btn-white" disabled={feedback === 0} onClick={(feedback !== 0 && feedback === 1) ? props.onYesPress : props.onNoPress}>Submit</button>
                                <button className="btn btn-skip" onClick={props.onClose}>Skip <i className="fas fa-arrow-right"></i></button>
                            </div>
                        </div>
                        {/* <h4 className="feedback-modal-title">Did you enjoy this class?</h4>
                        <div className="feedback-modal-actions">
                            <button className="btn btn-primary feedback-modal-actions-btn" onClick={props.onYesPress}>
                                Yes
                            </button>
                            <button className="btn btn-outline-primary feedback-modal-actions-btn" onClick={props.onNoPress}>
                                No
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

VideoFeedbackPopup.defaultProps = {
    visible: false,
    onClose: () => alert('Modal on close is missing!'),
    onYesPress: () => alert("Pressed yes!"),
    onNoPress: () => alert("Pressed no!"),
};

VideoFeedbackPopup.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onYesPress: PropTypes.func,
    onNoPress: PropTypes.func
};

export default VideoFeedbackPopup;