import React from 'react';

const ClockIcon = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 1.93548C5.54609 1.93548 1.93548 5.54609 1.93548 10C1.93548 14.4539 5.54609 18.0645 10 18.0645C14.4539 18.0645 18.0645 14.4539 18.0645 10C18.0645 5.54609 14.4539 1.93548 10 1.93548ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 3.6129C10.5345 3.6129 10.9677 4.04618 10.9677 4.58064V9.4019L14.0457 10.9409C14.5237 11.1799 14.7175 11.7612 14.4785 12.2392C14.2395 12.7173 13.6582 12.911 13.1801 12.672L9.56721 10.8656C9.23936 10.7016 9.03226 10.3666 9.03226 10V4.58064C9.03226 4.04618 9.46553 3.6129 10 3.6129Z" fill="white"/>
        </svg>
    );
}

export default ClockIcon;