import React from 'react'

const PlusIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44 23H23M23 23H2M23 23V2M23 23V44" stroke={color !== '' ? color : '#FCB333'} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default PlusIcon;