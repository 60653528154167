import React, { Component } from 'react';
import Logo from '../images/error-ilustrate.svg';

class NotFound extends Component {
    render() {
        return (
            <div className="fixed-bottom h-100 d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center flex-column">
                    <img src={Logo} alt="Unauthorized"></img>
                    <h2 className="mt-5">Oops!!! Error 404 - Page Not Found</h2>
                    <p>The page you requested could not be found</p>
                </div>
            </div>
        );
    }
}

export default NotFound