import React from 'react';
import MediaQuery from 'react-responsive';
import Hamburger from 'hamburger-react';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import Dialog from './dialog';
import * as userActions from "../cross-platform/actions/user-actions";

import ClassPlayIcon from '../components/elements/icons/class-play-icon';
import HomeIcon from '../components/elements/icons/home-icon';
import LeaderboardIcon from '../components/elements/icons/leaderboard-icon';
import SwitchProfileIcon from '../components/elements/icons/switch-profile-icon';
import LogoutIcon from '../components/elements/icons/logout-icon';
import CloseMenuIcon from '../components/elements/icons/close-menu-icon';
import OpenMenuIcon from '../components/elements/icons/open-menu-icon';
class Navigation extends React.Component {
    state = {
        isMobileMenuOpen: false
    };

    signOut = () => {
        this.props.signOut()
            .then(result => {
                if (result) window.location.reload();
            })
    };

    closeMenu = () => this.setState({ isMobileMenuOpen: false });

    toggleOpen = () => this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });

    render() {
        const navigationMenuClass = this.state.isMobileMenuOpen ? "show" : "";
        const { openProfilePageToggle, openProfilePage, location } = this.props;
        
        return (
            <>
            <MediaQuery maxWidth={768}>
                <div className={`collapse navbar-collapse navbar-bottom-responsive ${navigationMenuClass}`} id="navbarBottomResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <NavLink exact className="nav-link" activeClassName="active" to="/">
                                <HomeIcon width="22" height="22"/>
                                <span>Home</span>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink exact className="nav-link" activeClassName="active" to="/classes">
                                <ClassPlayIcon width="22" height="22" />
                                <span>Classes</span>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink exact className="nav-link" activeClassName="active" to="/leaderboard">
                                <LeaderboardIcon width="22" height="22"/>
                                <span>Leaderboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <button onClick={() => { this.closeMenu(); this.props.openSignOutModal(); }} className="btn-simple btn-logout"><LogoutIcon width={24} height={24} /> <span>Logout</span></button>
                        </li>
                    </ul>
                </div>
            </MediaQuery>
            <div className={`navigation navigation-bottom navigation-${navigationMenuClass}`}>
                {/* <div className="close-profile-menu">
                    
                </div> */}
                <MediaQuery maxWidth={768}>
                    <div className="navigation-container-mobile">
                        <Link to="/" className="logo">
                            <img className="img-fluid"
                            src="/Stryde.png"
                            alt="Stryde"></img>
                        </Link>
                        <div className="float-right">
                            <div className="profile-icon-wrapper">
                                <Link to={(!openProfilePageToggle) ? '/profiles' : '/'} className="switch-profile-wrapper">
                                        {!location.pathname.includes('/profiles' || !openProfilePageToggle) ? <SwitchProfileIcon /> : 
                                        <span className="close-profile" onClick={() => openProfilePage()}><CloseMenuIcon width={32} height={32} /></span>}
                                </Link>
                            </div>
                            <a onClick={this.toggleOpen} className="menu-icon-wrapper">{this.state.isMobileMenuOpen ? <CloseMenuIcon width={32} height={32} /> : <span className="open-icon"><OpenMenuIcon width={27} height={25} /></span>}</a>
                            {/* <Hamburger toggled={this.state.isMobileMenuOpen} toggle={this.toggleOpen} color={!this.state.isMobileMenuOpen ? '#000000' : "#ffffff"} size={20} /> */}
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={769}>
                    <nav className="navbar fixed-bottom navbar-expand navbar-light bg-white px-0 px-md-3">
                        <div className="navbar-container">
                            <Link className="navbar-brand font-weight-bold d-none d-md-inline" to="/">
                            <img className="logo"
                                src="/Katana_Logo.png"
                                alt="img"></img>
                            </Link>
                        </div>
                        <ul className="navbar-nav mx-md-2 mx-md-auto mt-2 mt-lg-0 main-nav-bottom">
                            <li className="nav-item">
                                <NavLink exact className="nav-link" activeClassName="active" to="/">
                                    <HomeIcon width="22" height="22" />
                                    <span className="d-none d-md-inline">Home</span>
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink exact className="nav-link" activeClassName="active" to="/classes">
                                    <ClassPlayIcon width="22" height="22" />
                                    <span className="d-none d-md-inline">Classes</span>
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink exact className="nav-link" activeClassName="active" to="/leaderboard">
                                    <LeaderboardIcon width="22" height="22" />
                                    <span className="d-none d-md-inline">Leaderboard</span>
                                </NavLink>
                            </li>

                            {/* <li className="nav-item">
                                <NavLink exact className="nav-link" activeClassName="active" to="/free-ride">
                                    <FreeRideIcon width="22" height="22" />
                                    <span className="d-none d-md-inline">Free Ride</span>
                                </NavLink>
                            </li> */}
                        </ul>
                                                        
                        <div className="navbar-container-right">
                            {this.props.userCan && this.props.userCan.administer &&
                                <Link to="/admin/analytics" className="navbar-brand mx-auto mx-md-0 p-2 px-3 p-md-0">
                                    <p className="d-none d-md-inline profile-name mr-2">Admin Panel</p>
                                </Link>}
                            <Link to={(!openProfilePageToggle) ? '/profiles' : '/'} className="navbar-brand mx-auto switch-profile-wrapper px-4">
                                    {!location.pathname.includes('/profiles' || !openProfilePageToggle) && <SwitchProfileIcon />}
                                    <span className={(openProfilePageToggle || location.pathname.includes('/profiles')) ? 'switch-profile-active' : ''} onClick={() => openProfilePage()}>{(openProfilePageToggle || location.pathname.includes('/profiles')) && <i className="fas fa-times" />} Switch Profile</span>
                                    {/* <span
                                        className={`d-none d-md-inline profile-name font-weight-bold ${this.props.history.location.pathname.includes('/account/') ? 'text-primary' : ''}`}>
                                        {this.props.userProfile.fullName}
                                    </span> */}
                            </Link>

                            <button onClick={this.props.openSignOutModal} className="btn-signout-modal btn-simple pt-4 pl-4 pb-4 pr-2 border-left"><LogoutIcon width={24} height={24} /></button>
                            
                        </div>
                    </nav>
                </MediaQuery>
                
                <Dialog title='Are you sure you want to sign out?' signOutHelper={this.props.signOutHelper}
                    display={this.props.signOutModal} onClose={this.props.onCloseSignOutModal}
                    onActionButtonClick={this.signOut} buttonText={'Logout'}>
                </Dialog>
            </div>
            </>
        )
    }
}

const mapStateToProps = ({ userStore }) => {
    return {
        ...userStore
    };
};

export default withRouter(connect(mapStateToProps, { ...userActions })(Navigation));