import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Link, Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../components/breadcrumbs';
import ColoredStatus from '../../components/common/colored-status';
import MessageBox from '../../components/common/message-box';
import { CLASS_ACTION_TYPES, CLASS_STATUSES } from '../../../cross-platform/references/constants';
import classService from '../../../cross-platform/services/class-service';
import ClassDetails from './_class-details';
import SessionBrowser from './_session-browser';
import restoreIco from '../../../images/restore-ico.svg';

const PAGE_STATE = {
    NEW: 'new',
    EDIT: 'edit'
}

// const CLASS_STATUSES = {
//     ACTIVE: 'active',
//     ARCHIVED: 'archived'
// }
const crumbs = [{
    title: 'Classes',
    path: '/classes'
}];

const DEFAULT_CLASS_DATA = {
    id: '',
    title: '',
    studioName: '',
    city: '',
    state: '',
    classStatus: CLASS_STATUSES.ACTIVE,
    description: '',
    difficulty: '',
    sessions: []
}

const DEFAULT_STATE = {
    class: DEFAULT_CLASS_DATA,
    isDirty: false,
    savedClass: DEFAULT_CLASS_DATA,
    PAGE_STATE: PAGE_STATE.NEW,
    error: ''
}

class ClassEditor extends Component {

    constructor(props) {
        super(props);

        this.state = _.cloneDeep(DEFAULT_STATE);
        this._classesDetailsRef = React.createRef();
    }

    componentDidMount() {
        this._loadClass();
    }

    render() {
        const classId = this.props.match.params.classId ? this.props.match.params.classId.toLowerCase() : '';
        const currentCrumbs = [].concat(crumbs);
        currentCrumbs.push({
            title: classId === 'new' ? 'New Class' : this.state.class.title
        });
        const currentLocaton = this.props.history.location.pathname;
        return (
            <div>
                <div className="admin-page">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumbs crumbs={currentCrumbs} root={'/admin'}></Breadcrumbs>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <h2 className="admin-page-title">{classId === 'new' ? 'Create class' : 'Edit class'} </h2>
                        </div>
                        <div className="col-6 text-right">
                            <ColoredStatus status={this.state.class.classStatus} />
                        </div>
                    </div>
                    <hr />
                    {this.state.error && this.state.error.message && <MessageBox messageType="error" message={this.state.error.message} />}
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-4">
                                {classId === 'new' ? (
                                    <div className="btn-group" role="group">
                                        <button type="button" onClick={this._trySave} className="btn btn-white btn-light-border font-weight-bolder"
                                            disabled={!this.state.isDirty}><i className="far fa-save mr-2"></i>Save</button>
                                        <button type="button" onClick={this._undo} className="btn btn-white font-weight-bolder"
                                            disabled={!this.state.isDirty}><i className="fas fa-undo mr-2"></i>Undo</button>
                                    </div>
                                ) : (
                                        <Fragment>
                                            <div className="btn-group" role="group">
                                                <button type="button" onClick={this._newClass} disabled={this.state.isDirty}
                                                    className="btn btn-white btn-light-border font-weight-bolder"><i className="fas fa-plus mr-2"></i>New</button>
                                                <button type="button" onClick={this._trySave} className="btn btn-white btn-light-border font-weight-bolder"
                                                    disabled={!this.state.isDirty}><i className="far fa-save mr-2"></i>Save</button>
                                                <button type="button" onClick={this._undo} className="btn btn-white btn-light-border font-weight-bolder"
                                                    disabled={!this.state.isDirty}><i className="fas fa-undo mr-2"></i>Undo</button>
                                                <button type="button" onClick={this.onDelete} className="btn btn-white font-weight-bolder" disabled={
                                                    (this.state.isDirty || (this.state.class.sessions && this.state.class.sessions.length > 0)) ||
                                                    this.state.class.classStatus !== CLASS_STATUSES.ACTIVE
                                                }><i className="far fa-trash-alt mr-2"></i>Delete</button>
                                                {this.state.class.classStatus === CLASS_STATUSES.ACTIVE && (
                                                    <button type="button" onClick={this.onArchiveClass} className="btn btn-white font-weight-bolder"
                                                        disabled={this.state.isDirty}><i className="fa fa-archive mr-2"></i>Archive</button>
                                                )}
                                                {this.state.class.classStatus === CLASS_STATUSES.ARCHIVED && (
                                                    <button type="button" onClick={this.onRestoreClass} className="btn btn-white font-weight-bolder"
                                                        disabled={this.state.isDirty}><img className="katana-svg-icon" src={restoreIco} alt="-" />Restore</button>
                                                )}
                                            </div>

                                            <Link to={`/admin/classes/${classId}/sessions/new`} className="btn btn-primary text-white font-weight-bolder ml-3">+ Add Session</Link>
                                        </Fragment>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card text-center border-0 mt-4 ">
                                {classId !== 'new' && (
                                    <div className="card-header bg-white">
                                        <ul className="nav">
                                            <li className={`nav-item ${(currentLocaton === `/admin/classes/${classId}/details`) ? 'active' : ''}`}>
                                                <Link className="nav-link" to={`/admin/classes/${classId}/details`}>Details</Link>
                                            </li>
                                            <li className={`nav-item ${(currentLocaton === `/admin/classes/${classId}/sessions`) ? 'active' : ''}`}>
                                                <Link className="nav-link" to={`/admin/classes/${classId}/sessions`}>Sessions</Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                <Switch>
                                    <Route exact path={`/admin/classes/${classId}/details`}
                                        render={() =>
                                            <ClassDetails
                                                ref={this._classesDetailsRef}
                                                class={this.state.class}
                                                _onChange={this._onChange}
                                                onSave={this._save} />
                                        } />
                                    <Route exact path={`/admin/classes/${classId}/sessions`}
                                        render={() =>
                                            <SessionBrowser classId={classId} />
                                        } />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _deleteClass = () => {
        classService.deleteClass(this.state.class.id)
            .then(_ => {
                this.props.history.push(`/admin/classes`);
            })
            .catch(error => {
                console.error('Failed to delete class:', error);
                this.setState({ error });
            })
    }

    _archiveClass = () => {
        classService.archiveClass(this.state.class.id)
            .then(response => {
                toast.success("Class has been archived successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.setState({
                    class: { ...this.state.class, classStatus: response.classStatus }
                })
            })
            .catch(error => {
                console.error('Failed to delete class:', error);
                this.setState({ error });
            })
    }

    _restoreClass = () => {
        classService.restoreClass(this.state.class.id)
            .then(response => {
                toast.success("Class has been restored successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.setState({
                    class: { ...this.state.class, classStatus: response.classStatus }
                })
            })
            .catch(error => {
                console.error('Failed to restore class:', error);
                this.setState({ error });
            })
    }

    _isDirty = () => {
        return !_.isEqual(this.state.class, this.state.savedClass);
    }

    _loadClass = () => {
        const $this = this;
        const classId = $this.props.match.params.classId;
        if (!classId || classId.toLowerCase() === 'new') return;

        classService.getClass(classId)
            .then(workoutClass => {
                if (!workoutClass || !workoutClass.id) {
                    return $this.props.history.push('/not-found')
                }
                $this.setState({
                    class: _.merge({}, $this.state.class, workoutClass),
                    savedClass: _.merge({}, $this.state.savedClass, workoutClass),
                    state: PAGE_STATE.EDIT
                });
            })
            .catch(error => {
                console.error(error);
                $this.setState({
                    error
                });
            });
    }

    _onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const updatedClass = { ...this.state.class, [name]: value };

        this.setState({
            class: updatedClass,
            isDirty: !_.isEqual(updatedClass, this.state.savedClass)
        });
    }

    _save = () => {
        classService.saveClass(this.state.class)
            .then(savedClass => {
                this.setState({
                    class: savedClass,
                    savedClass: _.merge({}, savedClass),
                    isDirty: false,
                    error: null
                });
                toast.success("Class saved!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

                if (savedClass.id !== this.props.match.params.classId) {
                    return this.props.history.push(`/admin/classes/${savedClass.id}/details`);
                }
            })
            .catch(error => {
                console.error('Could not save:', error);
                this.setState({ error });
            })
    }

    _trySave = () => {
        this._classesDetailsRef.current.attemptSave();
    }

    _undo = () => {
        this.setState({ class: this.state.savedClass, isDirty: false });
    }

    onDelete = () => {
        if (!window.confirm("Are you sure you want to delete this class?")) return;

        if (this.state.isDirty) return;
        const classId = this.state.class.id;

        classService.deleteClass(classId)
            .then(() => {
                toast.success("Class deleted successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                return this.props.history.push(`/admin/classes`);
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    error
                })
                toast.error("Failed to delete class!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
    }

    onArchiveClass = () => {
        if (!window.confirm("Are you sure you want to archive this class?")) return;

        const classData = _.cloneDeep(this.state.class);
        classService.saveClass(classData, { actionType: CLASS_ACTION_TYPES.ARCHIVE })
            .then(() => {
                classData.classStatus = CLASS_STATUSES.ARCHIVED
                this.setState({ class: classData, savedClass: _.merge({}, classData), error: null });
                toast.success("Class is archived successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(error => {
                console.error('Failed to archive class:', error);
                toast.error("Failed to archive class!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.setState({ error });
            })
    }

    onRestoreClass = () => {
        if (!window.confirm("Are you sure you want to restore this class?")) return;

        const classData = _.cloneDeep(this.state.class);
        classService.saveClass(classData, { actionType: CLASS_ACTION_TYPES.RESTORE })
            .then(() => {
                classData.classStatus = CLASS_STATUSES.ACTIVE
                this.setState({ class: classData, savedClass: _.merge({}, classData), error: null });
                toast.success("Class is restored successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(error => {
                console.error('Failed to restore class:', error);
                toast.error("Failed to restore class!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.setState({ error });
            })
    }

    _newClass = () => {
        const defaultState = _.cloneDeep(DEFAULT_STATE);

        this.setState({ ...defaultState }, () => {
            this.props.history.push("/admin/classes/new/details")
        })
    }
}

export default withRouter(ClassEditor);