// eslint-disable-next-line
import React, { Component } from 'react';
import accountService from '../../../services/accountService';
import { getUserSubscriptionStatus } from '../../../../cross-platform/common/helpers';
import { STRIPE_SUBSCRIPTION_STATUSES } from '../../../../cross-platform/references/constants';

class Subscription extends Component {
    state = {
        error: '',
        subscription: {},
        isSavingSubscription: false,
        subscriptionStatus: STRIPE_SUBSCRIPTION_STATUSES.ACTIVE,
        subscriptionActive: false
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.userAccount || !props.userAccount.subscription) return null;
        const subscriptionActive = props.userCan?.accessSubscriptions;
        const subscriptionStatus = getUserSubscriptionStatus(props.userAccount);
        const subscription = props.userAccount && props.userAccount.subscription;

        return { ...state, subscriptionActive, subscriptionStatus, subscription };
    }

    componentDidMount = () => {
        if (!this.props.userAccount.id)
            this.props.getUserAccount();
    }

    _isSavingSubscription = (isSaving) => {
        this.setState({ isSavingSubscription: isSaving });
    }

    _openCancelSubscriptionDialog = () => {
        this.setState({ showCancelSubscriptionDialog: true });
    }

    _hideCancelSubscriptionDialog = () => {
        this.setState({ showCancelSubscriptionDialog: false });
    }

    _cancelSubscription = () => {
        if (this.state.isCancelingSubscription) return;
        this.setState({
            isCancelingSubscription: true,
            showCancelSubscriptionDialog: false
        });

        return accountService.cancelSubscription()
            .then(updatedUserAccount => {
                this.setState({ isCancelingSubscription: false });
                return this.props.setUserAccount(updatedUserAccount);
            })
            .catch(error => {
                this.setState({
                    isCancelingSubscription: false,
                    error: error
                });
            })
    }

    _tryUpdateCard = (token) => {
        const $this = this;

        return accountService.updateDefaultSubscriptionCard(token.id)
            .then(result => {
                this._isSavingSubscription(false);
                return Promise.resolve(result);
            })
            .catch(error => {
                console.log('Failed to update cred card infos', error);
                $this.setState({
                    error: 'Failed to update cred card infos',
                    isSavingSubscription: false
                });
                return Promise.reject(error);
            })
    }

    _parseAmount = (subscription) => {
        if (!(subscription && subscription.plan && subscription.plan.amount)) return 'Unparsed amount';

        const amountInDollar = parseInt(subscription.plan.amount, 10) / 100;
        return '$' + amountInDollar
    }
}

export default Subscription;