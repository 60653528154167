import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AdminPageHeader from 'admin/components/admin-page-header';
import Breadcrumbs from 'admin/components/breadcrumbs';
import classService from 'services/class-service';

import editIcon from '../../../images/edit-icon.png';

const PlaylistBrowserTable = ({ classSessionPlaylists }) => (
  <table className="table table-responsive-lg">
    <thead>
      <tr>
        <th className="column-30-width">Title</th>
        <th className="column-30-width">Session Count</th>
        <th className="column-30-width">Active</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {classSessionPlaylists.length === 0 && <tr><td>No data.</td></tr>}
      {classSessionPlaylists.map((classPlaylist) => (
        <tr key={`playlist-item-${classPlaylist.id}`}>
          <td className="column-30-width">{classPlaylist.title}</td>
          <td className="column-30-width">{classPlaylist.sessions.length}</td>
          <td className="column-30-width">{classPlaylist.isActive ? 'YES' : 'NO'}</td>
          <td className="text-left">
            <Link
              to={`/admin/class-session/playlists/edit/${classPlaylist.id}`}
              className="primary-edit-button"
            >
              <img src={editIcon} alt="edit" />
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  </table >
);

const PlaylistBrowser = () => {
  const [classSessionPlaylists, setClassSessionsPlaylists] = useState([]);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);

  const fetchClassSessionPlaylists = useCallback(async () => {
    const data = await classService.fetchClassSessionPlaylists();
    setClassSessionsPlaylists(data);
  }, []);

  useEffect(() => {
    fetchClassSessionPlaylists()
      .catch((error) => {
        setApiErrorMessage(error.message || 'Something went wrong.');
      });
  }, []);

  return (
    <div>
      <AdminPageHeader pageTitle="Class Session Playlists">
        <div className="row">
          <div className="col-12">
            <ul className="breadcrumb-nav">
              <Breadcrumbs crumbs={[{ title: 'Class Playlists' }]} />
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h2 className="admin-page-title">Class Session Playlists</h2>
            {apiErrorMessage && <div className="alert alert-danger text-left">{apiErrorMessage}</div>}
          </div>
          <div className="col-6">
            <div className="main-page-header-toolbar text-right">
              <Link to="/admin/class-session/playlists/new" className="btn primary-admin-button">
                <i className="fas fa-plus mr-2" />
                New
              </Link>
            </div>
          </div>
        </div>
      </AdminPageHeader>
      <div className="admin-page-main-content">
        <PlaylistBrowserTable classSessionPlaylists={classSessionPlaylists} />
      </div>
    </div>
  );
};

PlaylistBrowserTable.propTypes = {
  classSessionPlaylists: PropTypes.arrayOf(PropTypes.shape(
    {
      title: PropTypes.string,
      slug: PropTypes.string,
      isActive: PropTypes.bool,
    },
  )),
};

PlaylistBrowserTable.defaultProps = {
  classSessionPlaylists: [],
};

PlaylistBrowser.propTypes = {};

export default PlaylistBrowser;
