import dayjs from 'dayjs';

export const getUserSubscriptionStatus = (userAccount) => {
    if (userAccount && userAccount.subscription) {
        return userAccount.subscription.status;
    }
    return 'inactive';
};

export const validateAndFormatBirthday = (birthday) => {
    const letters = /^[A-Za-z]+$/;
    if (Number.isNaN(new Date(birthday))) {
        return '';
    } if (typeof birthday !== 'object' && birthday.substring(0, 3).match(letters)) {
        return dayjs(new Date(birthday)).format('MMM D, YYYY');
    }
    return birthday;
};
