import React from 'react'

const CameraIcon = ({ width, height, color = '', fill = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 29 14" fill={fill !== '' ? fill : 'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M20.4091 1H3.86364C2.55824 1 1.5 1.89543 1.5 3V11C1.5 12.1046 2.55824 13 3.86364 13H20.4091C21.7145 13 22.7727 12.1046 22.7727 11V3C22.7727 1.89543 21.7145 1 20.4091 1Z" fill={fill !== '' ? fill : 'none'} fillOpacity="0.2"/>
        <path d="M27.5 9V5M3.86364 1H20.4091C21.7145 1 22.7727 1.89543 22.7727 3V11C22.7727 12.1046 21.7145 13 20.4091 13H3.86364C2.55824 13 1.5 12.1046 1.5 11V3C1.5 1.89543 2.55824 1 3.86364 1Z" stroke={color !== '' ? color : '#FCB333'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default CameraIcon;