import React from 'react';

const CloseMenuIcon = ({ width, height, color = '', fill = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width={width} height={height} rx="16" fill={fill !== '' ? fill : '#333333'} />
        <path d="M21 11L11 21M11 11L21 21" stroke={color !== '' ? color : 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default CloseMenuIcon;