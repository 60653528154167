import * as types from './action-types';
import authService from '../services/auth-service';
import modes from '../references/modes';
import config from '../../config/config';

export const authenticate = (values) => (dispatch, getState) => {
  let { email, password } = values || getState().authStore;
  if (!password) { password = getState().authStore.password }

  dispatch({ type: types.AUTH_MODE, mode: modes.FETCHING });
  return authService.authenticate({ username: email.toLowerCase(), password })
    .then((res) => {
      dispatch({ type: types.AUTH_MODE, mode: modes.BROWSE });
      return Promise.resolve();
    })
    .catch((error) => {
      let errorMessage = '';

      switch (error.code) {
        case 'UserNotConfirmedException':
          errorMessage = 'User did not finish confirmation';
          break;
        case 'LimitExceededException':
          errorMessage = 'You tried to many times, account has been blocked';
          break;
        case 'PasswordResetRequiredException':
          errorMessage = 'Your password has been reset';
          break;
        case 'NotAuthorizedException':
          errorMessage = 'Wrong username/password combination';
          break;
        case 'UserNotFoundException':
          errorMessage = 'Username does not exist';
          break;
        case 'NetworkError':
          errorMessage = 'Please check your internet connection';
          break;
        default:
          errorMessage = 'Failed to log in';
          break;
      }
      console.log("AUTH_ERROR", error);
      dispatch({ type: types.AUTH_MODE_ERROR, mode: modes.WITH_ERROR, errorMessage });
      return Promise.reject(errorMessage);
    });
};

export const refreshToken = () => (dispatch, getState) => authService.refreshToken();

export const confirmSignUp = (values = null) => (dispatch, getState) => {
  const { email, code } = values || getState().authStore;

  dispatch({ type: types.AUTH_MODE, mode: modes.FETCHING });
  return authService.confirmSignUp(email.trim().toLowerCase(), code)
    .then((res) => {
      dispatch({ type: types.AUTH_MODE, mode: modes.BROWSE });
      return Promise.resolve();
    })
    .catch((error) => {
      let errorMessage = '';

      switch (error.code) {
        case 'CodeMismatchException':
          errorMessage = 'Wrong code please check email or resend code';
          break;
        case 'UserNotFoundException':
          errorMessage = 'You do not have an account, Please sign up';
          break;
        case 'NotAuthorizedException':
          errorMessage = 'Account has already been confirmed';
          break;
        default:
          errorMessage = 'Failed to activate account';
          break;
      }

      dispatch({ type: types.AUTH_MODE_ERROR, mode: modes.WITH_ERROR, errorMessage });
      return Promise.reject(errorMessage);
    });
};

export const onChange = (event) => {
  if (config.platform === 'web') {
    const value = event.target.name === 'name' ? event.target.value : event.target.value.trim();
    return {
      type: types.AUTH_ON_CHANGE,
      name: event.target.name,
      value: event.target.type === 'checkbox' ? event.target.checked : value,
    };
  }
  const data = event;
  const name = Object.keys(data)[0];
  const value = Object.values(data)[0];
  return {
    type: types.AUTH_ON_CHANGE,
    name,
    value,
  };
};

export const resendSignUpCode = () => (dispatch, getState) => {
  const { email } = getState().authStore;
  return authService.resendCode(email)
    .then(() => dispatch({ type: types.AUTH_MODE, mode: modes.BROWSE }))
    .catch((error) => {
      let errorMessage = '';

      switch (error.code) {
        case 'LimitExceededException':
          errorMessage = 'You have resend the code to many times';
          break;
        case 'UserNotFoundException':
          errorMessage = 'You do not have an account, Please sign up';
          break;
        case 'InvalidParameterException':
          errorMessage = 'User is already confirmed.';
          break;
        default:
          errorMessage = 'Failed to activate account';
          break;
      }

      dispatch({ type: types.AUTH_MODE_ERROR, mode: modes.WITH_ERROR, errorMessage });
    });
};

export const resetErrorMessage = () => ({
  type: types.AUTH_MODE_ERROR_RESET,
  mode: modes.BROWSE,
});

export const resetPassword = (values = null) => (dispatch, getState) => {
  const {
    email, code, password, confirmPassword,
  } = values || getState().authStore;

  if (password !== confirmPassword) {
    const errorMessage = 'Your password and confirmation password do not match';
    dispatch({ type: types.AUTH_MODE_ERROR, mode: modes.WITH_ERROR, errorMessage });
    return Promise.reject();
  }

  dispatch({ type: types.AUTH_MODE, mode: modes.FETCHING });
  return authService.forgotPasswordSubmit(email, code, password)
    .then(() => dispatch({ type: types.AUTH_MODE, mode: modes.BROWSE }))
    .catch((error) => {
      let errorMessage = '';

      switch (error.code) {
        case 'CodeMismatchException':
          errorMessage = 'Invalid code provided, please try again';
          break;
        default:
          errorMessage = 'Invalid code provided, please request a code again';
          break;
      }

      dispatch({ type: types.AUTH_MODE_ERROR, mode: modes.WITH_ERROR, errorMessage });
      return Promise.reject();
    });
};

export const resetPasswordRequest = (values = null) => (dispatch, getState) => {
  const { email } = values || getState().authStore;
  dispatch({ type: types.AUTH_MODE, mode: modes.FETCHING });
  return authService.forgotPasswordRequest(email)
    .then(() => dispatch({ type: types.AUTH_MODE, mode: modes.BROWSE }))
    .catch((error) => {
      let errorMessage = '';

      switch (error.code) {
        case 'UserNotFoundException':
          errorMessage = 'Email does not exist';
          break;
        default:
          errorMessage = 'Failed to send reset password code';
          break;
      }

      dispatch({ type: types.AUTH_MODE_ERROR, mode: modes.WITH_ERROR, errorMessage });
      return Promise.reject();
    });
};
