import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import BarDiagram from 'components/barDiagram';
import UserSessionMetrics from './_user-session-metrics';
import modes from 'references/modes';
import * as analyticsActions from 'actions/analytics-actions';

import PaginatedList from 'widgets/paginated-list/PaginatedList';

class ExerciseHistory extends React.Component {
    state = {
        selectedUserSession: {}
    }

    componentDidMount() {
        this.props.fetchUserSessions().then(() => {
            if(this.props.match.params.sessionId) {
                const selectedUserSession = this.props.exerciseSessions.find((exerciseSession) => exerciseSession.userSessionId == this.props.match.params.sessionId);
                this.setState({ selectedUserSession });
                this.props.history.push(`/my/exercise-history/${selectedUserSession.userSessionId}`);
                return;
            }
            if (this.props.exerciseSessions.length > 0) {
                this.props.history.push(`/my/exercise-history/${this.props.exerciseSessions[0].userSessionId}`);
                this.setState({ selectedUserSession: this.props.exerciseSessions[0] });
            }
        });
        // this.props.fetchUserSessionMetrics(this.props.match.params.sessionId);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.match.params.sessionId === this.props.match.params.sessionId)
            return;
        this.props.fetchUserSessionMetrics(this.props.match.params.sessionId);
        const selectedUserSession = this.props.exerciseSessions.find((exerciseSession) => exerciseSession.userSessionId == this.props.match.params.sessionId);
        this.setState({ selectedUserSession });
    }

    render() {
        const { selectedUserSession } = this.state;
        return (
            <React.Fragment>
                <PaginatedList mode={this.props.mode} userProfileHelper={this.props.userProfileHelper} />
                {this.props.mode !== modes.FETCHING && (
                    <React.Fragment>
                        <Switch>
                            <Route exact path="/my/exercise-history"
                                render={() =>
                                    <BarDiagram
                                        data={this._calculateSessionSummaries()}
                                    />}
                            />
                            <Route path="/my/exercise-history/:sessionId" render={props =>
                                <UserSessionMetrics
                                    {...props}
                                    selectedUserSession={selectedUserSession}
                                    sessionMetrics={this.props.sessionMetrics}
                                    key={this.props.match.params.sessionId}
                                />
                            } />
                            <Route render={() => <span>No Route</span>} />
                        </Switch>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }

    _calculateSessionSummaries = () => {
        const sessions = _.orderBy(this.props.exerciseSessions || [], session => session.createdBy, 'desc')
            .filter((session, index) => index < 12);
        if (!sessions || !sessions.length) return {};

        const data = {
            labels: sessions.map(session => moment.utc(session.createdOn).format('L')),
            datasets: [{
                data: sessions.map(session => session.totalEnergy),
                backgroundColor: 'rgba(28, 160, 145, 1)',
                borderColor: 'rgba(28, 160, 145, 1)'
            }]
        }

        return data;
    }
}

const mapStateToProps = ({ analyticsStore, classStore }) => {
    return {
        ...analyticsStore,
        ...classStore
    };
};

export default connect(mapStateToProps, { ...analyticsActions })(ExerciseHistory);