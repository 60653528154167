import BaseService from './base-service';

class AnalyticsService extends BaseService {

    createUserSession(data) {
        return this._apiPost({
            path: '/analytics/sessions',
            data
        })
    }

    deleteUserSession = (userSessionId) => {
        return this._apiDelete({
            path: `/analytics/sessions/${userSessionId}`
        });
    }

    fetchClassSessions(page, pageSize, orderBy) {
        return this._apiGet({
            path: `/analytics/session-metrics?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}`
        });
    }

    async fetchLeaderboardOrderdByEnergy(classSessionId) {
        const data = await this._apiGet({
            path: `/users/leaderboard/${classSessionId}`,
        });
        const items = (data && data.items) || [];
        const orderedLeaderboard = items.sort((a, b) => (a.totalEnergy < b.totalEnergy ? 1 : -1)).map((score, index) => {
            return {
                rank: index + 1,
                fullName: score.owner.fullName,
                profileId: score.owner.profileId,
                score: score.totalEnergy,
            }
        });
        return orderedLeaderboard;
    }

    fetchUserSessions() {
        return this._apiGet({
            path: '/analytics/sessions'
        });
    }

    fetchMonthlySessions(month = 0) {
        return this._apiGet({
            path: `/analytics/sessions/monthly?month=${month}`
        });
    }

    fetchPaginatedUserSession(page, pageSize) {
        return this._apiGet({
            path: `/analytics/sessions/paginated?page=${page}&pageSize=${pageSize}`
        });
    }

    fetchUserSessionMetrics(userSessionId) {
        return this._apiGet({
            path: `/analytics/sessions/${userSessionId}/metrics`
        });
    }

    saveUserSessionMetrics(userSessionId, data) {
        return this._apiPost({
            path: `/analytics/sessions/${userSessionId}/metrics`,
            data
        });
    }

    saveUserSessionIdleTime(session, idleType) {
        const options = {
            path: `/analytics/sessions/${session.userSessionId}/idle-time`,
            data: {
                idleType
            }
        };

        return this._apiPost(options);
    }

    saveUserSessionIdleResumedTime(session) {
        const options = {
            path: `/analytics/sessions/${session.userSessionId}/idle-resumed`
        };
        return this._apiPost(options);
    }

    /**
     * Calculates `cadence`, `power`, `energy` and `resistance` from bikes data. Bike returns only rpm and resistance
     * and the rest of metrics we will calculate in this method.
     * 
     * Note: Data received from bike sdk { rpm: number, resistance: number }
     */
    calculateAverageValueOfMetrics = (metrics) => {
        let averageMetrics = metrics.reduce((running, current) => {
            return {
                cadence: parseFloat(running.cadence + (current.cadence || 0)),
                distance: parseFloat(running.distance + (current.distance || 0)),
                energy: parseFloat(running.energy + (current.energy || 0)),
                power: parseFloat(running.power + (current.power || 0)),
                resistance: parseFloat(running.resistance + (current.resistance || 0))
            };
        }, { cadence: 0, energy: 0, power: 0, resistance: 0, distance: 0 });

        if (metrics.length === 0) return averageMetrics;

        averageMetrics.cadence = parseFloat(averageMetrics.cadence) / parseFloat(metrics.length);
        averageMetrics.power = parseFloat(averageMetrics.power) / parseFloat(metrics.length);
        averageMetrics.resistance = parseFloat(averageMetrics.resistance) / parseFloat(metrics.length);

        averageMetrics.cadence = !isNaN(averageMetrics.cadence) ? parseFloat(averageMetrics.cadence.toFixed(2)) : averageMetrics.cadence;
        averageMetrics.power = !isNaN(averageMetrics.power) ? parseFloat(averageMetrics.power.toFixed(2)) : averageMetrics.power;
        averageMetrics.resistance = !isNaN(averageMetrics.resistance) ? parseFloat(averageMetrics.resistance.toFixed(2)) : averageMetrics.resistance;

        return averageMetrics;
    }


    /**
     * Calculates `cadence`, `power`, `energy` and `resistance` from bikes data. Bike returns only rpm and resistance
     * and the rest of metrics we will calculate in this method.
     * 
     * Note: Data received from bike sdk { rpm: number, resistance: number }
     */
    calculateAverageValueOfMetricsBT = (metrics) => {
        const totalMetrics = metrics.reduce((running, current) => {
            return {
                cadence: running.cadence + (current.cadence || 0),
                distance: running.distance + (current.distance || 0),
                energy: running.energy + (current.energy || 0),
                power: running.power + (current.power || 0),
                resistance: running.resistance + (current.resistance || 0)
            };
        });

        if (metrics.length === 0) return totalMetrics;

        const averageMetrics = {
            cadence: parseFloat(totalMetrics.cadence) / parseFloat(metrics.length), // AVG cadence
            distance: totalMetrics.distance,
            energy: totalMetrics.energy,
            power: parseFloat(totalMetrics.power) / parseFloat(metrics.length), // AVG power
            resistance: parseFloat(totalMetrics.resistance) / parseFloat(metrics.length), // AVG Resistance
        }
        // averageMetrics.cadence = parseFloat(averageMetrics.cadence) / parseFloat(metrics.length);
        // averageMetrics.power = parseFloat(averageMetrics.power) / parseFloat(metrics.length);
        // averageMetrics.resistance = parseFloat(averageMetrics.resistance) / parseFloat(metrics.length);

        // averageMetrics.cadence = !isNaN(averageMetrics.cadence) ? averageMetrics.cadence.toFixed(2) : averageMetrics.cadence;
        // averageMetrics.power = !isNaN(averageMetrics.power) ? averageMetrics.power.toFixed(2) : averageMetrics.power;
        // averageMetrics.resistance = !isNaN(averageMetrics.resistance) ? averageMetrics.resistance.toFixed(2) : averageMetrics.resistance;
        return averageMetrics;
    }


    /**
     * This method will calculate power, cadence and energy from rpm/cadence and resistance
     */
    calculateMetricParamsFromResistanceAndRMP = (bikesMetricsData) => {
        bikesMetricsData.cadence = parseInt(bikesMetricsData.cadence) || 0;
        bikesMetricsData.resistance = parseInt(bikesMetricsData.resistance) || 0;

        let cadenceValue = Math.abs(bikesMetricsData.rpm) || bikesMetricsData.cadence;
        /**
         * The library uses byte data structure to send/receive data from bike. 
         * This will cause issue when RPM is more than 128 since byte stores data in range (128, -128).
         * For example: if RPM 142 -> -114 (142-128=14) meaning +14 over the limit of 128, thus the result will be -114. 
         * cadence = 128 + (128 - 114) = 142
         */
        if (bikesMetricsData.rpm < 0) {
            cadenceValue = 128 + (128 - Math.abs(bikesMetricsData.rpm));
        }

        const bikesData = { cadence: cadenceValue || bikesMetricsData.cadence, resistance: bikesMetricsData.resistance };
        const calculatedParameters = {};

        calculatedParameters.cadence = parseInt(bikesData.cadence) || 0;
        calculatedParameters.resistance = parseInt(bikesData.resistance);
        calculatedParameters.power = parseInt(11 * (Math.max(0, ((bikesData.cadence - 16) / 14))) * (Math.pow(33, (bikesData.resistance / 100)) * Math.pow(7, (bikesData.cadence - 30) / 100 * bikesData.resistance / 100)));
        calculatedParameters.power = calculatedParameters.power < 0 ? 0 : calculatedParameters.power;
        calculatedParameters.distance = calculatedParameters.power <= 0 ? 0 : (2.1 * (Math.pow((calculatedParameters.power), 0.44) / 3600))
        calculatedParameters.energy = calculatedParameters.power / 1000;

        return calculatedParameters;
    }


    _getDistanceFromPower = (power) => {
        return power <= 0 ? 0 : (2.1 * (Math.pow((power), 0.44) / 3600))
    }

    _getEnergyFromPower = (power) => {
        return power / 1000;
    }

    /**
    * This method will calculate distance and energy from power
     */
    calculateMetricParamsUsingPower = (bikesMetricsData) => {
        bikesMetricsData.cadence = parseInt(bikesMetricsData.cadence) || 0;
        bikesMetricsData.resistance = parseInt(bikesMetricsData.resistance) || 0;
        bikesMetricsData.power = parseInt(bikesMetricsData.power) || 0;
        const bikeData = { cadence: bikesMetricsData.cadence, resistance: bikesMetricsData.resistance, power: bikesMetricsData.power };
        const calculatedParameters = {
            cadence: bikeData.cadence,
            resistance: bikeData.resistance,
            power: bikeData.power,
            distance: this._getDistanceFromPower(bikeData.power),
            energy: this._getEnergyFromPower(bikeData.power)
        };

        return calculatedParameters;
    }
}

export default new AnalyticsService();