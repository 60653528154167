import React from 'react';
import PropTypes from 'prop-types';
import IcWarningIcon from 'images/ic_warning_icon.svg';
import IcCloseIcon from 'images/ic_close.svg';

import {
  WarningImageWrapper as ImageWrapper,
  CancellationTitle as Title,
  HeaderWrapper,
  SubscriptionCancellationWarningIcon,
  SubscriptionCancellationCloseModalIcon,
} from '../elements/SubscriptionCancellationHeaderElements';

const CancellationHeader = ({ dontCancelHandler }) => (
  <HeaderWrapper>
    <SubscriptionCancellationCloseModalIcon
      src={IcCloseIcon}
      onClick={dontCancelHandler}
    />
    <ImageWrapper>
      <SubscriptionCancellationWarningIcon src={IcWarningIcon} />
    </ImageWrapper>
    <Title>Are you sure you want to cancel your Stryde Subscription?</Title>
  </HeaderWrapper>
);

export default CancellationHeader;

CancellationHeader.propTypes = {
  dontCancelHandler: PropTypes.func.isRequired,
};
