import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import modes from '../../../cross-platform/references/modes';
import Subscription from '../../../cross-platform/containers/my/account/subscription';
import {
  STRIPE_SUBSCRIPTION_STATUSES,
  DATE_FORMAT,
} from '../../../cross-platform/references/constants';
import * as userActions from '../../../cross-platform/actions/user-actions';
import userService from '../../../cross-platform/services/user-service';
import {hasWebSubscriptionExpired} from '../../../helpers/subscription-helper';

import { withRouter } from 'react-router-dom';
import SubscribeDialog from 'components/subscribe-dialog';
import CheckCircleIcon from 'components/elements/icons/check-circle-icon';

import SubscriptionCancellationDialog from './subscription-cancellation-dialog-content/SubscriptionCancellationDialogContent';

const ActiveSubscription = (props) => {
  const [isSubscribeModalShown, setIsSubscribeModalShown] = useState(false);
  const hide = () => setIsSubscribeModalShown(false);
  const show = () => setIsSubscribeModalShown(true);

  const Canceled = (
    <React.Fragment>
      <h4>Cancelled Subscription</h4>
      <p className="text-left mb-2 mt-2 stripe-subscription-row-info">
        You have canceled the subscription.
      </p>
      <p className="text-left mb-2 mt-2 stripe-subscription-row-info">
        You will have access to the subscription until
      </p>
      {props.subscription && (
        <>
          <p className="text-left mb-2 mt-2 stripe-subscription-row-info">
            {' '}
            the billing cycle end date:{' '}
            {moment
              .unix(props.subscription.cancel_at)
              .format(DATE_FORMAT)}
          </p>
          <p className="text-left mt-2 stripe-subscription-row-info">
            Subscription status: &nbsp;
            <span className="stripe-subscription-status value">
              {props.subscription.status}
            </span>
          </p>
        </>
      )}
      <>
        <button className="btn btn-main px-6 py-2 mb-3 mt-3" onClick={show}>
          Subscribe
        </button>
        <SubscribeDialog
          title=""
          signOutHelper={() => { }}
          display={isSubscribeModalShown}
          onClose={hide}
          onActionButtonClick={() => { }}
          buttonText={'Subscribe'}
          amount={props._parseAmount(props.subscription)}
          location={props.location}
          history={props.history}
        />
      </>

      {/* <Link to="/my/account/subscription" className="btn btn-main px-4 mt-3">Subscribe</Link> */}
    </React.Fragment>
  );

  const Active = (
    <React.Fragment>
      <div className="subscription-active-wrapper">
        <span>
          <MediaQuery minWidth={769}>
            <CheckCircleIcon width={64} height={64} />
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <CheckCircleIcon width={42} height={42} />
          </MediaQuery>
        </span>
        <h3 className="py-3 py-lg-5">Active Subscription</h3>
        {props.subscription && (
          <>
            <p>
              Next payment:{' '}
              <strong>
                {moment
                  .unix(props.subscription.current_period_end)
                  .format(DATE_FORMAT)}
              </strong>
            </p>
            {(props.subscription.status === STRIPE_SUBSCRIPTION_STATUSES.ACTIVE || props.subscription.status === STRIPE_SUBSCRIPTION_STATUSES.TRIALING) ?
            (
              <button
                className="btn-light-transparent px-5 py-3"
                disabled={props.isCancelingSubscription}
                onClick={props._openCancelSubscriptionDialog}>
                {props.isCancelingSubscription
                  ? 'Canceling Subscription ...'
                  : 'Cancel Subscription'}
              </button>
            ) : (
              <p className="text-center mt-4">
                You have canceled the subscription.
              </p>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );

  switch (props.subscriptionStatus) {
    case STRIPE_SUBSCRIPTION_STATUSES.ACTIVE || STRIPE_SUBSCRIPTION_STATUSES.TRIALING:
      return Active;
    case STRIPE_SUBSCRIPTION_STATUSES.CANCELED:
      return Canceled;
    default:
      return Active;
  }
};

const Subscribe = (props) => {
  const [isSubscribeModalShown, setIsSubscribeModalShown] = useState(false);
  const hide = () => setIsSubscribeModalShown(false);
  const show = () => setIsSubscribeModalShown(true);
  return (
    <React.Fragment>
      <h2>Subscribe for Video</h2>
      <MediaQuery minWidth={768}>
        <p className="mb-1 mt-2">
          With the Stryde Membership, access every live and
        </p>
        <p className="mb-2">on-demand Stryde class across your devices</p>
      </MediaQuery>
      <MediaQuery maxWidth={769}>
        <p>
          With the Stryde Membership, access every live and on-demand Stryde
          class across your devices
        </p>
      </MediaQuery>

      <div className="row d-flex justify-content-center align-items-center mt-3">
        <div className="col-5">
          <h3 className="subscription-price-header">
            {props.userCan.accessSubscriptions
              ? props._parseAmount(props.subscription)
              : '$29.99'}{' '}
            <small>/mo</small>
          </h3>
        </div>
        <div className="col-7">
          {/* <Link to="/my/account/subscription" className="btn btn-main py-3 d-none d-block">Subscription Management</Link> */}
          <button className="btn btn-main px-6 py-2 mb-3" onClick={show}>
            Subscribe
          </button>
        </div>
        <SubscribeDialog
          title=""
          signOutHelper={() => { }}
          display={isSubscribeModalShown}
          onClose={hide}
          onActionButtonClick={() => { }}
          buttonText={'Subscribe'}
          amount={
            props.userCan.accessSubscriptions
              ? props._parseAmount(props.subscription)
              : '$29.99'
          }
          location={props.location}
          history={props.history}
        />
      </div>
    </React.Fragment>
  );
};

const SubscribeCardContent = (props) => {
  return (
    <div className="p-lg-5 p-3 h-100 d-flex flex-column justify-content-center">
      {props.userAccount.mode === modes.FETCHING && (
        <p>Loading subscription data...</p>
      )}
      {props.userAccount.mode !== modes.FETCHING && (
        <Fragment>
          {!props.userAccount.subscription || hasWebSubscriptionExpired(props.userAccount)  ? (
            <Subscribe {...props} />
          ) : (
            <ActiveSubscription {...props} />
          )}
        </Fragment>
      )}
    </div>
  );
};

class SubscribeCard extends Subscription {
  state = {
    showCancelSubscriptionDialog: false,
    showCancellationReasonModal: false,
    selectedCancellationReason: 'na',
  };

  cancellationReasonChanged = (selectedOption) => {
    this.setState({
      selectedCancellationReason: selectedOption,
    });
  };

  render() {
    return (
      <>
        <SubscribeCardContent
          {...this.props}
          {...this.state}
          _parseAmount={this._parseAmount}
          _openCancelSubscriptionDialog={this._openCancelSubscriptionDialog}
        />
        <SubscriptionCancellationDialog
          showModal={this.state.showCancelSubscriptionDialog}
          showCancellationReasonModal={this.state.showCancellationReasonModal}
          dontCancelHandler={this._hideCancelSubscriptionDialog}
          cancelSubscriptionHandler={this.cancelSubscription}
          handleCancellationReasonVisibilityChange={() => {
            this.setState((prevState) => ({
              showCancellationReasonModal:
                !prevState.showCancellationReasonModal,
            }));
          }}
          selectedCancellationReason={this.state.selectedCancellationReason}
          cancellationReasonChanged={this.cancellationReasonChanged}
        />
      </>
    );
  }

  cancelSubscription = async () => {
    const { selectedCancellationReason } = this.state;
    try {
      await this._cancelSubscription();
      if (selectedCancellationReason !== 'na') {
        await userService.saveUserCancellationFeedback(selectedCancellationReason);
        return window.location.reload();
      }
    } catch (error) {
      console.error('Failed to save cancellation feedback:', error);
    }
  };
}

const mapStateToProps = ({ userStore }) => {
  return {
    ...userStore,
  };
};

export default withRouter(
  connect(mapStateToProps, { ...userActions })(SubscribeCard),
);
