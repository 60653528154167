import React from 'react'

const IntensityIcon = ({ width, height, color = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.05556 15.9861H12.0139V21.9444" fill={color !== '' ? color : '#FCB333'} fillOpacity="0.2"/>
        <path d="M21.9444 12.0139H15.9861V6.05556" fill={color !== '' ? color : '#FCB333'} fillOpacity="0.2"/>
        <path d="M27 14C27 21.1797 21.1797 27 14 27C6.8203 27 1 21.1797 1 14C1 6.8203 6.8203 1 14 1C21.1797 1 27 6.8203 27 14Z" fill={color !== '' ? color : '#FCB333'} fillOpacity="0.2"/>
        <path d="M6.05556 15.9861H12.0139M12.0139 15.9861V21.9444M12.0139 15.9861L5.0625 22.9375M21.9444 12.0139H15.9861M15.9861 12.0139V6.05556M15.9861 12.0139L22.9375 5.0625M27 14C27 21.1797 21.1797 27 14 27C6.8203 27 1 21.1797 1 14C1 6.8203 6.8203 1 14 1C21.1797 1 27 6.8203 27 14Z" stroke={color !== '' ? color : '#FCB333'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default IntensityIcon;