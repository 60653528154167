export const AUTH_MODE = 'AUTH_MODE';
export const AUTH_MODE_ERROR = 'AUTH_MODE_ERROR';
export const AUTH_MODE_ERROR_RESET = 'AUTH_MODE_ERROR_RESET';
export const AUTH_ON_CHANGE = 'AUTH_ON_CHANGE';

// Analytics
export const FETCHING_ANALYTICS_SESSIONS = 'FETCHING_ANALYTICS_SESSIONS';
export const ANALYTICS_SESSION_CREATED = 'ANALYTICS_SESSION_CREATED';
export const ANALYTICS_SESSIONS_FETCHED = 'ANALYTICS_SESSIONS_FETCHED';
export const ANALYTICS_SESSIONS_FETCH_FAILED = 'ANALYTICS_SESSIONS_FETCH_FAILED';
export const FETCHING_ANALYTICS_SESSION_METRICS = 'FETCHING_ANALYTICS_SESSION_METRICS';
export const ANALYTICS_SESSION_METRICS_FETCHED = 'ANALYTICS_SESSION_METRICS_FETCHED';

export const SET_CLASS = 'SET_CLASS';
export const SET_CLASSES = 'SET_CLASSES';
export const SET_CLASS_SESSION = 'SET_CLASS_SESSION';
export const SET_CLASS_SESSIONS = 'SET_CLASS_SESSIONS';
export const SET_CLASS_MOST_POPULAR_SESSIONS = 'SET_CLASS_MOST_POPULAR_SESSIONS';
export const SET_FAVORITE_CLASS_SESSION = 'SET_FAVORITE_CLASS_SESSION';
export const SET_FAVORITE_CLASS_SESSION_ERROR = 'SET_FAVORITE_CLASS_SESSION_ERROR';
export const RESET_CLASS_SESSION = 'RESET_CLASS_SESSION';
export const SET_FEATURED_CLASS_SESSION = 'SET_FEATURED_CLASS_SESSION';

export const SET_FILTERED_CLASS_SESSIONS = 'SET_FILTERED_CLASS_SESSIONS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_HOME_CONFIGURATION = 'SET_HOME_CONFIGURATION';
export const SET_LAST_UPDATED_SESSIONS = 'SET_LAST_UPDATED_SESSIONS';
export const SET_CLASS_SESSIONS_REFRESHING = 'SET_CLASS_SESSIONS_REFRESHING';

export const USER_SESSION_MODE = 'USER_SESSION_MODE';
export const SET_USER_SESSIONS = 'SET_USER_SESSIONS';
export const SET_USER_SESSION_SUMMARY = 'SET_USER_SESSION_SUMMARY';

export const START_SIMULATION = 'START_SIMULATION';
export const STOP_SIMULATION = 'STOP_SIMULATION';
export const UPDATE_SIMULATION = 'UPDATE_SIMULATION';
export const RESET_SIMULATION = 'RESET_SIMULATION';
export const SAVED_SIMULATION_METRICS = 'SAVED_SIMULATION_METRICS';

export const SET_USER_SESSION_ID = 'SET_USER_SESSION_ID';
export const RESET_USER_SESSION = 'RESET_USER_SESSION';
export const SET_LEADERBOARD = 'SET_LEADERBOARD';

export const SET_USER_SESSION_LEADERBOARD = 'SET_USER_SESSION_LEADERBOARD';
export const SET_CURRENT_USER_SESSION_LEADERBOARD = 'SET_CURRENT_USER_SESSION_LEADERBOARD';

// User Account
export const USER_ACCOUNT_RETRIEVED = 'USER_ACCOUNT_RETRIEVED';
export const USER_ACCOUNT_RETRIEVAL_FAILED = 'USER_ACCOUNT_RETRIEVAL_FAILED';

// USER PROFILE
export const GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FAILED';
export const SET_CURRENT_USER_RIGHTS = 'SET_CURRENT_USER_RIGHTS';
export const RESET_USER_PROFILE = 'RESET_USER_PROFILE';
export const SELECT_USER_AVATAR = 'SELECT_USER_AVATAR';
export const SELECT_USER_GENDER = 'SELECT_USER_GENDER';
export const CURRENT_USER_PROFILE_RETRIEVED = 'CURRENT_USER_PROFILE_RETRIEVED';
export const SET_USER_PROFILES = 'SET_USER_PROFILES';
export const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE';
export const SET_USER_PROFILE_MODE_ERROR = 'SET_USER_PROFILE_MODE_ERROR';
export const SET_USER_PROFILE_MODE = 'SET_USER_PROFILE_MODE';
export const SET_USER_ACCOUNT_MODE = 'SET_USER_ACCOUNT_MODE';
export const SET_USER_PROFILE_PREV_STATE = 'SET_USER_PROFILE_PREV_STATE';
export const USER_PROFILE_ON_CHANGE = 'USER_PROFILE_ON_CHANGE';
export const USER_PROFILES_FETCHED = 'USER_PROFILES_FETCHED';
export const RESET_SELECTED_PROFILE = 'RESET_SELECTED_PROFILE';
export const UPDATE_SELECTED_PROFILE = 'UPDATE_SELECTED_PROFILE';
export const SET_SELECTED_PROFILE_ID = 'SET_SELECTED_PROFILE_ID';

// Navigation
export const CLOSE_SIGN_OUT_MODAL = 'CLOSE_SIGN_OUT_MODAL';
export const LOGOUT_MODE = 'LOGOUT_MODE';
export const LOGOUT_MODE_ERROR = 'LOGOUT_MODE_ERROR';
export const OPEN_SIGN_OUT_MODAL = 'OPEN_SIGN_OUT_MODAL';
export const OPEN_PROFILE_PAGE = 'OPEN_PROFILE_PAGE';
