import React from 'react';
import { connect } from "react-redux";
import { toast } from 'react-toastify';

import Subscription from '../cross-platform/containers/my/account/subscription';
import * as userActions from '../cross-platform/actions/user-actions';
import accountService from '../cross-platform/services/accountService';
import authService from '../cross-platform/services/auth-service';

import CloseIcon from '../components/elements/icons/close-icon';
import CardForm from '../pages/my/account/_subscription-card-form';
import modes from "../cross-platform/references/modes";
import {hasWebSubscriptionExpired} from "../helpers/subscription-helper";

class SubscribeDialog extends Subscription {
    confirm = () => {
        if (this.props.onActionButtonClick) this.props.onActionButtonClick();
    }

    close = () => {
        if (this.props.onClose) this.props.onClose();
    }

    _tryUpdateCard = (token) => {
        const $this = this;
        const queryParams = new URLSearchParams(this.props.location.search);
        const redirectTo = queryParams.get('redirectTo');

        return accountService.updateDefaultSubscriptionCard(token.id)
            .then((updatedUserAccount) => {
                this.props.setUserAccount(updatedUserAccount);
                toast.success("Successfully updated cred card infos!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                $this.setState({
                    error: '',
                    isSavingSubscription: false
                });
            })
            .then(() => {
                if (redirectTo) return $this.props.history.push(redirectTo);
                $this.props.history.push('/my/account');
            })
            .catch(error => {
                console.log('Failed to update cred card infos', error);
                $this.setState({
                    error: (error && error.message) || 'Failed to update cred card infos',
                    isSavingSubscription: false
                });
            })
    }

    _trySubscribe = (token) => {
        const $this = this;
        const queryParams = new URLSearchParams(this.props.location.search);
        const redirectTo = queryParams.get('redirectTo');
        let tokenLevelExecution = false;
        accountService.createSubscription(token.id)
            .then(userAccount => {
                toast.success("Subscribed successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                $this.props.setUserAccount(userAccount);
                // Refresh user token with subscription changes
                return authService.getCurrentAuthenticatedUser(true)

            })
            .then(() => {
                tokenLevelExecution = true;
                // set new user claim to local storage
                return $this.props.setCurrentUserRights();
            })
            .then(() => {
                if (redirectTo) return $this.props.history.push(redirectTo);
                $this.props.history.push('/my/account');
            })
            .catch(error => {
                if (tokenLevelExecution) {
                    return $this.setState({
                        isSavingSubscription: false,
                        error: { message: 'Subscription was successful but we couldn\'t updated profile. \n please logout and then login to activate subscription.' }
                    })
                }

                $this.setState({ error: error.message, isSavingSubscription: false });
            })
    }

    render = () => {
        return (
            <React.Fragment>
                <div className="modal subscribe-modal-wrapper" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="true" style={{ display: this.props.display && 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content subscribe-dialog-content bg-white w-100 mx-auto">
                            <div className="modal-body px-lg-4 px-3 py-4 py-lg-5">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                                    <CloseIcon width={20} height={20} />
                                </button>
                                <div className="col-12 text-center mb-lg-5 mb-3">
                                    <h3 className="mb-5">Start Riding With <br />Top Studios</h3>
                                    <div className="row no-gutters py-3 px-3 subscribe-middle">
                                        <div className="col-12 col-lg-4 col-md-4 pr-3 subscribe-price-text">
                                            <p>{this.props.amount || '$29.99'}</p>
                                            <span>per month</span>
                                        </div>
                                        <div className="col-12 col-lg-8 col-md-8 subscribe-cancel-text">
                                            <p className="pl-3">Cancel your Membership any time.<br />Tax not included.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 px-0 mb-3">
                                    <div className="row no-gutters">
                                        <div className="col-12 text-center">
                                            {this.props.userAccount.mode !== modes.FETCHING && (!this.props.userAccount.subscription || hasWebSubscriptionExpired(this.props.userAccount))  ?
                                                <CardForm 
                                                    disabledSubmitButton={this.state.isSavingSubscription || this.state.isCancelingSubscription}
                                                    onSubmitPayment={this._trySubscribe}
                                                    isSavingSubscription={this._isSavingSubscription}
                                                    isSaving={this.state.isSavingSubscription}
                                                />
                                                :
                                                <CardForm
                                                    disabledSubmitButton={this.state.isSavingSubscription || this.state.isCancelingSubscription}
                                                    submitButtonText={'Update'}
                                                    onSubmitPayment={this._tryUpdateCard}
                                                    isSavingSubscription={this._isSavingSubscription}
                                                    isSaving={this.state.isSavingSubscription}
                                                />
                                            }
                                            {/* {this.props.onActionButtonClick &&
                                                <CardForm disabledSubmitButton={false}
                                                    onSubmitPayment={this._trySubscribe}
                                                    isSavingSubscription={() => {}}
                                                    isSaving={false}
                                                />
                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.display && <div className="modal-backdrop fade show"></div>}
            </React.Fragment>
        )
    }

}

const mapStateToProps = ({ userStore }) => {
    return {
        ...userStore
    };
};
export default connect(mapStateToProps, { ...userActions })(SubscribeDialog);
